import React, { Component, Fragment } from "react";
import CaptureGroupProductForm from "../../Components/CaptureGroupComponents/CaptureGroupProductForm";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import API from "../../Components/API";

class ViewCaptureGroupProductPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      init: {},
      loading: true
    };
  }

  componentDidMount() {
    API.get(
      `data_management/capture_group_products/${this.props.match.params.id}`
    )
      .then(res => {
        console.log(res)
        this.setState({
          init: res.data,
          loading: false
        });
      })
      .catch(error => {
        if (error) {
          this.setState({
            loading: false
          });
        }
        alert("Oops! something went wrong.");
        console.log(error);
      });
  }

  isEmpty = obj => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  // processData = data => {
  //   // data.mission_name = data.mission.name;
  //   // data.mission_link = data.mission.link;
  //   return data;
  // };

  render() {
    const captureGroupProductId = this.props.match.params.id;
    const urls = {
      "/": "DATA MANAGEMENT",
      "/data_management/capture_group_products": "CAPTURE GROUP PRODUCTS",
      "/view": "VIEW",
      id: captureGroupProductId
    };
    return (
      <Fragment>
        <BreadCrumbComponent data={urls} titlePage="View Capture Group" />
        <CaptureGroupProductForm
          captureGroupProductId={captureGroupProductId}
          initialValues={this.state.init}
          loading={this.state.loading}
        />
      </Fragment>
    );
  }
}

export default ViewCaptureGroupProductPage;
