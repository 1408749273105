import React, { Component } from "react";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";
import { Table, Dropdown, Icon, Menu, Button, Input } from "antd";
import { connect } from "react-redux";
import actionApi from "../MissionsComponents/AcquisitionLogic";
import { extractOrder } from "../CommonFunctions";
import * as actions from "../../Actions";
import Spinner from "../Layout/Spinner";

const redirectLink = (url, id) => {
  return `${url}${id}`;
};

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    render: text => (
      <Link
        to={redirectLink(
          "/missions_management/acquisition_missions/view/",
          text
        )}
      >
        {text}
      </Link>
    ),
    sorter: true
  },
  {
    title: "Section Number",
    dataIndex: "section_number"
    // sorter: true
  },
  {
    title: "Name",
    dataIndex: "name",
    sorter: true
  },
  {
    title: "Command Execution Time",
    dataIndex: "command"
    // sorter: true
  },
  {
    title: "Capture Time",
    dataIndex: "capture_time",
    sorter: true
  },
  {
    title: "Image Count",
    dataIndex: "image_count",
    sorter: true
  },
  {
    title: "Pointing Mode",
    dataIndex: "pointing_mode",
    sorter: true
  },
  {
    title: "Status",
    dataIndex: "status",
    sorter: true
  }
];

class PendingMissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      markSelected: "",
      page: 1,
      count: 0,
      search: "",
      status: "",
      pointSelected: "All",
      loading: true,
      sort: "-id",
      missionList: []
    };
  }

  componentDidMount() {
    this.processFilters();
    this.props.listPointingModes();
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.missions) {
  //     this.setState({ count: 0 });
  //   }
  // }

  componentDidUpdate(prevProps) {
    if (prevProps.missions !== this.props.missions) {
      this.passData(this.props.missions);
    }
    if (prevProps.pointingModes !== this.props.pointingModes) {
      this.setList(this.props.pointingModes);
    }
  }

  passData = missionData => {
    const missions = missionData.data;
    const data = missions.map(mission => ({
      key: `${mission.id}`,
      id: `${mission.id}`,
      section_number: mission.command
        ? `${mission.command.section_number}`
        : null,
      name: `${mission.name}`,
      command: mission.command ? `${mission.command.execution_time}` : null,
      capture_time: `${mission.capture_time}`,
      image_count: `${mission.image_count}`,
      pointing_mode: `${mission.pointing_mode}`,
      status: `${mission.status}`
    }));
    this.setState({ count: 0, missionList: data, loading: false });
  };

  setList(data) {
    if (!this.state.pointingModes) {
      this.setState({ pointingModeList: data });
    }
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  handleActionChange = () => {
    if (this.state.selectedRowKeys && this.state.markSelected) {
      this.setState({
        loading: true
      });
      const call = actionApi(
        this.state.markSelected,
        this.state.selectedRowKeys,
        "missions_management/missions"
      );
      call
        .then(() => {
          this.setState({
            selectedRowKeys: [],
            markSelected: ""
          });
        })
        .then(() => {
          this.processFilters();
          this.setState({ loading: false });
        });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState(
      {
        page: pagination.current,
        loading: true,
        sort: extractOrder(sorter) ? extractOrder(sorter) : "-id"
      },
      () => {
        this.processFilters();
      }
    );
  };

  markMenuClick = e => {
    this.setState({ markSelected: e.key });
  };

  searchHandler = e => {
    this.setState(
      {
        search: e.target.value,
        page: 1,
        loading: true
      },
      () => {
        this.processFilters();
      }
    );
  };

  statusHandler = e => {
    this.setState(
      {
        status: e.target.value,
        page: 1,
        loading: true
      },
      () => {
        this.processFilters();
      }
    );
  };

  processFilters = () => {
    const call = this.props.pendingMissions(
      this.state.search,
      this.state.status,
      this.state.pointSelected,
      this.state.page,
      this.state.sort
    );
    // call.then(() => {
    //   this.setState({ loading: false });
    // });
  };

  render() {
    const markMenu = (
      <Menu onClick={this.markMenuClick}>
        <Menu.Item key="Successful">Successful</Menu.Item>
        <Menu.Item key="Failed">Failed</Menu.Item>
        <Menu.Item key="Cancelled">Cancelled</Menu.Item>
        <Menu.Item key="Pending">Pending</Menu.Item>
      </Menu>
    );

    const onClick = ({ key }) => {
      this.setState(
        {
          pointSelected: key,
          page: 1,
          loading: true
        },
        () => {
          this.processFilters();
        }
      );
    };

    const pointingMenu = (
      <Menu onClick={onClick}>
        <Menu.Item key="All">All</Menu.Item>
        {this.state.pointingModeList
          ? this.state.pointingModeList.map((data, index) => (
              <Menu.Item key={data.name} id="pointSelected">
                {data.name}
              </Menu.Item>
            ))
          : ""}
      </Menu>
    );

    const { selectedRowKeys } = this.state;
    // const a = this.state.pagination ? this.state.pagination : [];
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selections: [
        {
          // key: "all-data",
          // text: "Select All Data",
          // onSelect: () => {
          //   this.setState({
          //     selectedRowKeys: [...Array(a).keys()]
          //   });
          // }
          // key: "all-data",
          // text: "Select All Data",
          // onSelect: () => {
          //   const rows = this.state.data.map(row => row.id);
          //   this.setState({
          //     selectedRowKeys: rows
          //   });
          // }
          // key: "all-data",
          // text: "Select All Data",
          // onSelect: changableRowKeys => {
          //   let newSelectedRowKeys = [];
          //   newSelectedRowKeys = changableRowKeys.filter((key, index) => {
          //     return true;
          //   });
          //   this.setState({ selectedRowKeys: newSelectedRowKeys });
          // }
        }
      ]
    };
    const hasSelected = selectedRowKeys.length > 0;

    if (
      this.state.count === 0 &&
      this.props.missions.data &&
      Array.isArray(this.props.missions.data)
    ) {
      const pagination = this.props.missions.meta.pagination.count;
      return (
        <div>
          <div style={{ marginBottom: "40px" }}>
            <Dropdown.Button
              style={{ paddingRight: "5px" }}
              disabled={!hasSelected}
              overlay={markMenu}
              icon={<Icon type="down" />}
            >
              {this.state.markSelected
                ? `Mark as ${this.state.markSelected}`
                : "Choose an Action"}
            </Dropdown.Button>
            <Button
              type="primary"
              onClick={() => {
                this.handleActionChange();
              }}
              disabled={!hasSelected}
            >
              Go
            </Button>
            <div style={{ float: "right" }}>
              <span style={{ paddingRight: 10 }}>
                POINTING MODE
                <Dropdown overlay={pointingMenu} trigger={["click"]}>
                  <Button
                    type="link"
                    className="ant-dropdown-link"
                    style={{ color: "black" }}
                  >
                    {this.state.pointSelected}
                    <Icon type="down" />
                  </Button>
                </Dropdown>
              </span>
              <span style={{ paddingLeft: 10, paddingRight: 10 }}>
                <Button.Group onClick={this.statusHandler}>
                  <Button type={this.state.status === "" ? "primary" : ""}>
                    All
                  </Button>
                  <Button
                    id="status"
                    value="pending"
                    type={this.state.status === "pending" ? "primary" : ""}
                  >
                    Pending
                  </Button>
                  <Button
                    id="status"
                    value="success"
                    type={this.state.status === "success" ? "primary" : ""}
                  >
                    Success
                  </Button>
                  <Button
                    id="status"
                    value="failed"
                    type={this.state.status === "failed" ? "primary" : ""}
                  >
                    Failed
                  </Button>
                </Button.Group>
              </span>
              <span style={{ paddingLeft: 10 }}>
                <Input
                  style={{ width: 200 }}
                  placeholder="input search text"
                  suffix={<Icon type="search" />}
                  onChange={this.searchHandler}
                  id="search"
                />
              </span>
            </div>
          </div>
          <div style={hasSelected ? { display: true } : { display: "none" }}>
            {`Selected ${selectedRowKeys.length} items out of ${pagination}`}
          </div>
          <Table
            size="middle"
            rowSelection={rowSelection}
            columns={columns}
            dataSource={this.state.missionList}
            pagination={{
              pageSize: 5,
              total: pagination,
              current: this.state.page
            }}
            onChange={this.handleTableChange}
            loading={this.state.loading}
            bordered
          />
        </div>
      );
    }

    return <Spinner />;
  }
}

const mapStateToProps = state => {
  return {
    missions: state.mission.missions,
    pointingModes: state.list.listPointingModes
  };
};

export default connect(mapStateToProps, actions)(PendingMissions);
