import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label
} from "recharts";

export default class DistributionAccessChart extends PureComponent {
  render() {
    return (
      <ResponsiveContainer width={"100%"} height={200}>
        <BarChart
          data={this.props.data}
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Rating" height={50}>
            <Label
              value="Rating"
              offset={10}
              position="insideBottom"
              style={{ textAnchor: "middle", color: "grey" }}
            />
          </XAxis>
          <YAxis>
            <Label
              value="Total Count"
              angle={-90}
              offset={10}
              position="insideLeft"
              style={{ textAnchor: "middle", color: "grey" }}
            />
          </YAxis>
          <Tooltip />
          <Legend />
          <Bar dataKey="Count" fill={this.props.fill} />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
