import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Table,
  Button,
  Dropdown,
  Icon,
  Menu,
  message,
  Row,
  Col,
  Skeleton
} from "antd";
import moment from "moment";
import { grsLocation } from "../CommonFunctions";
import * as actions from "../../Actions";
import "antd/dist/antd.css";

// COMPONENTS
import ShowSinglePass from "./ShowSinglePass";

function grsColor(e) {
  switch (e) {
    case "ASTI":
      return "#7bd1aa";
    case "Tohoku":
      return "#8884d8";
    default:
      return "#7bd1aa";
  }
}

class GRSPasses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selected: "ASTI",
      display: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.satellite !== this.props.satellite) {
      this.props.passesSatellite(
        nextProps.satellite,
        grsLocation(this.state.selected)
      );
      this.setState({ display: false });
    }
  }

  render() {
    const columns = [
      {
        title: "GRS",
        dataIndex: "grs",
        defaultSortOrder: ["ascend", "descend"],
        sorter: (a, b) => a.riseTime - b.riseTime
      },
      {
        title: "Rise Time",
        dataIndex: "riseTime",
        defaultSortOrder: ["ascend", "descend"],
        sorter: (a, b) => a.riseTime > b.riseTime
      },
      {
        title: "Max Altitude Time",
        dataIndex: "altitude",
        defaultSortOrder: ["ascend", "descend"],
        sorter: (a, b) => a.altitude > b.altitude
      },
      {
        title: "Set Time",
        dataIndex: "setTime",
        defaultSortOrder: ["ascend", "descend"],
        sorter: (a, b) => a.setTime > b.setTime
      },
      {
        title: "Duration",
        dataIndex: "duration",
        defaultSortOrder: ["ascend", "descend"],
        sorter: (a, b) => a.duration - b.duration
      }
      // {
      //   title: "",
      //   key: "action",
      //   render: (text, record) => (
      //     <Button
      //       type="link"
      //       icon="more"
      //       style={{ color: "grey" }}
      //       id={record.key}
      //       onClick={() => {
      //         const data = {
      //           rise: record.rise,
      //           set: record.set,
      //           altTime: record.altTime,
      //           alt: record.alt,
      //           riseAzi: record.riseAzi,
      //           setAzi: record.setAzi,
      //           grs: record.grs,
      //           duration: record.duration
      //         };
      //         this.props.trackSatelliteGRS(data);
      //         this.setState({ display: true });
      //       }}
      //     />
      //   )
      // }
    ];

    const passes = this.props.passes.map((info, index) =>
      this.state.data.length < this.props.passes.length
        ? {
            key: index,
            grs: (
              <span>
                {this.state.selected}{" "}
                <i
                  className="fas fa-circle"
                  style={{
                    fontSize: "10px",
                    color: grsColor(this.state.selected)
                  }}
                />
              </span>
            ),
            rise: info.pass.rise_time,
            set: info.pass.set_time,
            altTime: info.pass.max_altitude_time,
            alt: info.pass.max_altitude,
            riseAzi: info.pass.rise_azimuth,
            setAzi: info.pass.set_azimuth,
            riseTime: moment(info.pass.rise_time).format(
              "MMMM DD YYYY, HH:mm:ss"
            ),
            altitude: moment(info.pass.max_altitude_time).format(
              "MMMM DD YYYY, HH:mm:ss"
            ),
            setTime: moment(info.pass.set_time).format(
              "MMMM DD YYYY, HH:mm:ss"
            ),
            duration: Number(info.pass.duration)
          }
        : ""
    );

    const onClick = ({ key }) => {
      message.info(`Selected GRS: ${key}`);
      if (key !== this.state.selected) {
        this.setState({ selected: key, display: false });
        this.props.passesSatellite(this.props.satellite, grsLocation(key));
      }
    };
    const menu = (
      <Menu onClick={onClick}>
        <Menu.Item key="ASTI">ASTI GRS</Menu.Item>
        <Menu.Item key="Tohoku">Tohoku GRS</Menu.Item>
      </Menu>
    );

    return (
      <Row gutter={16} type="flex">
        <Col sm={24} md={15}>
          <div className="panel" style={{ height: "100%" }}>
            <div>
              <Dropdown overlay={menu} trigger={["click"]}>
                <Button
                  type="link"
                  className="ant-dropdown-link"
                  style={{ color: "black" }}
                >
                  {this.state.selected} GRS
                  <Icon type="down" />
                </Button>
              </Dropdown>
              <Table
                style={{ paddingTop: "20px" }}
                columns={columns}
                dataSource={passes}
                size="small"
                onRow={record => {
                  return {
                    onClick: () => {
                      const data = {
                        satellite: this.props.satellite,
                        rise: record.rise,
                        set: record.set,
                        altTime: record.altTime,
                        alt: record.alt,
                        riseAzi: record.riseAzi,
                        setAzi: record.setAzi,
                        grs: record.grs,
                        duration: record.duration
                      };
                      this.props.trackSatelliteGRS(data);
                      this.setState({ display: true });
                    } // click row
                  };
                }}
              />
            </div>
          </div>
        </Col>
        <Col sm={24} md={9}>
          <div className="panel" style={{ height: "100%" }}>
            {this.state.display ? (
              <ShowSinglePass
                grsCoordinates={grsLocation(this.state.selected)}
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    passes: state.passes.passes
  };
};

export default connect(mapStateToProps, actions)(GRSPasses);
