import React, { Component } from "react";
import { Col, Row } from "antd";
import diwataImage from "../../Assets/Images/diwata2.png";
import Satellite from "./Satellite";
import axios from "axios";

export default class Position extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coordinate: [0,0,0]
    };
  }

  fetch = () => {
    axios
      .get(`https://api.orbit-v2.phl-microsat.upd.edu.ph/tle/latest/43678`)
      .then(res => {
        return res.data.data;
      })
      .then(tle => {
        setInterval(() => {
          const res = Satellite(tle, new Date(), 0);
          this.setState({ coordinate: res.coordinates });
        }, 2000);
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.fetch()
  }

  render() {
    return (
      <div
        style={{
          width: "200px",
          height: "75px",
          position: "absolute",
          zIndex: "1000",
          backgroundColor: "white",
          bottom: "90px",
          left: "12.5px",
          padding: "7px 10px 0px 10px",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"
        }}
        >
        <Row gutter={16} type="flex">
          <Col span={10}>
          <img src={diwataImage} alt="logo" height={70} />
          </Col>
          <Col span={14}>
            <Row gutter={16} type="flex">
              <Col span={10}><b>lat:</b></Col>
              <Col>{this.state.coordinate[1].toFixed(2)}</Col>
            </Row>
            <Row gutter={16} type="flex">
              <Col span={10}><b>lon:</b></Col>
              <Col>{this.state.coordinate[0].toFixed(2)}</Col>
            </Row>
            <Row gutter={16} type="flex">
              <Col span={10}><b>elev:</b></Col>
              <Col>{this.state.coordinate[2].toFixed(0)}</Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}