import React, { Component } from "react";
import L from "leaflet";
import { Map, Marker, TileLayer } from "react-leaflet";
import { Button } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import API from "../../Components/API";
import "leaflet/dist/leaflet.css";
// import Spinner from "../../Components/Layout/Spinner";
import markerImage from "../../Assets/Images/marker.png";

const marker = new L.Icon({
  iconUrl: markerImage,
  iconRetinaUrl: markerImage,
  iconSize: new L.Point(30, 30)
});

class DrawerMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      marker: [],
      capture_id: "",
      image: ""
    };
  }

  componentDidMount() {
    this.setState(
      {
        capture_id: this.props.capture_id,
        image: this.props.image
      },
      () => {
        this.fetch();
      }
    );
    if (typeof window !== "undefined") {
      window.addEventListener("storage", this.localStorageUpdated);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.capture_id !== this.props.capture_id) {
      this.passData("capture_id", this.props.capture_id);
    }
    if (prevProps.image !== this.props.image) {
      this.passData("image", this.props.image);
    }
  }

  componentWillUnmount() {
    this.clearData();
  }

  passData(dataType, data) {
    switch (dataType) {
      case "capture_id":
        this.setState(
          {
            capture_id: data
          },
          () => {
            this.clearData();
            this.fetch();
          }
        );
        break;
      case "image":
        this.setState(
          {
            image: data
          },
          () => {
            this.clearData();
            this.fetch();
          }
        );
        break;
      default:
        break;
    }
  }

  clearData = () => {
    localStorage.removeItem("georefID");
    localStorage.removeItem("imageToGeoref");
    localStorage.removeItem("gcpCoordinates");
    localStorage.removeItem("initialCoordinates");
  };

  // fetch gcp points
  fetch = () => {
    this.setState({ loading: true });
    API.get(
      `${process.env.REACT_APP_API_ROOT}/data_management/gcp_files/?name=${this.state.capture_id}`
    )
      .then(res => {
        if (res.data.data.length > 0) {
          this.setState({
            marker: res.data.data[res.data.data.length - 1].json_data
          });
        } else {
          console.log("No map data");
          this.setState({ marker: [] });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  submitGeorefCoors = drawerID => {
    if (localStorage.georefID && localStorage.gcpCoordinates) {
      const gcpsArray = [];
      const gcpCoordinates = JSON.parse(localStorage.getItem("gcpCoordinates"));
      const imageArray = gcpCoordinates.imageCoordinates;
      const mapArray = gcpCoordinates.mapCoordinates;
      const id = localStorage.getItem("georefID");

      if (
        imageArray.length >= 3 &&
        imageArray.length === mapArray.length &&
        drawerID === id
      ) {
        for (let index = 0; index < mapArray.length; index++) {
          const gcp = {
            "mapX": `${mapArray[index][1]}`,
            "mapY": `${mapArray[index][2]}`,
            "pixelX": `${imageArray[index][1]}`,
            "pixelY": `${imageArray[index][2]}`,
            "enable": `${1}`,
            "dX": `${0.1732}`,
            "dY": `${-0.1177}`,
            "residual": `${0.2094}`
          };
          gcpsArray.push(gcp);
        }
        const data = {
          "capture_id": `${id}`,
          "json_string": JSON.stringify(JSON.stringify(gcpsArray))
        };

        const freezeData = JSON.parse(JSON.stringify(data));

        if (freezeData) {
          API.post(`/data_management/gcp_files/`, freezeData)
            .then(res => {
              this.fetch();
              // Clear localStorage
              localStorage.removeItem("gcpCoordinates");
            })
            .catch(err => {
              this.props.openNotification(
                "error",
                "Oops!",
                "Something went wrong!"
              );
              console.log(err);
            });
        }
      }
    } else {
      console.log("No map data to render.");
    }
  };

  localStorageUpdated = () => {
    if (!localStorage.getItem("gcpCoordinates")) {
      console.log("No coordinates");
    } else {
      const gcpCoordinates = JSON.parse(localStorage.getItem("gcpCoordinates"));
      const imageArray = gcpCoordinates.imageCoordinates;
      const mapArray = gcpCoordinates.mapCoordinates;
      const id = localStorage.getItem("georefID");
      if (
        imageArray.length >= 3 &&
        imageArray.length === mapArray.length &&
        this.state.capture_id === id
      ) {
        this.submitGeorefCoors(this.state.capture_id);
      }
    }
  };

  render() {
    return (
      <div>
        <a
          style={{
            float: "right",
            position: "relative",
            zIndex: "1000",
            top: "15px",
            right: "10px",
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)"
          }}
          href="../georeferencer"
          target="_blank"
          onClick={() => {
            localStorage.setItem("georefID", this.state.capture_id);
            localStorage.setItem("imageToGeoref", this.state.image);
            localStorage.removeItem("imageCoordinates");
            localStorage.removeItem("mapCoordinates");
            if (this.state.marker.length > 0) {
              localStorage.setItem(
                "initialCoordinates",
                JSON.stringify(this.state.marker)
              );
            }
          }}
        >
          {this.props.image ? (
            <Button>
              <EnvironmentOutlined style={{ color: "red" }} /> Georeference
            </Button>
          ) : (
            ""
          )}
        </a>
        <Map
          style={{
            height: "400px",
            width: "100%",
            top: `${this.props.image && "-30px"}`
          }}
          zoom={this.state.marker.length > 0 ? 9 : 5}
          center={
            this.state.marker.length > 0
              ? [this.state.marker[0].mapY, this.state.marker[0].mapX]
              : [13, 122]
          }
          scrollWheelZoom={false}
        >
          <TileLayer
            url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {this.state.marker.length > 0
            ? this.state.marker.map((coordinates, index) => {
                return (
                  <Marker
                    key={index}
                    position={[coordinates.mapY, coordinates.mapX]}
                    icon={marker}
                  />
                );
              })
            : ""}
        </Map>
      </div>
    );
    // return (
    //   <div style={{ height: "400px", width: "100%", backgroundColor: "white" }}>
    //     <Spinner />
    //   </div>
    // );
  }
}

export default DrawerMap;
