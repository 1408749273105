import React, { Component, Fragment } from "react";
import {
  Table,
  Button,
  Row,
  Col,
  Card,
  Pagination,
  DatePicker,
  Select,
  Dropdown,
  Menu
} from "antd";
import { DownOutlined } from '@ant-design/icons';
import Media from "react-media";
import queryString from "query-string";
import { connect } from "react-redux";
import moment from "moment";
import * as actions from "../../Actions";
import API from "../../Components/API";
import SearchComponent from "../../Components/MissionsComponents/SearchComponent";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import Drawer from "../../Components/RawFiles/DrawerTelemetry";
import {
  extractOrder,
  toTimeString,
  syncFilters,
  tableIndexStyle,
  convertToUTC,
  reverseUTC
} from "../../Components/CommonFunctions";
import Config from "../../Config/const";
import withToast from "../../Components/Hocs/withToast";
import axios from "axios";

let controllerRef;

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD HH:mm:ss";
const { Option } = Select;

let query = {};

class Telemetry extends Component {
  constructor(props) {
    query = queryString.parse(window.location.search);
    super(props);
    this.state = {
      selectedRowKeys: [],
      pagination: {},
      loading: false,
      telemetryFiles: "",
      relatedImages: [],
      downloadTag: null,
      visible: false,
      currentPage: 1,
      pageSize: 25,
      columns: [
        {
          title: <span className="table-index-number">No</span>,
          dataIndex: "index",
          align: "center",
          render(text) {
            return tableIndexStyle(text);
          }
        },
        {
          title: "Id",
          dataIndex: "id",
          sorter: true
        },
        {
          title: "Name",
          dataIndex: "name",
          sorter: true
        },
        {
          title: "Type",
          dataIndex: "telemetry_type",
          sorter: true
        },
        {
          title: "Receiving Station",
          dataIndex: "receiving_station",
          sorter: true
        },
        {
          title: "Satellite",
          dataIndex: "satellite",
          sorter: true
        },
        {
          title: "Upload Time",
          dataIndex: "date_uploaded",
          sorter: true,
          hideOnMedium: true
        },
        {
          title: "Created Time",
          dataIndex: "date_created",
          sorter: true
        },
        {
          title: "Uploaded By",
          dataIndex: "uploaded_by",
          sorter: true,
          hideOnMedium: true
        },
        {
          title: "",
          dataIndex: "key",
          key: "key",
          align: "center",
          render: key => {
            return (
              <Button
                shape="circle"
                icon="eye"
                onClick={event => {
                  this.showDrawer(key);
                }}
              />
            );
          }
        }
      ],
      // FILTERS
      search: query.search || "",
      telemetry_type: query.telemetry_type || "",
      receiving_station: query.receiving_station || "",
      satellite: query.satellite || "",
      date_created_from: query.date_created_from || "",
      date_created_to: query.date_created_to || "",
      date_uploaded_from: query.date_uploaded_from || "",
      date_uploaded_to: query.date_uploaded_to || "",
      uploaded_by: query.uploaded_by || ""
    };
  }

  componentDidMount() {
    this.fetch();
    this.props.listTelemetryTypes();
    this.props.listReceivingStation();
    this.props.listSatellite();
    this.props.listUsers();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.telemetryTypes !== this.props.telemetryTypes) {
      this.passData("telemetry", this.props.telemetryTypes);
    }
    if (prevProps.receivingStations !== this.props.receivingStations) {
      this.passData("receivingStations", this.props.receivingStations);
    }
    if (prevProps.satellites !== this.props.satellites) {
      this.passData("satellites", this.props.satellites);
    }
    if (prevProps.users !== this.props.users) {
      this.passData("users", this.props.users);
    }
  }

  passData(dataType, data) {
    switch (dataType) {
      case "telemetry":
        if (!this.state.telemetryTypes) {
          this.setState({ telemetryTypes: data });
        }
        break;
      case "receivingStations":
        if (!this.state.receivingStations) {
          this.setState({ receivingStations: data });
        }
        break;
      case "satellites":
        if (!this.state.satellites) {
          this.setState({ satellites: data });
        }
        break;
      case "users":
        if (!this.state.users) {
          this.setState({ users: data });
        }
        break;
      default:
        break;
    }
  }

  fetch = (page = 1, sort = "-date_created") => {
    this.setState({
      loading: true,
      selectedRowKeys: [],
      selectedRows: []
    });

    if (controllerRef) {
      controllerRef.cancel();
    }
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    controllerRef = source;

    API.get(
      `data_management/telemetry_files?format=json&search=${this.state.search}&telemetry_type=${this.state.telemetry_type}&receiving_station=${this.state.receiving_station}&ordering=${sort}&page=${this.state.currentPage}&page_size=${this.state.pageSize}&from:date_uploaded=${this.state.date_uploaded_from}&to:date_uploaded=${this.state.date_uploaded_to}&from:date_created=${this.state.date_created_from}&to:date_created=${this.state.date_created_to}&uploaded_by=${this.state.uploaded_by}&satellite=${this.state.satellite}`, { cancelToken: source.token }
    )
      .then(res => {
        const getTotalPages = parseInt(res ? res.data.pagination.count : 0);
        this.setState({
          loading: false,
          telemetryFiles: res.data,
          total: getTotalPages
        });
        controllerRef = null;
      })
      .catch(function(error) {
        if (error.toString() !== "Cancel") {
          console.log(error);
        }
      });
  };

  formatData = tableData => {
    const missions = [];
    const rawData = tableData ? tableData.data : [];
    rawData.map((val, index) => {
      return missions.push({
        index: `${this.state.pageSize * (this.state.currentPage - 1) +
          1 +
          index}`,
        key: `${val.id}`,
        id: `${val.id}`,
        name: `${val.name.replace(".bin", "")}`,
        telemetry_type: `${val.telemetry_type}`,
        receiving_station: `${val.receiving_station}`,
        satellite: val.satellite ? `${val.satellite.name}` : "",
        date_uploaded: val.date_uploaded
          ? `${toTimeString(val.date_uploaded)}`
          : "",
        date_created: `${toTimeString(val.date_created)}`,
        uploaded_by: `${val.uploaded_by}`,
        file: val.file
      });
    });
    return missions;
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.fetch(pagination.current, extractOrder(sorter));
  };

  handleFilterChange = (filter, data) => {
    // check if filter is a date
    const dateFilter = ["date_uploaded", "date_created"];
    if (dateFilter.includes(filter)) {
      const start = data.length > 0 ? convertToUTC(data[0]) : "";
      const end = data.length > 0 ? convertToUTC(data[1]) : "";

      // add start date to query
      query = queryString.parse(window.location.search);
      syncFilters(query, `${filter}_from`, start);

      // add end date to query
      query = queryString.parse(window.location.search);
      syncFilters(query, `${filter}_to`, end);
    } else {
      // if filter is not a date
      query = queryString.parse(window.location.search);
      syncFilters(query, filter, data);
    }

    switch (filter) {
      case "search":
        this.setState(
          {
            ...this.state,
            currentPage: 1,
            search: data
          },
          () => {
            this.fetch();
          }
        );
        break;
      case "telemetry_type":
        this.setState(
          {
            ...this.state,
            currentPage: 1,
            telemetry_type: data
          },
          () => {
            this.fetch();
          }
        );
        break;
      case "receiving_station":
        this.setState(
          {
            ...this.state,
            currentPage: 1,
            receiving_station: data
          },
          () => {
            this.fetch();
          }
        );
        break;
      case "satellite":
        this.setState(
          {
            ...this.state,
            currentPage: 1,
            satellite: data
          },
          () => {
            this.fetch();
          }
        );
        break;
      case "date_uploaded":
        if (data.length > 0) {
          this.setState(
            {
              ...this.state,
              currentPage: 1,
              date_uploaded_from: convertToUTC(data[0]),
              date_uploaded_to: convertToUTC(data[1])
            },
            () => {
              this.fetch();
            }
          );
        } else {
          this.setState(
            {
              currentPage: 1,
              date_uploaded_from: "",
              date_uploaded_to: ""
            },
            () => {
              this.fetch();
            }
          );
        }
        break;
      case "date_created":
        if (data.length > 0) {
          this.setState(
            {
              ...this.state,
              currentPage: 1,
              date_created_from: convertToUTC(data[0]),
              date_created_to: convertToUTC(data[1])
            },
            () => {
              this.fetch();
            }
          );
        } else {
          this.setState(
            {
              currentPage: 1,
              date_created_from: "",
              date_created_to: ""
            },
            () => {
              this.fetch();
            }
          );
        }
        break;
      case "uploaded_by":
        this.setState(
          {
            ...this.state,
            currentPage: 1,
            uploaded_by: data
          },
          () => {
            this.fetch();
          }
        );
        break;
      default:
        return false;
    }
  };

  handleActionChange = () => {
    if (!this.state.downloadTag) {
      return alert("Please choose a file to download");
    }

    this.state.selectedRows.forEach(rowData => {
      window.open(rowData.file, "_blank");
    });
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys });
    this.setState({ selectedRows });
  };

  enterPressed = event => {
    const keyCode = window.event ? event.which : event.keyCode;
    if (keyCode === 13) {
      this.setState(
        {
          currentPage: 1
        },
        () => {
          this.fetch();
        }
      );
    }
  };

  onPaginationChange = (page = 1, size) => {
    if (page !== this.state.currentPage && page > 0) {
      this.onPageChange(page);
    }

    if (size !== this.state.pageSize) {
      this.onPageSizeChange(size);
    }
  };

  onPageChange(page) {
    this.setState(
      {
        currentPage: page
      },
      () => {
        this.fetch();
      }
    );
  }

  onPageSizeChange(size) {
    this.setState(
      {
        pageSize: size,
        currentPage: 1
      },
      () => {
        this.fetch();
      }
    );
  }

  showDrawer(key) {
    const { data } = this.state.telemetryFiles;
    const rowData = data.find(x => x.id === Number(key));
    if (rowData) {
      API.get(
        `data_management/raw_images?format=json&from_telemetry_file=${rowData.name}`
      )
        .then(res => {
          this.setState({
            visible: true,
            drawerData: rowData,
            relatedImages: res.data.data
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }

  checkId = id => {
    this.showDrawer(id);
    localStorage.removeItem("openTelemetryId");
  };

  render() {
    const urls = {
      "/": "DATA MANAGEMENT",
      "/data_management/telemetry_files": "TELEMETRY"
    };

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true
    };
    const hasSelected = selectedRowKeys.length > 0;

    if (localStorage.openTelemetryId && this.state.telemetryFiles) {
      this.checkId(localStorage.openTelemetryId);
    }
    const computeSize = (size, count) => {
      if (count < size) {
        return count;
      }
      return size;
    };

    const getResponsiveColumns = matches => {
      if (matches.medium) {
        return this.state.columns.filter(column => !column.hideOnMedium);
      }
      if (matches.small) {
        return this.state.columns.filter(
          column => !column.hideOnMedium && !column.hideOnSmall
        );
      }
      return this.state.columns;
    };

    return (
      <div>
        <Drawer
          visible={this.state.visible}
          data={this.state.drawerData}
          relatedImages={this.state.relatedImages}
          closeDrawer={() => {
            this.setState({
              visible: false,
              drawerData: {},
              relatedImages: []
            });
          }}
        />
        <BreadCrumbComponent data={urls} titlePage="Telemetry" />
        <Row>
          <Col>
            <Card>
              <Row gutter={{ md: 24 }}>
                <Col md={3} xs={24}>
                  <span className="label">Type</span>
                  <Select
                    placeholder="All"
                    value={this.state.telemetry_type}
                    onChange={e => {
                      this.handleFilterChange("telemetry_type", e);
                    }}
                    style={{ width: "100%" }}
                  >
                    <Option key={0} value="">
                      All
                    </Option>
                    {this.state.telemetryTypes
                      ? this.state.telemetryTypes.map((data, index) => (
                          <Option key={data.id} value={data.name}>
                            {data.description}
                          </Option>
                        ))
                      : ""}
                  </Select>
                </Col>
                <Col md={4} xs={24}>
                  <span className="label">Receiving Station</span>
                  <Select
                    placeholder="All"
                    value={this.state.receiving_station}
                    onChange={e => {
                      this.handleFilterChange("receiving_station", e);
                    }}
                    style={{ width: "100%" }}
                  >
                    <Option key={0} value="">
                      All
                    </Option>
                    {this.state.receivingStations
                      ? this.state.receivingStations.map((data, index) => (
                          <Option key={data.id} value={data.name}>
                            {data.name}
                          </Option>
                        ))
                      : ""}
                  </Select>
                </Col>
                <Col md={3} xs={24}>
                  <span className="label">Satellite</span>
                  <Select
                    placeholder="All"
                    value={this.state.satellite}
                    onChange={e => {
                      this.handleFilterChange("satellite", e);
                    }}
                    style={{ width: "100%" }}
                  >
                    <Option key={0} value="">
                      All
                    </Option>
                    {this.state.satellites
                      ? this.state.satellites.map(data => (
                          <Option key={data.id} value={data.name}>
                            {data.name}
                          </Option>
                        ))
                      : ""}
                  </Select>
                </Col>
                <Col md={5} xs={24} style={{ textAlign: "left" }}>
                  <span className="label">Upload Time</span>
                  <div>
                    <RangePicker
                      format={dateFormat}
                      showTime={{
                        hideDisabledOptions: true,
                        defaultValue: [
                          moment("00:00:00", "HH:mm:ss"),
                          moment("23:59:59", "HH:mm:ss")
                        ]
                      }}
                      defaultValue={
                        this.state.date_uploaded_from
                          ? [
                              moment(
                                reverseUTC(this.state.date_uploaded_from),
                                dateFormat
                              ),
                              moment(
                                reverseUTC(this.state.date_uploaded_to),
                                dateFormat
                              )
                            ]
                          : null
                      }
                      style={{ width: "100%" }}
                      onChange={e => {
                        this.handleFilterChange("date_uploaded", e);
                      }}
                    />
                  </div>
                </Col>
                <Col md={5} xs={24} style={{ textAlign: "left" }}>
                  <span className="label">Created Time</span>
                  <div>
                    <RangePicker
                      format={dateFormat}
                      showTime={{
                        hideDisabledOptions: true,
                        defaultValue: [
                          moment("00:00:00", "HH:mm:ss"),
                          moment("23:59:59", "HH:mm:ss")
                        ]
                      }}
                      defaultValue={
                        this.state.date_created_from
                          ? [
                              moment(
                                reverseUTC(this.state.date_created_from),
                                dateFormat
                              ),
                              moment(
                                reverseUTC(this.state.date_created_to),
                                dateFormat
                              )
                            ]
                          : null
                      }
                      style={{ width: "100%" }}
                      onChange={e => {
                        this.handleFilterChange("date_created", e);
                      }}
                    />
                  </div>
                </Col>
                <Col md={3} xs={24}>
                  <span className="label">Uploader</span>
                  <Select
                    placeholder="All"
                    value={this.state.uploaded_by}
                    onChange={e => {
                      this.handleFilterChange("uploaded_by", e);
                    }}
                    style={{ width: "100%" }}
                  >
                    <Option key={0} value="">
                      All
                    </Option>
                    {this.state.users
                      ? this.state.users
                          .filter(obj => obj.is_staff)
                          .map((data, index) => (
                            <Option key={index} value={data.username}>
                              {data.username}
                            </Option>
                          ))
                      : ""}
                  </Select>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={24}>
            <Card style={{ marginTop: "20px" }}>
              <Row>
                <Col md={12}>
                  <div className="actionGroup">
                    <Dropdown
                      disabled={!hasSelected}
                      overlay={
                        <Menu
                          onClick={e => {
                              this.setState({ downloadTag: e }, () => this.handleActionChange())
                            }}
                          >
                          {Config.rawFiles.telemetry.map(data => (
                            <Menu.Item key={data.val} value={data.val} name={data.text}>
                              {data.text}
                            </Menu.Item>
                          ))}
                        </Menu>
                      }
                    >
                      <Button>
                        {this.state.downloadTag ? this.state.downloadTag.item.props.name : "Download"} <DownOutlined />
                      </Button>
                    </Dropdown>
                    <span style={{ marginLeft: 8 }}>
                      {hasSelected
                        ? `Selected ${selectedRowKeys.length} out of ${this.state.pageSize}`
                        : ""}
                    </span>
                  </div>
                </Col>
                <Col md={12}>
                  <Row>
                    <Col md={14}>
                      <div
                        style={{
                          float: "right",
                          paddingTop: "22px"
                        }}
                      >
                        {this.state.telemetryFiles
                          ? `${computeSize(
                              this.state.pageSize *
                                (this.state.currentPage - 1) +
                                1,
                              this.state.telemetryFiles.pagination.count
                            )} - ${computeSize(
                              this.state.pageSize * this.state.currentPage,
                              this.state.telemetryFiles.pagination.count
                            )} of ${
                              this.state.telemetryFiles.pagination.count
                            } items`
                          : ""}
                      </div>
                    </Col>
                    <Col md={10}>
                      <div
                        style={{
                          width: "95%",
                          float: "right",
                          paddingTop: "16px",
                          marginBottom: "20px"
                        }}
                      >
                        <SearchComponent
                          name="search"
                          value={this.state.search}
                          onkeypress={e => {
                            this.enterPressed(e);
                          }}
                          onchange={e => {
                            this.setState({
                              search: e.target.value
                            });
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Media
                queries={{
                  xs: "(max-width: 1099px)",
                  small: "(min-width: 1100px) and (max-width: 1199px)",
                  medium: "(min-width: 1200px) and (max-width: 1299px)",
                  large: "(min-width: 1300px)"
                }}
              >
                {matches => (
                  <Fragment>
                    <Table
                      scroll={matches.xs ? { x: "max-content" } : {}}
                      size="middle"
                      rowSelection={rowSelection}
                      columns={getResponsiveColumns(matches)}
                      loading={this.state.loading}
                      dataSource={this.formatData(this.state.telemetryFiles)}
                      pagination={false}
                      onChange={this.handleTableChange}
                      bordered
                    />
                  </Fragment>
                )}
              </Media>
              <Pagination
                style={{ marginTop: "20px", float: "right" }}
                showSizeChanger
                onChange={this.onPaginationChange}
                onShowSizeChange={this.onPaginationChange}
                current={this.state.currentPage}
                total={this.state.total}
                pageSizeOptions={["25", "50", "100"]}
                pageSize={this.state.pageSize}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    telemetryTypes: state.list.listTelemetryTypes,
    receivingStations: state.list.listRecevingStation,
    satellites: state.list.listSatellite,
    users: state.list.listUsers
  };
};

export default connect(mapStateToProps, actions)(withToast(Telemetry));
