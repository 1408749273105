import React, { Fragment } from "react";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import CommandsForm from "../../Components/MissionsComponents/CommandsForm";

export default function AddCommandBatchesPage() {
  const urls = {
    "/": "MISSIONS",
    "/missions_management/commands": "COMMANDS",
    "/add": "ADD"
  };
  return (
    <Fragment>
      <BreadCrumbComponent data={urls} titlePage="Add Command" />
      <CommandsForm
        view="add"
        initialValues={{
          status: "Pending",
          satellite: "Diwata-1"
        }}
      />
    </Fragment>
  );
}
