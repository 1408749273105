import React, { Component, Fragment } from "react";
import { Table, Button, Row, Col, Card, Icon, Pagination } from "antd";
import Media from "react-media";
import { Link } from "react-router-dom";
import queryString from "query-string";
import API from "../../Components/API";
import FilterComponent from "../../Components/MissionsComponents/FilterComponent";
import SearchComponent from "../../Components/MissionsComponents/SearchComponent";
import Config from "../../Config/const";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import {
  extractOrder,
  syncFilters,
  tableIndexStyle
} from "../../Components/CommonFunctions";
import actionApi from "../../Components/MissionsComponents/AcquisitionLogic";
import history from "../../history";
import axios from "axios";

let controllerRef;

const renderIcon = stat => {
  stat = stat === "true" ? 1 : 0;
  const icon = stat ? "check-circle" : "close-circle";
  const iconColor = stat ? "#52c41a" : "#F63A49";
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Icon type={icon} theme="twoTone" twoToneColor={iconColor} />
    </div>
  );
};

const redirectLink = (url, id) => {
  return `${url}${id}`;
};

const columns = [
  {
    title: <span className="table-index-number">NO</span>,
    dataIndex: "index",
    align: "center",
    render(text) {
      return tableIndexStyle(text);
    }
  },
  {
    title: "ID",
    dataIndex: "id",
    render: id => (
      <Link to={redirectLink("/missions_management/command_batches/view/", id)}>
        {id}
      </Link>
    )
  },
  {
    title: "NAME",
    dataIndex: "name",
    sorter: true
  },
  {
    title: "UPLOAD TIME",
    dataIndex: "upload_time",
    sorter: true
  },
  {
    title: "SATELLITE",
    dataIndex: "satellite",
    sorter: true
  },
  {
    title: "STATUS",
    dataIndex: "status",
    sorter: true
  },
  {
    title: "REMARKS",
    dataIndex: "remarks",
    sorter: true,
    hideOnMedium: true
  },
  {
    title: "COMMAND LIST",
    dataIndex: "command_list",
    sorter: true,
    hideOnMedium: true
  }
];

const getResponsiveColumns = matches => {
  if (matches.medium) {
    return columns.filter(column => !column.hideOnMedium);
  }
  if (matches.small) {
    return columns.filter(
      column => !column.hideOnMedium && !column.hideOnSmall
    );
  }
  return columns;
};

const processCommandList = val => {
  const newArr = [];
  val.map(e => {
    newArr.push(e.section_number);
  });
  return newArr.toString();
};

let query = {};
export default class CommandBatchesMissionPage extends Component {
  constructor(props) {
    query = queryString.parse(window.location.search);

    super(props);
    this.state = {
      satellite: query.satellite || "",
      selectedRowKeys: [],
      pagination: {},
      loading: false,
      commandMissions: "",
      keyword: "",
      status: query.status || "",
      currentPage: 1,
      pageSize: 25
    };
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = (page = 1, sort = "-id") => {
    this.setState({ loading: true });
    if (controllerRef) {
      controllerRef.cancel();
    }
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    controllerRef = source;

    API.get(
      `missions_management/command_batches?format=json&satellite=${this.state.satellite}&status=${this.state.status}&ordering=${sort}&search=${this.state.keyword}&page=${this.state.currentPage}&page_size=${this.state.pageSize}`, { cancelToken: source.token }
    )
      .then(res => {
        const getTotalPages = parseInt(res ? res.data.pagination.count : 0);
        this.setState({
          loading: false,
          commandMissions: res.data,
          total: getTotalPages
        });
        controllerRef = null;
      })
      .catch(function(error) {
        if (error.toString() !== "Cancel") {
          console.log(error);
        }
      });
  };

  formatMissions = tableData => {
    const commands = [];
    const rawData = tableData ? tableData.data : [];
    rawData.map((val, index) => {
      commands.push({
        index: `${this.state.pageSize * (this.state.currentPage - 1) +
          1 +
          index}`,
        key: `${val.id}`,
        id: `${val.id}`,
        satellite: `${val.satellite}`,
        command_list: `${processCommandList(val.command_batch_commands_list) ||
          "N/A"}`,
        name: `${val.name}`,
        description: `${val.description}`,
        remarks: `${val.remarks}`,
        status: `${val.status}`,
        upload_time: `${val.upload_time}`
      });
    });
    return commands;
  };

  handleActionChange = () => {
    this.setState({
      loading: true
    });
    const call = actionApi(
      this.state.action,
      this.state.selectedRowKeys,
      "missions_management/command_batches"
    );
    call
      .then(e => {
        this.fetch();
        this.setState({
          selectedRowKeys: []
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loading: false,
          selectedRowKeys: []
        });
      });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.fetch(pagination.current, extractOrder(sorter));
  };

  handleFilterChange = (filter, data) => {
    query = queryString.parse(window.location.search);
    syncFilters(query, filter, data);

    switch (filter) {
      case "status":
        this.setState(
          {
            status: data,
            currentPage: 1
          },
          () => {
            this.fetch();
          }
        );
        break;
      case "pointing":
        this.setState(
          {
            station: data,
            currentPage: 1
          },
          () => {
            this.fetch();
          }
        );
        break;
      case "satellite":
        this.setState(
          {
            satellite: data,
            currentPage: 1
          },
          () => {
            this.fetch();
          }
        );
        break;
      default:
        return false;
    }
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  enterPressed = event => {
    const keyCode = window.event ? event.which : event.keyCode;
    if (keyCode === 13) {
      this.setState(
        {
          currentPage: 1
        },
        () => {
          this.fetch();
        }
      );
    }
  };

  onPaginationChange = (page = 1, size) => {
    if (page !== this.state.currentPage && page > 0) {
      this.onPageChange(page);
    }

    if (size !== this.state.pageSize) {
      this.onPageSizeChange(size);
    }
  };

  onPageChange(page) {
    this.setState(
      {
        currentPage: page
      },
      () => {
        this.fetch();
      }
    );
  }

  onPageSizeChange(size) {
    this.setState(
      {
        pageSize: size,
        currentPage: 1
      },
      () => {
        this.fetch();
      }
    );
  }

  render() {
    const urls = {
      "/": "MISSIONS",
      "/missions_management/command_batches": "COMMAND BATCHES"
    };
    const { selectedRowKeys, uploadMissions } = this.state;
    const a = uploadMissions ? uploadMissions.pagination.count : [];
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selections: [
        {
          key: "all-data",
          text: "Select All Data",
          onSelect: () => {
            this.setState({
              selectedRowKeys: [...Array(a).keys()]
            });
          }
        },
        {
          key: "odd",
          text: "Select Odd Row",
          onSelect: changableRowKeys => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              if (index % 2 !== 0) {
                return false;
              }
              return true;
            });
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          }
        },
        {
          key: "even",
          text: "Select Even Row",
          onSelect: changableRowKeys => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              if (index % 2 !== 0) {
                return true;
              }
              return false;
            });
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          }
        }
      ]
    };
    const hasSelected = selectedRowKeys.length > 0;
    const computeSize = (size, count) => {
      if (count < size) {
        return count;
      }
      return size;
    };
    return (
      <Fragment>
        <BreadCrumbComponent data={urls} titlePage="Command Batches" />
        <Row>
          <Col>
            <Card
              title="Filters"
              extra={
                <Button
                  type="primary"
                  onClick={() =>
                    history.push("/missions_management/command_batches/add")
                  }
                >
                  Add Command Batch <Icon type="plus-circle" />
                </Button>
              }
            >
              <Row gutter={{ md: 24 }}>
                <Col md={6} xs={24}>
                  <span className="label">By Status</span>
                  <FilterComponent
                    value={this.state.status}
                    optionData={Config.mission.acquisition.filterStats}
                    placeholder="Choose a status"
                    handleChange={e => {
                      this.handleFilterChange("status", e);
                    }}
                  />
                </Col>
                <Col md={6} xs={24}>
                  <span className="label">By Satellite</span>
                  <FilterComponent
                    value={this.state.satellite}
                    optionData={Config.mission.acquisition.filterSatellite}
                    placeholder="Choose a Satellite"
                    handleChange={e => {
                      this.handleFilterChange("satellite", e);
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={24}>
            <Card style={{ marginTop: "20px" }}>
              <Row>
                <Col md={12}>
                  <div className="actionGroup">
                    <span className="label">Action:</span>
                    <div style={{ width: "300px", margin: "0 15px" }}>
                      <FilterComponent
                        optionData={Config.mission.acquisition.genericAction}
                        placeholder="Choose an action"
                        handleChange={e => {
                          this.setState({ action: e });
                        }}
                      />
                    </div>
                    <Button
                      type="primary"
                      onClick={() => {
                        this.handleActionChange();
                      }}
                      disabled={!hasSelected}
                      loading={this.state.loading}
                    >
                      Go
                    </Button>
                    <span style={{ marginLeft: 8 }}>
                      {hasSelected
                        ? `Selected ${selectedRowKeys.length} items out of ${this.state.pagination.total}`
                        : ""}
                    </span>
                  </div>
                </Col>
                <Col md={12}>
                  <Row>
                    <Col md={14}>
                      <div
                        style={{
                          float: "right",
                          paddingTop: "22px"
                        }}
                      >
                        {this.state.commandMissions
                          ? `${computeSize(
                              this.state.pageSize *
                                (this.state.currentPage - 1) +
                                1,
                              this.state.commandMissions.pagination.count
                            )} - ${computeSize(
                              this.state.pageSize * this.state.currentPage,
                              this.state.commandMissions.pagination.count
                            )} of ${
                              this.state.commandMissions.pagination.count
                            } items`
                          : ""}
                      </div>
                    </Col>
                    <Col md={10}>
                      <div
                        style={{
                          width: "95%",
                          float: "right",
                          paddingTop: "16px"
                        }}
                      >
                        <SearchComponent
                          name="search"
                          onkeypress={e => {
                            this.enterPressed(e);
                          }}
                          onchange={e => {
                            this.setState({
                              keyword: e.target.value
                            });
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Media
                queries={{
                  xs: "(max-width: 1099px)",
                  small: "(min-width: 1100px) and (max-width: 1199px)",
                  medium: "(min-width: 1200px) and (max-width: 1299px)",
                  large: "(min-width: 1300px)"
                }}
              >
                {matches => (
                  <Fragment>
                    <Table
                      scroll={matches.xs ? { x: "max-content" } : {}}
                      size="middle"
                      rowSelection={rowSelection}
                      columns={getResponsiveColumns(matches)}
                      loading={this.state.loading}
                      dataSource={this.formatMissions(
                        this.state.commandMissions
                      )}
                      pagination={false}
                      onChange={this.handleTableChange}
                      bordered
                    />
                  </Fragment>
                )}
              </Media>
              <Pagination
                style={{ marginTop: "20px", float: "right" }}
                showSizeChanger
                onChange={this.onPaginationChange}
                onShowSizeChange={this.onPaginationChange}
                current={this.state.currentPage}
                total={this.state.total}
                pageSizeOptions={["25", "50", "100"]}
                pageSize={this.state.pageSize}
              />
            </Card>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
