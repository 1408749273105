import axios from "axios";
import moment from "moment";
import API from "../Components/API";
import {
  PASSES_SATELLITE,
  PASS_NEXTGRS,
  TRACK_SATELLITE,
  TRACK_SATELLITE_GRS,
  TRACK_GRSPASS
} from "./types";

function processTimestamp(time) {
  return moment
    .parseZone(time)
    .utc()
    .format()
    .replace(/[&/\\#,+()$~%.'":*?<>{}-]/g, "");
}

function formatTimestamp(time) {
  return moment
    .parseZone(time)
    .utc()
    .format()
    .replace(/:/g, "");
}

export const passesSatellite = (satellite, coordinates) => async dispatch => {
  axios
    .get(
      `${process.env.REACT_APP_API_ORBIT_V2}/v1/passes/${satellite}?lon=${coordinates.long}&lat=${coordinates.lat}&alt=${coordinates.alt}`
    )
    .then(res => {
      dispatch({
        type: PASSES_SATELLITE,
        payload: res.data.data
      });
    })
    .catch(error => {
      console.log(error);
    });
};

export const passNextGRS = (satellite, coordinates) => async dispatch => {
  axios
    .get(
      `${process.env.REACT_APP_API_ORBIT}/v1/passes/${satellite}?lon=${coordinates.long}&lat=${coordinates.lat}&alt=${coordinates.alt}`
    )
    .then(res => {
      dispatch({
        type: PASS_NEXTGRS,
        payload: res.data.data
      });
    })
    .catch(error => {
      console.log(error);
    });
};

// SATELLITE TRACK - 1st component on Mission Dashboard
export const trackSatellite = satellite => async dispatch => {
  if (!satellite) {
    dispatch({
      type: TRACK_SATELLITE,
      payload: {}
    });
  } else {
    const now = new Date();
    const startTime = formatTimestamp(now);
    const end = now.setHours(now.getHours() + 1.5);
    const endTime = formatTimestamp(end);

    API.get(
      `${process.env.REACT_APP_API_ORBIT_V2}/track/${satellite}/${startTime}/${endTime}`
    )
      .then(res => {
        // res.data.data["type"]= "MultiPoint"
        dispatch({
          type: TRACK_SATELLITE,
          payload: res.data.data
        });
      })
      .catch(error => {
        console.log(error);
        dispatch({
          type: TRACK_SATELLITE,
          payload: "Error"
        });
      });
  }
};

// UNDER UPCOMING PASSES
export const trackSatelliteGRS = showData => async dispatch => {
  const start = showData.rise;
  const end = showData.set;
  const startTime = processTimestamp(start);
  const endTime = processTimestamp(end);

  const response = await axios.get(
    `${process.env.REACT_APP_API_ORBIT}/v1/track/${showData.satellite}/${startTime}/${endTime}`
  );
  const passes = await response.data.data.features.map((info, index) => [
    info.geometry.coordinates[1],
    info.geometry.coordinates[0],
    info.properties.elevation,
    info.properties.timestamp,
    { key: index }
  ]);

  const data = [
    {
      rise: showData.rise,
      set: showData.set,
      altTime: showData.altTime,
      alt: showData.alt,
      riseAzi: showData.riseAzi,
      setAzi: showData.setAzi,
      grs: showData.grs,
      duration: showData.duration
    },
    passes
  ];
  dispatch({
    type: TRACK_SATELLITE_GRS,
    payload: data
  });
};

export const trackGRSPass = (satellite, rise, set) => async dispatch => {
  const start = rise;
  const end = set;
  const startTime = processTimestamp(start);
  const endTime = processTimestamp(end);

  const response = await axios.get(
    `${process.env.REACT_APP_API_ORBIT}/v1/track/${satellite}/${startTime}/${endTime}`
  );
  const passes = await response.data.data.features.map((info, index) => [
    info.geometry.coordinates[0],
    info.geometry.coordinates[1],
    info.properties.elevation,
    info.properties.timestamp,
    { key: index }
  ]);

  dispatch({
    type: TRACK_GRSPASS,
    payload: passes
  });
};
