// import { Col, Row } from "antd";
import React, { Component } from "react";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer
} from "recharts";

const labels = [
  { dataKey: "Processable", fill: "#6EB5FF" },
  { dataKey: "Not_Processable", fill: "#7BD1AA" },
  { dataKey: "Unchecked", fill: "#FFC300" },
];

export default class DownloadedBarChart extends Component {
  render() {
    return (
      <div>
        {/* <Row gutter={16} type="flex">
          <Col span={8} style={{ display: "flex" }}>
            Processable
          </Col>
          <Col span={8} style={{ display: "flex" }}>
            Not Processable
          </Col>
          <Col span={8} style={{ display: "flex" }}>
            Unchecked
          </Col>
        </Row> */}
      
        <ResponsiveContainer width="100%" height={250}>
          <ComposedChart
            data={this.props.downloadStats}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="date" />
            <YAxis>
              <Label
                angle={-90}
                value="Number of Images"
                position="insideLeft"
                style={{ textAnchor: "middle" }}
              />
            </YAxis>
            <Tooltip />
            <Legend />
            {labels.map((item, index) => (
              <Bar key={index} dataKey={item.dataKey} fill={item.fill} stackId="a" barSize={20} />
            ))}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
