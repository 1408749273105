// Checks if there is jwt token for all routes

import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Layout } from "antd";
import NavComponent from "./Layout/NavComponent";
import SidebarComponent from "./Layout/SidebarComponent";

const { Content } = Layout;
const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const user = localStorage.getItem("auth");
        if (user) {
          return (
            <Layout style={{ minHeight: "100vh" }}>
              <SidebarComponent />
              <Layout>
                <NavComponent />
                <Content>
                  <div style={{ padding: "36px" }}>
                    <Component {...props} />
                  </div>
                </Content>
              </Layout>
            </Layout>
          );
        }
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

export default withRouter(ProtectedRoute);

ProtectedRoute.propTypes = {
  location: PropTypes.object
};
