import React, { PureComponent } from "react";
import palette from "google-palette";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label
} from "recharts";

export default class DLbyPrimaryApplicationOverTime extends PureComponent {
  render() {
    let labels = [];
    if (this.props.data.length) {
      Object.keys(this.props.data[0]).map(val => {
        if (val !== "Months") {
          labels.push(val);
        }
      });
    }
    let seq = palette("tol-rainbow", labels.length);
    return (
      <ResponsiveContainer width={"100%"} height={400}>
        <BarChart
          data={this.props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Months" height={50}>
            <Label
              value="Months"
              offset={10}
              position="insideBottom"
              style={{ textAnchor: "middle", color: "grey" }}
            />
          </XAxis>
          <YAxis>
            <Label
              angle={-90}
              value="Number of Downloads"
              position="insideLeft"
              style={{ textAnchor: "middle" }}
            />
          </YAxis>
          <Tooltip />
          <Legend margin={{ top: 20 }} />
          {labels.map((val, i) => {
            return (
              <Bar key={i} stackId="1" dataKey={val} fill={`#${seq[i]}`} />
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
