/**
 * Getting the percentage
 * @param {*} percentFor
 * @param {*} percentOf
 */
const getWholePercent = (percentFor, percentOf) => {
  return ((percentFor / percentOf) * 100).toFixed(2);
};

export default getWholePercent;
