import React, { Fragment } from "react";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import CreateTargetForm from "../../Components/TargetsComponents/CreateTargetForm";

const CreateTargetPage = () => {
  const urls = {
    "/": "MISSIONS",
    "/missions_management/targets/": "TARGETS",
    add: "ADD"
  };
  return (
    <Fragment>
      <BreadCrumbComponent data={urls} titlePage="Add Target" />
      <CreateTargetForm view="add" loading={false} />
    </Fragment>
  );
};

export default CreateTargetPage;
