import React, { Component } from "react";
import { Map, TileLayer, GeoJSON } from "react-leaflet";
import "leaflet-draw/dist/leaflet.draw.css";
import L from "leaflet";

class ViewMap extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    if (prevProps.footprint !== this.props.footprint) {
      this.passData(this.props.footprint);
    }
  }

  passData(data) {
    if (!this.state.footprint) {
      this.setState({ footprint: data });
    }
  }

  handleAddedLayer = e => {
    if (e) {
      const bounds = new L.GeoJSON(this.state.footprint).getBounds();
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      if (ne && sw) {
        const mapRef = e.props.leaflet.map;
        const convertedBounds = [
          [ne.lat, ne.lng],
          [sw.lat, sw.lng]
        ];
        mapRef.fitBounds(convertedBounds);
      }
    }
  };

  render() {
    return (
      <Map
        style={{ height: "300px", width: "80%" }}
        center={[12.57513, 122.27081]}
        zoom={4}
        scrollWheelZoom={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {this.state.footprint && (
          <GeoJSON
            data={this.state.footprint}
            color="blue"
            ref={reactFGref => {
              this.handleAddedLayer(reactFGref);
            }}
          />
        )}
      </Map>
    );
  }
}

export default ViewMap;
