/* eslint-disable camelcase */
import React, { Component, Fragment } from "react";
import {
  Table,
  Divider,
  Tag,
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  Drawer,
  Card,
  Button,
  Pagination,
  Dropdown,
  Menu,
  Tooltip
} from "antd";
import { DownloadOutlined, DownOutlined, CaretUpOutlined } from "@ant-design/icons";
import Fullscreen from "react-full-screen";
import Media from "react-media";
import { Link } from "react-router-dom";
import queryString from "query-string";
import _, { isNull } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import moment from "moment";
import API from "../../Components/API";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import actionApi from "../../Components/MissionsComponents/AcquisitionLogic";
import * as actions from "../../Actions";
import {
  toTimeString,
  splitString,
  syncFilters,
  extractOrder,
  tableIndexStyle,
  convertToUTC,
  reverseUTC
} from "../../Components/CommonFunctions";
import Config from "../../Config/const";
import withToast from "../../Components/Hocs/withToast";
import noImage from "../../Assets/Images/noImage.png";
import DrawerMap from "./DrawerMap";
import axios from "axios";

let controllerRef;

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD HH:mm:ss";
const clearText = true;

let query = {};

const redirectLink = (url, id) => {
  return `${url}${id}`;
};

export class RawDataManagement extends Component {
  rowSelection = {
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User",
      name: record.name
    })
  };

  constructor(props) {
    query = queryString.parse(window.location.search);
    super(props);
    this.state = {
      loading: false,
      selectedRows: [],
      selectedRowKeys: [], //
      sort: "-packet_loss",
      downloadTag: null,

      // Filters
      updatedBandFilter: query.band || "",
      updatedMinPacketLoss: query.packet_loss_min || "",
      updatedMaxPacketLoss: query.packet_loss_max || "",
      updatedCaptureIdFilter: query.capture_id || "",
      updatedMissionIdFilter: query.mission_id || "",
      date_created_from: query.date_created_from || "",
      date_created_to: query.date_created_to || "",
      imageTagsFilter: query.image_tags || "",

      // Drawer visibility
      visible: false,

      // List of all capture data
      captures: [],

      // Page size
      pageSize: 25,

      // Current page
      currentPage: 1,

      // Total count of captures
      totalCaptures: 0,

      // Selected item from table
      selectedItem: {},

      // Table definitions
      rawColumns: [
        // Row Numbers
        {
          title: <span className="table-index-number">No</span>,
          dataIndex: "index",
          align: "center",
          render(text) {
            return tableIndexStyle(text);
          }
        },
        // Image
        {
          title: "Image",
          dataIndex: "image",
          key: "image",
          align: "center",
          width: "80px",
          render: image => {
            return image === null ? (
              ""
            ) : (
              <img
                src={image ? image : noImage}
                alt="dataImages"
                height="40px"
                width="50px"
              />
            );
          }
        },
        // Data ID
        {
          title: "Capture ID",
          dataIndex: "capture_id",
          key: "capture_id",
          sorter: true
        },
        // Missions
        {
          title: "Mission",
          dataIndex: "mission",
          key: "mission",
          render: (mission, row) => (
            <Link
              to={redirectLink(
                "/missions_management/acquisition_missions/view/",
                row.mission_id
              )}
            >
              {mission}
            </Link>
          )
        },
        // Tags
        {
          title: "Tags",
          key: "tags",
          dataIndex: "tags",
          width: "300px",
          render: (key, rows) => {
            return (
              <Select
                showSearch
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Tags"
                defaultValue={rows.tags}
                filterOption={(input, option) =>
                  option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onBlur={tags => {
                  this.addTag(tags, rows.capture_id);
                }}
                onDeselect={tag => {
                  this.removeTag(tag, rows.capture_id);
                }}
              >
                {this.state.tags}
              </Select>
            );
          }
        },
        // File extension
        {
          title: "Files",
          dataIndex: "files",
          key: "files",
          align: "center",
          hideOnMedium: true,
          render: files => {
            return <Tag color="green">{files}</Tag>;
          }
        },
        // Packet loss
        {
          title: "Packet Loss",
          dataIndex: "packet_loss",
          key: "packet_loss",
          align: "center",
          sorter: true,
          render: packet_loss => {
            return (
              <span>{packet_loss != null ? `${packet_loss}%` : "N/A"}</span>
            );
          }
        },
        // Date added
        {
          title: "Created Time",
          dataIndex: "created_time",
          key: "created_time",
          hideOnMedium: true,
          sorter: true,
          render: created_time => {
            return toTimeString(created_time);
          }
        },
        // View more details
        {
          title: "",
          dataIndex: "key",
          key: "key",
          align: "center",
          render: key => {
            return (
              <Button
                shape="circle"
                icon="eye"
                // On button click open drawer and show item details.
                onClick={event => {
                  this.getItemDetails(key);
                }}
              />
            );
          }
        },
        {
          title: "",
          dataIndex: "capture_id",
          key: "actions",
          align: "center",
          render: key => {
            return (
              <Tooltip placement="bottom" title="Move to Level 0">
                <Button
                  shape="circle"
                  // On button click move capture to level zero.
                  onClick={() => {
                    this.setState({
                      loading: true
                    });
                    const call = actionApi("toZero", [key], "data_management/captures");
                    call
                      .then(res => {
                        this.getCaptures();
                        // alert("Moved to Level 0");
                      })
                      .catch(err => {
                        console.log(err);
                        this.setState({
                          loading: false,
                          selectedRowKeys: []
                        });
                      });
                  }}
                >
                  <CaretUpOutlined />
                </Button>
              </Tooltip>
            );
          }
        },
        {
          title: "",
          dataIndex: "key",
          key: "keyy",
          align: "center",
          render: (key, rows) => {
            return (
              <Button
                shape="circle"
                onClick={event => {
                  event.preventDefault();

                  this.handleAddToCart([rows]);
                }}
              >
                <FontAwesomeIcon icon={faCartPlus} />
              </Button>
            );
          }
        }
      ],

      // Table data
      rawData: [],

      tags: [], // will update from this.props.imageTagsList

      bands: [
        <Option key="HPT-R">HPT-R</Option>,
        <Option key="HPT-G">HPT-G</Option>,
        <Option key="HPT-B">HPT-B</Option>,
        <Option key="HPT-N">HPT-N</Option>,
        <Option key="SMI-N">SMI-N</Option>,
        <Option key="SMI-V">SMI-V</Option>,
        <Option key="MFC">MFC</Option>,
        <Option key="WFC">WFC</Option>
      ],

      // Drawer tables
      // Table definition for data info table.
      dataInfoColumns: [
        {
          title: "",
          dataIndex: "label",
          key: "label"
        },
        {
          title: "",
          dataIndex: "value",
          key: "value",
          render: value => {
            return value === null ? "" : <span>{value}</span>;
          }
        }
      ],
      // Table definition for file info table.
      fileInfoColumns: [
        {
          title: "",
          dataIndex: "label",
          key: "label"
        },
        {
          title: "",
          dataIndex: "value",
          key: "value",
          render: value => {
            if (value && value !== null) {
              const file = value.props.href;
              const fileSubStrings = splitString(file);
              const subStrings = fileSubStrings.split("?");

              return <a href={value}>{subStrings[0]}</a>;
            }
            return "";
          }
        }
      ],
      // Data info data
      dataInfoData: [],
      // File info data
      fileInfoData: [],
      // Image Fullscreen
      isFull: false
    };
  }

  componentDidMount() {
    this.getCaptures();
    this.props.listImageTags();

    // Clear localStorage for goereferencer
    localStorage.removeItem("georefID");
    localStorage.removeItem("imageToGeoref");
    localStorage.removeItem("gcpCoordinates");
  }

  componentDidUpdate(prevProps) {
    if (prevProps.imageTagsList !== this.props.imageTagsList) {
      this.passData(this.props.imageTagsList);
    }
  }

  passData = data => {
    const tagList = data.map(tag => <Option key={tag.name}>{tag.name}</Option>);
    this.setState({ tags: tagList });
  };

  getCaptures() {
    this.setState({
      loading: true,
      selectedRowKeys: [],
      selectedRows: []
    });

    if (controllerRef) {
      controllerRef.cancel();
    }
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    controllerRef = source;

    API.get(
      `/data_management/captures/?page=${this.state.currentPage}&page_size=${this.state.pageSize}&ordering=${this.state.sort}&processing_level=Raw&format=json&capture_id=${this.state.updatedCaptureIdFilter}&band=${this.state.updatedBandFilter}&from:created_time=${this.state.date_created_from}&to:created_time=${this.state.date_created_to}&from:packet_loss=${this.state.updatedMinPacketLoss}&to:packet_loss=${this.state.updatedMaxPacketLoss}&image_tags=${this.state.imageTagsFilter}&mission_id=${this.state.updatedMissionIdFilter}`, { cancelToken: source.token }
    )
      .then(res => {
        controllerRef = null;
        this.setState({
          captures: res.data.data,
          totalCaptures: res.data.pagination.count
        });

        const updatedTableData = [];
        const captureObj = {};

        this.state.captures.map((data, index) => {
          let newCaptureObj = Object.assign({}, captureObj);

          newCaptureObj = {
            index: `${this.state.pageSize * (this.state.currentPage - 1) +
              1 +
              index}`,
            key: data.id,
            image: data.merged_raw_image ? data.merged_raw_image.thumbnail : "",
            capture_id: data.capture_id,
            mission_id: !isNull(data.mission) ? data.mission.id : "",
            mission: !isNull(data.mission)
              ? `${data.mission.id} - ${data.mission.name}`
              : "",
            tags: data.image_tags,
            files: ".tif",
            packet_loss: data.packet_loss,
            created_time: data.created_time,
            bin: data.merged_raw_image
              ? data.merged_raw_image.merged_rg3_image
              : "",
            file_tif: data.merged_raw_image
              ? data.merged_raw_image.file_tif
              : "",
            file_full_tif: data.merged_raw_image
              ? data.merged_raw_image.file_full_tif
              : "",
            jpg: data.merged_raw_image ? data.merged_raw_image.thumbnail : "",
            json: data.merged_raw_image ? data.merged_raw_image.meta_json : "",
            csv: data.merged_raw_image ? data.merged_raw_image.meta_csv : ""
          };

          updatedTableData.push(newCaptureObj);
          return true;
        });

        updatedTableData.map(data => {
          if (data.packet_loss != null) {
            data.packet_loss = Number(
              `${Math.round(`${data.packet_loss}e${4}`)}e-${4}`
            );
          }
        });

        this.setState({
          rawData: updatedTableData,
          loading: false
        });
      })
      .catch(function(error) {
        if (error.toString() !== "Cancel") {
          console.log(error);
        }
      });
  }

  onClose = () => {
    this.setState({
      visible: false,
      selectedItem: {},
      dataInfoData: [],
      fileInfoData: []
    });
  };

  showDrawer = () => {
    this.setState({
      visible: true
    });
    return true;
  };

  setDataInformation = () => {
    const { selectedItem } = this.state;
    const currentInfoData = [];
    const currentFileInfoData = [];
    // console.log(selectedItem)
    currentInfoData.push({
      key: "tags",
      label: "Tags",
      value: (
        <Select
          showSearch
          mode="tags"
          style={{ width: "100%" }}
          placeholder="Tags"
          defaultValue={selectedItem.image_tags}
          filterOption={(input, option) =>
            option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onSelect={tag => {
            this.addTag([tag], selectedItem.capture_id);
          }}
          onDeselect={tag => {
            this.removeTag(tag, selectedItem.capture_id);
          }}
        >
          {this.state.tags}
        </Select>
      )
    });

    currentInfoData.push({
      key: "1",
      label: "Capture Time",
      value: toTimeString(selectedItem.capture_time)
    });

    currentInfoData.push({
      key: "2",
      label: "Packet Loss",
      value: selectedItem.packet_loss ? `${selectedItem.packet_loss}%` : ""
    });

    currentInfoData.push({
      key: "3",
      label: "Gain",
      value: selectedItem.gain
    });

    currentInfoData.push({
      key: "4",
      label: "Exposure Time",
      value: selectedItem.exposure_time
    });

    currentInfoData.push({
      key: "5",
      label: "Cloud Cover",
      value: selectedItem.cloud_cover
    });

    currentFileInfoData.push({
      key: "1",
      label: "Merged rg3 image",
      value: selectedItem.merged_raw_image ? (
        <a href={selectedItem.merged_raw_image.merged_rg3_image}>
          {splitString(selectedItem.merged_raw_image.merged_rg3_image)}
        </a>
      ) : (
        ""
      )
    });

    currentFileInfoData.push({
      key: "2",
      label: "File tif",
      value: selectedItem.merged_raw_image ? (
        <a href={selectedItem.merged_raw_image.file_tif}>
          {splitString(selectedItem.merged_raw_image.file_tif)}
        </a>
      ) : (
        ""
      )
    });

    currentFileInfoData.push({
      key: "3",
      label: "File full tif",
      value: selectedItem.merged_raw_image ? (
        <a href={selectedItem.merged_raw_image.file_full_tif}>
          {splitString(selectedItem.merged_raw_image.file_full_tif)}
        </a>
      ) : (
        ""
      )
    });

    currentFileInfoData.push({
      key: "4",
      label: "Thumbnail",
      value: selectedItem.merged_raw_image ? (
        <a
          href={selectedItem.merged_raw_image.thumbnail}
          target="_blank"
          rel="noreferrer"
        >
          {splitString(selectedItem.merged_raw_image.thumbnail)}
        </a>
      ) : (
        ""
      )
    });

    currentFileInfoData.push({
      key: "5",
      label: "Meta json",
      value: selectedItem.merged_raw_image ? (
        <a
          href={selectedItem.merged_raw_image.meta_json}
          target="_blank"
          rel="noreferrer"
        >
          {splitString(selectedItem.merged_raw_image.meta_json)}
        </a>
      ) : (
        ""
      )
    });

    currentFileInfoData.push({
      key: "6",
      label: "Meta csv",
      value: selectedItem.merged_raw_image ? (
        <a href={selectedItem.merged_raw_image.meta_csv}>
          {splitString(selectedItem.merged_raw_image.meta_csv)}
        </a>
      ) : (
        ""
      )
    });

    this.setState(
      {
        dataInfoData: currentInfoData,
        fileInfoData: currentFileInfoData
      },
      () => {
        this.showDrawer();
      }
    );
  };

  getItemDetails = key => {
    const capturesId = [];

    this.state.captures.map(data => {
      capturesId.push(data.id);
    });

    const condition = element => element === key;
    const index = capturesId.findIndex(condition);

    this.setState(
      {
        selectedItem: Object.assign(
          this.state.selectedItem,
          this.state.captures[index]
        )
      },
      () => {
        this.setDataInformation();
      }
    );
  };

  // Filter maximum packet loss
  handleMaxPacketLossChange(value) {
    query = queryString.parse(window.location.search);
    syncFilters(query, "packet_loss_max", value);
    this.setState(
      {
        ...this.state,
        currentPage: 1,
        updatedMaxPacketLoss: value
      },
      () => {
        this.getCaptures();
      }
    );
  }

  // Filter minimum packet loss
  handleMinPacketLossChange(value) {
    query = queryString.parse(window.location.search);
    syncFilters(query, "packet_loss_min", value);
    this.setState(
      {
        ...this.state,
        currentPage: 1,
        updatedMinPacketLoss: value
      },
      () => {
        this.getCaptures();
      }
    );
  }

  handleAddToCart = selected => {
    const objCount = Object.keys(selected).length;

    if (objCount > 1) {
      this.props.openNotification(
        "success",
        "Success!",
        `${objCount} items added to cart`
      );
    } else {
      this.props.openNotification(
        "success",
        "Success!",
        `${selected[0].capture_id} added to Cart`
      );
    }

    this.props.addDownload(selected);
    const getCurrentCart = JSON.parse(localStorage.getItem("cart"))
      ? JSON.parse(localStorage.getItem("cart"))
      : [];

    const combinedCart = _.unionBy(getCurrentCart, selected, "key");

    localStorage.setItem("cart", JSON.stringify(combinedCart));
  };

  // Filter date
  handleDateChange(data) {
    if (data.length > 0) {
      this.setState(
        {
          ...this.state,
          currentPage: 1,
          date_created_from: convertToUTC(data[0]),
          date_created_to: convertToUTC(data[1])
        },
        () => {
          this.getCaptures();

          // add start date to query
          query = queryString.parse(window.location.search);
          syncFilters(query, "date_created_from", this.state.date_created_from);

          // add end date to query
          query = queryString.parse(window.location.search);
          syncFilters(query, "date_created_to", this.state.date_created_to);
        }
      );
    } else {
      this.setState(
        {
          currentPage: 1,
          date_created_from: "",
          date_created_to: ""
        },
        () => {
          this.getCaptures();
          // add start date to query
          query = queryString.parse(window.location.search);
          syncFilters(query, "date_created_from", "");

          // add end date to query
          query = queryString.parse(window.location.search);
          syncFilters(query, "date_created_to", "");
        }
      );
    }
  }

  // Filter by capture id
  handleCaptureIdChange(value) {
    query = queryString.parse(window.location.search);
    syncFilters(query, "capture_id", value);
    this.setState(
      {
        ...this.state,
        currentPage: 1,
        updatedCaptureIdFilter: value
      },
      () => {
        this.getCaptures();
      }
    );
  }

  // Filter by mission id
  handleMissionIdChange(value) {
    query = queryString.parse(window.location.search);
    syncFilters(query, "mission_id", value);
    this.setState(
      {
        ...this.state,
        currentPage: 1,
        updatedMissionIdFilter: value
      },
      () => {
        this.getCaptures();
      }
    );
  }

  // Filter bands
  handleBandChange(value) {
    query = queryString.parse(window.location.search);
    syncFilters(query, "band", value.toString());
    this.setState(
      {
        ...this.state,
        currentPage: 1,
        updatedBandFilter: value.toString()
      },
      () => {
        this.getCaptures();
      }
    );
  }

  removeBandChange(value) {
    const inititalList = this.state.updatedBandFilter.split(",");
    const newBandList = inititalList.filter(x => x !== value);

    query = queryString.parse(window.location.search);
    syncFilters(query, "band", newBandList.toString());
    this.setState(
      {
        ...this.state,
        currentPage: 1,
        updatedBandFilter: newBandList.toString()
      },
      () => {
        this.getCaptures();
      }
    );
  }

  // Filter tags
  handleTagChange = value => {
    query = queryString.parse(window.location.search);
    syncFilters(query, "image_tags", value.toString());
    this.setState(
      {
        ...this.state,
        currentPage: 1,
        imageTagsFilter: value.toString()
      },
      () => {
        this.getCaptures();
      }
    );
  };

  removeTagChange = value => {
    const inititalList = this.state.imageTagsFilter.split(",");
    const newTagList = inititalList.filter(x => x !== value);

    query = queryString.parse(window.location.search);
    syncFilters(query, "image_tags", newTagList.toString());
    this.setState(
      {
        ...this.state,
        currentPage: 1,
        imageTagsFilter: newTagList.toString()
      },
      () => {
        this.getCaptures();
      }
    );
  };

  // add/remove tags on each row
  addTag = (tags, id) => {
    if (tags.length > 0) {
      const data = {
        image_tags: tags
      };
      const freezeData = JSON.parse(JSON.stringify(data));
      API.post(`/data_management/captures/${id}/image_tags/`, freezeData)
        .then(res => {
          this.setState({ loading: true });
          const rowItems = [...this.state.rawData];
          const rowIndex = rowItems.findIndex(row => row.capture_id === id);
          const capturesItems = [...this.state.captures];
          const captureIndex = capturesItems.findIndex(
            row => row.capture_id === id
          );
          if (this.state.visible) {
            this.setState({
              rawData: [],
              captures: []
            });
          }

          const newTagList = res.data.image_tags;
          rowItems[rowIndex].tags = newTagList;
          capturesItems[captureIndex].image_tags = newTagList;

          this.setState(
            {
              rawData: rowItems,
              captures: capturesItems
            },
            () => {
              this.setState({ loading: false });
            }
          );
        })
        .catch(err => {
          this.props.openNotification(
            "error",
            "Oops!",
            "Something went wrong!"
          );
          console.log(err);
        });
    }
  };

  removeTag = (tag, id) => {
    const data1 = {
      data: {
        image_tags: [tag]
      }
    };
    const freezeData = JSON.parse(JSON.stringify(data1));
    API.delete(`/data_management/captures/${id}/image_tags/`, freezeData)
      .then(res => {
        this.setState({ loading: true });
        const rowItems = [...this.state.rawData];
        const rowIndex = rowItems.findIndex(row => row.capture_id === id);
        const capturesItems = [...this.state.captures];
        const captureIndex = capturesItems.findIndex(
          row => row.capture_id === id
        );
        if (this.state.visible) {
          this.setState({
            rawData: [],
            captures: []
          });
        }

        const newTagList = res.data.image_tags;
        rowItems[rowIndex].tags = newTagList;
        capturesItems[captureIndex].image_tags = newTagList;

        this.setState(
          {
            rawData: rowItems,
            captures: capturesItems
          },
          () => {
            this.setState({ loading: false });
          }
        );
      })
      .catch(err => {
        this.props.openNotification("error", "Oops!", "Something went wrong!");
        console.log(err);
      });
  };

  onPaginationChange(page = 1, size) {
    if (page !== this.state.currentPage && page > 0) {
      this.onPageChange(page);
    }

    if (size !== this.state.pageSize) {
      this.onPageSizeChange(size);
    }
  }

  onPageChange(page) {
    this.setState(
      {
        currentPage: page
      },
      () => {
        this.getCaptures();
      }
    );
  }

  onPageSizeChange(size) {
    this.setState(
      {
        currentPage: 1,
        pageSize: size
      },
      () => {
        this.getCaptures();
      }
    );
  }

  handleActionChange = () => {
    if (!this.state.downloadTag) {
      return alert("Please choose a file to download");
    }

    this.state.selectedRows.forEach(rowData => {
      window.open(rowData[`${this.state.downloadTag.key}`], "_blank");
    });
  };

  onSelectChange = (keys, selectedRows) => {
    this.setState({ selectedRowKeys: keys });
    this.setState({ selectedRows });
  };

  handleMoveSubmit = e => {
    e.preventDefault();
    this.setState({
      loading: true
    });
    const captures = this.state.selectedRows;
    const captureIds = [];
    for (let i = 0; i < captures.length; i++) {
      captureIds.push(captures[i].capture_id);
    }
    console.log(captureIds)
    const call = actionApi("toZero", captureIds, "data_management/captures");
    call
      .then(res => {
        this.getCaptures();
        alert("Moved to Level 0");
        this.setState({
          loading: false,
          selectedRowKeys: []
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loading: false,
          selectedRowKeys: []
        });
      });
  };

  enterPressed = (event, filterName) => {
    const keyCode = window.event ? event.which : event.keyCode;
    if (keyCode === 13) {
      const { value } = event.target;
      this.setState(
        {
          currentPage: 1
        },
        () => {
          switch (filterName) {
            case "updatedCaptureIdFilter":
              this.handleCaptureIdChange(value);
              break;
            case "updatedMissionIdFilter":
              this.handleMissionIdChange(value);
              break;
            case "updatedMinPacketLoss":
              this.handleMinPacketLossChange(value);
              break;
            case "updatedMaxPacketLoss":
              this.handleMaxPacketLossChange(value);
              break;
            default:
              this.getCaptures();
              break;
          }
        }
      );
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState(
      {
        pagination: pager,
        sort: extractOrder(sorter)
      },
      () => {
        this.getCaptures();
      }
    );
  };

  render() {
    const urls = {
      "/": "DATA MANAGEMENT",
      "/data_management/captures_raw": "RAW"
    };

    const { selectedItem, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };
    const hasSelected = selectedRowKeys.length > 0;

    const computeSize = (size, count) => {
      if (count < size) {
        return count;
      }
      return size;
    };

    const getResponsiveColumns = matches => {
      if (matches.medium) {
        return this.state.rawColumns.filter(column => !column.hideOnMedium);
      }
      if (matches.small) {
        return this.state.rawColumns.filter(
          column => !column.hideOnMedium && !column.hideOnSmall
        );
      }
      return this.state.rawColumns;
    };

    return (
      <div>
        <Drawer
          title={selectedItem.capture_id}
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          width="600"
        >
          <div className="imageContainer">
            <div style={{ textAlign: "center" }}>
              <Fullscreen
                enabled={this.state.isFull}
                onChange={isFull => this.setState({ isFull })}
              >
                <img
                  className={`${this.state.isFull ? `imageFullscreen` : ""}`}
                  src={
                    selectedItem.merged_raw_image
                      ? selectedItem.merged_raw_image.thumbnail
                      : noImage
                  }
                  alt="dataImages"
                  width={!this.state.isFull ? "100%" : ""}
                />
              </Fullscreen>
            </div>
            <div className="fullscreenButton">
              <Button
                icon="fullscreen"
                onClick={() => this.setState({ isFull: true })}
              />
            </div>
          </div>

          <Table
            style={{ marginTop: "20px" }}
            columns={this.state.dataInfoColumns}
            dataSource={this.state.dataInfoData}
            bordered
            pagination={false}
            showHeader={false}
            size="small"
          />

          <Divider style={{ marginTop: "20px" }}>Files</Divider>

          <Table
            loading={this.state.loading}
            style={{ marginTop: "20px" }}
            columns={this.state.fileInfoColumns}
            dataSource={this.state.fileInfoData}
            bordered
            pagination={false}
            showHeader={false}
            size="small"
          />

          <Divider style={{ marginTop: "20px" }}>Georeferencing</Divider>

          <DrawerMap
            capture_id={selectedItem.capture_id}
            image={
              selectedItem.merged_raw_image
                ? selectedItem.merged_raw_image.thumbnail
                : ""
            }
          />
        </Drawer>
        <BreadCrumbComponent data={urls} titlePage="Raw" />
        <Card style={{ borderRadius: "5px" }}>
          <Row gutter={10}>
            <Col span={4} style={{ textAlign: "left" }}>
              <span className="label">Capture ID</span>
              <div>
                <Input
                  defaultValue={this.state.updatedCaptureIdFilter}
                  placeholder="Search By Capture ID"
                  onKeyPress={e => {
                    this.enterPressed(e, "updatedCaptureIdFilter");
                  }}
                  onBlur={e => {
                    this.handleCaptureIdChange(e.target.value);
                  }}
                  onChange={e => {
                    this.setState({
                      updatedCaptureIdFilter: e.target.value
                    });
                  }}
                />
              </div>
            </Col>
            <Col span={4} style={{ textAlign: "left" }}>
              <span className="label">Band</span>
              <Select
                allowClear={clearText}
                defaultValue={
                  this.state.updatedBandFilter
                    ? this.state.updatedBandFilter.split(",")
                    : []
                }
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Select Band"
                onBlur={this.handleBandChange.bind(this)}
                onDeselect={this.removeBandChange.bind(this)}
              >
                {this.state.bands}
              </Select>
            </Col>
            <Col span={4} style={{ textAlign: "left" }}>
              <span className="label">Mission ID</span>
              <div>
                <Input
                  defaultValue={this.state.updatedMissionIdFilter}
                  placeholder="Search By Mission ID"
                  onKeyPress={e => {
                    this.enterPressed(e, "updatedMissionIdFilter");
                  }}
                  onBlur={e => {
                    this.handleMissionIdChange(e.target.value);
                  }}
                  onChange={e => {
                    this.setState({
                      updatedMissionIdFilter: e.target.value
                    });
                  }}
                />
              </div>
            </Col>
            <Col span={4} style={{ textAlign: "left" }}>
              <span className="label">Created Time</span>
              <RangePicker
                format={dateFormat}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss")
                  ]
                }}
                defaultValue={
                  this.state.date_created_from
                    ? [
                        moment(
                          reverseUTC(this.state.date_created_from),
                          dateFormat
                        ),
                        moment(
                          reverseUTC(this.state.date_created_to),
                          dateFormat
                        )
                      ]
                    : null
                }
                style={{ width: "100%" }}
                onChange={this.handleDateChange.bind(this)}
              />
            </Col>
            <Col span={3} style={{ textAlign: "left" }}>
              <span className="label">Packet Loss</span>
              <Row gutter={10}>
                <Col span={12}>
                  <Input
                    defaultValue={this.state.updatedMinPacketLoss}
                    placeholder="Min"
                    onKeyPress={e => {
                      this.enterPressed(e, "updatedMinPacketLoss");
                    }}
                    onBlur={e => {
                      this.handleMinPacketLossChange(e.target.value);
                    }}
                    onChange={e => {
                      this.setState({
                        updatedMinPacketLoss: e.target.value
                      });
                    }}
                  />
                </Col>
                <Col span={12}>
                  <Input
                    defaultValue={this.state.updatedMaxPacketLoss}
                    placeholder="Max"
                    onKeyPress={e => {
                      this.enterPressed(e, "updatedMaxPacketLoss");
                    }}
                    onBlur={e => {
                      this.handleMaxPacketLossChange(e.target.value);
                    }}
                    onChange={e => {
                      this.setState({
                        updatedMaxPacketLoss: e.target.value
                      });
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={5} style={{ textAlign: "left" }}>
              <span className="label">Tags</span>
              <Select
                showSearch
                allowClear={clearText}
                defaultValue={
                  this.state.imageTagsFilter
                    ? this.state.imageTagsFilter.split(",")
                    : []
                }
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Select Tags"
                filterOption={(input, option) =>
                  option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onBlur={this.handleTagChange.bind(this)}
                onDeselect={this.removeTagChange.bind(this)}
              >
                {this.state.tags}
              </Select>
            </Col>
          </Row>
        </Card>

        <Card style={{ borderRadius: "5px", marginTop: "30px" }}>
          <Row>
            <Col span={18}>
              <div className="actionGroup">
                <Button
                  disabled={selectedRowKeys.length ? false : true}
                  type="submit"
                  icon="caret-up"
                  onClick={this.handleMoveSubmit}
                  style={{ marginRight: "10px" }}
                >
                  Move To Level 0
                </Button>
                <Button
                  disabled={selectedRowKeys.length ? false : true}
                  type="submit"
                  onClick={() => {
                    this.handleAddToCart(this.state.selectedRows);
                  }}
                  style={{ marginRight: "10px" }}
                >
                  <FontAwesomeIcon icon={faCartPlus} /> &nbsp; Add to Cart
                </Button>
                <Dropdown
                  disabled={!hasSelected}
                  overlay={
                    <Menu
                      onClick={e => {
                          this.setState({ downloadTag: e }, () => this.handleActionChange())
                        }}
                      >
                      {Config.captures.downloads.map(data => (
                        <Menu.Item key={data.val} value={data.val} name={data.text}>
                          {data.text}
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                >
                  <Button>
                    {this.state.downloadTag ? this.state.downloadTag.item.props.name : "Download"} <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </Col>
            <Col span={6}>
              <div
                style={{
                  float: "right",
                  paddingTop: "22px"
                }}
              >
                {`${computeSize(
                  this.state.pageSize * (this.state.currentPage - 1) + 1,
                  this.state.totalCaptures
                )} - ${computeSize(
                  this.state.pageSize * this.state.currentPage,
                  this.state.totalCaptures
                )} of ${this.state.totalCaptures} items`}
              </div>
            </Col>
          </Row>
          <Media
            queries={{
              xs: "(max-width: 1199px)",
              small: "(min-width: 1200px) and (max-width: 1299px)",
              medium: "(min-width: 1300px) and (max-width: 1399px)",
              large: "(min-width: 1400px)"
            }}
          >
            {matches => (
              <Fragment>
                <Table
                  scroll={matches.xs ? { x: "max-content" } : {}}
                  style={{ marginTop: "20px" }}
                  rowSelection={rowSelection}
                  columns={getResponsiveColumns(matches)}
                  dataSource={this.state.rawData}
                  loading={this.state.loading}
                  pagination={false}
                  bordered
                  size="middle"
                  onChange={this.handleTableChange}
                />
              </Fragment>
            )}
          </Media>
          <Pagination
            style={{ marginTop: "20px", float: "right" }}
            showSizeChanger
            onChange={this.onPaginationChange.bind(this)}
            onShowSizeChange={this.onPaginationChange.bind(this)}
            current={this.state.currentPage}
            total={this.state.totalCaptures}
            pageSizeOptions={["25", "50", "100"]}
            pageSize={this.state.pageSize}
          />
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    imageTagsList: state.list.listImageTags
  };
};

export default connect(mapStateToProps, actions)(withToast(RawDataManagement));
