/* eslint-disable camelcase */
import React, { Component } from "react";
import { Row, Card, Form, Col, Select, Spin, Table } from "antd";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import * as actions from "../../Actions";
import {
  renderSelect,
  renderTextArea,
  renderCheckBox
} from "../MissionsComponents/MissionFormComponents";
import API from "../API/index";
import history from "../../history";
import noImage from "../../Assets/Images/noImage.png";

const { Option } = Select;

class CaptureGroupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: "",
      loadingSubmit: false,
      groupProductColumns: [
        {
          title: "Image",
          dataIndex: "thumbnail",
          key: "thumbnail",
          align: "center"
        },
        {
          title: "Product ID",
          dataIndex: "capture_group_product_id",
          key: "capture_group_product_id"
        },
        {
          title: "Product Type",
          dataIndex: "product_type",
          key: "product_type"
        }
      ],
      dataColumnsImages: [
        {
          title: "Image",
          dataIndex: "label",
          key: "label",
          align: "center"
        },
        {
          title: "Capture ID",
          dataIndex: "value",
          key: "value",
          width: "85%"
        }
      ],
      groupProductData: [],
      capturesData: []
    };
  }

  componentDidMount() {
    this.props.listPayload();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.payloads !== this.props.payloads) {
      this.passData("payloads", this.props.payloads);
    }
    if (prevProps.initialValues !== this.props.initialValues) {
      this.setDataInformation();
    }
  }

  passData(dataType, data) {
    switch (dataType) {
      case "payloads":
        if (!this.state.payloads) {
          this.setState({ payloads: data });
        }
        break;
      default:
        break;
    }
  }

  setDataInformation = () => {
    const { captures, capture_group_products } = this.props.initialValues;
    const capturesArray = [];
    const productsArray = [];

    if (captures && captures.length > 0) {
      captures.map((image, index) => {
        return capturesArray.push({
          key: index,
          label: (
            <img
              src={image.thumbnail ? image.thumbnail : noImage}
              alt="rawImage"
              height="40px"
            />
          ),
          value: image.capture_id
        });
      });
    }

    if (capture_group_products && capture_group_products.length > 0) {
      capture_group_products.map((product, index) => {
        return productsArray.push({
          key: index,
          thumbnail: (
            <img
              src={product.thumbnail ? product.thumbnail : noImage}
              alt="rawImage"
              height="40px"
            />
          ),
          capture_group_product_id: product.capture_group_product_id,
          product_type: product.product_type
        });
      });
    }

    this.setState({
      groupProductData: productsArray,
      capturesData: capturesArray
    });
  };

  processValues = data => {
    const formValues = {
      description: data.description,
      payload: data.payload,
      mission: data.mission_name,
      is_published: data.is_published
    };
    return formValues;
  };

  onSubmit = formValues => {
    const freezeData = JSON.parse(JSON.stringify(formValues));
    this.setState({
      loadingSubmit: true
    });
    API.patch(
      `/data_management/capture_groups/${this.props.captureGroupId}/`,
      this.processValues(freezeData)
    )
      .then(res => {
        this.setState({
          loadingSubmit: false
        });
        alert("Capture Group Edited");
        history.push(
          `/data_management/capture_groups/view/${this.props.captureGroupId}`
        );
      })
      .catch(err => {
        this.setState({
          loadingSubmit: false
        });
        alert("Oops, Something went wrong!");
        console.log(err);
      });
  };

  render() {
    const {
      capture_group_id,
      added_by,
      mission_name
    } = this.props.initialValues;

    return (
      <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Spin spinning={this.props.loading || this.state.loadingSubmit}>
          <Card title="Capture Group Parameters">
            <Row style={{ marginBottom: "20px" }}>
              <Col md={5}>
                <span className="label">Capture Group ID:</span>
              </Col>
              <Col md={10}>{capture_group_id}</Col>
            </Row>
            <Row>
              <Field
                name="description"
                component={renderTextArea}
                label="Description"
              />
            </Row>
            <Row>
              <Field name="payload" component={renderSelect} label="Payload">
                {this.state.payloads
                  ? this.state.payloads.map((data, index) => (
                      <Option key={index} value={data.name}>
                        {data.name}
                      </Option>
                    ))
                  : ""}
              </Field>
            </Row>
            <Row style={{ marginBottom: "20px" }}>
              <Col md={5}>
                <span className="label">Mission:</span>
              </Col>
              <Col md={10}>{mission_name}</Col>
            </Row>
            <Row style={{ marginBottom: "20px" }}>
              <Col md={5}>
                <span className="label">Added by:</span>
              </Col>
              <Col md={10}>{added_by}</Col>
            </Row>
            <Row className="form-row">
              <Field
                name="is_published"
                component={renderCheckBox}
                label="Published"
              />
            </Row>
          </Card>
          <button className="ant-btn ant-btn-primary m-t-10px" type="submit">
            Save
          </button>
        </Spin>
        <Card title="Capture Group Products" style={{ marginTop: "50px" }}>
          <Table
            columns={this.state.groupProductColumns}
            dataSource={this.state.groupProductData}
            bordered
            pagination={false}
            showHeader
          />
        </Card>
        <Card title="Captures" style={{ marginTop: "30px" }}>
          <Table
            columns={this.state.dataColumnsImages}
            dataSource={this.state.capturesData}
            bordered
            pagination={false}
            showHeader
          />
        </Card>
      </Form>
    );
  }
}

const validate = formValues => {
  const errors = {};
  if (!formValues.payload) {
    errors.payload = "You must enter a payload";
  }
  if (!formValues.mission_name) {
    errors.mission_name = "You must enter a mission name";
  }
  return errors;
};

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues, // retrieve name from redux store
  payloads: state.list.listPayload
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "captureGroupForm",
    validate,
    enableReinitialize: true
  })(CaptureGroupForm)
);
