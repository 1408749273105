// import axios from "axios";
import API from "../Components/API";
import { POSITION_SATELLITE } from "./types";

export const positionSatellite = satellite => async dispatch => {
  if (!satellite) {
    dispatch({
      type: POSITION_SATELLITE,
      payload: {}
    });
  } else {
    API.get(`${process.env.REACT_APP_API_ORBIT_V2}/point/${satellite}/`)
      .then(res => {
        // console.log(res.data)
        dispatch({
          type: POSITION_SATELLITE,
          payload: res.data
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
};
