import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  Button,
  Row,
  Col,
  Form,
  Card,
  Icon,
  Modal,
  Pagination
} from "antd";
import Media from "react-media";
import queryString from "query-string";
import { connect } from "react-redux";
import * as actions from "../../Actions";
import API from "../API";
import FilterComponent from "./FilterComponent";
import SearchComponent from "./SearchComponent";
import Config from "../../Config/const";
import actionApi from "./AcquisitionLogic";
import {
  extractOrder,
  toTimeString,
  syncFilters,
  tableIndexStyle
} from "../CommonFunctions";
import history from "../../history";
import withToast from "../Hocs/withToast";
import axios from "axios";

let controllerRef;

const redirectLink = (url, id) => {
  return `${url}${id}`;
};

const columns = [
  {
    title: <span className="table-index-number">NO</span>,
    dataIndex: "index",
    align: "center",
    render(text) {
      return tableIndexStyle(text);
    }
  },
  {
    title: "ID",
    dataIndex: "id",
    render: text => (
      <Link
        to={redirectLink(
          "/missions_management/acquisition_missions/view/",
          text
        )}
      >
        {text}
      </Link>
    )
  },
  {
    title: "TARGET",
    dataIndex: "name",
    sorter: true
  },
  {
    title: "COMMAND EXECUTION TIME",
    dataIndex: "command_execution_time",
    hideOnMedium: true
  },
  {
    title: "SATELLITE",
    dataIndex: "satellite",
    sorter: true
  },
  {
    title: "CAPTURE TIME",
    dataIndex: "capture_time",
    sorter: true,
    hideOnSmall: true
  },
  {
    title: "IMAGE COUNT",
    dataIndex: "image_count",
    sorter: true,
    hideOnMedium: true
  },
  {
    title: "POINTING MODE",
    dataIndex: "pointing_mode",
    sorter: true
  },
  {
    title: "STATUS",
    dataIndex: "status",
    sorter: true
  },
  {
    title: "COMMAND",
    dataIndex: "command",
    sorter: true,
    hideOnSmall: true,
    render: (text, data) => {
      if (text === "N/A") {
        return "N/A";
      }
      return (
        <Link
          to={redirectLink(
            "/missions_management/commands/view/",
            data.commandId
          )}
          target="_blank"
        >
          {text} <Icon type="edit" />
        </Link>
      );
    }
  }
];

const getResponsiveColumns = matches => {
  if (matches.medium) {
    return columns.filter(column => !column.hideOnMedium);
  }
  if (matches.small) {
    return columns.filter(
      column => !column.hideOnMedium && !column.hideOnSmall
    );
  }
  return columns;
};

let query = {};
class AcquisitionTable extends Component {
  constructor(props) {
    query = queryString.parse(window.location.search);
    super(props);
    this.state = {
      selectedRowKeys: [], // Check here to configure the default column,
      pagination: {},
      satellite: query.satellite || "",
      pmode: query.pointing || "",
      status: query.status || "",
      loading: false,
      missionData: "",
      action: "",
      keyword: "",
      currentPage: 1,
      pageSize: 25
    };
  }

  componentDidMount() {
    this.fetch();
    this.props.listSatellite();
    this.props.listPointingModes();
    this.props.listMissionStatusTypes();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.satelliteList !== this.props.satelliteList) {
      this.passData("satelliteList", this.props.satelliteList);
    }
    if (prevProps.pointingModesList !== this.props.pointingModesList) {
      this.passData("pointingModesList", this.props.pointingModesList);
    }
    if (prevProps.missionStatusList !== this.props.missionStatusList) {
      this.passData("missionStatusList", this.props.missionStatusList);
    }
  }

  passData(dataType, data) {
    switch (dataType) {
      case "satelliteList":
        if (!this.state.satelliteList) {
          this.setState({ satelliteList: data });
        }
        break;
      case "pointingModesList":
        if (!this.state.pointingModesList) {
          this.setState({ pointingModesList: data });
        }
        break;
      case "missionStatusList":
        if (!this.state.missionStatusList) {
          let tempArray = [
            { val: "", text: "------" },
            { val: "Delete", text: "Delete Missions" }
          ]
          data.map(val => {
            tempArray.push({
              val: val.name, text: `Mark as ${val.name}`
            })
          })
          this.setState({ missionStatusList: tempArray });
        }
        break;
      default:
        break;
    }
  }

  fetch = (page = 1, sort = "-id") => {
    this.setState({ loading: true });
    if (controllerRef) {
      controllerRef.cancel();
    }
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    controllerRef = source;

    API.get(
      `missions_management/missions?format=json&satellite=${this.state.satellite}&pointing_mode=${this.state.pmode}&status=${this.state.status}&ordering=${sort}&search=${this.state.keyword}&page=${this.state.currentPage}&page_size=${this.state.pageSize}`, { cancelToken: source.token }
    )
      .then(res => {
        const getTotalPages = parseInt(res ? res.data.pagination.count : 0);
        this.setState({
          loading: false,
          missionData: res.data,
          total: getTotalPages
        });
        controllerRef = null;
      })
      .catch(error => {
        if (error.toString() !== "Cancel") {
          console.log(error);
          this.props.openNotification("error", "Oops!", "Something went wrong!");
        }
      });
  };

  formatMissions = tableData => {
    const missions = [];
    const rawData = tableData ? tableData.data : [];
    rawData.map((val, index) => {
      return missions.push({
        index: `${this.state.pageSize * (this.state.currentPage - 1) +
          1 +
          index}`,
        key: `${val.id}`,
        id: `${val.id}`,
        name: `${val.name}`,
        command_execution_time: `${
          val.command ? toTimeString(val.command.execution_time) : "N/A"
        }`,
        satellite: `${val.satellite}`,
        capture_time: `${toTimeString(val.capture_time)}`,
        image_count: `${val.image_count}`,
        pointing_mode: `${val.pointing_mode}`,
        status: `${val.status}`,
        command: `${val.command ? val.command.section_number : "N/A"}`,
        commandId: `${val.command ? val.command.id : "N/A"}`,
        visible: false
      });
    });
    return missions;
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.fetch(pagination.current, extractOrder(sorter));
  };

  handleFilterChange = (filter, data) => {
    query = queryString.parse(window.location.search);
    syncFilters(query, filter, data);

    switch (filter) {
      case "status":
        this.setState(
          {
            ...this.state,
            currentPage: 1,
            status: data
          },
          () => {
            this.fetch();
          }
        );
        break;
      case "pointing":
        this.setState(
          {
            ...this.state,
            currentPage: 1,
            pmode: data
          },
          () => {
            this.fetch();
          }
        );
        break;
      case "satellite":
        this.setState(
          {
            ...this.state,
            currentPage: 1,
            satellite: data
          },
          () => {
            this.fetch();
          }
        );
        break;
      default:
        return false;
    }
  };

  processAction = () => {
    this.setState({
      loading: true
    });
    const call = actionApi(
      this.state.action,
      this.state.selectedRowKeys,
      "missions_management/missions"
    );
    call
      .then(e => {
        this.fetch();
        this.setState({
          selectedRowKeys: []
        });
        this.props.openNotification(
          "success",
          "Success!",
          "Request successful!"
        );
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loading: false,
          selectedRowKeys: []
        });
        this.props.openNotification("error", "Oops!", "Something went wrong!");
      });
  };

  handleActionChange = () => {
    if (this.state.action === "Delete") {
      this.setState({
        loading: true,
        visible: true
      });
    } else {
      this.processAction();
    }
  };

  deleteRows = () => {
    this.setState({ visible: false });
    this.processAction();
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  enterPressed = event => {
    const keyCode = window.event ? event.which : event.keyCode;
    if (keyCode === 13) {
      this.setState(
        {
          currentPage: 1
        },
        () => {
          this.fetch();
        }
      );
    }
  };

  onPaginationChange = (page = 1, size) => {
    if (page !== this.state.currentPage && page > 0) {
      this.onPageChange(page);
    }

    if (size !== this.state.pageSize) {
      this.onPageSizeChange(size);
    }
  };

  onPageChange(page) {
    this.setState(
      {
        currentPage: page
      },
      () => {
        this.fetch();
      }
    );
  }

  onPageSizeChange(size) {
    this.setState(
      {
        pageSize: size,
        currentPage: 1
      },
      () => {
        this.fetch();
      }
    );
  }

  render() {
    const { selectedRowKeys, missionData } = this.state;
    const a = missionData ? missionData.pagination.count : [];
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selections: [
        {
          key: "all-data",
          text: "Select All Data",
          onSelect: () => {
            this.setState({
              selectedRowKeys: [...Array(a).keys()]
            });
          }
        },
        {
          key: "odd",
          text: "Select Odd Row",
          onSelect: changableRowKeys => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              if (index % 2 !== 0) {
                return false;
              }
              return true;
            });
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          }
        },
        {
          key: "even",
          text: "Select Even Row",
          onSelect: changableRowKeys => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              if (index % 2 !== 0) {
                return true;
              }
              return false;
            });
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          }
        }
      ]
    };
    const hasSelected = selectedRowKeys.length > 0;
    const computeSize = (size, count) => {
      if (count < size) {
        return count;
      }
      return size;
    };
    return (
      <div>
        <Form className="ant-advanced-search-form">
          <Row>
            <Col>
              <Card
                title="Filters"
                extra={
                  <Button
                    type="primary"
                    onClick={() =>
                      history.push(
                        "/missions_management/acquisition_missions/add"
                      )
                    }
                  >
                    Add Missions <Icon type="plus-circle" />
                  </Button>
                }
              >
                <Row gutter={{ md: 24 }}>
                  <Col md={6} xs={24}>
                    <span className="label">By Status</span>
                    <FilterComponent
                      value={this.state.status}
                      optionData={
                        this.props.missionStatusList ?
                        this.props.missionStatusList :
                        Config.mission.acquisition.filterStats
                      }
                      placeholder="Choose a status"
                      handleChange={e => {
                        this.handleFilterChange("status", e);
                      }}
                      source="api"
                    />
                  </Col>
                  <Col md={6} xs={24}>
                    <span className="label">By Pointing Mode</span>
                    <FilterComponent
                      value={this.state.pmode}
                      optionData={
                        this.state.pointingModesList
                          ? this.state.pointingModesList
                          : []
                      }
                      placeholder="Choose a Pointing Mode"
                      handleChange={e => {
                        this.handleFilterChange("pointing", e);
                      }}
                      source="api"
                    />
                  </Col>
                  <Col md={6} xs={24}>
                    <span className="label">By Satellite</span>
                    <FilterComponent
                      value={this.state.satellite}
                      optionData={
                        this.state.satelliteList ? this.state.satelliteList : []
                      }
                      placeholder="Choose a Satellite"
                      handleChange={e => {
                        this.handleFilterChange("satellite", e);
                      }}
                      source="api"
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>

        <Row>
          <Col md={24}>
            <Card style={{ marginTop: "20px" }}>
              <Row>
                <Col md={12}>
                  <div className="actionGroup">
                    <span className="label">Action:</span>
                    <div style={{ width: "300px", margin: "0 15px" }}>
                      <FilterComponent
                        optionData={
                          this.state.missionStatusList ? this.state.missionStatusList : []
                        }
                        placeholder="Choose an action"
                        handleChange={e => {
                          this.setState({ action: e });
                        }}
                      />
                    </div>
                    <Button
                      type="primary"
                      onClick={() => {
                        this.handleActionChange();
                      }}
                      disabled={!hasSelected}
                      loading={this.state.loading}
                    >
                      Go
                    </Button>
                    <span style={{ marginLeft: 8 }}>
                      {hasSelected
                        ? `Selected ${selectedRowKeys.length} items out of ${this.state.missionData.pagination.count}`
                        : ""}
                    </span>
                  </div>
                </Col>
                <Col md={12}>
                  <Row>
                    <Col md={14}>
                      <div
                        style={{
                          float: "right",
                          paddingTop: "22px"
                        }}
                      >
                        {this.state.missionData
                          ? `${computeSize(
                              this.state.pageSize *
                                (this.state.currentPage - 1) +
                                1,
                              this.state.missionData.pagination.count
                            )} - ${computeSize(
                              this.state.pageSize * this.state.currentPage,
                              this.state.missionData.pagination.count
                            )} of ${
                              this.state.missionData.pagination.count
                            } items`
                          : ""}
                      </div>
                    </Col>
                    <Col md={10}>
                      <div
                        style={{
                          width: "95%",
                          float: "right",
                          paddingTop: "16px"
                        }}
                      >
                        <SearchComponent
                          name="search"
                          onkeypress={e => {
                            this.enterPressed(e);
                          }}
                          onchange={e => {
                            this.setState({
                              keyword: e.target.value
                            });
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Media
                queries={{
                  xs: "(max-width: 1099px)",
                  small: "(min-width: 1100px) and (max-width: 1299px)",
                  medium: "(min-width: 1300px) and (max-width: 1499px)",
                  large: "(min-width: 1500px)"
                }}
              >
                {matches => (
                  <Fragment>
                    <Table
                      scroll={matches.xs ? { x: "max-content" } : {}}
                      size="middle"
                      rowSelection={rowSelection}
                      columns={getResponsiveColumns(matches)}
                      loading={this.state.loading}
                      dataSource={this.formatMissions(this.state.missionData)}
                      pagination={false}
                      onChange={this.handleTableChange}
                      bordered
                    />
                  </Fragment>
                )}
              </Media>
              <Pagination
                style={{ marginTop: "20px", float: "right" }}
                showSizeChanger
                onChange={this.onPaginationChange}
                onShowSizeChange={this.onPaginationChange}
                current={this.state.currentPage}
                total={this.state.total}
                pageSizeOptions={["25", "50", "100"]}
                pageSize={this.state.pageSize}
              />
            </Card>
          </Col>
          <Modal
            visible={this.state.visible}
            onOk={this.deleteRows}
            onCancel={() => {
              this.setState({ visible: false, loading: false });
            }}
          >
            <h3 style={{ margin: "20px" }}>
              <Icon
                type="question-circle"
                className="modal_icon"
                style={{ color: "orange" }}
              />
              Are you sure you want to delete?
            </h3>
          </Modal>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    pointingModesList: state.list.listPointingModes,
    satelliteList: state.list.listSatellite,
    missionStatusList: state.list.listMissionStatusTypes
  };
};

export default connect(mapStateToProps, actions)(withToast(AcquisitionTable));
