import React, { Component } from "react";
import { Row, Col, Button, Popover, Icon } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import * as actions from "../../Actions";
import Spinner from "../Layout/Spinner";

class CurrentPosition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      position: {}
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.props.positionSatellite(this.props.satelliteCode);
    }, 5000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.satelliteCode !== this.props.satelliteCode) {
      this.props.positionSatellite();
      this.clearData();
    }

    if (prevProps.position !== this.props.position) {
      this.passData(this.props.position);
    }
  }

  clearData() {
    this.setState({ loading: true });
    this.props.positionSatellite(this.props.satelliteCode);
  }

  passData(data) {
    this.setState({ position: data, loading: false });
  }

  render() {
    return (
      <div className="panel" style={{ height: "100%" }}>
        <div style={{ paddingBottom: "20px" }}>
          <span className="panel__title label">CURRENT POSITION</span>
          <span className="span_component" style={{ float: "right" }}>
            <CopyToClipboard
              text={
                this.state.position.data
                  ? `${this.state.position.data.coordinates[0].toFixed(
                      4
                    )},${this.state.position.data.coordinates[1].toFixed(
                      4
                    )},${this.state.position.data.coordinates[2].toFixed(4)}`
                  : ""
              }
              onCopy={() => this.setState({ copiedPosition: true })}
            >
              <Popover
                content={this.state.copiedPosition ? "Copied!" : ""}
                trigger="click"
              >
                <Button type="link" style={{ color: "#7bd1aa" }}>
                  <Icon type="copy" />
                </Button>
              </Popover>
            </CopyToClipboard>
          </span>
        </div>
        {!this.state.loading &&
        Object.entries(this.state.position).length > 0 ? (
          <div style={{ paddingTop: "20px" }}>
            <Row gutter={8} className="details">
              <Col span={8}>
                <strong>Latitude</strong>
              </Col>
              <Col span={12}>
                {this.state.position.data
                  ? this.state.position.data.coordinates[1].toFixed(4)
                  : ""}
              </Col>
            </Row>
            <Row gutter={8} className="details">
              <Col span={8}>
                <strong>Longitude</strong>
              </Col>
              <Col span={12}>
                {this.state.position.data
                  ? this.state.position.data.coordinates[0].toFixed(4)
                  : ""}
              </Col>
            </Row>
            <Row gutter={8} className="details">
              <Col span={8}>
                <strong>Elevation</strong>
              </Col>
              <Col span={12}>
                {this.state.position.data
                  ? this.state.position.data.coordinates[2].toFixed(4)
                  : ""}
              </Col>
            </Row>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    position: state.position.positionData
  };
};

export default connect(mapStateToProps, actions)(CurrentPosition);
