import React, { Component } from "react";
import { Map, TileLayer, FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import { connect } from "react-redux";
import L from "leaflet";
import * as actions from "../../Actions";

class UpdateMap extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.targetGeom(this.props.polygon);
  }

  onCreated = e => {
    this.props.targetGeom(e.layer.toGeoJSON());
  };

  onFeatureGroupReady = ref => {
    if (ref === null) {
      return;
    }
    const editableFG = ref;
    if (this.props.mapData) {
      // WHAT TO PUT HERE? IF REMOVED OR SWAP WITH ELSE STATEMENT, THIS WILL GET AN ERROR
      // https://stackoverflow.com/questions/52684688/importing-geojson-to-react-leaflet-draw
    } else if (this.props.polygon) {
      const leafletGeoJSON = new L.GeoJSON(this.props.polygon);
      const leafletFG = editableFG.leafletElement;
      leafletGeoJSON.eachLayer(layer => leafletFG.addLayer(layer));
    }
  };

  render() {
    return (
      <Map
        style={{ height: "300px", width: "100%" }}
        ref={m => {
          this.leafletMap = m;
        }}
        center={this.props.center ? this.props.center : [12.57513, 122.27081]}
        zoom={this.props.center ? 15 : 6}
        scrollWheelZoom={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <FeatureGroup
          ref={reactFGref => {
            this.onFeatureGroupReady(reactFGref);
          }}
        >
          <EditControl
            position="topright"
            onEdit={this.onEditPath}
            onEdited={e => {
              e.layers.eachLayer(a => {
                this.props.targetGeom(a.toGeoJSON());
              });
            }}
            edit={{ remove: true }}
            onCreated={this.onCreated}
            draw={{
              polyline: false,
              polygon: true,
              rectangle: true,
              circlemarker: false,
              circle: false,
              marker: false
            }}
            onDeleted={e => {
              this.props.targetGeom(null);
            }}
          />
        </FeatureGroup>
      </Map>
    );
  }
}

const mapStateToProps = state => {
  return {
    mapData: state.target.data
  };
};

export default connect(mapStateToProps, actions)(UpdateMap);
