const grsLocation = e => {
  switch (e) {
    case "ASTI":
      return {
        lat: 14.647318,
        lon: 121.071999,
        alt: 77
      };
    case "Tohoku":
      return {
        lat: 38.257947638000076,
        lon: 140.8361905280001,
        alt: 0
      };
    default:
      // default ASTI
      return {
        lat: 14.647318,
        lon: 121.071999,
        alt: 77
      };
  }
};

export default grsLocation;
