import React, { Component } from 'react';
import Funnel, {
  Title,
  Margin,
  Export,
  Tooltip,
  Item,
  Border,
  Label,
  Font
} from 'devextreme-react/funnel';
import { Empty } from "antd";
import {
  ResponsiveContainer
} from "recharts";
import API from "../../Components/API";
import Spinner from "../../Components/Layout/Spinner";

export default class FunnelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      missionData: null,
      captureData: null
    };
  }

  componentDidMount() {
    this.fetchMissionData();
    this.fetchImageData();
  };

  fetchImageData = async () => {
    try {
      await API.get(`stats/data_management/image_processing_funnel`)
        .then(res => {
          const {
            downloaded_total,
            initial_image_assessment: { Processable, Unchecked }, 
            georeferenced,
            stacked
          } = res.data

          const data = [
            { argument: 'Downloaded', value: downloaded_total },
            { argument: 'Processable', value: Processable.count },
            { argument: 'Georeferenced', value: georeferenced.count },
            { argument: 'Stacked', value: stacked.count }
          ];

          this.setState({
            captureData: {
              data : data,
              error: null
            },
            loading: false
          });
        })
    } catch (error) {
      console.log(error)
      this.setState({
        captureData: {
          data : null,
          error: error
        },
        loading: false
      });
    }
  }

  fetchMissionData = async () => {
    try {
      await API.get(`stats/missions_management/mission_processing_funnel`)
        .then(res => {
          const {
            total_missions,
            mission_statuses,
            mission_statuses: {
              Captured,
              Downloaded,
              Processed,
              Distributed
            }, 
          } = res.data

          const data = [
            { argument: 'Planned', value: total_missions },
            { argument: 'Captured', value: Captured.count },
            { argument: 'Downloaded', value: Downloaded.count },
            { argument: 'Processable', value: Downloaded.count - mission_statuses["Not Processable"].count },
            { argument: 'Processed', value: Processed.count },
            { argument: 'Distributed', value: Distributed.count },
          ];

          this.setState({
            missionData: {
              data : data,
              error: null
            },
            loading: false
          });
        })
    } catch (error) {
      console.log(error)
      this.setState({
        missionData: {
          data : null,
          error: error
        },
        loading: false
      });
    }
  }

  formatLabel(arg) {
    return `<span class="label">${arg.value}</span><br/>${arg.item.argument}-${arg.percentText}`;
  }

  render() {
    return (
      <ResponsiveContainer width="100%" height={350}>
        <div style={{ width: "100%", zoom: "0.825", MozTransform: "scale(0.5)", marginTop: "20px" }}>
          {!this.state.loading ? (
            <div>
              {this.state.missionData?.data && this.state.captureData?.data ? (
                <Funnel
                  id="funnel"
                  dataSource={
                    this.props.dataType.value === "Missions" ?
                    this.state.missionData?.data :
                    this.state.captureData?.data
                  }
                  palette="Soft Pastel"
                  argumentField="argument"
                  valueField="value"
                  onItemClick={e => console.log(e)}
                >
                  {/* <Title text=
                    {
                      this.props.dataType.value === "Missions" ?
                      "Diwata-2 Operations Funnel" :
                      "Image Processing Funnel"
                    }
                  >
                    <Margin bottom={10} />
                  </Title> */}
                  {/* <Export enabled={true} /> */}
                  <Tooltip enabled={true} format="fixedPoint" />
                  <Item>
                    <Border visible={true} />
                  </Item>
                  <Label
                    visible={true}
                    horizontalAlignment="right"
                    backgroundColor="none"
                    customizeText={this.formatLabel}
                    showForZeroValues
                  >
                    <Font size={14} />
                  </Label>
                </Funnel>
              ) : (
                <Empty />
              )}
            </div>
          ) : (
            <Spinner />
          )}
        </div>
      </ResponsiveContainer>
    );
  }
}

