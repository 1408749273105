import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label
} from "recharts";

export default class UsersOverTimeByUserType extends PureComponent {
  render() {
    return (
      <ResponsiveContainer width={"100%"} height={400}>
        <BarChart
          data={this.props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" height={50}>
            <Label
              value="Months"
              offset={10}
              position="insideBottom"
              style={{ textAnchor: "middle", color: "grey" }}
            />
          </XAxis>
          <YAxis>
            <Label
              angle={-90}
              value="Number of new users"
              position="insideLeft"
              style={{ textAnchor: "middle" }}
            />
          </YAxis>
          <Tooltip />
          <Legend margin={{ top: 20 }} />
          <Bar stackId="1" dataKey="End user" stroke="#1A9E76" fill="#1A9E76" />
          <Bar
            stackId="1"
            dataKey="Technical user"
            stroke="#DA5E01"
            fill="#DA5E01"
          />
          <Bar stackId="1" dataKey="None" stroke="#7570B3" fill="#7570B3" />
          <Bar
            stackId="1"
            dataKey="Product developer"
            stroke="#E7298A"
            fill="#E7298A"
          />
          <Bar
            stackId="1"
            dataKey="Data Provider"
            stroke="#66A61D"
            fill="#66A61D"
          />
          <Bar stackId="1" dataKey="Manager" stroke="#E7AB02" fill="#E7AB02" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
