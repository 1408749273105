import React, { Component } from "react";

export default class UpdateTargetPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div>This is UpdateTargetPage</div>;
  }
}
