import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import auth from "./authReducer";
import downloads from "./downloadsReducer";
import profiles from "./userProfilesReducer";
import passReducer from "./passReducer";
import positionReducer from "./positionReducer";
import decay from "./decayReducer";
import mission from "./missionReducer";
import shu from "./shuReducer";
import list from "./listReducer";
import target from "./targetReducer";
import report from "./reportReducer";
import commandTypes from "./commandTypesReducer";
import downloadCart from "./downloadCartReducer";

export default combineReducers({
  auth,
  downloads,
  profiles,
  commandTypes,
  passes: passReducer,
  position: positionReducer,
  decay,
  mission,
  shu,
  form: formReducer,
  list,
  target,
  report,
  downloadCart
});
