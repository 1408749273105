/* eslint-disable camelcase */
import React, { Component } from "react";
import PlotlyTempGraph from "./PlotlyTempGraph";

export default class PlotlyTemp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { dataAll } = this.props;
    const XData = [];

    const YData_smi = [];
    const YData_smi_low = [];
    const YData_smi_high = [];

    const YData_hpt = [];
    const YData_hpt_low = [];
    const YData_hpt_high = [];

    const YData_mfc = [];
    const YData_mfc_low = [];
    const YData_mfc_high = [];

    const YData_wfc = [];
    const YData_wfc_low = [];
    const YData_wfc_high = [];

    const YData_erc = [];
    const YData_erc_low = [];
    const YData_erc_high = [];

    const YData_lctf_vis = [];
    const YData_lctf_vis_low = [];
    const YData_lctf_vis_high = [];

    const YData_lctf_nir = [];
    const YData_lctf_nir_low = [];
    const YData_lctf_nir_high = [];

    const YData_shu1 = [];
    const YData_shu1_low = [];
    const YData_shu1_high = [];

    const YData_shu2 = [];
    const YData_shu2_low = [];
    const YData_shu2_high = [];

    dataAll.forEach(element => {
      XData.push(element.capture_time);

      YData_smi.push(element.temperature_smi.avg);
      YData_smi_low.push(element.temperature_smi.min);
      YData_smi_high.push(element.temperature_smi.max);

      YData_hpt.push(element.temperature_hpt.avg);
      YData_hpt_low.push(element.temperature_hpt.min);
      YData_hpt_high.push(element.temperature_hpt.max);

      YData_mfc.push(element.temperature_mfc.avg);
      YData_mfc_low.push(element.temperature_mfc.min);
      YData_mfc_high.push(element.temperature_mfc.max);

      YData_wfc.push(element.temperature_wfc.avg);
      YData_wfc_low.push(element.temperature_wfc.min);
      YData_wfc_high.push(element.temperature_wfc.max);

      YData_erc.push(element.temperature_erc.avg);
      YData_erc_low.push(element.temperature_erc.min);
      YData_erc_high.push(element.temperature_erc.max);

      YData_lctf_vis.push(element.temperature_lctf_vis.avg);
      YData_lctf_vis_low.push(element.temperature_lctf_vis.min);
      YData_lctf_vis_high.push(element.temperature_lctf_vis.max);

      YData_lctf_nir.push(element.temperature_lctf_nir.avg);
      YData_lctf_nir_low.push(element.temperature_lctf_nir.min);
      YData_lctf_nir_high.push(element.temperature_lctf_nir.max);

      YData_shu1.push(element.temperature_shu1.avg);
      YData_shu1_low.push(element.temperature_shu1.min);
      YData_shu1_high.push(element.temperature_shu1.max);

      YData_shu2.push(element.temperature_shu2.avg);
      YData_shu2_low.push(element.temperature_shu2.min);
      YData_shu2_high.push(element.temperature_shu2.max);
    });

    return (
      <div style={{ width: "100%" }}>
        <PlotlyTempGraph
          dataType="Temperature (°C)"
          X={XData}
          Y={YData_smi}
          title="SMI"
          min={this.props.minMax.SMI.min}
          max={this.props.minMax.SMI.max}
          low={YData_smi_low}
          high={YData_smi_high}
        />
        <PlotlyTempGraph
          dataType="Temperature (°C)"
          X={XData}
          Y={YData_lctf_vis}
          title="LCTF Vis"
          min={this.props.minMax.SMI.min}
          max={this.props.minMax.SMI.max}
          low={YData_lctf_vis_low}
          high={YData_lctf_vis_high}
        />
        <PlotlyTempGraph
          dataType="Temperature (°C)"
          X={XData}
          Y={YData_lctf_nir}
          title="LCTF Nir"
          min={this.props.minMax.SMI.min}
          max={this.props.minMax.SMI.max}
          low={YData_lctf_nir_low}
          high={YData_lctf_nir_high}
        />
        <PlotlyTempGraph
          dataType="Temperature (°C)"
          X={XData}
          Y={YData_hpt}
          title="HPT"
          min={this.props.minMax.HPT.min}
          max={this.props.minMax.HPT.max}
          low={YData_hpt_low}
          high={YData_hpt_high}
        />
        <PlotlyTempGraph
          dataType="Temperature (°C)"
          X={XData}
          Y={YData_mfc}
          title="MFC"
          min={this.props.minMax.MFC.min}
          max={this.props.minMax.MFC.max}
          low={YData_mfc_low}
          high={YData_mfc_high}
        />
        <PlotlyTempGraph
          dataType="Temperature (°C)"
          X={XData}
          Y={YData_wfc}
          title="WFC"
          min={this.props.minMax.WFC.min}
          max={this.props.minMax.WFC.max}
          low={YData_wfc_low}
          high={YData_wfc_high}
        />
        <PlotlyTempGraph
          dataType="Temperature (°C)"
          X={XData}
          Y={YData_erc}
          title="ERC"
          min={this.props.minMax.ERC.min}
          max={this.props.minMax.ERC.max}
          low={YData_erc_low}
          high={YData_erc_high}
        />
        <PlotlyTempGraph
          dataType="Temperature (°C)"
          X={XData}
          Y={YData_shu1}
          title="SHU1"
          min={this.props.minMax.SHU.min}
          max={this.props.minMax.SHU.max}
          low={YData_shu1_low}
          high={YData_shu1_high}
        />
        <PlotlyTempGraph
          dataType="Temperature (°C)"
          X={XData}
          Y={YData_shu2}
          title="SHU2"
          min={this.props.minMax.SHU.min}
          max={this.props.minMax.SHU.max}
          low={YData_shu2_low}
          high={YData_shu2_high}
        />
      </div>
    );
  }
}
