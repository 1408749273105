import _ from "lodash";
import moment from "moment";
import {
  OTHERS,
  DISASTER_CALAM,
  INFRA,
  SCIENTIFIC,
  AGRI,
  CLIMATE,
  WATER,
  ENV
} from "../Constants";
import { getPercentage } from ".";

/**
 * Returns the total count of users
 * @param {object} profiles
 */
export const getTotalUsers = profiles => {
  if (profiles) {
    return Object.keys(profiles).length;
  }
  // return profiles ? Object.keys(profiles).length : "";
};

/**
 * Group the profiles by user type
 * @param {object} profiles
 * @return {object} mapped
 */
export const groupObject = (data, control) => {
  let mapped = [];
  if (data) {
    mapped = _.groupBy(data, control);
    return mapped;
  }
};

export const constructUserWaffle = data => {
  const newArr = [];
  delete data.null;
  Object.keys(data).map((key, value) => {
    const obj = {
      idx: value,
      id: `${key}`,
      label: `${key}`,
      value: data[key].length
    };
    return newArr.push(obj);
  });
  return newArr;
};

/**
 * Group user data by created time
 * @param {*} data
 */
const groupUsersByDate = data => {
  const userData = Object.assign({}, data);
  const dateArr = [];
  Object.keys(userData).map(val => {
    const rawDate = userData[val].created_time;
    const convertedDate = moment(rawDate).format("YYYY-MM");
    userData[val].created_time = convertedDate;
    return dateArr.push(userData[val]);
  });
  return groupObject(dateArr, "created_time");
};

export const userTypeOverTime = data => {
  const arr = [];
  if (data) {
    const groupByDate = groupUsersByDate(data);

    Object.keys(groupByDate).map(val => {
      const groupByUserType = groupObject(groupByDate[val], "user_type");
      groupByDate[val] = groupByUserType;
      return true;
    });
    Object.keys(groupByDate).map(val => {
      const structObj = {
        date: val,
        "End user": groupByDate[val]["End user"]
          ? groupByDate[val]["End user"].length
          : 0,
        "Technical user": groupByDate[val]["Technical user"]
          ? groupByDate[val]["Technical user"].length
          : 0,
        None: groupByDate[val].None ? groupByDate[val].None.length : 0,
        "Product developer": groupByDate[val]["Product developer"]
          ? groupByDate[val]["Product developer"].length
          : 0,
        "Data provider": groupByDate[val]["Data provider"]
          ? groupByDate[val]["Data provider"].length
          : 0,
        Manager: groupByDate[val].Manager ? groupByDate[val].Manager.length : 0
      };
      return arr.push(structObj);
    });
  }
  // Check if array is in correct order
  if (arr.length > 0) {
    if (arr[arr.length-1].date > arr[0].date) {
      // Ascending
      return arr;
    } else {
      // Descending - need to reverse
      return Array.prototype.reverse.call(arr);
    }
  }
  return arr;
};

/**
 * Data for the line chart Users over time
 * @param {object} data
 */
export const userOverTime = data => {
  const chartData = [];
  if (data) {
    const userCount = groupUsersByDate(data);

    Object.keys(userCount).map(key => {
      const x = {
        date: key,
        count: userCount[key].length
      };
      return chartData.push(x);
    });
  }
  // Check if array is in correct order
  if (chartData.length > 0) {
    if (chartData[chartData.length-1].date > chartData[0].date) {
      // Ascending
      return chartData;
    } else {
      // Descending - need to reverse
      return Array.prototype.reverse.call(chartData);
    }
  }
  return chartData;
};

export const constructAveRating = (data, averageParam) => {
  const ratingData = groupObject(data, averageParam);

  delete ratingData.null;
  const ratingArr = [];
  Object.keys(ratingData).map(i => {
    const x = {
      Rating: i,
      Count: ratingData[i].length
    };
    return ratingArr.push(x);
  });
  return ratingArr;
};

export const getAverageRating = data => {
  let sum = 0;
  let mean = 0;
  let totalMean = 0;
  delete data.null;
  if (data) {
    data.map(val => {
      mean = parseInt(val.Rating, 10) * parseInt(val.Count, 10);
      totalMean += mean;
      sum += parseInt(val.Count, 10);
      return true;
    });
  }
  return (totalMean / sum).toFixed(1);
};

const currentMonth = moment().format("YYYY-MM");
const pastCurrentMonth = moment(currentMonth, "YYYY-MM")
  .subtract(1, "months")
  .format("YYYY-MM");
/**
 * Get New users for current month
 * @param {*} data
 * @return {object} statUSer
 */
export const getNewUsersThisMonth = data => {
  const statUser = {};
  let count;
  let trend;
  if (data) {
    const groupedData = groupUsersByDate(data);
    Object.keys(groupedData).map(val => {
      if (val === currentMonth) {
        count = groupedData[val].length;
        trend = count - groupedData[pastCurrentMonth].length;
      }
      return true;
    });
    statUser.count = count ? count : 0;
    statUser.trendVal = trend;
    statUser.trendType = trend > 0 ? 1 : 0;
  }
  return statUser;
};

export const getActiveUsersThisMonth = data => {
  let count;
  const dataObj = data;
  const actUser = {};
  if (data) {
    Object.keys(dataObj).map(val => {
      dataObj[val].last_login = moment(dataObj[val].last_login).format(
        "YYYY-MM"
      );
      return true;
    });
    const activeMonths = groupObject(dataObj, "last_login");
    if (activeMonths[currentMonth]) {
      count = activeMonths[currentMonth].length;
      actUser.count = count;
      actUser.trendVal = activeMonths[pastCurrentMonth]
        ? count - activeMonths[pastCurrentMonth].length
        : count;
      actUser.trendType = actUser.trendVal > 0 ? 1 : 0;
    } else {
      actUser.count = 0;
      actUser.trendVal = "";
      actUser.trendType = 0;
    }
  }
  return actUser;
};

export const constructDownloadsTreeMap = data => {
  const groupedData = groupObject(data, "primary_application");
  const finalArr = [];
  if (groupedData) {
    Object.keys(groupedData).map(val => {
      const percentage = getPercentage(
        groupedData[val].length,
        Object.keys(data).length
      );
      const i = {
        name: val,
        children: [
          {
            name: `${percentage}%`,
            value: groupedData[val].length
          }
        ]
      };
      return finalArr.push(i);
    });
    return finalArr;
  }
};

const groupDownloadsByStartTime = data => {
  const newDate = [];
  if (data) {
    const downloadsData = _.cloneDeep(data);
    Object.keys(downloadsData).map(val => {
      const rawDate = downloadsData[val].start_time;
      const convertedDate = moment(rawDate).format("YYYY-MM");
      downloadsData[val].start_time = convertedDate;
      return newDate.push(downloadsData[val]);
    });
  }
  return _.groupBy(newDate, "start_time");
};

export const constructDownloadsByPrimaryApp = data => {
  const arr = [];
  if (data) {
    const fData = groupDownloadsByStartTime(_.cloneDeep(data));
    Object.keys(fData).map(val => {
      const groupByUserType = groupObject(fData[val], "primary_application");
      fData[val] = groupByUserType;
      return true;
    });
    Object.keys(fData).map(val => {
      const structObj = {
        Months: val,
        [OTHERS]: fData[val][OTHERS] ? fData[val][OTHERS].length : 0,
        [DISASTER_CALAM]: fData[val][DISASTER_CALAM]
          ? fData[val][DISASTER_CALAM].length
          : 0,
        [INFRA]: fData[val][INFRA] ? fData[val][INFRA].length : 0,
        [SCIENTIFIC]: fData[val][SCIENTIFIC]
          ? fData[val][SCIENTIFIC].length
          : 0,
        [AGRI]: fData[val][AGRI] ? fData[val][AGRI].length : 0,
        [CLIMATE]: fData[val][CLIMATE] ? fData[val][CLIMATE].length : 0,
        [WATER]: fData[val][WATER] ? fData[val][WATER].length : 0,
        [ENV]: fData[val][ENV] ? fData[val][ENV].length : 0
      };
      return arr.push(structObj);
    });
  }
  return arr;
};

/**
 * Function for data of line chart for downloads over time
 * @param {*} data
 */
export const downloadsOverTime = data => {
  const chartData = [];
  if (data) {
    const downloadsCount = groupDownloadsByStartTime(_.cloneDeep(data));

    Object.keys(downloadsCount).map(key => {
      const x = {
        date: key,
        count: downloadsCount[key].length
      };
      return chartData.push(x);
    });
  }
  return chartData;
};

const groupDownloadsByDateFormat = (data, format) => {
  const newDate = [];
  if (data) {
    const downloadsData = _.clone(data, true);
    Object.keys(downloadsData).map(val => {
      const rawDate = downloadsData[val].start_time;
      const convertedDate = moment(rawDate).format(format);
      downloadsData[val].start_time = convertedDate;
      return newDate.push(downloadsData[val]);
    });
  }
  return groupObject(newDate, "start_time");
};

/**
 * Function for extracting the time type
 * @param {string} type
 */
const extractTimeType = type => {
  let time = "";
  switch (type) {
    case "month":
      time = moment().format("YYYY-MM");
      break;
    case "year":
      time = moment().format("YYYY");
      break;
    case "day":
      time = moment().format("YYYY-MM-DD");
      break;
    case "yesterday":
      time = moment()
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      break;
    default:
      time = "";
  }
  return time;
};

/**
 * Common function for getting the downloads and scenes count
 * @param {object} data
 * @param {string} timeType
 */
const getUserDownloadsStats = (data, timeType) => {
  const tType = extractTimeType(timeType);
  const stats = {
    downloadsCount: 0,
    scenesCount: 0
  };

  Object.keys(data).map(val => {
    if (val === tType) {
      stats.scenesCount = data[val].length;
      const groupedUsers = _.groupBy(data[val], "profile_id");
      stats.downloadsCount = Object.keys(groupedUsers).length;
    }
    return true;
  });
  return stats;
};

/**
 * Function for getting downloads for the current week
 * @param {object} data
 */
const getUserDownloadsStatsPerWeek = data => {
  const fromDate = moment()
    .startOf("week")
    .toDate();
  const toDate = moment()
    .endOf("week")
    .toDate();

  const result = _.pickBy(data, function(value, key) {
    const dates = moment(key).toDate();
    return dates >= fromDate && dates <= toDate;
  });

  const arrCont = [];
  Object.keys(result).map(key => {
    console.log("Here");
    return result[key].map(val => {
      return arrCont.push(val);
    });
  });

  return {
    downloadsCount: arrCont
      ? Object.keys(_.groupBy(arrCont, "profile_id")).length
      : 0,
    scenesCount: arrCont ? arrCont.length : 0
  };
};

const getDownloadTrend = (today, yesterday) => {
  const userCountTrendVal = today.downloadsCount - yesterday.downloadsCount;
  const sceneCountTrendVal = today.scenesCount - yesterday.scenesCount;

  return {
    sceneTrendVal: sceneCountTrendVal,
    userTrendVal: userCountTrendVal
  };
};

/**
 * Function to calculate the stats in distribution downloads
 * @return {object} data
 */
export const getDownloadStats = data => {
  const stats = {};
  const copyData = _.cloneDeep(data);
  if (copyData) {
    // get total downloads and users
    const scenesObj = groupObject(copyData, "scene_id");
    const usersObj = groupObject(copyData, "profile_id");
    const dlDateObjByDate = groupDownloadsByDateFormat(copyData, "YYYY-MM-DD");
    const dlDateObjByMonth = groupDownloadsByDateFormat(copyData, "YYYY-MM");
    const dlDateObjByYear = groupDownloadsByDateFormat(copyData, "YYYY");

    console.log("Users");
    console.log(usersObj);
    stats.total_scenes = Object.keys(scenesObj).length;
    stats.total_users = Object.keys(usersObj).length;
    stats.total_scenes_month = getUserDownloadsStats(dlDateObjByMonth, "month");
    stats.total_scenes_year = getUserDownloadsStats(dlDateObjByYear, "year");
    stats.total_scenes_day = getUserDownloadsStats(dlDateObjByDate, "day");
    stats.total_scenes_yesterday = getUserDownloadsStats(
      dlDateObjByDate,
      "yesterday"
    );
    stats.total_scenes_week = getUserDownloadsStatsPerWeek(dlDateObjByDate);
    stats.downloadTrend = getDownloadTrend(
      stats.total_scenes_day,
      stats.total_scenes_yesterday
    );
  }
  return stats;
};
