import React, { Component, Fragment } from "react";
import { Menu, Dropdown, Icon, message, Button } from "antd";
import moment from "moment";
import { connect } from "react-redux";
import API from "../../Components/API";
import * as actions from "../../Actions";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import GPSMap from "../../Components/SatelliteHealth/GPSMap";
import AltVelocity from "../../Components/SatelliteHealth/AltVelocity";
import Spinner from "../../Components/Layout/Spinner";
import PlotlyTemp from "../../Components/SatelliteHealth/PlotlyTemp";
import PlotlyCurrent from "../../Components/SatelliteHealth/PlotlyCurrent";
import PlotlyVoltage from "../../Components/SatelliteHealth/PlotlyVoltage";

const panelMargin = {
  marginTop: "50px"
};

const container = {
  marginTop: "20px"
};

class SatelliteHealthDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "",
      year: "",
      graphData: [],
      loading: true
    };
  }

  componentDidMount() {
    const years = [];
    let startYear = moment(new Date());
    const endYear = moment("2012-01-01");
    while (moment(startYear).format("YYYY") >= moment(endYear).format("YYYY")) {
      years.push(moment(startYear).format("YYYY"));
      startYear = moment(startYear).subtract(1, "years");
    }
    this.setState({
      listYears: years,
      year: years[0]
    });
    this.props.listSatellite();
    this.fetchMinMax();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.list !== this.props.list) {
      this.passData(this.props.list);
    }
  }

  passData(data) {
    const satelliteList = data.filter(
      satellite => satellite.status === "Available"
    );
    this.setState(
      {
        list: satelliteList,
        selected: satelliteList[0].name,
        satelliteCode: satelliteList[0].catalog_no
      },
      () => {
        this.fetchData(1);
      }
    );
  }

  fetchData = page => {
    const endYear = Number(this.state.year) + 1;
    API.get(
      `satellite_health_management/satellite_temp_power_computed_data?format=json&satellite=${this.state.selected}&page_size=300&page=${page}&from:capture_time=${this.state.year}-01-01&to:capture_time=${endYear}-01-01`
    )
      .then(res => {
        if (res.data.pagination.links.next) {
          const dataArray = this.state.graphData;
          const combinedArray = dataArray.concat(res.data.data);
          this.setState(
            {
              graphData: combinedArray,
              loading: true
            },
            () => {
              this.fetchData(page + 1);
            }
          );
        } else {
          const dataArray = this.state.graphData;
          const combinedArray = dataArray.concat(res.data.data);
          this.setState({
            graphData: combinedArray,
            loading: false
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  fetchMinMax = () => {
    API.get(`satellite_health_management/satellite_temp_min_max?format=json`)
      .then(res => {
        this.setState({
          minMax: res.data
        });
      })
      .catch(function(error) {
        console.log(error);
        if (error) {
          message.error("Error in fetching data, please reload the page.");
        }
      });
  };

  render() {
    const onClick = ({ item, key }) => {
      message.info(`Selected Satellite: ${key}`);
      this.setState(
        {
          selected: key,
          graphData: [],
          loading: true
        },
        () => {
          this.fetchData(1);
        }
      );
    };

    const onClickYears = ({ item, key }) => {
      message.info(`Selected Year: ${key}`);
      this.setState(
        {
          year: key,
          graphData: [],
          loading: true
        },
        () => {
          this.fetchData(1);
        }
      );
    };

    const menu = (
      <Menu onClick={onClick} className="panel__title label">
        {this.state.list
          ? Array.from(this.state.list)
              .filter(data => data.status === "Available")
              .map(val => (
                <Menu.Item key={val.name} code={val.catalog_no}>
                  {val.name}
                </Menu.Item>
              ))
          : ""}
      </Menu>
    );

    const menuYear = (
      <Menu onClick={onClickYears} className="panel__title label">
        {this.state.listYears
          ? this.state.listYears.map(val => (
              <Menu.Item key={val} code={val}>
                {val}
              </Menu.Item>
            ))
          : ""}
      </Menu>
    );

    const urls = {
      "/": "Satellite Health Management",
      "/satellite_health_management/satellite_health_dashboard":
        "Satellite Health Dashboard"
    };

    return (
      <Fragment>
        <BreadCrumbComponent
          data={urls}
          titlePage="Satellite Health Dashboard"
        />
        <div style={{ paddingBottom: "30px" }}>
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            disabled={this.state.loading ? true : false}
          >
            <Button
              type="link"
              className="ant-dropdown-link"
              style={{ color: "black" }}
            >
              {this.state.selected} <Icon type="down" />
            </Button>
          </Dropdown>
        </div>
        <div style={{ float: "right", position: "relative" }}>
          <span style={{ marginLeft: "20px" }}>Filter Power by Year: </span>
          <Dropdown
            overlay={menuYear}
            trigger={["click"]}
            disabled={this.state.loading ? true : false}
          >
            <Button className="ant-dropdown-link" style={{ color: "black" }}>
              {this.state.year} <Icon type="down" />
            </Button>
          </Dropdown>
        </div>
        <div style={panelMargin}>
          <h3 className="panel__title label">TEMPERATURE</h3>
        </div>
        <div className="panel" style={container}>
          {!this.state.loading && this.state.graphData && this.state.minMax ? (
            <PlotlyTemp
              dataAll={this.state.graphData}
              minMax={this.state.minMax}
            />
          ) : (
            <Spinner />
          )}
        </div>
        <div style={panelMargin}>
          <h3 className="panel__title label">CURRENT</h3>
        </div>
        <div className="panel" style={container}>
          {!this.state.loading && this.state.graphData ? (
            <PlotlyCurrent dataAll={this.state.graphData} />
          ) : (
            <Spinner />
          )}
        </div>
        <div style={panelMargin}>
          <h3 className="panel__title label">VOLTAGE</h3>
        </div>
        <div className="panel" style={container}>
          {!this.state.loading && this.state.graphData ? (
            <PlotlyVoltage dataAll={this.state.graphData} />
          ) : (
            <Spinner />
          )}
        </div>
        <GPSMap satellite={this.state.selected} />
        <AltVelocity satellite={this.state.selected} />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    list: state.list.listSatellite
  };
};

export default connect(mapStateToProps, actions)(SatelliteHealthDashboard);
