import React, { useState, useEffect } from "react";

import { Layout, Menu, Icon } from "antd";
import { NavLink } from "react-router-dom";
import history from "../../history";
import satellite from "../../Assets/Images/satellite.png";
import { isAllowed } from "../Authorization";
import Const from "../../Config/const";

// NOTE: Please update const.js when adding/updating/editing the key or link of menu item
function SidebarComponent() {
  const [collapsed, setCollapsed] = useState(false);
  const [rules, setRules] = useState([]);
  const { SubMenu } = Menu;
  const { Sider } = Layout;
  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const rootSubmenuKeys = ["sub2", "sub1", "data management"];

  const [openKeys, setOpenKeys] = useState(["sub2"]);
  const [selectedKeys, setSelectedKeys] = useState(["1"]);

  const logoFlag = collapsed ? "none" : "inline";

  const highlightSelected = () => {
    Const.sidebar_menu.map(menu => {
      // Without Sub-menu
      if (menu.link) {
        if (history.location.pathname.includes(menu.link)) {
          setOpenKeys([]);
          setSelectedKeys([menu.key]);
        }
      } else {
        // With Sub-menus
        menu.sub_menu.map(submenu => {
          if (history.location.pathname.includes(submenu.link)) {
            setOpenKeys([menu.key]);
            setSelectedKeys([submenu.key]);
          }
        });
      }
    });
  };

  useEffect(() => {
    const getRules = JSON.parse(localStorage.getItem("rules"));
    setRules(getRules);
    highlightSelected();

    history.listen(() => {
      highlightSelected();
    });
  }, []);

  const onOpenChange = openKey => {
    const latestOpenKey = openKey.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(openKey);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onTitleClick = data => {
    setSelectedKeys(data.keyPath);
  };

  return (
    <Sider
      breakpoint={("xs", "sm", "md")}
      width={300}
      theme="light"
      collapsible
      collapsed={collapsed}
      onCollapse={() => onCollapse(collapsed)}
      className="sidebar__main"
    >
      <div className="sidebar__logo">
        <NavLink to="/">
          <img
            alt="satellite"
            className="sidebar__logoImg"
            src={satellite}
            width="25"
            height="25"
          />
          <span style={{ display: logoFlag }}>DIWATA OPERATIONS</span>
        </NavLink>
      </div>

      <Menu
        theme="light"
        selectedKeys={selectedKeys}
        defaultOpenKeys={["1", "sub1", "sub2"]}
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        onSelect={onTitleClick}
      >
        <Menu.Item key="home">
          <NavLink to="/">
            <span>
              <Icon type="home" />
              <span className="sidebar__sub-title">HOME</span>
            </span>
          </NavLink>
        </Menu.Item>
        <SubMenu
          className="sidebar__sub"
          key="sub2"
          title={
            <span>
              <Icon type="deployment-unit" />
              <span className="sidebar__sub-title">
                SATELLITE HEALTH MANAGEMENT
              </span>
            </span>
          }
        >
          {/* <Menu.Item key="17">
            <NavLink to="/satellite_health_management/beta_angles">
              Beta Angle Monitoring
            </NavLink>
          </Menu.Item> */}
          <Menu.Item key="26">
            <NavLink to="/satellite_health_management/satellite_health_dashboard">
              Satellite Health Dashboard
            </NavLink>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          className="sidebar__sub"
          key="sub1"
          title={
            <span>
              <Icon type="rocket" />
              <span className="sidebar__sub-title">MISSION MANAGEMENT</span>
            </span>
          }
        >
          <Menu.Item key="6">
            <NavLink to="/missions_management/mission_planning_dashboard">
              Mission Planning Dashboard
            </NavLink>
          </Menu.Item>

          {isAllowed(rules, ["missions_management.view_targettag"]) ? (
            <Menu.ItemGroup title="TARGETS" style={{ marginTop: "15px" }}>
              <Menu.Item key="8">
                <NavLink to="/missions_management/targets">Target List</NavLink>
              </Menu.Item>
            </Menu.ItemGroup>
          ) : (
            ""
          )}

          {isAllowed(rules, [
            "missions_management.view_mission",
            "missions_management.view_uploadmission",
            "missions_management.view_downloadmission",
            "missions_management.add_aculogdownloadmission"
          ]) ? (
            <Menu.ItemGroup
              title="MISSIONS"
              style={{
                marginTop: "15px"
              }}
            >
              {isAllowed(rules, ["missions_management.view_mission"]) ? (
                <Menu.Item key="12">
                  <NavLink to="/missions_management/acquisition_missions">
                    Acquisition Missions
                  </NavLink>
                </Menu.Item>
              ) : (
                ""
              )}

              {isAllowed(rules, ["missions_management.view_uploadmission"]) ? (
                <Menu.Item key="13">
                  <NavLink to="/missions_management/upload_missions">
                    Upload Missions
                  </NavLink>
                </Menu.Item>
              ) : (
                ""
              )}
              {isAllowed(rules, [
                "missions_management.view_downloadmission"
              ]) ? (
                <Menu.Item key="14">
                  <NavLink to="/missions_management/download_missions">
                    Download Missions
                  </NavLink>
                </Menu.Item>
              ) : (
                ""
              )}
              {isAllowed(rules, [
                "missions_management.add_aculogdownloadmission"
              ]) ? (
                <Menu.Item key="15">
                  <NavLink to="/missions_management/acu_log_download_missions">
                    ACU Logs Download Missions
                  </NavLink>
                </Menu.Item>
              ) : (
                ""
              )}
            </Menu.ItemGroup>
          ) : (
            ""
          )}

          {isAllowed(rules, [
            "missions_management.view_commandbatch",
            "missions_management.view_command"
          ]) ? (
            <Menu.ItemGroup title="Commands">
              {isAllowed(rules, ["missions_management.view_commandbatch"]) ? (
                <Menu.Item key="21">
                  <NavLink to="/missions_management/command_batches">
                    Command Batches
                  </NavLink>
                </Menu.Item>
              ) : (
                ""
              )}

              {isAllowed(rules, ["missions_management.view_command"]) ? (
                <Menu.Item key="22">
                  <NavLink to="/missions_management/commands">Commands</NavLink>
                </Menu.Item>
              ) : (
                ""
              )}
            </Menu.ItemGroup>
          ) : (
            ""
          )}

          <Menu.ItemGroup title="REPORTS" style={{ marginTop: "15px" }}>
            <Menu.Item key="20">
              <NavLink to="/missions_management/mission_reports">
                Mission Reports
              </NavLink>
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>

        <SubMenu
          className="sidebar__sub"
          key="data management"
          title={
            <span>
              <Icon type="dot-chart" />
              <span className="sidebar__sub-title">DATA MANAGEMENT</span>
            </span>
          }
        >
          {isAllowed(rules, ["data_management.view_mergedrawimage"]) ? (
            <Menu.ItemGroup title="RAW FILES">
              <Menu.Item key="23">
                <NavLink to="/data_management/telemetry_files">
                  Telemetry
                </NavLink>
              </Menu.Item>
              <Menu.Item key="24">
                <NavLink to="/data_management/raw_images">Raw Images</NavLink>
              </Menu.Item>
              <Menu.Item key="25">
                <NavLink to="/data_management/merged_raw_images">
                  Merged Raw Images
                </NavLink>
              </Menu.Item>
            </Menu.ItemGroup>
          ) : (
            ""
          )}
          {isAllowed(rules, ["data_management.view_capturestatus"]) ? (
            <Menu.ItemGroup title="CAPTURES">
              <Menu.Item key="raw">
                <NavLink to="/data_management/captures_raw">Raw</NavLink>
              </Menu.Item>
              <Menu.Item key="level0">
                <NavLink to="/data_management/captures_level0">Level 0</NavLink>
              </Menu.Item>
              <Menu.Item key="level1">
                <NavLink to="/data_management/captures_level1">Level 1</NavLink>
              </Menu.Item>
            </Menu.ItemGroup>
          ) : (
            ""
          )}
          {isAllowed(rules, ["data_management.view_capturestatus"]) ? (
            <Menu.ItemGroup title="CAPTURE GROUP">
              <Menu.Item key="capture_groups">
                <NavLink to="/data_management/capture_groups">Capture Groups</NavLink>
              </Menu.Item>
              <Menu.Item key="capture_group_products">
                <NavLink to="/data_management/capture_group_products">
                  Capture Group Products
                </NavLink>
              </Menu.Item>
            </Menu.ItemGroup>
          ) : (
            ""
          )}

          <Menu.ItemGroup title="REPORTS" style={{ marginTop: "15px" }}>
            <Menu.Item key="sign-ups">
              <NavLink to="/data_management/distribution_users_dashboard">
                Distribution Site Users
              </NavLink>
            </Menu.Item>
            <Menu.Item key="dls">
              <NavLink to="/data_management/distribution_downloads_dashboard">
                Downloads Statistics
              </NavLink>
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
      </Menu>
    </Sider>
  );
}

export default SidebarComponent;
