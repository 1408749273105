import React, { Component } from "react";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer
} from "recharts";

const labels = [
  { dataKey: "Within_PH", fill: "#6EB5FF" },
  { dataKey: "Outside_PH", fill: "#7BD1AA" },
];

export default class GeorefBarChart extends Component {
  render() {
    return (
      <ResponsiveContainer width="100%" height={250}>
        <ComposedChart
          data={this.props.georefStats}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="date" />
          <YAxis>
            <Label
              angle={-90}
              value="Number of Images"
              position="insideLeft"
              style={{ textAnchor: "middle" }}
            />
          </YAxis>
          <Tooltip />
          <Legend />
          {labels.map((item, index) => (
            <Bar key={index} dataKey={item.dataKey} fill={item.fill} stackId="a" barSize={20} />
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}
