import React, { Component, Fragment } from "react";
import CaptureGroupForm from "../../Components/CaptureGroupComponents/CaptureGroupForm";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import API from "../../Components/API";

class ViewCaptureGroupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      init: {},
      loading: true
    };
  }

  componentDidMount() {
    API.get(`data_management/capture_groups/${this.props.match.params.id}`)
      .then(res => {
        this.setState({
          init: this.processData(res.data),
          loading: false
        });
      })
      .catch(error => {
        if (error) {
          this.setState({
            loading: false
          });
        }
        alert("Oops! something went wrong.");
        console.log(error);
      });
  }

  isEmpty = obj => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  processData = data => {
    data.mission_name = data.mission?.name;
    data.mission_link = data.mission?.link;
    return data;
  };

  render() {
    const captureGroupId = this.props.match.params.id;
    const urls = {
      "/": "DATA MANAGEMENT",
      "/data_management/capture_groups": "CAPTURE GROUPS",
      "/view": "VIEW",
      id: captureGroupId
    };
    return (
      <Fragment>
        <BreadCrumbComponent data={urls} titlePage="View Capture Group" />
        <CaptureGroupForm
          captureGroupId={captureGroupId}
          initialValues={this.state.init}
          loading={this.state.loading}
        />
      </Fragment>
    );
  }
}

export default ViewCaptureGroupPage;
