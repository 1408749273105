import { TARGET_GEOM } from "./types";

export const targetGeom = geoJSON => async dispatch => {
  if (geoJSON) {
    dispatch({
      type: TARGET_GEOM,
      payload: geoJSON.geometry
    });
  } else {
    dispatch({
      type: TARGET_GEOM,
      payload: {}
    });
  }
};
