import React, { Component, Fragment } from "react";
import {
  Table,
  Button,
  Row,
  Col,
  Card,
  Pagination,
  DatePicker,
  Select,
  Input,
  Dropdown,
  Menu
} from "antd";
import { DownOutlined } from '@ant-design/icons';
import Media from "react-media";
import queryString from "query-string";
import { connect } from "react-redux";
import moment from "moment";
import * as actions from "../../Actions";
import API from "../../Components/API";
import SearchComponent from "../../Components/MissionsComponents/SearchComponent";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import Drawer from "../../Components/RawFiles/DrawerMergedRawImages";
import {
  extractOrder,
  toTimeString,
  syncFilters,
  tableIndexStyle,
  convertToUTC,
  reverseUTC
} from "../../Components/CommonFunctions";
import Config from "../../Config/const";
import withToast from "../../Components/Hocs/withToast";
import noImage from "../../Assets/Images/noImage.png";
import axios from "axios";

let controllerRef;

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD HH:mm:ss";
const { Option } = Select;

let query = {};

class MergedRawImages extends Component {
  constructor(props) {
    query = queryString.parse(window.location.search);
    super(props);
    this.state = {
      selectedRowKeys: [],
      action: "all",
      pagination: {},
      loading: false,
      mergedRawImageFiles: "",
      downloadTag: null,
      visible: false,
      currentPage: 1,
      pageSize: 25,
      columns: [
        {
          title: <span className="table-index-number">No</span>,
          dataIndex: "index",
          align: "center",
          render(text) {
            return tableIndexStyle(text);
          }
        },
        {
          title: "Id",
          dataIndex: "id",
          sorter: true
        },
        {
          title: "Image",
          dataIndex: "thumbnail",
          width: "60px"
        },
        {
          title: "Name",
          dataIndex: "name",
          sorter: true
        },
        {
          title: "Satellite",
          dataIndex: "satellite",
          sorter: true
        },
        {
          title: "SHU Address",
          dataIndex: "shu_address",
          sorter: true
        },
        {
          title: "Capture Time",
          dataIndex: "capture_time",
          sorter: true
        },
        {
          title: "",
          dataIndex: "key",
          key: "key",
          align: "center",
          render: key => {
            return (
              <Button
                shape="circle"
                icon="eye"
                onClick={event => {
                  this.showDrawer(key);
                }}
              />
            );
          }
        }
      ],
      // FILTERS
      search: query.search || "",
      shu_address: query.shu_address || "",
      satellite: query.satellite || "",
      capture_time_from: query.capture_time_from || "",
      capture_time_to: query.capture_time_to || ""
    };
  }

  componentDidMount() {
    this.fetch();
    this.props.listSatellite();
    this.props.listUsers();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.satellites !== this.props.satellites) {
      this.passData("satellites", this.props.satellites);
    }
    if (prevProps.users !== this.props.users) {
      this.passData("users", this.props.users);
    }
  }

  passData(dataType, data) {
    switch (dataType) {
      case "satellites":
        if (!this.state.satellites) {
          this.setState({ satellites: data });
        }
        break;
      case "users":
        if (!this.state.users) {
          this.setState({ users: data });
        }
        break;
      default:
        break;
    }
  }

  fetch = (page = 1, sort = "-id") => {
    this.setState({
      loading: true,
      selectedRowKeys: [],
      selectedRows: []
    });

    if (controllerRef) {
      controllerRef.cancel();
    }
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    controllerRef = source;

    API.get(
      `data_management/merged_raw_images?format=json&search=${this.state.search}&ordering=${sort}&page=${this.state.currentPage}&page_size=${this.state.pageSize}&satellite=${this.state.satellite}&from:capture_time=${this.state.capture_time_from}&to:capture_time=${this.state.capture_time_to}&shu_address=${this.state.shu_address}`, { cancelToken: source.token }
    )
      .then(res => {
        const getTotalPages = parseInt(res ? res.data.pagination.count : 0);
        this.setState({
          loading: false,
          mergedRawImageFiles: res.data,
          total: getTotalPages
        });
        controllerRef = null;
      })
      .catch(function(error) {
        if (error.toString() !== "Cancel") {
          console.log(error);
        }
      });
  };

  formatData = tableData => {
    const missions = [];
    const rawData = tableData ? tableData.data : [];
    rawData.map((val, index) => {
      return missions.push({
        index: `${this.state.pageSize * (this.state.currentPage - 1) +
          1 +
          index}`,
        key: `${val.id}`,
        id: `${val.id}`,
        thumbnail: (
          <img
            src={val.thumbnail ? val.thumbnail : noImage}
            alt="rawImage"
            height="40px"
            width="50px"
          />
        ),
        name: `${val.capture_id}`,
        satellite: val.satellite ? `${val.satellite.name}` : "",
        capture_time: val.capture_time
          ? `${toTimeString(val.capture_time)}`
          : "",
        shu_address: val.shu_address ? `${val.shu_address}` : "",
        bin: `${val.merged_rg3_image}`,
        file_tif: `${val.file_tif}`,
        file_full_tif: `${val.file_full_tif}`,
        jpg: `${val.thumbnail}`,
        json: `${val.meta_json}`,
        csv: `${val.meta_csv}`
      });
    });
    return missions;
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.fetch(pagination.current, extractOrder(sorter));
  };

  handleFilterChange = (filter, data) => {
    // check if filter is a date
    const dateFilter = ["capture_time"];
    if (dateFilter.includes(filter)) {
      const start = data.length > 0 ? convertToUTC(data[0]) : "";
      const end = data.length > 0 ? convertToUTC(data[1]) : "";

      // add start date to query
      query = queryString.parse(window.location.search);
      syncFilters(query, `${filter}_from`, start);

      // add end date to query
      query = queryString.parse(window.location.search);
      syncFilters(query, `${filter}_to`, end);
    } else {
      // if filter is not a date
      query = queryString.parse(window.location.search);
      syncFilters(query, filter, data);
    }

    switch (filter) {
      case "search":
        this.setState(
          {
            ...this.state,
            currentPage: 1,
            search: data
          },
          () => {
            this.fetch();
          }
        );
        break;
      case "satellite":
        this.setState(
          {
            ...this.state,
            currentPage: 1,
            satellite: data
          },
          () => {
            this.fetch();
          }
        );
        break;
      case "shu_address":
        this.setState(
          {
            ...this.state,
            currentPage: 1,
            shu_address: data
          },
          () => {
            this.fetch();
          }
        );
        break;
      case "capture_time":
        if (data.length > 0) {
          this.setState(
            {
              ...this.state,
              currentPage: 1,
              capture_time_from: convertToUTC(data[0]),
              capture_time_to: convertToUTC(data[1])
            },
            () => {
              this.fetch();
            }
          );
        } else {
          this.setState(
            {
              currentPage: 1,
              capture_time_from: "",
              capture_time_to: ""
            },
            () => {
              this.fetch();
            }
          );
        }
        break;
      default:
        return false;
    }
  };

  handleActionChange = () => {
    if (!this.state.downloadTag) {
      return alert("Please choose a file to download");
    }

    this.state.selectedRows.forEach(rowData => {
      window.open(rowData[`${this.state.downloadTag.key}`], "_blank");
    });
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys });
    this.setState({ selectedRows });
  };

  enterPressed = (event, filterName) => {
    const keyCode = window.event ? event.which : event.keyCode;
    if (keyCode === 13) {
      const { value } = event.target;
      this.setState(
        {
          currentPage: 1
        },
        () => {
          if (filterName) {
            this.handleFilterChange(filterName, value);
          } else {
            this.fetch();
          }
        }
      );
    }
  };

  onPaginationChange = (page = 1, size) => {
    if (page !== this.state.currentPage && page > 0) {
      this.onPageChange(page);
    }

    if (size !== this.state.pageSize) {
      this.onPageSizeChange(size);
    }
  };

  onPageChange(page) {
    this.setState(
      {
        currentPage: page
      },
      () => {
        this.fetch();
      }
    );
  }

  onPageSizeChange(size) {
    this.setState(
      {
        pageSize: size,
        currentPage: 1
      },
      () => {
        this.fetch();
      }
    );
  }

  showDrawer(key) {
    const { data } = this.state.mergedRawImageFiles;
    const rowData = data.find(x => x.id === Number(key));
    this.setState({
      visible: true,
      drawerData: rowData
    });
  }

  render() {
    const urls = {
      "/": "DATA MANAGEMENT",
      "/data_management/merged_raw_images": "MERGED RAW IMAGES"
    };

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true
    };
    const hasSelected = selectedRowKeys.length > 0;

    const computeSize = (size, count) => {
      if (count < size) {
        return count;
      }
      return size;
    };

    const getResponsiveColumns = matches => {
      if (matches.medium) {
        return this.state.columns.filter(column => !column.hideOnMedium);
      }
      if (matches.small) {
        return this.state.columns.filter(
          column => !column.hideOnMedium && !column.hideOnSmall
        );
      }
      return this.state.columns;
    };

    return (
      <div>
        <Drawer
          visible={this.state.visible}
          data={this.state.drawerData}
          closeDrawer={() => {
            this.setState({ visible: false, drawerData: {} });
          }}
        />
        <BreadCrumbComponent data={urls} titlePage="Merged Raw Images" />
        <Row>
          <Col>
            <Card>
              <Row gutter={{ md: 24 }}>
                <Col md={4} xs={24}>
                  <span className="label">Satellite</span>
                  <Select
                    placeholder="All"
                    value={this.state.satellite}
                    onChange={e => {
                      this.handleFilterChange("satellite", e);
                    }}
                    style={{ width: "100%" }}
                  >
                    <Option key={0} value="">
                      All
                    </Option>
                    {this.state.satellites
                      ? this.state.satellites.map(data => (
                          <Option key={data.id} value={data.name}>
                            {data.name}
                          </Option>
                        ))
                      : ""}
                  </Select>
                </Col>
                <Col md={4} xs={24}>
                  <span className="label">SHU Address</span>
                  <Input
                    defaultValue={this.state.shu_address}
                    onKeyPress={e => {
                      this.enterPressed(e, "shu_address");
                    }}
                    onBlur={e => {
                      this.handleFilterChange("shu_address", e.target.value);
                    }}
                    onChange={e => {
                      this.setState({
                        shu_address: e.target.value
                      });
                    }}
                  />
                </Col>
                <Col md={5} xs={24} style={{ textAlign: "left" }}>
                  <span className="label">Capture Time</span>
                  <div>
                    <RangePicker
                      format={dateFormat}
                      showTime={{
                        hideDisabledOptions: true,
                        defaultValue: [
                          moment("00:00:00", "HH:mm:ss"),
                          moment("23:59:59", "HH:mm:ss")
                        ]
                      }}
                      defaultValue={
                        this.state.capture_time_from
                          ? [
                              moment(
                                reverseUTC(this.state.capture_time_from),
                                dateFormat
                              ),
                              moment(
                                reverseUTC(this.state.capture_time_to),
                                dateFormat
                              )
                            ]
                          : null
                      }
                      style={{ width: "100%" }}
                      onChange={e => {
                        this.handleFilterChange("capture_time", e);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={24}>
            <Card style={{ marginTop: "20px" }}>
              <Row>
                <Col md={12}>
                  <div className="actionGroup">
                    <Dropdown
                      disabled={!hasSelected}
                      overlay={
                        <Menu
                          onClick={e => {
                              this.setState({ downloadTag: e }, () => this.handleActionChange())
                            }}
                          >
                          {Config.rawFiles.mergedRawImages.map(data => (
                            <Menu.Item key={data.val} value={data.val} name={data.text}>
                              {data.text}
                            </Menu.Item>
                          ))}
                        </Menu>
                      }
                    >
                      <Button>
                        {this.state.downloadTag ? this.state.downloadTag.item.props.name : "Download"} <DownOutlined />
                      </Button>
                    </Dropdown>
                    <span style={{ marginLeft: 8 }}>
                      {hasSelected
                        ? `Selected ${selectedRowKeys.length} out of ${this.state.pageSize}`
                        : ""}
                    </span>
                  </div>
                </Col>
                <Col md={12}>
                  <Row>
                    <Col md={14}>
                      <div
                        style={{
                          float: "right",
                          paddingTop: "22px"
                        }}
                      >
                        {this.state.mergedRawImageFiles
                          ? `${computeSize(
                              this.state.pageSize *
                                (this.state.currentPage - 1) +
                                1,
                              this.state.mergedRawImageFiles.pagination.count
                            )} - ${computeSize(
                              this.state.pageSize * this.state.currentPage,
                              this.state.mergedRawImageFiles.pagination.count
                            )} of ${
                              this.state.mergedRawImageFiles.pagination.count
                            } items`
                          : ""}
                      </div>
                    </Col>
                    <Col md={10}>
                      <div
                        style={{
                          width: "95%",
                          float: "right",
                          paddingTop: "16px",
                          marginBottom: "20px"
                        }}
                      >
                        <SearchComponent
                          name="search"
                          value={this.state.search}
                          onkeypress={e => {
                            this.enterPressed(e);
                          }}
                          onchange={e => {
                            this.setState({
                              search: e.target.value
                            });
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Media
                queries={{
                  xs: "(max-width: 1099px)",
                  small: "(min-width: 1100px) and (max-width: 1199px)",
                  medium: "(min-width: 1200px) and (max-width: 1299px)",
                  large: "(min-width: 1300px)"
                }}
              >
                {matches => (
                  <Fragment>
                    <Table
                      scroll={matches.xs ? { x: "max-content" } : {}}
                      size="middle"
                      rowSelection={rowSelection}
                      columns={getResponsiveColumns(matches)}
                      loading={this.state.loading}
                      dataSource={this.formatData(
                        this.state.mergedRawImageFiles
                      )}
                      pagination={false}
                      onChange={this.handleTableChange}
                      bordered
                    />
                  </Fragment>
                )}
              </Media>
              <Pagination
                style={{ marginTop: "20px", float: "right" }}
                showSizeChanger
                onChange={this.onPaginationChange}
                onShowSizeChange={this.onPaginationChange}
                current={this.state.currentPage}
                total={this.state.total}
                pageSizeOptions={["25", "50", "100"]}
                pageSize={this.state.pageSize}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    satellites: state.list.listSatellite,
    users: state.list.listUsers
  };
};

export default connect(mapStateToProps, actions)(withToast(MergedRawImages));
