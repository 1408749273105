import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import am4themes_animated from "@amcharts/amcharts4/themes/animated"; // eslint-disable-line no-use-before-define

am4core.useTheme(am4themes_animated);

export default class TreeMapComponent extends Component {
  createChart = chart => {
    chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect

    chart.data = this.props.data;

    // define data fields
    chart.dataFields.value = "value";
    chart.dataFields.name = "name";
    chart.dataFields.children = "children";

    chart.zoomable = false;

    const bgColor = new am4core.InterfaceColorSet();

    // level 0 series template
    const level0SeriesTemplate = chart.seriesTemplates.create("0");
    const level0ColumnTemplate = level0SeriesTemplate.columns.template;

    level0ColumnTemplate.column.cornerRadius(10, 10, 10, 10);
    level0ColumnTemplate.fillOpacity = 0;
    level0ColumnTemplate.strokeWidth = 10;
    level0ColumnTemplate.strokeOpacity = 0;

    // level 1 series template
    const level1SeriesTemplate = chart.seriesTemplates.create("1");
    const level1ColumnTemplate = level1SeriesTemplate.columns.template;

    level1SeriesTemplate.tooltip.animationDuration = 0;
    level1SeriesTemplate.strokeOpacity = 1;

    level1ColumnTemplate.column.cornerRadius(10, 10, 10, 10);
    level1ColumnTemplate.fillOpacity = 1;
    level1ColumnTemplate.strokeWidth = 10;
    level1ColumnTemplate.stroke = bgColor;

    const bullet1 = level1SeriesTemplate.bullets.push(
      new am4charts.LabelBullet()
    );

    bullet1.locationY = 0.5;
    bullet1.locationX = 0.5;
    bullet1.label.text = `{parentName} : {name}`;
    bullet1.label.fill = am4core.color("#ffffff");

    chart.maxLevels = 2;

    /* Add a lagend */
    if (this.props.isFullScreen) {
      chart.legend = new am4charts.Legend();
    }
  };

  componentDidMount() {
    const chart = am4core.create("chartdiv", am4charts.TreeMap);
    this.chart = this.createChart(chart);
  }

  componentDidUpdate() {
    if (this.chart) {
      this.chart.dispose();
    }
    const chart = am4core.create("chartdiv", am4charts.TreeMap);
    this.chart = this.createChart(chart);
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return <div id="chartdiv" style={{ width: "100%", height: "535px" }} />;
  }
}
