import React from "react";
import { Select, Icon, Divider } from "antd";

const { Option } = Select;

const index = 0;

class CreateTags extends React.Component {
  state = {
    items: []
  };

  render() {
    const { items } = this.state;
    const { input, meta } = this.props;
    return (
      <Select
        {...input}
        value={input.value ? input.value : []}
        onChange={input.onChange}
        mode="tags"
        tokenSeparators={[","]}
      />
    );
  }
}

export default CreateTags;
