import React, { Component } from "react";
import Plotly from "react-plotly.js";

export default class PlotlyTempGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const shapesArray = [
      {
        title: "Max",
        type: "line",
        xref: "paper",
        x0: 0,
        y0: this.props.max,
        x1: 1,
        y1: this.props.max,
        line: {
          color: "rgb(255, 0, 0)",
          width: 2,
          dash: "dot"
        }
      },
      {
        type: "line",
        xref: "paper",
        x0: 0,
        y0: this.props.min,
        x1: 1,
        y1: this.props.min,
        line: {
          color: "rgb(255, 0, 0)",
          width: 2,
          dash: "dot"
        }
      }
    ];
    this.props.X.forEach((element, index) => {
      if (this.props.low[index] !== this.props.high[index]) {
        const shapeData = {
          type: "line",
          layer: "below",
          x0: element,
          y0: this.props.low[index],
          x1: element,
          y1: this.props.high[index],
          line: {
            color: "#FFAE42",
            width: 2
          }
        };
        shapesArray.push(shapeData);
      }
    });
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <Plotly
          data={[
            {
              x: this.props.X,
              y: this.props.low,
              type: "scatter",
              mode: "markers",
              marker: { color: "#FFAE42", size: "2" },
              name: "Min"
            },
            {
              x: this.props.X,
              y: this.props.high,
              type: "scatter",
              mode: "markers",
              marker: { color: "#FFAE42", size: "2" },
              name: "Max"
            },
            {
              x: this.props.X,
              y: this.props.Y,
              type: "scatter",
              mode: "lines+markers",
              line: { color: "#00d69f" },
              marker: { color: "#17BECF", size: "4" },
              name: "Avg"
            }
          ]}
          layout={{
            showlegend: false,
            hovermode: "x unified",
            title: {
              text: this.props.title,
              yref: "paper",
              y: 1.2,
              x: 0,
              xanchor: "left",
              yanchor: "bottom"
            },
            xaxis: {
              showgrid: false,
              title: {
                text: "Capture Time",
                font: {
                  size: 15,
                  color: "#7f7f7f"
                }
              }
            },
            yaxis: {
              rangemode: "tozero",
              title: {
                text: this.props.dataType,
                font: {
                  size: 15,
                  color: "#7f7f7f"
                }
              },
              fixedrange: true
            },
            autosize: true,
            shapes: shapesArray
          }}
          useResizeHandler
          style={{
            width: "100%",
            height: "300px",
            x: 0,
            xanchor: "left",
            y: 0,
            yanchor: "top"
          }}
          tracetoggle={false}
          config={{
            displayModeBar: false,
            responsive: true
          }}
        />
      </div>
    );
  }
}
