import React, { Component } from "react";
import { Form, Card, Row, Col, Select, Spin } from "antd";
import { Field, reduxForm, FieldArray } from "redux-form";
import { connect } from "react-redux";
import {
  renderTextArea,
  renderInput,
  renderSelect
} from "./MissionFormComponents";
import GenerateBatchCommand from "./GenerateBatchCommand";
import CommandBatchList from "./CommandBatchList";
import Const from "../../Config/const";
import DatePickerWithTimezone from "./DatePickerWithTimezone";
import API from "../API/index";
import history from "../../history";

const { Option } = Select;

class CommandBatchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: "",
      loadingSubmit: false
    };
  }

  formatEditValues = data => {
    Object.keys(data).map(e => {
      if (e === "command_batch_commands_list") {
        for (let i = 0; i < data[e].length; i++) {
          delete data[e][i].command_type;
          delete data[e][i].created_time;
          delete data[e][i].section_number;
          delete data[e][i].execution_time;
        }
      }
    });
    return data;
  };

  onSubmit = formValues => {
    this.setState({
      loadingSubmit: true
    });
    if (this.props.view === "add") {
      API.post("/missions_management/command_batches/?", formValues)
        .then(res => {
          this.setState({
            loadingSubmit: false
          });
          history.push(
            `/missions_management/command_batches/view/${res.data.id}`
          );
        })
        .catch(err => {
          console.log(err);
          alert("Oops, Something went wrong!");
          console.log(err);
          this.setState({
            loadingSubmit: false
          });
        });
    } else {
      API.patch(
        `/missions_management/command_batches/${this.props.commandBatchId}/`,
        this.formatEditValues(formValues)
      )
        .then(res => {
          this.setState({
            loadingSubmit: false
          });
          alert("Upload Mission Edited");
          window.location.reload();
        })
        .catch(err => {
          this.setState({
            loadingSubmit: false
          });
          alert("Oops, Something went wrong!");
          console.log(err);
        });
    }
  };

  render() {
    const commandVal =
      Object.keys(this.props.initialValues).length !== 0
        ? this.props.initialValues.batch_command_list
        : "";
    return (
      <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Spin spinning={this.props.loading || this.state.loadingSubmit}>
          <Card title="Command Batches Parameters">
            <Row>
              <Field
                name="name"
                component={renderInput}
                label="Name"
                type="text"
              />
            </Row>
            <Row>
              <Field
                name="upload_time"
                component={DatePickerWithTimezone}
                label="Upload Time"
                note="You are 8 hours ahead of server time."
              />
            </Row>
            <Row>
              <Field
                name="description"
                component={renderTextArea}
                label="Description"
              />
            </Row>
            <Row>
              <Field
                name="status"
                component={renderSelect}
                label="Status"
                defVal="Pending"
                note="Kindly input remarks for Failed/Cancelled missions."
              >
                {Const.mission.acquisition.addMissionFilterStats.map(data => (
                  <Option key={data} value={data.val}>
                    {data.text}
                  </Option>
                ))}
              </Field>
            </Row>
            <Row>
              <Field
                name="remarks"
                component={renderTextArea}
                label="Remarks"
              />
            </Row>
            <Row>
              <Field
                name="satellite"
                component={renderSelect}
                label="Satellite"
              >
                {Const.satellites.map(data => (
                  <Option key={data} value={data.val}>
                    {data.text}
                  </Option>
                ))}
              </Field>
            </Row>
            <Row>
              {this.props.view === "edit" ? (
                <GenerateBatchCommand command={commandVal} />
              ) : (
                ""
              )}
            </Row>
            <Card className="m-t-10px" title="Command Batch List">
              <FieldArray
                name="command_batch_commands_list"
                component={CommandBatchList}
              />
            </Card>
          </Card>
          <button className="ant-btn ant-btn-primary m-t-10px" type="submit">
            Submit
          </button>
        </Spin>
      </Form>
    );
  }
}

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues // retrieve name from redux store
});

const validate = formValues => {
  const errors = {};
  if (!formValues.name) {
    errors.name = "You must enter a name";
  }
};

export default connect(mapStateToProps)(
  reduxForm({
    form: "CommandBatchForm",
    validate,
    enableReinitialize: true
  })(CommandBatchForm)
);
