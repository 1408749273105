import {
  REPORT_MISSION_STATUS,
  REPORT_CAPTURE_STATUS,
  REPORT_BREAKDOWN_STATUS,
  REPORT_MISSIONTYPE_STATUS,
  REPORT_COVERAGE_MAP
} from "../Actions/types";

const INITIAL_STATE = {
  missionStats: null,
  captureStats: null,
  breakdownStats: null,
  missionTypeStats: null,
  coverageMapStats: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REPORT_MISSION_STATUS:
      return {
        ...state,
        missionStats: action.payload
      };
    case REPORT_CAPTURE_STATUS:
      return {
        ...state,
        captureStats: action.payload
      };
    case REPORT_BREAKDOWN_STATUS:
      return {
        ...state,
        breakdownStats: action.payload
      };
    case REPORT_MISSIONTYPE_STATUS:
      return {
        ...state,
        missionTypeStats: action.payload
      };
    case REPORT_COVERAGE_MAP:
      return {
        ...state,
        coverageMapStats: action.payload
      };
    default:
      return state;
  }
}
