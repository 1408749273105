import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Layout, Button, Drawer, Badge } from "antd";
import { connect } from "react-redux";
import history from "../../history";
import DownloadCartButton from "./DownloadCartButton";
import * as actions from "../../Actions";

function NavComponent(props) {
  // const cartCount = props.cart_count;
  const cartCount = JSON.parse(localStorage.getItem("cart"));
  const [user, setUser] = useState("");

  useEffect(() => {
    setUser(localStorage.getItem("auth"));
  }, []);

  const [visible, setVisible] = useState(false);

  const { Header } = Layout;
  const logOut = () => {
    localStorage.removeItem("auth");
    localStorage.removeItem("guest");
    localStorage.removeItem("expiry");
    localStorage.removeItem("rules");
    history.push("/login");
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  if (user) {
    return (
      <Header style={{ background: "#fff", padding: 0 }}>
        <div className="navbar">
          {cartCount ? (
            <DownloadCartButton
              cartCount={cartCount.length}
              cartDetails={cartCount}
            />
          ) : (
            ""
          )}
          <div className="navbar__items">Profile</div>
          <div className="navbar__items">Search</div>
          <Button
            className="navbar__logout"
            onClick={() => {
              logOut();
            }}
          >
            Logout
          </Button>
          <Button
            icon="bars"
            className="navbar__drawer"
            onClick={() => showDrawer()}
          />

          <Drawer
            title="Basic Drawer"
            placement="right"
            closable={false}
            onClose={() => {
              onClose();
            }}
            visible={visible}
          >
            <Badge count={cartCount}>
              <a href="#" className="head-example" />
            </Badge>
            <p>Profile</p>
            <p>Search</p>
            <p>Log-out</p>
          </Drawer>
        </div>
      </Header>
    );
  }
  return (
    <Header style={{ background: "#fff", padding: 0 }}>
      <div className="navbar">
        <Button
          className="navbar__logout"
          onClick={() => {
            history.push({
              pathname: "/login",
              // search: "?query=abc",
              state: { from: props.location }
            });
          }}
        >
          Login
        </Button>
        <Button
          icon="bars"
          className="navbar__drawer"
          onClick={() => showDrawer()}
        />

        <Drawer
          title="Basic Drawer"
          placement="right"
          closable={false}
          onClose={() => {
            onClose();
          }}
          visible={visible}
        >
          <p>Log-in</p>
        </Drawer>
      </div>
    </Header>
  );
}

const mapStateToProps = ({ downloadCart }) => {
  return {
    cart_count: downloadCart.download_cart
  };
};

export default connect(mapStateToProps, actions)(withRouter(NavComponent));
