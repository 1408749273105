/* eslint-disable array-callback-return */
import React, { Component, useRef, useEffect } from "react";
import L from "leaflet";
import {
  Map,
  TileLayer,
  Marker,
  CircleMarker,
  GeoJSON,
  Popup
} from "react-leaflet";
import axios from "axios";
import moment from "moment";
import "leaflet/dist/leaflet.css";
import "../../Styles/Components/grsComponent.css";
import markerImage from "../../Assets/Images/marker.png";
import grsImage from "../../Assets/Images/grs.png";
import iconSatellite from "../../Assets/Images/diwata2.png";
import Satellite from "./Satellite";

let trackInterval;
let positionInterval;

const marker = new L.Icon({
  iconUrl: markerImage,
  iconRetinaUrl: markerImage,
  iconSize: new L.Point(30, 30)
});

const grsMarker = new L.Icon({
  iconUrl: grsImage,
  iconRetinaUrl: grsImage,
  iconSize: new L.Point(20, 20)
});

const satelliteMarker = new L.Icon({
  iconUrl: iconSatellite,
  iconRetinaUrl: iconSatellite,
  iconSize: new L.Point(50, 50)
});

function PointMarker(props) {
  const markerRef = useRef(null);
  const { mission, openPopup, index, setSelectedIndex } = props;

  useEffect(() => {
    if (openPopup) {
      markerRef.current.leafletElement.openPopup();
    } else {
      markerRef.current.leafletElement.options.leaflet.map.closePopup()
    }
  }, [openPopup]);

  return (
    <Marker
      key={index}
      position={mission.coordinates}
      icon={marker}
      ref={markerRef}
      onclick={() => setSelectedIndex(index)}
    >
      <Popup offset={[0, -5]} onClose={() => setSelectedIndex(null)}>
        <div><b>{mission.name}</b></div>
        <div>{mission.capture_time}</div>
      </Popup>
    </Marker>
  );
}

export default class MapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      passes: {},
      marker: [],
      center: [],
      counter: 0,
      satellitePosition: "",
      coordinates: null
    };
  }

  componentDidMount() {
    this.fetchTLE(225);
    setInterval(() => {
      clearInterval(trackInterval);
      clearInterval(positionInterval);
      this.fetchTLE(225);
    }, 10 * 60 * 1000); // recompute every 10mins
  }

  componentDidUpdate(prevProps) {
    if (prevProps.coverageData !== this.props.coverageData) {
      this.setState({ coverageData: this.props.coverageData })
    }

    if (prevProps.data !== this.props.data) {
      this.setState({ data: this.props.data })
    }

    if (prevProps.zoomToCoverege !== this.props.zoomToCoverege) {
      this.refs.map.leafletElement.fitBounds(L.geoJson(this.state.coverageData).getBounds());
    }

    if (prevProps.selectedIndex !== this.props.selectedIndex) {
      this.setState({ selectedIndex: this.props.selectedIndex })
    }
  }

  fetchTLE = counterLimit => {
    axios
      .get(`https://api.orbit-v2.phl-microsat.upd.edu.ph/tle/latest/43678`)
      .then(res => {
        this.setState({ tle: res.data.data })
        return res.data.data
      })
      .then(tle => {
        let counter = 0;
        let tempArray = [];
        const now = new Date();
        trackInterval = setInterval(() => {
          const res = Satellite(tle, now, this.state.counter)
          tempArray[res.id] = res;

          // overall datapoint count
          this.setState({ counter: this.state.counter+1 });
          // counter for limiting interval loop
          counter = counter + 1;

          // End the loop once data points reached the counterLimit
          if (counter === counterLimit) {
            this.setState({
              track: tempArray,
              counter: 0
            });
            clearInterval(trackInterval);
          }
        }, 1);

        positionInterval = setInterval(() => {
          const data = Satellite(tle, new Date(), 0)
          this.setState({ coordinates: data.coordinates });
        }, 2000);
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
      const corner1 = L.latLng(-90, -360);
      const corner2 = L.latLng(90, 360);
      const bounds = L.latLngBounds(corner1, corner2);
      return (
        <Map
          ref="map"
          style={{ height: "400px", width: "100%", position: "absolute" }}
          zoom={2}
          center={[12.57513, 122.27081]}
          // scrollWheelZoom={false}
          maxBoundsViscosity={1.0}
          maxBounds={bounds}
        >
          <TileLayer
            url="http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
            attribution= '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors © <a href="https://carto.com/about-carto/" class="attribution">light_all</a>'
          />
          {this.state.coverageData && <GeoJSON data={this.state.coverageData} />}
          {this.state.data?.length > 0 
            ? this.state.data.map((mission, index) => {
                if (mission.coordinates.length > 0) {
                    return (
                      <PointMarker
                        openPopup={this.state.selectedIndex===index}
                        mission={mission}
                        index={index}
                        setSelectedIndex={this.props.setSelectedIndex}
                      />
                    );
                }
              })
          : ""}
          {this.props.GRSList && this.props.GRSList.map(grs => (
            <Marker
              key={grs.id}
              position={[grs.location_lat, grs.location_lng]}
              icon={grsMarker}
            >
              <Popup offset={[0, -5]}>
                <div><b>{grs.name}</b></div>
              </Popup>
            </Marker>
          ))}
          {this.state.track?.length > 0 
            ? this.state.track.map(position => (
              <CircleMarker
                key={`track${position.id}`}
                center={[position.coordinates[1], position.coordinates[0]]}
                radius={0.25}
                fillOpacity={1}
                color="red"
              >
                <Popup offset={[0, -5]}>
                  <div><b>{moment(position.pass).format('lll')}</b></div>
                  <div>lat: {position.coordinates[1].toFixed(2)}</div>
                  <div>lon: {position.coordinates[0].toFixed(2)}</div>
                  <div>elev: {position.coordinates[2].toFixed(2)}</div>
                </Popup>
              </CircleMarker>
            ))
          : ""}
          {this.state.coordinates && (
            <Marker
              key="satellite"
              position={[this.state.coordinates[1], this.state.coordinates[0]]}
              icon={satelliteMarker}
            >
              <Popup offset={[0, -5]}>
                <div><b>Diwata-2</b></div>
              </Popup>
            </Marker>
          )}
        </Map>
      );
  }
}
