import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form, Icon, Input, Button, Spin } from "antd";
import PropTypes from "prop-types";
import * as actions from "../../Actions";
import staminaLogo from "../../Assets/Images/s4s.png";
import planet from "../../Assets/Images/planet.jpg";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: ""
    };
  }

  handleUser = val => {
    this.setState({
      username: val
    });
  };

  handlePassword = val => {
    this.setState({
      password: val
    });
  };

  submitForm = e => {
    e.preventDefault();
    this.props.form.validateFields(err => {
      if (!err) {
        this.props.loginStart(
          this.state.username,
          this.state.password,
          this.props.location.state
        );
      }
    });
  };

  render() {
    return (
      <div className="login" style={{ backgroundImage: `url(${planet})` }}>
        <Form onSubmit={e => this.submitForm(e)} className="login-form">
          <div>
            <img className="logo" src={staminaLogo} alt="logo" />
          </div>
          <div className="login__title">DIWATA OPERATIONS SOFTWARE</div>
          <Form.Item>
            {this.props.form.getFieldDecorator("username", {
              setFieldsValue: this.state.username,
              rules: [
                { required: true, message: "Please input your username!" }
              ]
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="text"
                name="username"
                onChange={e => this.handleUser(e.target.value)}
                placeholder="email"
              />
            )}
          </Form.Item>
          <Form.Item>
            {this.props.form.getFieldDecorator("password", {
              setFieldsValue: this.state.password,
              rules: [
                { required: true, message: "Please input your Password!" }
              ]
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="password"
                name="password"
                onChange={e => this.handlePassword(e.target.value)}
                placeholder="*****"
              />
            )}
          </Form.Item>
          <div className="errorDescription">
            {this.props.error ? this.props.error.error_description : ""}
          </div>
          <Form.Item>
            {this.props.status ? (
              <div className="loginSpinner">
                <Spin />
              </div>
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Log in
              </Button>
            )}
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    status: state.auth.status,
    error: state.auth.error
  };
};

const WrappedNormalLoginForm = Form.create({ name: "normal_login" })(Login);

export default connect(
  mapStateToProps,
  actions
)(withRouter(WrappedNormalLoginForm));

// Specifies the default values for props:
Login.defaultProps = {
  loginStart: ""
};

Login.propTypes = {
  loginStart: PropTypes.func
};
