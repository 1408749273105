import axios from "axios";
import tokenProvider from "axios-token-interceptor";
import moment from "moment";
import history from "../../history";
import { getTokenMaxAge, getToken } from "../CommonFunctions";

/**
 * Axios instance
 * baseURL: root config
 */
const API = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT
});

/**
 * Interceptor to attach the auth token
 */
API.interceptors.request.use(
  tokenProvider({
    getToken: () => getToken(),
    getMaxAge: () => getTokenMaxAge()
  })
);

/**
 * Interceptor to validate the validity of token
 */
API.interceptors.request.use(
  function(config) {
    const expiry = localStorage.getItem("expiry");
    const now = moment();
    if (Date.parse(now) > Date.parse(expiry)) {
      alert("Your session has expired");
      localStorage.removeItem("auth");
      localStorage.removeItem("expiry");
      history.push("/login");
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

/**
 * Cancel token
 */
API.CancelToken = axios.CancelToken;

export default API;
