import {
  LIST_SATELLITE,
  LIST_PAYLOAD,
  LIST_RECEIVING_STATION,
  LIST_TELEMETRY_TYPES,
  LIST_USERS,
  LIST_IMAGE_TAGS,
  LIST_MISSION_TYPES,
  LIST_MISSION_STATUSES,
  LIST_POINTING_MODES,
  LIST_MISSION_STATUS_TYPES
} from "../Actions/types";

const INITIAL_STATE = {
  listSatellite: null,
  listPayload: null,
  listRecevingStation: null,
  listTelemetryTypes: null,
  listUsers: null,
  listImageTags: null,
  listMissionTypes: null,
  listMissionStatuses: null,
  listPointingModes: null,
  listMissionStatusTypes: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LIST_SATELLITE:
      return {
        ...state,
        listSatellite: action.payload
      };
    case LIST_PAYLOAD:
      return {
        ...state,
        listPayload: action.payload
      };
    case LIST_RECEIVING_STATION:
      return {
        ...state,
        listRecevingStation: action.payload
      };
    case LIST_TELEMETRY_TYPES:
      return {
        ...state,
        listTelemetryTypes: action.payload
      };
    case LIST_USERS:
      return {
        ...state,
        listUsers: action.payload
      };
    case LIST_IMAGE_TAGS:
      return {
        ...state,
        listImageTags: action.payload
      };
    case LIST_MISSION_TYPES:
      return {
        ...state,
        listMissionTypes: action.payload
      };
    case LIST_MISSION_STATUSES:
      return {
        ...state,
        listMissionStatuses: action.payload
      };
    case LIST_POINTING_MODES:
      return {
        ...state,
        listPointingModes: action.payload
      };
    case LIST_MISSION_STATUS_TYPES:
      return {
        ...state,
        listMissionStatusTypes: action.payload
      };
    default:
      return state;
  }
}
