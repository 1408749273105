/* eslint-disable camelcase */
import React, { Component } from "react";
import { Row, Card, Form, Col, Select, Spin } from "antd";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import * as actions from "../../Actions";
import { splitString } from "../CommonFunctions";
import {
  renderInput,
  renderSelect,
  renderSelectTags,
  renderTextArea,
  renderCheckBox
} from "../MissionsComponents/MissionFormComponents";
import API from "../API/index";
import history from "../../history";
import Map from "./ViewMap";

const { Option } = Select;

class CaptureGroupProductForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: "",
      loadingSubmit: false
    };
  }

  componentDidMount() {
    this.props.listImageTags();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.imageTagsList !== this.props.imageTagsList) {
      this.passData(this.props.imageTagsList);
    }
  }

  passData(data) {
    const tagList = data.map(tag => <Option key={tag.name}>{tag.name}</Option>);
    this.setState({ imageTagsList: tagList });
  }

  processValues = data => {
    const formValues = {
      description: data.description,
      image_tags: data.image_tags,
      product_type: data.product_type,
      capture_group: data.capture_group,
      is_published: data.is_published
    };
    return formValues;
  };

  onSubmit = formValues => {
    const freezeData = JSON.parse(JSON.stringify(formValues));
    this.setState({
      loadingSubmit: true
    });
    API.patch(
      `/data_management/capture_group_products/${this.props.captureGroupProductId}/`,
      this.processValues(freezeData)
    )
      .then(res => {
        this.setState({
          loadingSubmit: false
        });
        alert("Capture Group Product Edited");
        history.push(
          `/data_management/capture_group_products/view/${this.props.captureGroupProductId}`
        );
      })
      .catch(err => {
        this.setState({
          loadingSubmit: false
        });
        alert("Oops, Something went wrong!");
        console.log(err);
      });
  };

  render() {
    const {
      capture_group_product_id,
      md5sum,
      footprint,
      file,
      thumbnail,
      image_tags,
      uploaded_by
    } = this.props.initialValues;

    return (
      <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Spin spinning={this.props.loading || this.state.loadingSubmit}>
          <Card title="Capture Group Product Parameters">
            <Row style={{ marginBottom: "20px" }}>
              <Col md={5}>
                <label className="label" htmlFor="ID">
                  Capture Group Product ID:
                </label>
              </Col>
              <Col md={10}>{capture_group_product_id}</Col>
            </Row>
            <Row style={{ marginBottom: "20px" }}>
              <Col md={5}>
                <label className="label" htmlFor="Md5sum">
                  Md5sum:
                </label>
              </Col>
              <Col md={10}>{md5sum}</Col>
            </Row>
            <Row>
              <Field
                name="description"
                component={renderTextArea}
                label="Description"
              />
            </Row>
            <Row style={{ marginBottom: "20px" }}>
              <Col md={5}>
                <label className="label" htmlFor="Footprint">
                  Footprint:
                </label>
                <p className="mute">{!footprint && "No data"}</p>
              </Col>
              <Col md={10}>
                <Map footprint={footprint} />
              </Col>
            </Row>
            <Row style={{ marginBottom: "20px" }}>
              <Col md={5}>
                <label className="label" htmlFor="File">
                  File:
                </label>
              </Col>
              <Col md={10}>
                {file ? (
                  <a href={file} target="_blank" rel="noreferrer">
                    {file && splitString(file)}
                  </a>
                ) : (
                  <p style={{ fontStyle: "italic" }}>No data for file</p>
                )}
              </Col>
            </Row>
            <Row style={{ marginBottom: "20px" }}>
              <Col md={5}>
                <label className="label" htmlFor="Thumbnail">
                  Thumbnail:
                </label>
              </Col>
              <Col md={10}>
                {thumbnail ? (
                  <a href={thumbnail} target="_blank" rel="noreferrer">
                    {thumbnail && splitString(thumbnail)}
                  </a>
                ) : (
                  <p style={{ fontStyle: "italic" }}>No data for thumbnail</p>
                )}
              </Col>
            </Row>
            <Row>
              {image_tags && (
                <Field
                  name="image_tags"
                  component={renderSelectTags}
                  label="Image Tags"
                  defVal={image_tags}
                >
                  {this.state.imageTagsList}
                </Field>
              )}
            </Row>
            <Row>
              <Field
                name="product_type"
                component={renderSelect}
                label="Product Type"
              >
                <Option key="Stacked" value="Stacked">
                  Stacked
                </Option>
                <Option key="Mosaic" value="Mosaic">
                  Mosaic
                </Option>
              </Field>
            </Row>
            <Row>
              <Field
                name="capture_group"
                component={renderInput}
                label="Capture Group"
                type="text"
              />
            </Row>
            <Row style={{ marginBottom: "20px" }}>
              <Col md={5}>
                <label className="label" htmlFor="Uploaded by">
                  Uploaded by:
                </label>
              </Col>
              <Col md={10}>{uploaded_by}</Col>
            </Row>
            <Row className="form-row">
              <Field
                name="is_published"
                component={renderCheckBox}
                label="Published"
              />
            </Row>
          </Card>
          <button className="ant-btn ant-btn-primary m-t-10px" type="submit">
            Save
          </button>
        </Spin>
      </Form>
    );
  }
}

const validate = formValues => {
  const errors = {};
  if (!formValues.payload) {
    errors.payload = "You must enter a payload";
  }
  if (!formValues.mission_name) {
    errors.mission_name = "You must enter a mission name";
  }
  return errors;
};

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues, // retrieve name from redux store
  imageTagsList: state.list.listImageTags
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "captureGroupProductForm",
    validate,
    enableReinitialize: true
  })(CaptureGroupProductForm)
);
