import React, { Fragment } from "react";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import DownloadForm from "../../Components/MissionsComponents/DownloadForm";

export default function AddDownloadMissionsPage() {
  const urls = {
    "/": "MISSIONS",
    "/missions_management/download_missions": "DOWNLOAD",
    "/add": "ADD"
  };
  return (
    <Fragment>
      <BreadCrumbComponent data={urls} titlePage="Add Download Missions" />
      <DownloadForm
        view="add"
        loading={false}
        initialValues={{
          satellite: "Diwata-2",
          status: "Pending"
        }}
      />
    </Fragment>
  );
}
