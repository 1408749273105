import React, { Component } from "react";
import { Row, Col, Empty } from "antd";
import { connect } from "react-redux";
import * as actions from "../../Actions";

class SHU extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    this.props.shuTracker(this.props.satellite, this.props.selected);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.satellite !== this.props.satellite ||
      prevProps.selected !== this.props.selected
    ) {
      this.props.shuTracker(this.props.satellite, this.props.selected);
    }
  }

  render() {
    const data = this.props.tracker ? Object.entries(this.props.tracker) : [];
    return (
      <div style={{ textAlign: "center" }}>
        {this.props.tracker === "Error" ? (
          <Empty />
        ) : (
          <div>
            {this.props.tracker && data.length === 1 ? (
              // IF THERE IS ONE BAND IN SELECTED PAYLOAD
              <div>
                {data.map((value, index) => {
                  return (
                    <div key={index}>
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "45px"
                        }}
                      >
                        {value[1][this.props.dataType]}
                      </div>
                      <div>{value[0]}</div>
                    </div>
                  );
                })}
              </div>
            ) : (
              // IF THERE IS MORE THAN ONE BAND IN SELECTED PAYLOAD
              <Row style={{ textAlign: "center" }} type="flex">
                {data.map((value, index) => {
                  return (
                    <Col md={{ span: 24 }} lg={{ span: 12 }} key={index}>
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: data.length === 2 ? "40px" : "30px"
                        }}
                      >
                        {value[1][this.props.dataType]}
                      </div>
                      <div>{value[0]}</div>
                    </Col>
                  );
                })}
              </Row>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    tracker: state.shu.tracker
  };
};

export default connect(mapStateToProps, actions)(SHU);
