import React, { Component } from "react";
import { Drawer, Divider, Table, Button } from "antd";
import Fullscreen from "react-full-screen";
import { toTimeString } from "../CommonFunctions";
import noImage from "../../Assets/Images/noImage.png";

export default class DrawerCaptureGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      isFull: false,
      dataColumns: [
        {
          title: "",
          dataIndex: "label",
          key: "label"
        },
        {
          title: "",
          dataIndex: "value",
          key: "value",
          width: "75%"
        }
      ],
      groupProductColumns: [
        {
          title: "Image",
          dataIndex: "thumbnail",
          key: "thumbnail",
          align: "center"
        },
        {
          title: "Product ID",
          dataIndex: "capture_group_product_id",
          key: "capture_group_product_id"
        },
        {
          title: "Product Type",
          dataIndex: "product_type",
          key: "product_type"
        }
      ],
      dataColumnsImages: [
        {
          title: "Image",
          dataIndex: "label",
          key: "label",
          align: "center"
        },
        {
          title: "Capture ID",
          dataIndex: "value",
          key: "value",
          width: "85%"
        }
      ],
      dataInfoData: [],
      dataImages: []
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setDataInformation(this.props.data);
    }
  }

  setDataInformation = data => {
    const capturesArray = [];
    const productsArray = [];
    const currentInfoData = [
      {
        key: "1",
        label: "Mission",
        value: data.mission ? data.mission.name : ""
      },
      {
        key: "2",
        label: "Description",
        value: data.description
      },
      {
        key: "3",
        label: "Payload",
        value: data.payload
      },
      {
        key: "4",
        label: "Created Time",
        value: toTimeString(data.created_time)
      },
      {
        key: "5",
        label: "Added by",
        value: data.added_by
      },
      {
        key: "6",
        label: "Published?",
        value:
          typeof data.is_published === "boolean"
            ? data.is_published.toString()
            : ""
      }
    ];

    if (data.captures && data.captures.length > 0) {
      data.captures.map((image, index) => {
        return capturesArray.push({
          key: index,
          label: (
            <img
              src={image.thumbnail ? image.thumbnail : noImage}
              alt="rawImage"
              height="40px"
            />
          ),
          value: image.capture_id
        });
      });
    }

    if (data.capture_group_products && data.capture_group_products.length > 0) {
      data.capture_group_products.map((product, index) => {
        return productsArray.push({
          key: index,
          thumbnail: (
            <img
              src={product.thumbnail ? product.thumbnail : noImage}
              alt="rawImage"
              height="40px"
            />
          ),
          capture_group_product_id: product.capture_group_product_id,
          product_type: product.product_type
        });
      });
    }

    this.setState({
      dataInfoData: currentInfoData,
      groupProductData: productsArray,
      captures: capturesArray
    });
  };

  render() {
    const { data, closeDrawer, visible } = this.props;
    const {
      dataColumns,
      dataInfoData,
      groupProductColumns,
      groupProductData,
      dataColumnsImages,
      captures
    } = this.state;
    if (data) {
      return (
        <div>
          <Drawer
            title={data.capture_group_id}
            placement="right"
            closable={false}
            onClose={closeDrawer}
            visible={visible}
            width="600"
          >
            <div className="imageContainer">
              <div style={{ textAlign: "center" }}>
                <Fullscreen
                  enabled={this.state.isFull}
                  onChange={isFull => this.setState({ isFull })}
                >
                  <img
                    className={`${this.state.isFull ? `imageFullscreen` : ""}`}
                    src={data.thumbnail ? data.thumbnail : noImage}
                    alt="dataImages"
                    width={!this.state.isFull ? "100%" : ""}
                  />
                </Fullscreen>
              </div>
              <div className="fullscreenButton">
                <Button
                  icon="fullscreen"
                  onClick={() => this.setState({ isFull: true })}
                />
              </div>
            </div>
            <Table
              style={{ marginTop: "20px" }}
              columns={dataColumns}
              dataSource={dataInfoData}
              bordered
              pagination={false}
              showHeader={false}
              size="small"
            />
            <Divider style={{ marginTop: "50px" }}>
              Capture Group Products
            </Divider>
            <Table
              style={{ marginTop: "20px" }}
              columns={groupProductColumns}
              dataSource={groupProductData}
              bordered
              pagination={false}
              showHeader
            />
            <Divider style={{ marginTop: "50px" }}>Captures</Divider>
            <Table
              style={{ marginTop: "20px" }}
              columns={dataColumnsImages}
              dataSource={captures}
              bordered
              pagination={false}
            />
          </Drawer>
        </div>
      );
    }
    return null;
  }
}
