import React, { PureComponent } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { Row, Col } from "antd";

// const COLORS = ["#7BD1AA", "#E56F6F", "#FFC300"];

const COLORS = [
  "#7BD1AA",
  "#E56F6F",
  "#B28DFF",
  "#6EB5FF",
  "#FFC300",
  "#FF66FF",
  "#C8C8C8"
];

export default class MissionActPieChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0
    };
  }

  // onPieEnter = (data, index) => {
  //   this.setState({
  //     // activeIndex: index,
  //   });
  // };

  render() {
    const total = this.props.total !== 0 ? this.props.total : 1;

    const data = [
      { name: "Routine operations", value: this.props.routineOps },
      {
        name: "Special operations - Disaster",
        value: this.props.specialOpsDisaster
      },
      {
        name: "Special operations - Request",
        value: this.props.specialOpsRequest
      },
      { name: "Calibration Testing", value: this.props.calibrationTest },
      { name: "Housekeeping operations", value: this.props.housekeepingOps },
      { name: "Anomaly operations", value: this.props.anomalyOps },
      { name: "None", value: this.props.none }
    ];

    return (
      <div>
        <Row type="flex">
          <Col sm={24} md={12}>
            <div
              style={{ paddingLeft: "5%", paddingTop: "10px", height: "100%" }}
            >
              <div>
                {data.map((stats, index) => (
                  <div style={{ paddingTop: "10px" }} key={index}>
                    <i
                      className="fas fa-square"
                      style={{ color: COLORS[index], paddingRight: "10px" }}
                    />
                    {stats.name}
                    <span style={{ paddingLeft: "20px" }}>
                      {total !== 0
                        ? Math.floor((stats.value / total) * 100).toFixed(1)
                        : 0}
                      %
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </Col>
          <Col sm={24} md={12}>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={600} height={300}>
                <Pie
                  // activeIndex={this.state.activeIndex}
                  // activeShape={renderActiveShape}
                  data={data}
                  innerRadius={65}
                  outerRadius={110}
                  fill="#8884d8"
                  dataKey="value"
                  // onMouseEnter={this.onPieEnter}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Col>
        </Row>
      </div>
    );
  }
}
