import React, { Component } from "react";
import { Menu, Dropdown, Icon, message, Row, Col, Button, Empty } from "antd";
import moment from "moment";
import { connect } from "react-redux";
import { grsLocation } from "../CommonFunctions";
import * as actions from "../../Actions";
import "../../Styles/Components/grsComponent.css";

// COMPONENTS
import Countdown from "./Countdown";
import ShowMapWithGRS from "./ShowMapWithGRS";

class GRSPass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "ASTI"
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.satellite !== this.props.satellite) {
      this.props.passNextGRS(this.props.satellite, grsLocation("ASTI"));
      this.clearData();
    }
  }

  clearData() {
    this.setState({ selected: "ASTI" });
  }

  render() {
    const onClick = ({ key }) => {
      message.info(`Selected GRS Pass: ${key}`);
      this.setState({ selected: key });
      this.props.passNextGRS(this.props.satellite, grsLocation(key));
    };

    const menu = (
      <Menu onClick={onClick}>
        <Menu.Item key="ASTI">ASTI</Menu.Item>
        <Menu.Item key="Tohoku">Tohoku</Menu.Item>
      </Menu>
    );
    return (
      <div>
        <div style={{ paddingBottom: "20px" }}>
          <span className="panel__title label">NEXT GRS PASS</span>
          <span className="span_component" style={{ float: "right" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button
                type="link"
                className="ant-dropdown-link"
                style={{ color: "black" }}
              >
                {this.state.selected} <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </div>
        {this.props.passes ? (
          <div>
            <div
              className="map_small"
              style={{ height: "300px", position: "relative" }}
            >
              <ShowMapWithGRS
                satellite={this.props.satellite}
                riseTime={this.props.passes.pass.rise_time}
                setTime={this.props.passes.pass.set_time}
                grsCoordinates={grsLocation(this.state.selected)}
              />
            </div>
            <div>
              <Row gutter={16}>
                <Col sm={8} md={6} style={{ textAlign: "center" }}>
                  <Countdown
                    date={this.props.passes.pass.rise_time}
                    satellite={this.props.satellite}
                    grs={grsLocation(this.state.selected)}
                  />
                </Col>
                <Col sm={24} md={18} style={{ paddingTop: "25px" }}>
                  <Row gutter={8} className="details">
                    <Col span={8}>
                      <strong>Rise Time</strong>
                    </Col>
                    <Col span={16}>
                      {moment(this.props.passes.pass.rise_time).format(
                        "MMMM DD YYYY, HH:mm:ss Z"
                      )}
                    </Col>
                  </Row>
                  <Row gutter={8} className="details">
                    <Col span={8}>
                      <strong>Max Altitude Time</strong>
                    </Col>
                    <Col span={16}>
                      {moment(this.props.passes.pass.max_altitude_time).format(
                        "MMMM DD YYYY, HH:mm:ss Z"
                      )}
                    </Col>
                  </Row>
                  <Row gutter={8} className="details">
                    <Col span={8}>
                      <strong>Set Time</strong>
                    </Col>
                    <Col span={16}>
                      {moment(this.props.passes.pass.set_time).format(
                        "MMMM DD YYYY, HH:mm:ss Z"
                      )}
                    </Col>
                  </Row>
                  <Row gutter={8} className="details">
                    <Col span={8}>
                      <strong>Duration</strong>
                    </Col>
                    <Col span={16}>{this.props.passes.pass.duration}</Col>
                  </Row>
                  <Row gutter={8} className="details">
                    <Col span={8}>
                      <strong>Rise Azimuth</strong>
                    </Col>
                    <Col span={16}>{this.props.passes.pass.rise_azimuth}</Col>
                  </Row>
                  <Row gutter={8} className="details">
                    <Col span={8}>
                      <strong>Set Azimuth</strong>
                    </Col>
                    <Col span={16}>{this.props.passes.pass.set_azimuth}</Col>
                  </Row>
                  <Row gutter={8} className="details">
                    <Col span={8}>
                      <strong>Max Altitude</strong>
                    </Col>
                    <Col span={16}>{this.props.passes.pass.max_altitude}</Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <Empty />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    passes: state.passes.nextGrs[0]
  };
};

export default connect(mapStateToProps, actions)(GRSPass);
