import React, { Component } from "react";
import { Drawer, Divider, Table, Button } from "antd";
import Fullscreen from "react-full-screen";
import { toTimeString, splitString } from "../CommonFunctions";
import noImage from "../../Assets/Images/noImage.png";

export default class DrawerRawImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      isFull: false,
      dataColumns: [
        {
          title: "",
          dataIndex: "label",
          key: "label"
        },
        {
          title: "",
          dataIndex: "value",
          key: "value",
          width: "70%"
        }
      ],
      dataInfoData: [],
      dataFile: []
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setDataInformation(this.props.data);
    }
  }

  setDataInformation = data => {
    const currentInfoData = [
      {
        key: "1",
        label: "Depacketed Version",
        value: data.depacketer_version
      },
      {
        key: "2",
        label: "Packet Loss",
        value: data.packet_loss
      },
      {
        key: "3",
        label: "Satellite",
        value: data.satellite ? data.satellite.name : ""
      },
      {
        key: "4",
        label: "Capture Time",
        value: toTimeString(data.capture_time)
      },
      {
        key: "5",
        label: "Extracted Time",
        value: toTimeString(data.date_extracted)
      },
      {
        key: "6",
        label: "Download Time",
        value: data.download_time ? toTimeString(data.download_time) : ""
      },
      {
        key: "7",
        label: "Uploaded by",
        value: data.uploaded_by
      },
      {
        key: "8",
        label: "Md5sum",
        value: data.md5sum
      }
    ];

    const currentFile = [
      {
        key: "1",
        label: "Telemetry",
        value: data.from_telemetry_file ? (
          <a href={data.from_telemetry_file.file}>
            {data.from_telemetry_file.name}
          </a>
        ) : (
          ""
        )
      },
      {
        key: "2",
        label: "Raw",
        value: data.file ? <a href={data.file}>{data.name}</a> : ""
      },
      {
        key: "3",
        label: "Thumbnail",
        value: data.thumbnail ? (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a href={data.thumbnail} target="_blank">
            {splitString(data.thumbnail)}
          </a>
        ) : (
          ""
        )
      }
    ];

    this.setState({
      dataInfoData: currentInfoData,
      dataFile: currentFile
    });
  };

  render() {
    if (this.state.dataInfoData.length > 0) {
      return (
        <div>
          <Drawer
            title={this.props.data.capture_id}
            placement="right"
            closable={false}
            onClose={this.props.closeDrawer}
            visible={this.props.visible}
            width="600"
          >
            <div className="imageContainer">
              <div style={{ textAlign: "center" }}>
                <Fullscreen
                  enabled={this.state.isFull}
                  onChange={isFull => this.setState({ isFull })}
                >
                  <img
                    className={`${this.state.isFull ? `imageFullscreen` : ""}`}
                    src={
                      this.props.data.thumbnail
                        ? this.props.data.thumbnail
                        : noImage
                    }
                    alt="dataImages"
                    width={!this.state.isFull ? "100%" : ""}
                  />
                </Fullscreen>
              </div>
              <div className="fullscreenButton">
                <Button
                  icon="fullscreen"
                  onClick={() => this.setState({ isFull: true })}
                />
              </div>
            </div>
            <Table
              style={{ marginTop: "20px" }}
              columns={this.state.dataColumns}
              dataSource={this.state.dataInfoData}
              bordered
              pagination={false}
              showHeader={false}
              size="small"
            />
            <Divider style={{ marginTop: "50px" }}>Files</Divider>
            <Table
              style={{ marginTop: "20px" }}
              columns={this.state.dataColumns}
              dataSource={this.state.dataFile}
              bordered
              pagination={false}
              showHeader={false}
              size="small"
            />
          </Drawer>
        </div>
      );
    }
    return null;
  }
}
