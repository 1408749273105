import * as satellite from 'satellite.js';

function Satellite(tle, startDate, increment) {
  var setDate = new Date(startDate.getTime() + (1000*20*increment));
  var tleLine1 = `${tle.line1}`,
  tleLine2 = `${tle.line2}`;    

  var satrec = satellite.twoline2satrec(tleLine1, tleLine2);

  var positionAndVelocity = satellite.propagate(satrec, setDate);
  var positionEci = positionAndVelocity.position
  var gmst = satellite.gstime(new Date());
  var positionGd    = satellite.eciToGeodetic(positionEci, gmst)

  var longitude = positionGd.longitude * 180 / Math.PI,
  latitude  = positionGd.latitude * 180 / Math.PI,
  height    = positionGd.height * 1000;

  var objectData = {
    "id": increment,
    "coordinates": [longitude, latitude, height],
    "pass": setDate
  }
  return objectData;
};

export default Satellite;