import { DOWNLOAD_CART, DELETE_CART } from "../Actions/types";

const INITIAL_STATE = {
  download_cart: ""
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DOWNLOAD_CART:
      return { ...state, download_cart: action.payload };
    case DELETE_CART:
      return { ...state, download_cart: INITIAL_STATE };
    default:
      return state;
  }
}
