import axios from "axios";
import API from "../API";

// type {string} : action status
// data {array} : selected items ids
// url {string} : it should return the desired url

const actionApi = (type, data, url) => {
  const promises = [];

  for (let i = 0; i < data.length; i++) {
    const myUrl = `${url}/${data[i]}/`;
    if (type === "Delete") {
      promises.push(API.delete(myUrl));
    } else if (type === "toZero") {
      promises.push(
        API.patch(myUrl, {
          processing_level: "L0"
        })
      );
    } else if (type === "toRaw") {
      promises.push(
        API.patch(myUrl, {
          processing_level: "Raw"
        })
      );
    } else {
      promises.push(
        API.patch(myUrl, {
          status: `${type}`
        })
      );
    }
  }

  const promisesResolved = promises.map(promise =>
    promise.catch(error => ({ error }))
  );
  function checkFailed(then) {
    return function(responses) {
      const someFailed = responses.some(response => response.error);

      if (someFailed) {
        throw responses;
      }

      return then(responses);
    };
  }

  return axios
    .all(promisesResolved)
    .then(
      checkFailed(([someUrl, anotherUrl]) => {
        return `SUCCESS ${someUrl}, ${anotherUrl}`;
      })
    )
    .catch(err => {
      return `FAIL ${err}`;
    });
};

export default actionApi;
