import API from "../Components/API";
import {
  REPORT_MISSION_STATUS,
  REPORT_CAPTURE_STATUS,
  REPORT_BREAKDOWN_STATUS,
  REPORT_MISSIONTYPE_STATUS,
  REPORT_COVERAGE_MAP
} from "./types";

export const reportStats = (satellite, start, end) => async dispatch => {
  if (start && end) {
    // MISSION STATS
    API.get(
      `missions_management/mission_status_stats?satellite=${satellite}&from:created_time=${start}&to:created_time=${end}`
    )
      .then(res => {
        // console.log(res.data[Object.keys(res.data)[0]])
        dispatch({
          type: REPORT_MISSION_STATUS,
          payload: res.data[Object.keys(res.data)[0]]
        });
      })
      .catch(error => {
        console.log(error);
      });

    // CAPTURE STATS
    API.get(
      `data_management/capture_stats?satellite=${satellite}&from:created_time=${start}&to:created_time=${end}`
    )
      .then(res => {
        dispatch({
          type: REPORT_CAPTURE_STATUS,
          payload: res.data[Object.keys(res.data)[0]].Status.stats
        });
      })
      .catch(error => {
        console.log(error);
      });

    // BREAKDOWN STATS
    API.get(
      `missions_management/upload_failure_stats?satellite=${satellite}&from:upload_time=${start}&to:upload_time=${end}`
    )
      .then(res => {
        dispatch({
          type: REPORT_BREAKDOWN_STATUS,
          payload: res.data[Object.keys(res.data)[0]]
        });
      })
      .catch(error => {
        console.log(error);
      });

    // MISSION TYPE STATS
    API.get(
      `missions_management/mission_type_stats?satellite=${satellite}&from:created_time=${start}&to:created_time=${end}`
    )
      .then(res => {
        // console.log(res.data[Object.keys(res.data)[0]].MissionTypes)
        dispatch({
          type: REPORT_MISSIONTYPE_STATUS,
          payload: res.data[Object.keys(res.data)[0]].MissionTypes
        });
      })
      .catch(error => {
        console.log(error);
      });
  } else {
    // MISSION STATS
    API.get(`missions_management/mission_status_stats?satellite=${satellite}`)
      .then(res => {
        // console.log(res.data[Object.keys(res.data)[0]])
        dispatch({
          type: REPORT_MISSION_STATUS,
          payload: res.data[Object.keys(res.data)[0]]
        });
      })
      .catch(error => {
        console.log(error);
      });

    // CAPTURE STATS
    API.get(`data_management/capture_stats?satellite=${satellite}`)
      .then(res => {
        dispatch({
          type: REPORT_CAPTURE_STATUS,
          payload: res.data[Object.keys(res.data)[0]].Status.stats
        });
      })
      .catch(error => {
        console.log(error);
      });

    // BREAKDOWN STATS
    API.get(`missions_management/upload_failure_stats?satellite=${satellite}`)
      .then(res => {
        dispatch({
          type: REPORT_BREAKDOWN_STATUS,
          payload: res.data[Object.keys(res.data)[0]]
        });
      })
      .catch(error => {
        console.log(error);
      });

    // MISSION TYPE STATS
    API.get(`missions_management/mission_type_stats?satellite=${satellite}`)
      .then(res => {
        // console.log(res.data[Object.keys(res.data)[0]].MissionTypes)
        dispatch({
          type: REPORT_MISSIONTYPE_STATUS,
          payload: res.data[Object.keys(res.data)[0]].MissionTypes
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
};

export const coverageMapStats = (
  satellite,
  payload,
  start,
  end
) => async dispatch => {
  if (start && end) {
    API.get(
      `
data_management/missions_coverage?satellite=${satellite}&from:capture_time=${start}&to:capture_time=${end}&payload=${payload}`
    )
      .then(res => {
        dispatch({
          type: REPORT_COVERAGE_MAP,
          payload: res.data
        });
      })
      .catch(error => {
        console.log(error);
      });
  } else {
    API.get(
      `
data_management/missions_coverage?satellite=${satellite}&payload=${payload}`
    )
      .then(res => {
        dispatch({
          type: REPORT_COVERAGE_MAP,
          payload: res.data
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
};
