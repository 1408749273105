import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import moment from "moment";
import * as actions from "../../Actions";

// COMPONENTS
import OrbitalDecayLineChart from "./OrbitalDecayLineChart";
import Spinner from "../Layout/Spinner";

class OrbitalDecay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: {}
    };
  }

  componentDidMount() {
    this.props.orbitalDecay(this.props.satellite);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.satellite !== this.props.satellite) {
      this.processData("satellite");
    }
    if (prevProps.endLifetime !== this.props.endLifetime) {
      this.processData("endLifetime");
    }
    if (prevProps.decayData !== this.props.decayData) {
      this.keepData();
    }
  }

  keepData() {
    if (!this.state[this.props.satellite]) {
      this.setState({
        satellite: this.props.satellite,
        [this.props.satellite]: {
          data: this.props.decayData,
          endLifetime: this.props.endLifetime
        }
      });
    }
  }

  processData(data) {
    switch (data) {
      case "satellite":
        this.setState({ loading: true });
        if (!this.state[this.props.satellite]) {
          this.props.orbitalDecay(this.props.satellite);
        } else if (this.props.satellite === 43678) {
          setTimeout(() => {
            this.setState({
              satellite: this.props.satellite,
              data: this.state[this.props.satellite].endLifetime,
              loading: false
            });
          }, 3000);
        } else {
          this.setState({
            satellite: this.props.satellite,
            data: this.state[this.props.satellite].endLifetime,
            loading: false
          });
        }
        break;
      case "endLifetime":
        this.setState({
          loading: false,
          data: this.props.endLifetime
        });
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <div>
        <div className="panel__title label" style={{ paddingBottom: "20px" }}>
          TIME VS ALTITUDE GRAPH
        </div>
        {!this.state.loading ? (
          <Row gutter={16}>
            <Col sm={24} md={18}>
              {this.state[this.state.satellite] ? (
                <OrbitalDecayLineChart
                  satellite={this.state.satellite}
                  data={this.state[this.state.satellite].data}
                />
              ) : (
                ""
              )}
            </Col>
            <Col sm={24} md={6}>
              <div style={{ padding: "5px" }}>
                <strong>Decay Altitude</strong>
              </div>
              <div style={{ padding: "5px" }}>120 km above sea level</div>
              <div style={{ padding: "5px" }}>
                <strong>Projected End Of Lifetime (PHT)</strong>
              </div>
              <div style={{ padding: "5px" }}>
                {this.state.data
                  ? moment(this.state.data.timestamp).format(
                      "MMMM DD YYYY, HH:mm:ss Z"
                    )
                  : "---"}
              </div>
              <div style={{ padding: "5px" }}>
                <strong>Altitude At End Of Lifetime</strong>
              </div>
              <div style={{ padding: "5px" }}>
                {this.state.data ? this.state.data.altitude : "---"}
              </div>
            </Col>
          </Row>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    endLifetime: state.decay.endLifetime,
    decayData: state.decay.decayData
  };
};

export default connect(mapStateToProps, actions)(OrbitalDecay);
