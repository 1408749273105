import React, { Component } from "react";

export default class GRSOperationsDashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div>This is GRSOperationsDashboardPage</div>;
  }
}
