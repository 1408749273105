import React from "react";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { Button, Badge } from "antd";
import history from "../../history";

export default function DownloadCartButton(props) {
  const viewDownloadCart = () => {
    history.push("/data_management/download_cart");
  };

  return (
    <Badge count={props.cartCount}>
      <Button
        onClick={e => {
          e.preventDefault();
          viewDownloadCart();
        }}
      >
        <CloudDownloadOutlined />
        View Cart
      </Button>
    </Badge>
  );
}
