import React, { Component, Fragment } from "react";
import { Row, Col, Icon, Button } from "antd";
import { connect } from "react-redux";
import Fullscreen from "react-full-screen";
import _ from "lodash";

import * as actions from "../../Actions";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import TreeMapComponent from "../../Components/Vis/TreeMapComponent";
import DLbyPrimaryApplicationOverTime from "../../Components/Vis/Distributions/DLbyPrimaryApplicationOverTime";
import DownloadsOverTime from "../../Components/Vis/Distributions/DownloadsOverTime";
import Spinner from "../../Components/Layout/Spinner";
import {
  constructDownloadsTreeMap,
  constructDownloadsByPrimaryApp,
  downloadsOverTime,
  getDownloadStats
} from "../../Components/CommonFunctions/DistributionFunctions";

class DistributionDownloadsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFull: false,
      dlFull: false
    };
  }

  componentDidMount() {
    this.props.fetchDownloadProfiles();
  }

  goFull = () => {
    this.setState({ isFull: true });
  };

  goDlFull = () => {
    this.setState({ dlFull: true });
  };

  extDlFull = () => {
    this.setState({ dlFull: false });
  };

  render() {
    const downloadsData = _.cloneDeep(this.props.downloads);

    const treeMapData = constructDownloadsTreeMap(downloadsData);
    const dlByPrimaryAppData = constructDownloadsByPrimaryApp(downloadsData);
    const downloadsDataOverTime = downloadsOverTime(downloadsData);
    const downloadStats = getDownloadStats(downloadsData);

    const downloadStatsThisMonth = downloadStats.total_scenes_month
      ? downloadStats.total_scenes_month
      : "";
    const downloadStatsThisYear = downloadStats.total_scenes_year
      ? downloadStats.total_scenes_year
      : "";
    const downloadStatsToday = downloadStats.total_scenes_day
      ? downloadStats.total_scenes_day
      : "";
    const downloadStatsThisWeek = downloadStats.total_scenes_week
      ? downloadStats.total_scenes_week
      : "";

    const sceneTrend = downloadStats.downloadTrend
      ? parseInt(downloadStats.downloadTrend.sceneTrendVal)
      : 0;
    const userTrend = downloadStats.downloadTrend
      ? parseInt(downloadStats.downloadTrend.userTrendVal)
      : 0;

    const urls = {
      "/": "HOME",
      "/data_management/distribution_downloads_dashboard":
        "DOWNLOADS STATISTICS"
    };
    return (
      <Fragment>
        <BreadCrumbComponent data={urls} titlePage="Downloads Statistics" />

        <Row gutter={16} className="downloads">
          <Col sm={24} md={8}>
            <Row>
              <div className="panel">
                <div className="panel__title label">
                  DOWNLOADS AND DOWNLOADERS
                </div>
                <div className="panel__title-sec">TODAY</div>
                <hr className="hrStyled" />
                <Row gutter={16}>
                  <Col sm={24} md={12}>
                    <div className="download-trend-panel">
                      <div className="trend trend--left">
                        <span
                          className={
                            sceneTrend > 0 ? "trend--up" : "trend--down"
                          }
                        >
                          <Icon
                            className="trend__icon"
                            type={sceneTrend > 0 ? "up-circle" : "down-circle"}
                            theme="filled"
                          />
                          {sceneTrend}
                        </span>
                      </div>
                      <div className="statNumber m-t-20">
                        {this.props.loader ? 0 : downloadStatsToday.scenesCount}
                      </div>
                      <div className="caption">SCENES</div>
                    </div>
                  </Col>
                  <Col sm={24} md={12}>
                    <div className="download-trend-panel">
                      <div className="trend trend--left">
                        <span
                          className={
                            userTrend > 0 ? "trend--up" : "trend--down"
                          }
                        >
                          <Icon
                            className="trend__icon"
                            type={userTrend > 0 ? "up-circle" : "down-circle"}
                            theme="filled"
                          />
                          {userTrend}
                        </span>
                      </div>
                      <div className="statNumber m-t-20">
                        {this.props.loader
                          ? 0
                          : downloadStatsToday.downloadsCount}
                      </div>
                      <div className="caption">USERS</div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Row>
            <Row gutter={16}>
              <Col sm={24} md={12}>
                <div className="panel">
                  <div className="panel__title label">THIS WEEK</div>
                  <hr className="hrStyled" />
                  <Row gutter={16}>
                    <Col sm={24} md={12}>
                      <div className="statNumber statNumber--small m-t-10">
                        {this.props.loader
                          ? 0
                          : downloadStatsThisWeek.scenesCount}
                      </div>
                      <div className="caption caption--small">SCENE</div>
                    </Col>
                    <Col sm={24} md={12}>
                      <div className="statNumber statNumber--small m-t-10">
                        {this.props.loader
                          ? 0
                          : downloadStatsThisWeek.downloadsCount}
                      </div>
                      <div className="caption caption--small">USERS</div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col sm={24} md={12}>
                <div className="panel">
                  <div className="panel__title label">THIS MONTH</div>
                  <hr className="hrStyled" />
                  <Row gutter={16}>
                    <Col sm={24} md={12}>
                      <div className="statNumber statNumber--small m-t-10">
                        {this.props.loader
                          ? 0
                          : downloadStatsThisMonth.scenesCount}
                      </div>
                      <div className="caption caption--small">SCENE</div>
                    </Col>
                    <Col sm={24} md={12}>
                      <div className="statNumber statNumber--small m-t-10">
                        {this.props.loader
                          ? 0
                          : downloadStatsThisMonth.downloadsCount}
                      </div>
                      <div className="caption caption--small">USERS</div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col sm={24} md={12}>
                <div className="panel">
                  <div className="panel__title label">THIS YEAR</div>
                  <hr className="hrStyled" />
                  <Row gutter={16}>
                    <Col sm={24} md={12}>
                      <div className="statNumber statNumber--small m-t-10">
                        {this.props.loader
                          ? 0
                          : downloadStatsThisYear.scenesCount}
                      </div>
                      <div className="caption caption--small">SCENE</div>
                    </Col>
                    <Col sm={24} md={12}>
                      <div className="statNumber statNumber--small m-t-10">
                        {this.props.loader
                          ? 0
                          : downloadStatsThisYear.downloadsCount}
                      </div>
                      <div className="caption caption--small">USERS</div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col sm={24} md={12}>
                <div className="panel">
                  <div className="panel__title label">TOTAL</div>
                  <hr className="hrStyled" />
                  <Row gutter={16}>
                    <Col sm={24} md={12}>
                      <div className="statNumber statNumber--small m-t-10">
                        {this.props.loader ? 0 : downloadStats.total_scenes}
                      </div>
                      <div className="caption caption--small">SCENE</div>
                    </Col>
                    <Col sm={24} md={12}>
                      <div className="statNumber statNumber--small m-t-10">
                        {this.props.loader ? 0 : downloadStats.total_users}
                      </div>
                      <div className="caption caption--small">USERS</div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>

          <Col sm={24} md={16}>
            <Fullscreen
              enabled={this.state.isFull}
              onChange={isFull => this.setState({ isFull })}
            >
              <div
                className={`panel ${this.state.isFull ? `isFullScreen` : ""}`}
              >
                <div className="panel__title label fullscreen-title">
                  <span>DOWNLOADS BY PRIMARY APPLICATION</span>
                  {this.state.isFull ? (
                    ""
                  ) : (
                    <Button icon="fullscreen" onClick={this.goFull} />
                  )}
                </div>
                <div style={{ height: "535px" }}>
                  {this.props.loader ? (
                    <Spinner />
                  ) : (
                    <TreeMapComponent
                      data={treeMapData}
                      isFullScreen={this.state.isFull}
                    />
                  )}
                </div>
              </div>
            </Fullscreen>
          </Col>
        </Row>
        <Row gutter={16} style={{ padding: "8px 0" }} type="flex">
          <Col sm={24} md={12}>
            <Fullscreen
              enabled={this.state.dlFull}
              onChange={dlFull => this.setState({ dlFull })}
            >
              <div
                className={`panel ${this.state.dlFull ? `isFullScreen` : ""}`}
                style={{ height: "100%" }}
              >
                <div className="panel__title label fullscreen-title">
                  <span>DOWNLOADS BY PRIMARY APPLICATION OVER TIME</span>
                  {this.state.dlFull ? (
                    <Button icon="fullscreen-exit" onClick={this.extDlFull} />
                  ) : (
                    <Button icon="fullscreen" onClick={this.goDlFull} />
                  )}
                </div>
                {this.props.loader ? (
                  <Spinner />
                ) : (
                  <DLbyPrimaryApplicationOverTime data={dlByPrimaryAppData} />
                )}
              </div>
            </Fullscreen>
          </Col>
          <Col sm={24} md={12}>
            <div className="panel" style={{ height: "100%" }}>
              <div className="panel__title label">DOWNLOADS OVER TIME</div>
              {this.props.loader ? (
                <Spinner />
              ) : (
                <DownloadsOverTime data={downloadsDataOverTime} />
              )}
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
const mapStateToProps = state => {
  // console.log(state);
  return {
    downloads: state.downloads.downloads,
    loader: state.downloads.download_loading
  };
};

export default connect(mapStateToProps, actions)(DistributionDownloadsPage);
