import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label
} from "recharts";

export default class UsersOverTime extends PureComponent {
  render() {
    return (
      <ResponsiveContainer width={"100%"} height={400}>
        <LineChart
          data={this.props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" height={50}>
            <Label
              value="Months"
              offset={10}
              position="insideBottom"
              style={{ textAnchor: "middle", color: "grey" }}
            />
          </XAxis>
          <YAxis>
            <Label
              angle={-90}
              value="Number of registered users"
              position="insideLeft"
              style={{ textAnchor: "middle" }}
            />
          </YAxis>
          <Tooltip />
          <Legend margin={{ top: 20 }} />
          <Line dataKey="count" stroke="#1A9E76" fill="#1A9E76" dot={false} />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}
