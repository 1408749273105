import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  convertToUTC
} from "../../Components/CommonFunctions";
import API from "../../Components/API";
import {
  Col,
  Row,
  Button,
  Popover,
  Empty
} from "antd";
import { toTimeString } from "../CommonFunctions";
// import markerImage from "../../Assets/Images/marker.png";
import philImage from "../../Assets/Images/phil.png";
import MapComponent from "./MapComponent";
import Position from "./Position";
import Spinner from "../../Components/Layout/Spinner";

export default class PendingMissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      selectedIndex: null,
      GRSList: []
    };
  }

  componentDidMount() {
    this.fetch();
    this.fetchCoverage();
    this.fetchGRSList();
  }

  componentDidCatch(error, errorInfo) {
    // errorService.report(errorInfo);
    this.setState({ error: error, errorInfo: errorInfo })
    console.log([error, errorInfo])
  }

  formatMissions = tableData => {
    const missions = [];
    const rawData = tableData ? tableData.data : [];
    rawData.map((val, index) => {
      return missions.push({
        index: `${index + 1}`,
        key: `${val.id}`,
        id: `${val.id}`,
        name: `${val.name}`,
        created_time: `${toTimeString(val.created_time)}`,
        capture_time: `${toTimeString(val.capture_time)}`,
        coordinates: val.target_lat && val.target_lng ?
        [val.target_lat, val.target_lng] :
        []
      });
    });
    return missions;
  };

  fetch = async () => {
    const now = convertToUTC(new Date());
    // const now = "2020-01-01T16:00:00Z"
    this.setState({ loading: true });

    try {
      await API.get(
        `missions_management/missions?format=json&satellite=Diwata-2&status=Pending&ordering=capture_time&page=1&page_size=10&from:capture_time=${now}`
      ) //${now}
        .then(res => {
          this.setState({
            loading: false,
            missionData: this.formatMissions(res.data)
          });
        })
    } catch (error) {
      console.log(error)
      this.setState({ fetchError: error, loading: false });
    }
  };


  fetchCoverage = () => {
    API.get(
      `data_management/missions_coverage?satellite=Diwata-2`
    )
      .then(res => {
        this.setState({
          coverageData: res.data
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  
  fetchGRSList = () => {
    API.get(`receiving_station`)
      .then(res => {
        this.setState({
          GRSList: res.data.data
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  };
 
  render() {
    return (
      <div className="panel" style={{ height: "400px", padding: "0", display: "flex" }}>
        <div style={{ width: "25%", padding: "30px" }}>
          <div className="panel__title label">
            {/* <img src={markerImage} alt="logo" height={25} /> */}
            UPCOMING MISSIONS
          </div>
          {!this.state.loading ? (
            <div style={{ overflowY: "scroll", height: "85%", marginTop: "20px" }}>
              {this.state.missionData?.length > 0 ? (
                <>
                  {!this.state.fetchError && this.state.missionData?.map((mission, index) => (
                    <div
                      style={{
                        marginTop: "10px",
                        backgroundColor: `${this.state.selectedIndex === index ? "#F5F5F5" : "#FFF"}`
                      }}
                      onMouseOver={() => this.setState({ selectedIndex: index })}
                      onMouseLeave={() => this.setState({ selectedIndex: null })}
                    >
                      <Row>
                        <Col span={2} style={{ textAlign: "right" }}>
                          <b>{index + 1}.</b>
                        </Col>
                        <Col style={{ marginLeft: "30px" }}>
                          <div style={{ marginLeft: "10px" }}>
                            <Link
                              to={`/missions_management/acquisition_missions/view/${mission.id}`}
                            >
                              <b style={{ color: "#484848" }}>
                                {mission.name}
                              </b>
                            </Link>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={2}></Col>
                        <Col style={{ marginLeft: "43px" }}>{mission.capture_time}</Col>
                      </Row>
                    </div>
                  ))}
                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    <Link
                      to="/missions_management/acquisition_missions/?status=Pending"
                    > {/**add capture date filter if possible */}
                      Show more
                    </Link>
                  </div>
                </>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          ) : (
            <Spinner />
          )}

        </div>
        <div style={{ width: "75%", position: "relative" }}>
          <MapComponent
            data={this.state.missionData || []}
            coverageData={this.state.coverageData}
            zoomToCoverege={this.state.zoomToCoverege}
            selectedIndex={this.state.selectedIndex}
            setSelectedIndex={e => this.setState({ selectedIndex: e })}
            GRSList={this.state.GRSList}
          />
          <Position />
          <div
            style={{
              width: "200px",
              height: "75px",
              position: "absolute",
              zIndex: "1000",
              backgroundColor: "white",
              bottom: "10px",
              left: "12.5px",
              padding: "10px",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              margin: "auto"
            }}
          >
            <Row gutter={16} type="flex">
              <Col span={6}>
                <div style={{ textAlign: "center" }}>
                  <img src={philImage} alt="logo" height={50} />
                </div>
              </Col>
              <Col span={18}>
                <div style={{ fontSize: "20px", textAlign: "center" }}>
                  {this.state.coverageData ? (
                    <b>
                      {
                      this.state.coverageData?.features[0]?.properties?.percent_covered?.toFixed(2) ||
                      "--"
                      } %
                    </b>
                  ) : (
                    <Spinner />
                  )}
                </div>
                <div style={{ textAlign: "center" }}>
                  PH COVERAGE
                  {this.state.coverageData && (
                    <Popover
                      placement="bottom"
                      content="View Coverage"
                    >
                      <Button
                        shape="circle"
                        icon="eye"
                        type="link"
                        onClick={() => {
                          if (this.state.coverageData?.features[0]?.properties) {
                            this.setState({
                              zoomToCoverege : !this.state.zoomToCoverege
                            });
                          }
                        }}
                        style={{ color: "black" }}
                        size="small"
                      />
                    </Popover>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}