import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Select,
  Empty
} from "antd";
import moment from "moment";
import { connect } from "react-redux";
import BreadCrumbComponent from "../Components/Layout/BreadCrumbComponent";
import {
  Funnel,
  MissionsBarChart,
  CapturesBarChart,
  GeorefBarChart,
  PendingMissions,
  DownloadedBarChart,
  UpcomingMissions
} from "../Components/HomeComponents";
import API from "../Components/API";
import Spinner from "../Components/Layout/Spinner";
import * as actions from "../Actions";
import withToast from "../Components/Hocs/withToast";

const { Option } = Select;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      dataType: {
        key: 0,
        value: "Missions"
      },
      showBy: "Month",
      year: moment().format('YYYY'),
      payload: "All"
    };
  }

  componentDidMount() {
    this.fetch()
    const duration = moment.duration(moment().diff(moment("2016-01-01").format("YYYY-MM-DD")));
    let yearList = [];
    for (let index = Math.floor(duration.asYears()); index >=0; index--) {
      yearList.push(2016+index)
    }
    this.setState({ yearList: yearList });
    this.props.listPayload();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.payloads !== this.props.payloads) {
      this.passData(this.props.payloads);
    }
  }

  passData(data) {
    let payloadList = [{
        id: 0,
        name: "All",
        description: "All"
      },
      ...data
    ];
    this.setState({ payloads: payloadList });
  }

  fetch = async () => {
    this.setState({ loading: true, fetchError: null });
    const payload = this.state.payload === "All" ? "" : `&payload=${this.state.payload}`;

    try {
      await API.get(`stats/diwata2_dashboard/monthly_data?show_by=${this.state.showBy}&year=${this.state.year}${payload}`)
        .then(res => {
          let missionStats = [];
          let captureStats = [];
          let downloadStats = [];
          let georefStats = [];

          Object.keys(res.data).forEach((log, index) => {
            const date = moment(log, "YYYY-MM").format("MMM-YYYY");
            const {
              mission_data: {
                stats: {
                  Captured,
                  Downloaded,
                  Processed,
                  Distributed
                }
              },
              capture_data: {
                downloaded_count,
                georeferenced,
                georeferenced: {
                  within_PH,
                  outside_PH
                },
                stacked,
                distributed,
                initial_image_assessment,
                initial_image_assessment: {
                  Processable,
                  Unchecked
                }
              }
            } = res.data[log]

            missionStats.push({
              date: date,
              Captured: Captured,
              Downloaded: Downloaded,
              Processed: Processed,
              Distributed: Distributed,
            })

            captureStats.push({
              date: date,
              Downloaded: downloaded_count,
              Georeferenced: georeferenced.count,
              Stacked: stacked,
              Distributed: distributed,
            })

            downloadStats.push({
              date: date,
              Processable: Processable,
              Not_Processable: initial_image_assessment["Not Processable"],
              Unchecked: Unchecked,
            })

            georefStats.push({
              date: date,
              Within_PH: within_PH,
              Outside_PH: outside_PH,
            })
          })
          this.setState({
            loading: false,
            missionStats: missionStats.reverse(),
            captureStats: captureStats.reverse(),
            downloadStats: downloadStats.reverse(),
            georefStats: georefStats.reverse()
          });
        })
    } catch (error) {
      console.log(error.response)
      this.setState({ fetchError: error, loading: false })
    }
  };

  render() {
    const urls = {
      "/": "HOME",
      "/home": "DIWATA 2 DASHBOARD"
    };
    return (
      <Fragment>
        <BreadCrumbComponent data={urls} titlePage="Diwata 2 Dashboard" />
        <UpcomingMissions />

        <Row gutter={16} style={{ marginTop: "20px" }} type="flex">
          <Col sm={24} md={12}>
            <div className="panel">
              <span className="panel__title label">OPERATIONS FUNNEL</span>
              <span className="span_component" style={{ float: "right" }}>
                <Row>
                  Data type:
                  <Select
                    defaultValue="Missions"
                    style={{ marginLeft: "5px", marginRight: "10px" }}
                    onChange={(e, data)=> {
                      this.setState({
                        dataType: {
                          key: Number(data.key),
                          value: e
                        }
                      })
                    }}
                  >
                    <Option key={0} value="Missions">
                      Missions
                    </Option>
                    <Option key={1} value="Captures">
                      Captures
                    </Option>
                  </Select>

                  Payload:
                  <Select
                    defaultValue="All"
                    style={{ marginLeft: "5px" }}
                    disabled={this.state.dataType.value !== "Captures"}
                    // onChange={(e)=> {
                    //   console.log(e)
                    // }}
                  >
                    {this.state.payloads?.map(payload => (
                      <Option key={payload.id} value={payload.name}>
                        {payload.name}
                      </Option>
                    ))}
                  </Select>
                </Row>
              </span>
              <Funnel dataType={this.state.dataType} />
            </div>
          </Col>
          <Col sm={24} md={12}>
            <div className="panel">
              <div className="panel__title label">PENDING MISSIONS</div>
              <PendingMissions />
            </div>
          </Col>
        </Row>
        
        <Row>
          <div
            style={{
              float: "right",
              position: "relative",
              marginTop: "40px",
              display: "flex"
            }}
          >
            <Row gutter={16} type="flex">
              <Col>
                <Row>
                  <u><b>General Filters</b></u>
                </Row>
                <Row>
                  Show data by:
                  <Select
                    defaultValue={this.state.showBy}
                    style={{ marginLeft: "5px", marginRight: "10px" }}
                    onChange={(e) => {
                      this.setState(
                        {
                          showBy: e
                        },
                        () => {
                          this.fetch();
                        }
                      )
                    }}
                    disabled={this.state.loading}
                  >
                    <Option key={1} value="Month">
                      Month
                    </Option>
                    <Option key={2} value="Week">
                      Week
                    </Option>
                  </Select>

                  Year:
                  <Select
                    defaultValue={this.state.year}
                    style={{ marginLeft: "5px", marginRight: "25px" }}
                    onChange={(e) => {
                      this.setState(
                        {
                          year: e
                        },
                        () => {
                          this.fetch();
                        }
                      )
                    }}
                    disabled={this.state.loading}
                  >
                    {this.state.yearList?.map((yr, index) => (
                      <Option key={index} value={yr}>
                        {yr}
                      </Option>
                    ))}
                  </Select>
                </Row>
              </Col>

              <Col>
                <Row>
                  <u><b>Captures</b></u>
                </Row>
                <Row>
                  Payload:
                  <Select
                    defaultValue="All"
                    style={{ marginLeft: "5px", width: "100px" }}
                    onChange={(e) => {
                      this.setState(
                        {
                          payload: e
                        },
                        () => {
                          this.fetch();
                        }
                      )
                    }}
                    disabled={this.state.loading}
                  >
                    {this.state.payloads?.map(payload => (
                      <Option key={payload.id} value={payload.name}>
                        {payload.name}
                      </Option>
                    ))}
                  </Select>
                </Row>
              </Col>
            </Row>
            
          </div>
        </Row>

        <Row style={{ marginTop: "20px" }}>
          <div className="panel" style={{ width: "100%" }}>
            <h3 className="panel__title label">
              MISSIONS
            </h3>
            {this.state.loading && (
              <Spinner />
            )}
            {!this.state.loading && this.state.missionStats && !this.state.fetchError && (
              <MissionsBarChart missionStats={this.state.missionStats}/>
            )}
            {this.state.fetchError && (
              <Empty />
            )}
            
          </div>
        </Row>

        <>
          <div style={{ marginTop: "20px" }} className="panel">
            <h3 className="panel__title label">
              CAPTURES
            </h3>
            {this.state.loading && (
              <Spinner />
            )}
            {!this.state.loading && this.state.captureStats && !this.state.fetchError && (
              <CapturesBarChart captureStats={this.state.captureStats}/>
            )}
            {this.state.fetchError && (
              <Empty />
            )}
          </div>

          <Row gutter={16} style={{ marginTop: "20px" }} type="flex">
            <Col sm={24} md={12}>
              <div className="panel">
                <h3 className="panel__title label">
                  DOWNLOADED IMAGES
                </h3>
                {this.state.loading && (
                  <Spinner />
                )}
                {!this.state.loading && this.state.downloadStats && !this.state.fetchError && (
                  <DownloadedBarChart downloadStats={this.state.downloadStats} />
                )}
                {this.state.fetchError && (
                  <Empty />
                )}
              </div>
            </Col>
            <Col sm={24} md={12}>
              <div className="panel">
                <h3 className="panel__title label">
                  GEOREFERENCED IMAGES
                </h3>
                {this.state.loading && (
                  <Spinner />
                )}
                {!this.state.loading && this.state.georefStats && !this.state.fetchError && (
                  <GeorefBarChart georefStats={this.state.georefStats} />
                )}
                {this.state.fetchError && (
                  <Empty />
                )}
              </div>
            </Col>
          </Row>
        </>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    payloads: state.list.listPayload
  };
};

export default connect(mapStateToProps, actions)(withToast(Home));