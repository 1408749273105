import { USER_PROFILES, PROFILES_LOADING } from "../Actions/types";

const INITIAL_STATE = {
  user_profiles: ""
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case USER_PROFILES:
      return { ...state, user_profiles: action.payload };
    case PROFILES_LOADING:
      return { ...state, profile_loading: action.payload };
    default:
      return state;
  }
}
