import React from "react";
import { Card, Row, Col } from "antd";
import CommandView from "./CommandView";

export default function GenerateBatchCommand({ command }) {
  return (
    <Card className="m-t-10px" title="Generate Command">
      <Row style={{ marginTop: "20px" }}>
        <Col md={5}>
          <span className="label">Batch Command List: </span>
        </Col>
        <Col>
          <CommandView data={command} />
        </Col>
      </Row>
    </Card>
  );
}
