import React, { Component } from "react";
import { Form, Card, Row, Col, Select, Spin } from "antd";
import { Field, reduxForm, FieldArray } from "redux-form";
import { connect } from "react-redux";
import {
  renderTextArea,
  renderInput,
  renderSelect
} from "./MissionFormComponents";
import GenerateBatchCommand from "./GenerateBatchCommand";
import CommandBatchList from "./CommandBatchList";
import Const from "../../Config/const";
import DatePickerWithTimezone from "./DatePickerWithTimezone";
import API from "../API/index";
import history from "../../history";
import * as actions from "../../Actions";

const { Option } = Select;

class CommandsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: "",
      loadingSubmit: false
    };
  }

  componentDidMount() {
    this.props.getCommandTypes();
  }

  onSubmit = formValues => {
    delete formValues.command_batch;
    this.setState({
      loadingSubmit: true
    });
    if (this.props.view === "add") {
      API.post("/missions_management/commands/?", formValues)
        .then(res => {
          this.setState({
            loadingSubmit: false
          });
          history.push(`/missions_management/commands/view/${res.data.id}`);
        })
        .catch(err => {
          console.log(err);
          alert("Oops, Something went wrong!");
          console.log(err);
          this.setState({
            loadingSubmit: false
          });
        });
    } else {
      API.patch(
        `/missions_management/commands/${this.props.commandId}/`,
        formValues
      )
        .then(res => {
          this.setState({
            loadingSubmit: false
          });
          alert("Upload Mission Edited");
          history.push(`/missions_management/commands/view/${res.data.id}`);
        })
        .catch(err => {
          this.setState({
            loadingSubmit: false
          });
          alert("Oops, Something went wrong!");
          console.log(err);
        });
    }
  };

  render() {
    const commandTypes = this.props.commandTypes.command_types;

    return (
      <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Spin spinning={this.props.loading || this.state.loadingSubmit}>
          <Card title="Commands Parameters">
            <Row>
              <Field
                name="content"
                component={renderTextArea}
                label="Content"
                type="text"
              />
            </Row>
            <Row>
              <Field
                name="section_number"
                component={renderInput}
                label="Section Number"
              />
            </Row>
            <Row>
              <Field
                name="execution_time"
                component={DatePickerWithTimezone}
                label="Execution Time"
                note="You are 8 hours ahead of server time."
              />
            </Row>
            <Row>
              <Field
                name="command_type"
                component={renderSelect}
                label="Command Type"
                defVal={
                  this.props.initialValues.command_type
                    ? this.props.initialValues.command_type
                    : "-------"
                }
              >
                {commandTypes.length !== 0 ? (
                  commandTypes.map(data => (
                    <Option key={data.id} value={data.id}>
                      {data.full_name}
                    </Option>
                  ))
                ) : (
                  <Option key="1" value="">
                    Select a command type
                  </Option>
                )}
              </Field>
            </Row>
          </Card>
          <button className="ant-btn ant-btn-primary m-t-10px" type="submit">
            Submit
          </button>
        </Spin>
      </Form>
    );
  }
}

const mapStateToProps = (state, props) => ({
  commandTypes: state.commandTypes,
  initialValues: props.initialValues // retrieve name from redux store
});

const validate = formValues => {
  const errors = {};
  if (!formValues.name) {
    errors.name = "You must enter a name";
  }
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "CommandsForm",
    validate,
    enableReinitialize: true
  })(CommandsForm)
);
