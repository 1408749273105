import React, { Fragment } from "react";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import UploadForm from "../../Components/MissionsComponents/UploadForm";

export default function AddUploadMissionPage() {
  const urls = {
    "/": "MISSIONS",
    "/missions/upload": "UPLOAD",
    "/add": "ADD"
  };
  return (
    <Fragment>
      <BreadCrumbComponent data={urls} titlePage="Add Upload Missions" />
      <UploadForm
        view="add"
        initialValues={{
          receiving_station: "ASTI",
          status: "Pending",
          satellite: "Diwata-1"
        }}
      />
    </Fragment>
  );
}
