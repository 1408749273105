import React from "react";
import { Link } from "react-router-dom";
import Config from "../Config/const";

export default function InfoPage() {
  return (
    <div>
      <h3>Diwata Operations Software</h3>
      <p>API: {process.env.REACT_APP_API_ROOT}</p>
    </div>
  );
}
