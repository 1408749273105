import React, { Component, Fragment } from "react";
import UploadForm from "../../Components/MissionsComponents/UploadForm";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import API from "../../Components/API";

class ViewUploadMissionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      init: {},
      loading: true
    };
  }

  componentDidMount() {
    API.get(`missions_management/upload_missions/${this.props.match.params.id}`)
      .then(res => {
        this.setState({
          init: res.data,
          loading: false
        });
      })
      .catch(function(error) {
        console.log(error);
        this.setState({
          loading: false
        });
        alert(error);
      });
  }

  render() {
    const uploadId = this.props.match.params.id;
    const urls = {
      "/": "MISSIONS",
      "/missions_management/upload_missions/": "UPLOAD",
      "/view/": "VIEW",
      id: uploadId
    };
    return (
      <Fragment>
        <BreadCrumbComponent data={urls} titlePage="View Upload Mission" />
        <UploadForm
          uploadId={uploadId}
          view="edit"
          initialValues={this.state.init}
          loading={this.state.loading}
        />
      </Fragment>
    );
  }
}

export default ViewUploadMissionPage;
