import React, { Fragment } from "react";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import AcuLogsDownloadForm from "../../Components/MissionsComponents/AcuLogsDownloadForm";

export default function AddAcuLogsDownloadMissionsPage() {
  const urls = {
    "/": "MISSIONS",
    "/missions/acu-log-download-missions": "ACU LOGS",
    "/add": "ADD"
  };
  return (
    <Fragment>
      <BreadCrumbComponent
        data={urls}
        titlePage="Add ACU Logs Download Missions"
      />
      <AcuLogsDownloadForm
        view="add"
        initialValues={{
          receiving_station: "TU",
          status: "Pending",
          satellite: "Diwata-2",
          download_segments_a_and_b: true,
          acu_log_segment: "SEG-B",
          number_of_sets: 192,
          record_interval_seconds: 1
        }}
      />
    </Fragment>
  );
}
