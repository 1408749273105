import React, { Component, Fragment } from "react";
import AcquisitionForm from "../../Components/MissionsComponents/AcquisitionForm";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import API from "../../Components/API";

class ViewAcquisitionMissionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      init: {},
      loading: true
    };
  }

  componentDidMount() {
    API.get(`missions_management/missions/${this.props.match.params.id}`)
      .then(res => {
        this.setState({
          init: this.processData(res.data),
          loading: false
        });
      })
      .catch(error => {
        if (error) {
          this.setState({
            loading: false
          });
        }
        alert("Oops! something went wrong."); // eslint-disable-line
        console.log(error);
      });
  }

  isEmpty = obj => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  processData = data => {
    console.log(data);
    // handle acu log seg object
    const flag = Object.entries(data).length !== 0 ? true : false;
    let acuName = "";
    let acuAddress = "";
    if (flag) {
      acuName = !this.isEmpty(data.acu_log_segment)
        ? data.acu_log_segment.name
        : "--";
      acuAddress = !this.isEmpty(data.acu_log_segment)
        ? data.acu_log_segment.starting_address
        : "--";
    }
    // data.acu_log_segment = `${acuName} | ${acuAddress}`;
    data.acu_log_segment = `${acuName}`;

    // handle mission cameras
    if (data.mission_cameras) {
      const cams = data.mission_cameras;
      for (let i = 0; i < cams.length; i++) {
        const a = cams[i].wavelengths_nir
          ? cams[i].wavelengths_nir.split(",")
          : "";
        cams[i].wavelengths_nir = a;
      }
      for (let b = 0; b < cams.length; b++) {
        const x = cams[b].wavelengths_vis
          ? cams[b].wavelengths_vis.split(",")
          : "";
        cams[b].wavelengths_vis = x;
      }
    }
    return data;
  };

  render() {
    const missionId = this.props.match.params.id;
    const urls = {
      "/": "MISSIONS",
      "/missions_management/acquisition_missions": "ACQUISITION",
      "/view": "VIEW",
      id: missionId
    };
    return (
      <Fragment>
        <BreadCrumbComponent data={urls} titlePage="View Acquisition Mission" />
        <AcquisitionForm
          missionId={missionId}
          view="edit"
          initialValues={this.state.init}
          loading={this.state.loading}
        />
      </Fragment>
    );
  }
}

export default ViewAcquisitionMissionPage;
