import React, { useState } from "react";
import { Button, Icon, Popover } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function CommandView({ data }) {
  const [copied, setCopied] = useState(false);
  return (
    <pre
      style={{
        color: "white",
        backgroundColor: "#29313c",
        padding: "10px",
        borderRadius: "3px"
      }}
    >
      <span style={{ float: "right" }}>
        <CopyToClipboard text={data} onCopy={() => setCopied(true)}>
          <Popover content={copied ? "Copied!" : ""} trigger="click">
            <Button type="link" style={{ color: "#7bd1aa" }}>
              <Icon type="copy" /> Copy
            </Button>
          </Popover>
        </CopyToClipboard>
      </span>
      {data.split("\n").map((line, index) => {
        if (line.includes("#####")) {
          return (
            <code key={index} style={{ color: "grey" }}>
              {line}
              <br />
            </code>
          );
        }
        return (
          <code key={index}>
            {line}
            <br />
          </code>
        );
      })}
    </pre>
  );
}
