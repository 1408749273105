import { POSITION_SATELLITE } from "../Actions/types";

const INITIAL_STATE = {
  positionData: {}
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case POSITION_SATELLITE:
      return {
        ...state,
        positionData: action.payload
      };
    default:
      return state;
  }
}
