import L from "leaflet";
import satellite from "../../Assets/Images/diwata.png";

const iconSatellite = new L.Icon({
  iconUrl: satellite,
  iconRetinaUrl: satellite,
  iconSize: new L.Point(60, 60)
});

export { iconSatellite };
