import React, { Component } from "react";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import AcquisitionTable from "../../Components/MissionsComponents/AcquisitionTable";

export default class AcquisitionMissionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const urls = {
      "/": "MISSIONS",
      "/missions_management/acquisition_missions": "ACQUISITION MISSIONS"
    };

    return (
      <div>
        <BreadCrumbComponent data={urls} titlePage="Acquisition Missions" />
        <AcquisitionTable />
      </div>
    );
  }
}
