import axios from "axios";
import { DOWNLOAD_PROFILES, DOWNLOAD_LOADING } from "./types";

export const fetchDownloadProfiles = () => async dispatch => {
  dispatch({ type: DOWNLOAD_LOADING, payload: 1 });
  axios({
    url: `${process.env.REACT_APP_API_ROOT}/stats/distribution/downloads?format=json`,
    method: "get",
    headers: {
      Authorization: process.env.REACT_APP_API_GUEST_TOKEN
    }
  })
    .then(res => {
      dispatch({ type: DOWNLOAD_PROFILES, payload: res.data });
      dispatch({ type: DOWNLOAD_LOADING, payload: 0 });
    })
    .catch(function(error) {
      console.log(error);
    });
};
