import React, { Component } from "react";

export default class UpdateCapturePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div>This is UpdateCapturePage</div>;
  }
}
