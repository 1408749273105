import React, { Fragment } from "react";
import { Col, Input, Row, Select, Checkbox, Form, DatePicker } from "antd";
import moment from "moment";
import _ from "lodash";
import CommandModal from "./CommandModal";
import InputCommand from "./InputCommand";

const { TextArea } = Input;
const { Option } = Select;

const renderError = ({ error, touched }) => {
  let errObj = { status: "", msg: "" };
  if (touched && error) {
    errObj = { status: "error", msg: error };
  }
  return errObj;
};

export const TextInput = ({ input, meta, type }) => {
  const err = renderError(meta);
  return (
    <Fragment>
      <Form.Item validateStatus={err.status} help={err.msg}>
        {meta.form === "CommandBatchForm" ? (
          <InputCommand commandId={input.value} input={input} type={type} />
        ) : (
          <Input {...input} autoComplete="off" type={type} />
        )}
      </Form.Item>
    </Fragment>
  );
};

export const renderInput = ({ input, label, meta, note, type, defval }) => {
  const err = renderError(meta);
  return (
    <Fragment>
      <Col md={5}>
        <label className="label" htmlFor={label}>
          {label}:
        </label>
        <p className="mute">{note}</p>
      </Col>
      <Col md={11}>
        <Row>
          <Form.Item validateStatus={err.status} help={err.msg}>
            <Input
              {...input}
              autoComplete="off"
              type={type}
              defaultValue="192"
            />
          </Form.Item>
        </Row>
      </Col>
    </Fragment>
  );
};

export const renderDatePicker = ({ input, label, meta, note }) => {
  const err = renderError(meta);
  const defValDate = input.value ? moment(input.value) : null;
  return (
    <Fragment>
      <Col md={5}>
        <label className="label" htmlFor={label}>
          {label}:
        </label>
        <p className="mute">{note}</p>
      </Col>
      <Col md={12}>
        <Form.Item validateStatus={err.status} help={err.msg}>
          <DatePicker
            value={defValDate}
            showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
            format="YYYY-MM-DD HH:mm:ss"
            onChange={input.onChange}
          />
        </Form.Item>
      </Col>
    </Fragment>
  );
};

export const renderTags = ({ input, meta, children }) => {
  const err = renderError(meta);
  return (
    <Fragment>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <Form.item validateStatus={err.status} help={err.msg}>
        <Select mode="tags" {...input} onChange={input.onChange}>
          {children}
        </Select>
      </Form.item>
    </Fragment>
  );
};

export const showDropDown = ({ input, meta, children }) => {
  const err = renderError(meta);
  return (
    <Fragment>
      <Form.Item validateStatus={err.status} help={err.msg}>
        <Select {...input} onChange={input.onChange}>
          {children}
        </Select>
      </Form.Item>
    </Fragment>
  );
};

export const renderSelect = ({
  input,
  label,
  meta,
  note,
  children,
  defVal
}) => {
  const err = renderError(meta);
  return (
    <Fragment>
      <Col md={5}>
        <label className="label" htmlFor={label}>
          {label}:
        </label>
        <p className="mute">{note}</p>
      </Col>
      <Col md={11}>
        <Form.Item validateStatus={err.status} help={err.msg}>
          <Select defaultValue={defVal} {...input} onChange={input.onChange}>
            {children}
          </Select>
        </Form.Item>
      </Col>
    </Fragment>
  );
};

export const renderSelectTags = ({
  input,
  label,
  meta,
  note,
  children,
  defVal
}) => {
  const defaultValue = Array.isArray(defVal) ? defVal : [];
  const err = renderError(meta);
  return (
    <Fragment>
      <Col md={5}>
        <label className="label" htmlFor={label}>
          {label}:
        </label>
        <p className="mute">{note}</p>
      </Col>
      <Col md={11}>
        <Form.Item validateStatus={err.status} help={err.msg}>
          <Select
            showSearch
            mode="tags"
            placeholder="Tags"
            defaultValue={defaultValue}
            onChange={input.onChange}
          >
            {children}
          </Select>
        </Form.Item>
      </Col>
    </Fragment>
  );
};

export const showTextArea = ({ input, meta }) => {
  const err = renderError(meta);
  return (
    <Fragment>
      <Form.Item validateStatus={err.status} help={err.msg}>
        <TextArea rows={10} {...input} />
      </Form.Item>
    </Fragment>
  );
};

export const renderTextArea = ({ input, label, meta, note }) => {
  const err = renderError(meta);
  return (
    <Fragment>
      <Col md={5}>
        {label === "Content" && meta.form === "CommandsForm" ? (
          <CommandModal label={label} data={input.value} />
        ) : (
          <label className="label" htmlFor={label}>
            {label}
          </label>
        )}
        <p className="mute">{note}</p>
      </Col>
      <Col md={11}>
        <Form.Item validateStatus={err.status} help={err.msg}>
          <TextArea rows={10} {...input} />
        </Form.Item>
      </Col>
    </Fragment>
  );
};

export const renderCheckBox = ({ input, label, meta, note, cbval }) => {
  const err = renderError(meta);
  return (
    <Fragment>
      <Col md={5}>
        <label className="label" htmlFor={label}>
          {label}:
        </label>
        <p className="mute">{note}</p>
      </Col>
      <Col md={10}>
        <Form.Item validateStatus={err.status} help={err.msg}>
          <Checkbox onChange={input.onChange} checked={input.value}>
            {cbval}
          </Checkbox>
        </Form.Item>
      </Col>
    </Fragment>
  );
};
