import React, { Fragment, useState } from "react";
import { Col, Select, DatePicker, Row, Form, Tooltip, Button } from "antd";
import { CarryOutOutlined } from "@ant-design/icons";
import moment from "moment";
import _ from "lodash";

const { Option } = Select;

require("moment-timezone");

const DatePickerWithTimezone = ({ input, label, meta, note, aosData }) => {
  const renderError = ({ error, touched }) => {
    let errObj = { status: "", msg: "" };
    if (touched && error) {
      errObj = { status: "error", msg: error };
    }
    return errObj;
  };

  const [zone, setTmz] = useState("Asia/Manila");

  const formatTimeZones = tmz => {
    const offsetTmz = [];
    for (const i in tmz) {
      offsetTmz.push({
        gmt: moment.tz(tmz[i]).format("Z"),
        zone: tmz[i]
      });
    }
    return _.orderBy(offsetTmz, "gmt", "asc");
  };
  const timeZones = formatTimeZones(moment.tz.names());

  const handleDateChange = date => {
    if (date) {
      return date.tz(`${zone}`);
    }
    return null;
  };

  const renderDateTimePicker = (defValDate, inputt) => {
    return (
      <span>
        <DatePicker
          value={defValDate}
          showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
          format="YYYY-MM-DD HH:mm:ss"
          onChange={e => {
            inputt.onChange(handleDateChange(e));
          }}
        />
        {(label === "ARU On Time" || label === "ARU Off Time") &&
        aosData.label === "Aos @ 5 time" &&
        aosData.value ? (
          <Tooltip title="Auto fill (AOS@5 Time - 10mins)">
            <Button
              type="text"
              shape="circle-outline"
              size="small"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                const setTime = moment(aosData.value).subtract(10, "minutes");
                inputt.onChange(handleDateChange(setTime));
              }}
            >
              <CarryOutOutlined />
            </Button>
          </Tooltip>
        ) : (
          ""
        )}
      </span>
    );
  };

  const err = renderError(meta);
  const defValDate = input.value ? moment(input.value) : null;
  return (
    <Fragment>
      <Col md={5}>
        <label className="label" htmlFor={label}>
          {label}:
        </label>
        <p className="mute">{note}</p>
      </Col>
      <Col md={15}>
        <Row gutter={30}>
          <Col md={12}>
            <Form.Item>
              <Select
                defaultValue="Asia/Manila"
                showSearch
                onChange={e => {
                  setTmz(e);
                }}
              >
                {timeZones.map((val, index) => (
                  <Option key={index} value={val.zone}>
                    {`GMT ${val.gmt} - ${val.zone}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item validateStatus={err.status} help={err.msg}>
              {renderDateTimePicker(defValDate, input)}
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Fragment>
  );
};

export default DatePickerWithTimezone;
