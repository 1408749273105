import axios from "axios";
import { USER_PROFILES, PROFILES_LOADING } from "./types";

export const fetchUserProfiles = () => async dispatch => {
  dispatch({ type: PROFILES_LOADING, payload: 1 });
  axios({
    url: `${process.env.REACT_APP_API_ROOT}/stats/distribution/profiles?format=json`,
    method: "get",
    headers: {
      Authorization: process.env.REACT_APP_API_GUEST_TOKEN
    }
  })
    .then(res => {
      dispatch({ type: USER_PROFILES, payload: res.data });
      dispatch({ type: PROFILES_LOADING, payload: 0 });
    })
    .catch(function(error) {
      console.log(error);
    });
};
