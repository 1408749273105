import React, { PureComponent } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { Row, Col } from "antd";

const COLORS = ["#7BD1AA", "#E56F6F", "#B28DFF", "#6EB5FF"];

export default class SuccessFailPieChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const total =
      this.props.total !== 0
        ? this.props.success +
          this.props.fail +
          this.props.cancel +
          this.props.pending
        : 1;

    const data = [
      { name: "Successful", value: this.props.success },
      { name: "Failed", value: this.props.fail },
      { name: "Cancelled", value: this.props.cancel },
      { name: "Pending", value: this.props.pending }
    ];

    return (
      <div>
        <div>
          <Row type="flex">
            <Col md={24} lg={12} style={{ paddingTop: "10px" }}>
              <Row style={{ paddingTop: "10px" }}>
                <i
                  className="fas fa-square"
                  style={{ color: "#7BD1AA", paddingRight: "10px" }}
                />
                Successful
                <span style={{ paddingLeft: "10px" }}>
                  {Math.floor((this.props.success / total) * 100)}%
                </span>
              </Row>
              <Row style={{ paddingTop: "10px" }}>
                <i
                  className="fas fa-square"
                  style={{ color: "#E56F6F", paddingRight: "10px" }}
                />
                Failed
                <span style={{ paddingLeft: "10px" }}>
                  {Math.floor((this.props.fail / total) * 100)}%
                </span>
              </Row>
              <Row style={{ paddingTop: "10px" }}>
                <i
                  className="fas fa-square"
                  style={{ color: "#B28DFF", paddingRight: "10px" }}
                />
                Cancelled
                <span style={{ paddingLeft: "10px" }}>
                  {Math.floor((this.props.cancel / total) * 100)}%
                </span>
              </Row>
              <Row style={{ paddingTop: "10px" }}>
                <i
                  className="fas fa-square"
                  style={{ color: "#6EB5FF", paddingRight: "10px" }}
                />
                Pending
                <span style={{ paddingLeft: "10px" }}>
                  {Math.floor((this.props.pending / total) * 100)}%
                </span>
              </Row>
            </Col>
            <Col sm={24} md={12}>
              <ResponsiveContainer width="100%" height={220}>
                <PieChart
                  width={500}
                  height={220}
                  onMouseEnter={this.onPieEnter}
                >
                  <Pie
                    data={data}
                    // cx={250}
                    // cy={120}
                    innerRadius={65}
                    outerRadius={110}
                    fill="#8884d8"
                    // paddingAngle={5}
                    dataKey="value"
                    // label={true}
                    // labelLine={true}
                    // isAnimationActive={false}
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
