import React, { PureComponent } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const COLORS = ["#7BD1AA", "#E56F6F"];

export default class BreakdownPieChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const total = this.props.total ? this.props.fail + this.props.cancel : 1;
    const data = [
      { name: "Fail", value: this.props.fail }, // not final
      { name: "Cancel", value: this.props.cancel } // not final
      // { name: "Others", value: this.props.others } // not final
    ];

    return (
      <div>
        <div>
          <div style={{ paddingTop: "10px" }}>
            <i
              className="fas fa-square"
              style={{ color: "#7BD1AA", paddingRight: "10px" }}
            />
            Failed Upload
            <span style={{ paddingLeft: "20px" }}>
              {Math.floor((this.props.fail / total) * 100)}%
            </span>
          </div>
          <div style={{ paddingTop: "10px" }}>
            <i
              className="fas fa-square"
              style={{ color: "#E56F6F", paddingRight: "10px" }}
            />
            Cancelled Upload
            <span style={{ paddingLeft: "20px" }}>
              {Math.floor((this.props.cancel / total) * 100)}%
            </span>
          </div>
          {/* <div style={{ paddingTop: "10px" }}>
            <i
              className="fas fa-square"
              style={{ color: "#FFC300", paddingRight: "10px" }}
            />
            Others
            <span style={{ paddingLeft: "20px" }}>
              {Math.floor((this.props.others / total) * 100)}%
            </span>
          </div> */}
        </div>
        <ResponsiveContainer width="100%" height={250}>
          <PieChart width={500} height={220} onMouseEnter={this.onPieEnter}>
            <Pie
              data={data}
              innerRadius={65}
              outerRadius={110}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
