import React, { Component } from "react";
import { Form, Card, Row, Col, Select, Spin } from "antd";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import {
  renderInput,
  renderSelect,
  renderTextArea,
  renderCheckBox
} from "./MissionFormComponents";
import CommandComponent from "./CommandComponent";
import Const from "../../Config/const";
import DatePickerWithTimezone from "./DatePickerWithTimezone";
import API from "../API/index";
import history from "../../history";

const { Option } = Select;

class AcuLogsDownloadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: "",
      loadingSubmit: false
    };
  }

  onSubmit = formValues => {
    this.setState({
      loadingSubmit: true
    });
    if (this.props.view === "add") {
      API.post("/missions_management/acu_log_download_missions/?", formValues)
        .then(res => {
          this.setState({
            loadingSubmit: false
          });
          alert("success");
          history.push(
            `/missions_management/acu_log_download_missions/view/${res.data.id}`
          );
        })
        .catch(err => {
          console.log(err);
          alert("Oops, Something went wrong!");
          console.log(err);
          this.setState({
            loadingSubmit: false
          });
        });
    } else {
      API.patch(
        `/missions_management/acu_log_download_missions/${this.props.acuLogId}/`,
        formValues
      )
        .then(res => {
          this.setState({
            loadingSubmit: false
          });
          alert("Upload Mission Edited");
          history.push(
            `/missions_management/acu_log_download_missions/view/${this.props.acuLogId}`
          );
        })
        .catch(err => {
          this.setState({
            loadingSubmit: false
          });
          alert("Oops, Something went wrong!");
          console.log(err);
        });
    }
  };

  render() {
    const int = value => parseInt(value);
    const commandVal = this.props.initialValues.command
      ? this.props.initialValues.command
      : {};
    return (
      <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Spin spinning={this.props.loading || this.state.loadingSubmit}>
          <Card title="ACU Log Download Parameters">
            <Row>
              <Field
                name="name"
                component={renderInput}
                label="Name"
                type="text"
              />
            </Row>
            <Row>
              <Field
                name="execution_time"
                component={DatePickerWithTimezone}
                label="Execution Time"
                note="You are 8 hours ahead of server time."
              />
            </Row>
            <Row style={{ marginBottom: "20px" }}>
              <Col md={5}>
                <span className="label">Satellite:</span>
              </Col>
              <Col md={10}>Diwata 2</Col>
            </Row>
            <Row>
              <Field
                name="receiving_station"
                component={renderSelect}
                label="Receiving Station"
              >
                {Const.mission.acquisition.receivingStation.map(data => (
                  <Option key={data} value={data.val}>
                    {data.text}
                  </Option>
                ))}
              </Field>
            </Row>
            <Row>
              <Field
                name="status"
                component={renderSelect}
                label="Status"
                defVal="Pending"
                note="Kindly input remarks for Failed/Cancelled missions."
              >
                {Const.mission.acquisition.addMissionFilterStats.map(data => (
                  <Option key={data} value={data.val}>
                    {data.text}
                  </Option>
                ))}
              </Field>
            </Row>
            <Row>
              <Field
                name="remarks"
                component={renderTextArea}
                label="Remarks"
              />
            </Row>
            <Row className="form-row">
              <Field
                note="Select to download both segments A and B"
                name="download_segments_a_and_b"
                component={renderCheckBox}
                label="Download Segments A and B"
              />
            </Row>
            <Row>
              <Field
                name="acu_log_segment"
                component={renderSelect}
                label="Acu Log Segment"
              >
                {Const.segments.map(data => (
                  <Option key={data} value={data.val}>
                    {data.text}
                  </Option>
                ))}
              </Field>
            </Row>
            <Row>
              <Field
                label="Number of sets"
                name="number_of_sets"
                note="Standard number of sets: 192 or 384"
                component={renderInput}
                type="number"
                normalize={int}
                defval="192"
              />
            </Row>
            <Row>
              <Field
                label="Record interval seconds"
                name="record_interval_seconds"
                note="Standard record interval: 1, 2, 5, or 20 seconds"
                component={renderInput}
                type="number"
                normalize={int}
              />
            </Row>
            <Row>
              {this.props.view === "edit" ? (
                <CommandComponent command={commandVal} />
              ) : (
                ""
              )}
            </Row>
          </Card>
          <button className="ant-btn ant-btn-primary m-t-10px" type="submit">
            Submit
          </button>
        </Spin>
      </Form>
    );
  }
}

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues // retrieve name from redux store
});

const validate = formValues => {
  const errors = {};
  if (!formValues.name) {
    errors.name = "You must enter a name";
  }
  if (!formValues.execution_time) {
    errors.execution_time = "You must enter an execution time";
  }
  if (!formValues.acu_log_segment) {
    errors.acu_log_segment = "You must enter an acu log segment";
  }
  if (!formValues.acu_log_segment) {
    errors.acu_log_segment = "You must enter an acu log segment";
  }
  if (!formValues.number_of_sets) {
    errors.number_of_sets = "You must enter number of sets";
  }
  if (!formValues.record_interval_seconds) {
    errors.record_interval_seconds = "You must enter record interval seconds";
  }
};

export default connect(mapStateToProps)(
  reduxForm({
    form: "acuLogsDownloadForm",
    validate,
    enableReinitialize: true
  })(AcuLogsDownloadForm)
);
