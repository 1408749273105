import React from "react";
import { Card, Row, Button, Select, Col } from "antd";
import { Field } from "redux-form";
import { TextInput, showDropDown } from "./MissionFormComponents";
import CreateTags from "./CreateTags";
import Const from "../../Config/const";

const { Option } = Select;
const float = value => parseFloat(value);

const renderCameras = ({ fields, meta: { error, submitFailed } }) => (
  <div>
    <Button onClick={() => fields.push({})}>Add Mission Camera</Button>
    {submitFailed && error && <span>{error}</span>}
    {fields.map((member, index) => (
      <Card
        key={index}
        className="m-t-10px"
        title={`Camera #${index + 1}`}
        extra={
          <Button type="danger" onClick={() => fields.remove(index)}>
            Remove
          </Button>
        }
      >
        <Row gutter={16}>
          <Col md={6}>
            <label className="label" htmlFor={`${member}.payload`}>
              Payload:
            </label>
            <Field
              name={`${member}.payload`}
              component={showDropDown}
              label="Payload"
            >
              {Const.payload.map(data => (
                <Option key={data} value={data.val}>
                  {data.text}
                </Option>
              ))}
            </Field>
          </Col>
          <Col md={6}>
            <label className="label" htmlFor={`${member}.exposure_time`}>
              Exposure Time:
            </label>
            <Field
              name={`${member}.exposure_time`}
              component={TextInput}
              type="number"
              normalize={float}
            />
          </Col>
          <Col md={6}>
            <label className="label" htmlFor={`${member}.wavelength_vis`}>
              Wavelength VIS
            </label>
            <Field
              label="Wavelength VIS"
              name={`${member}.wavelengths_vis`}
              component={CreateTags}
            />
          </Col>
          <Col md={6}>
            <label className="label" htmlFor={`${member}.wavelength_nir`}>
              Wavelength NIR
            </label>
            <Field name={`${member}.wavelengths_nir`} component={CreateTags} />
          </Col>
        </Row>
      </Card>
    ))}{" "}
  </div>
);

export default renderCameras;
