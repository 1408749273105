import React from "react";
import { Select } from "antd";

const { Option } = Select;

const FilterComponent = ({
  optionData,
  placeholder,
  handleChange,
  value,
  source
}) => {
  if (source === "api") {
    return (
      <Select
        // value={value}
        defaultValue={value}
        placeholder={placeholder}
        onChange={handleChange}
        style={{ width: "100%" }}
      >
        <Option key={0} value="">
          All
        </Option>
        {optionData.map(data => (
          <Option key={data.id} value={data.name}>
            {data.name}
          </Option>
        ))}
      </Select>
    );
  }
  return (
    <Select
      // value={value}
      defaultValue={value}
      placeholder={placeholder}
      onChange={handleChange}
      style={{ width: "100%" }}
    >
      {optionData.map(data => (
        <Option key={data} value={data.val}>
          {data.text}
        </Option>
      ))}
    </Select>
  );
};

export default FilterComponent;
