import React, { Component } from "react";
import { Map, CircleMarker, TileLayer, Marker } from "react-leaflet";
import { iconGRS } from "./iconGRS";
import "leaflet/dist/leaflet.css";

export default class ShowMapSinglePass extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Map
        style={{
          height: "250px",
          width: `calc(100% + 50px)`,
          top: "-25px"
        }}
        zoom={3}
        center={this.props.center}
        scrollWheelZoom={false}
      >
        <TileLayer
          url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {this.props.passes.map((coordinates, index) => {
          return (
            <CircleMarker
              key={index}
              center={coordinates}
              radius={0.5}
              fillOpacity={1}
              color="red"
            />
          );
        })}

        <Marker position={this.props.position} icon={iconGRS} />
      </Map>
    );
  }
}
