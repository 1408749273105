import React, { useState } from "react";
import { connect } from "react-redux";
import { Table, Select, DatePicker, Button, Modal, Icon, Card } from "antd";
import { DownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import * as actions from "../../Actions";

import withToast from "../../Components/Hocs/withToast";

function DownloadCart(props) {
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem("cart")));
  const [idx, setIdx] = useState("");
  const [visible, setVisible] = useState(false);
  const [visibleDeleteAll, setVisibleDeleteAll] = useState(false);
  const { Option } = Select;

  const handleDelete = () => {
    const newCart = cart.filter(obj => {
      return obj.key !== idx;
    });
    localStorage.setItem("cart", JSON.stringify(newCart));
    setCart(newCart);
    props.addDownload(newCart);
    props.openNotification("success", "Success", "Item Deleted");
    setVisible(false);
  };

  const deleteAll = () => {
    localStorage.removeItem("cart");
    props.deleteAll();
    props.openNotification("success", "Success", "Removed all items in cart");
    setCart([]);
    setVisibleDeleteAll(false);
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      align: "center",
      width: "80px",
      render: image => {
        return image === null ? (
          ""
        ) : (
          <img src={image} alt="dataImages" height="40px" />
        );
      }
    },
    // Data ID
    {
      title: "Capture ID",
      dataIndex: "captureId",
      key: "captureId"
    },

    // View more details
    {
      title: "",
      dataIndex: "key",
      key: "key",
      align: "center",
      render: idx => {
        return (
          <Button
            shape="circle"
            icon="close"
            // On button click open drawer and show item details.
            onClick={event => {
              event.preventDefault();
              setVisible(true);
              setIdx(idx);
            }}
          />
        );
      }
    }
  ];

  return (
    <Card>
      <div style={{ display: "flex" }}>
        <BreadCrumbComponent titlePage="Download Cart" />
        <Button
          style={{ marginLeft: "auto" }}
          type="primary"
          disabled={cart ? false : true}
        >
          <DownloadOutlined />
          Download
        </Button>
        <Button
          disabled={cart ? false : true}
          style={{ marginLeft: "5px" }}
          type="danger"
          onClick={() => {
            setVisibleDeleteAll(true);
          }}
        >
          <DeleteOutlined />
          Delete All
        </Button>
      </div>
      <Table
        size="middle"
        style={{ marginTop: "20px" }}
        columns={columns}
        dataSource={cart}
        bordered
      />
      <Modal
        visible={visibleDeleteAll}
        onOk={deleteAll}
        onCancel={() => {
          setVisibleDeleteAll(false);
        }}
      >
        <h3 style={{ margin: "20px" }}>
          <Icon
            type="question-circle"
            className="modal_icon"
            style={{ color: "orange" }}
          />
          Remove all items in the cart?
        </h3>
      </Modal>
      <Modal
        visible={visible}
        onOk={handleDelete}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <h3 style={{ margin: "20px" }}>
          <Icon
            type="question-circle"
            className="modal_icon"
            style={{ color: "orange" }}
          />
          Remove this item in the cart?
        </h3>
      </Modal>
    </Card>
  );
}

export default connect(null, actions)(withToast(DownloadCart));
