/* eslint-disable no-underscore-dangle */
import React, { Component } from "react";
import moment from "moment";
import { DatePicker, message } from "antd";
import "leaflet/dist/leaflet.css";
import "../../Styles/Components/grsComponent.css";
import API from "../API";
import PlotlyAltVelocity from "./PlotlyAltVelocity";

import Spinner from "../Layout/Spinner";

const { RangePicker } = DatePicker;
const dateFormat = "MMMM DD, YYYY";

export default class AltVelocity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      satellite: "",
      GPSData: [],
      center: [42, 72],
      end: new Date(),
      start: moment(new Date()).subtract(7, "days"),
      grouping: "days"
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.satellite !== this.props.satellite) {
      this.passData(this.props.satellite);
    }
  }

  passData(data) {
    this.setState(
      {
        loading: true,
        GPSData: [],
        satellite: data
      },
      () => {
        this.fetchData(1);
      }
    );
  }

  fetchData(page) {
    const startDate = moment(this.state.start).format("YYYY-MM-DD");
    const endDate = moment(this.state.end).format("YYYY-MM-DD");
    API.get(
      `satellite_health_management/satellite_location_velocity_computed_data?satellite=${this.props.satellite}&page_size=100&page=${page}&from:epoch_acu_date=${startDate}&to:epoch_acu_date=${endDate}`
    )
      .then(res => {
        if (res.data.pagination.links.next) {
          const dataArray = this.state.GPSData;
          const combinedArray = dataArray.concat(res.data.data);
          this.setState(
            {
              GPSData: combinedArray,
              loading: true
            },
            () => {
              this.fetchData(page + 1);
            }
          );
        } else {
          const dataArray = this.state.GPSData;
          const combinedArray = dataArray.concat(res.data.data);
          this.setState({
            GPSData: combinedArray,
            loading: false
          });
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error) {
          message.error("Error in fetching data, please reload the page.");
        }
      });
  }

  rangePickerChangeHandler = e => {
    if (e.length !== 0) {
      if (e[0]._d && e[1]._d) {
        const diff = moment(e[1]).diff(moment(e[0]), "days");
        this.setState(
          {
            GPSData: [],
            loading: true,
            start: e[0],
            end: e[1],
            grouping: "hours"
          },
          () => {
            this.fetchData(1);
          }
        );
      }
    }
  };

  render() {
    return (
      <div>
        <div
          style={{
            marginTop: "-80px",
            float: "right",
            position: "relative",
            marginBottom: "60px"
          }}
        >
          <RangePicker
            defaultValue={[
              moment(this.state.start, dateFormat),
              moment(this.state.end, dateFormat)
            ]}
            format={dateFormat}
            onChange={this.rangePickerChangeHandler}
            disabled={this.state.loading ? true : false}
          />
        </div>
        {this.state.GPSData && !this.state.loading ? (
          <div>
            <PlotlyAltVelocity dataAll={this.state.GPSData} />
          </div>
        ) : (
          <div>
            <div style={{ marginTop: "50px" }}>
              <h3 className="panel__title label">ALTITUDE</h3>
            </div>
            <div className="panel" style={{ height: "100%" }}>
              <Spinner />
            </div>
            <div style={{ marginTop: "50px" }}>
              <h3 className="panel__title label">VELOCITY</h3>
            </div>
            <div className="panel" style={{ height: "100%" }}>
              <Spinner />
            </div>
          </div>
        )}
      </div>
    );
  }
}
