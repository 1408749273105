import API from "../Components/API";
import {
  LIST_SATELLITE,
  LIST_PAYLOAD,
  LIST_RECEIVING_STATION,
  LIST_TELEMETRY_TYPES,
  LIST_USERS,
  LIST_IMAGE_TAGS,
  LIST_MISSION_TYPES,
  LIST_MISSION_STATUSES,
  LIST_POINTING_MODES,
  LIST_MISSION_STATUS_TYPES
} from "./types";

export const listSatellite = () => async dispatch => {
  API.get(`core/satellites`)
    .then(res => {
      dispatch({
        type: LIST_SATELLITE,
        payload: res.data.data
      });
    })
    .catch(error => {
      console.log(error);
    });
};

export const listPayload = () => async dispatch => {
  API.get(`payloads`)
    .then(res => {
      dispatch({
        type: LIST_PAYLOAD,
        payload: res.data.data
      });
    })
    .catch(error => {
      console.log(error);
    });
};

export const listReceivingStation = () => async dispatch => {
  API.get(`receiving_station`)
    .then(res => {
      dispatch({
        type: LIST_RECEIVING_STATION,
        payload: res.data.data
      });
    })
    .catch(error => {
      console.log(error);
    });
};

export const listTelemetryTypes = () => async dispatch => {
  API.get(`data_management/telemetry_file_types`)
    .then(res => {
      dispatch({
        type: LIST_TELEMETRY_TYPES,
        payload: res.data.data
      });
    })
    .catch(error => {
      console.log(error);
    });
};

export const listUsers = () => async dispatch => {
  API.get(`core/users`)
    .then(res => {
      dispatch({
        type: LIST_USERS,
        payload: res.data.data
      });
    })
    .catch(error => {
      console.log(error);
    });
};

export const listImageTags = () => async dispatch => {
  API.get(`data_management/image_tags`)
    .then(res => {
      dispatch({
        type: LIST_IMAGE_TAGS,
        payload: res.data.data
      });
    })
    .catch(error => {
      console.log(error);
    });
};

export const listMissionTypes = () => async dispatch => {
  API.get(`missions_management/mission_types`)
    .then(res => {
      dispatch({
        type: LIST_MISSION_TYPES,
        payload: res.data.data
      });
    })
    .catch(error => {
      console.log(error);
    });
};

export const listMissionStatuses = () => async dispatch => {
  API.get(`missions_management/mission_status_stats`)
    .then(res => {
      dispatch({
        type: LIST_MISSION_STATUSES,
        payload: res.data.ALL
      });
    })
    .catch(error => {
      console.log(error);
    });
};

export const listPointingModes = () => async dispatch => {
  API.get(`missions_management/pointing_modes`)
    .then(res => {
      dispatch({
        type: LIST_POINTING_MODES,
        payload: res.data.data
      });
    })
    .catch(error => {
      console.log(error);
    });
};

export const listMissionStatusTypes = () => async dispatch => {
  API.get(`missions_management/mission_statuses`)
    .then(res => {
      dispatch({
        type: LIST_MISSION_STATUS_TYPES,
        payload: res.data.data
      });
    })
    .catch(error => {
      console.log(error);
    });
};
