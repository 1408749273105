import React, { PureComponent } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const COLORS = ["#7BD1AA", "#6EB5FF"];

export default class DLCapturedPieChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const total =
      this.props.total !== 0 ? this.props.downloaded + this.props.captured : 1;

    const data = [
      { name: "Downloaded", value: this.props.downloaded },
      { name: "Captured", value: this.props.captured }
    ];

    return (
      <div>
        <div>
          {data.map((stats, index) => (
            <div style={{ paddingTop: "10px" }} key={index}>
              <i
                className="fas fa-square"
                style={{ color: COLORS[index], paddingRight: "10px" }}
              />
              {stats.name}
              <span style={{ paddingLeft: "20px" }}>
                {total !== 0
                  ? Math.floor((stats.value / total) * 100).toFixed(1)
                  : 0}
                %
              </span>
            </div>
          ))}
        </div>
        <ResponsiveContainer width="100%" height={250}>
          <PieChart width={500} height={220} onMouseEnter={this.onPieEnter}>
            <Pie
              data={data}
              innerRadius={65}
              outerRadius={110}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
