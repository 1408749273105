import queryString from "query-string";
import history from "../../history";

const syncFilters = (query, filter, data) => {
  const filterObj = { [filter]: data };
  const modifiedQuery = {
    ...query,
    ...filterObj
  };

  return history.replace({
    pathname: window.location.pathname,
    search: queryString.stringify(modifiedQuery)
  });
};

export default syncFilters;
