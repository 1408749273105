import React, { Component } from "react";
import { Map, TileLayer, FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import { connect } from "react-redux";
import * as actions from "../../Actions";

class BlankMap extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onCreated = e => {
    this.props.targetGeom(e.layer.toGeoJSON());
  };

  render() {
    return (
      <Map
        style={{ height: "300px", width: "100%" }}
        ref={m => {
          this.leafletMap = m;
        }}
        center={[12.57513, 122.27081]}
        zoom={6}
        scrollWheelZoom={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <FeatureGroup>
          <EditControl
            position="topright"
            onEdit={this.onEditPath}
            onEdited={e => {
              e.layers.eachLayer(a => {
                this.props.targetGeom(a.toGeoJSON());
              });
            }}
            edit={{ remove: true }}
            onCreated={this.onCreated}
            draw={{
              polyline: false,
              polygon: true,
              rectangle: true,
              circlemarker: false,
              circle: false,
              marker: false
            }}
          />
        </FeatureGroup>
      </Map>
    );
  }
}

const mapStateToProps = state => {
  return {
    mapData: state.target.data
  };
};

export default connect(mapStateToProps, actions)(BlankMap);
