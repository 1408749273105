import API from "../Components/API";
import { PENDING_MISSIONS } from "./types";

export const pendingMissions = (
  search,
  status,
  pointingMode,
  pagination,
  sort
) => async dispatch => {
  const setMode = pointingMode === "All" ? "" : pointingMode;
  API.get(
    `missions_management/missions?format=json&status=${status}&pointing_mode=${setMode}&page_size=5&page=${pagination}&ordering=${sort}&search=${search}`
  )
    .then(res => {
      dispatch({
        type: PENDING_MISSIONS,
        payload: res.data
      });
    })
    .catch(error => {
      console.log(error);
    });
};
