import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import history from "./history";
import store from "./Store";
import ProtectedRoute from "./Components/ProtectedRoutes";
import OpenRoute from "./Components/OpenRoutes";
import ScrollToTop from "./Components/ScrollToTop";
import "./Styles/index.scss";
import Login from "./Pages/Auth/Login";

import DataManagementDashboard from "./Pages/DataManagement/DataManagementDashboard";
import Telemetry from "./Pages/DataManagement/Telemetry";
import RawImages from "./Pages/DataManagement/RawImages";
import MergedRawImages from "./Pages/DataManagement/MergedRawImages";
import RawDataManagement from "./Pages/DataManagement/RawDataManagement";
import LevelZeroDataManagement from "./Pages/DataManagement/LevelZeroDataManagement";
import LevelOneDataManagement from "./Pages/DataManagement/LevelOneDataManagement";
import UpdateCapturePage from "./Pages/DataManagement/UpdateCapturePage";
import CaptureGroups from "./Pages/DataManagement/CaptureGroups";
import ViewCaptureGroupPage from "./Pages/DataManagement/ViewCaptureGroupPage";
import CaptureGroupProducts from "./Pages/DataManagement/CaptureGroupProducts";
import ViewCaptureGroupProductPage from "./Pages/DataManagement/ViewCaptureGroupProductPage";
import ManageDistributionPage from "./Pages/DataManagement/ManageDistributionPage";
import DistributionDownloadsPage from "./Pages/Distributions/DistributionDownloadsPage";
import DistributionUsersPage from "./Pages/Distributions/DistributionUsersPage";

import MissionPlaningDashboardPage from "./Pages/Missions/MissionPlaningDashboardPage";
import GRSOperationsDashboardPage from "./Pages/Missions/GRSOperationsDashboardPage";
import ReportsPage from "./Pages/Missions/ReportsPage";
import TargetsPage from "./Pages/Missions/TargetsPage";
import ViewTargetPage from "./Pages/Missions/ViewTargetPage";
import CreateTargetPage from "./Pages/Missions/CreateTargetPage";
import UpdateTargetPage from "./Pages/Missions/UpdateTargetPage";
import CreateCommandBatchPage from "./Pages/Missions/CreateCommandBatchPage";
import UpdateCommandBatchPage from "./Pages/Missions/UpdateCommandBatchPage";

import AcquisitionMissionPage from "./Pages/Missions/AcquisitionMissionPage";
import AddAcquisitionMissionPage from "./Pages/Missions/AddAcquisitionMissionPage";
import ViewAcquisitionMissionPage from "./Pages/Missions/ViewAcquisitionMissionPage";
import ViewAcuLogsDownloadMissionPage from "./Pages/Missions/ViewAcuLogsDownloadMissionPage";

import UploadMissionsPage from "./Pages/Missions/UploadMissionsPage";
import AddUploadMissionPage from "./Pages/Missions/AddUploadMissionPage";
import ViewUploadMissionPage from "./Pages/Missions/ViewUploadMissionPage";

import ViewDownloadMissionsPage from "./Pages/Missions/ViewDownloadMissionsPage";
import AddDownloadMissionsPage from "./Pages/Missions/AddDownloadMissionsPage";
import DownloadMissionsPage from "./Pages/Missions/DownloadMissionsPage";

import AcuLogsDownloadMissionsPage from "./Pages/Missions/AcuLogsDownloadMissionsPage";
import AddAcuLogsDownloadMissionsPage from "./Pages/Missions/AddAcuLogsDownloadMissionsPage";

import CommandBatchesMissionPage from "./Pages/Missions/CommandBatchesMissionPage";
import ViewCommandBatchesPage from "./Pages/Missions/ViewCommandBatchesPage";
import AddCommandBatchesPage from "./Pages/Missions/AddCommandBatchesPage";

import ViewCommandsPage from "./Pages/Missions/ViewCommandsPage";
import CommandsMission from "./Pages/Missions/CommandsMission";
import AddCommandsPage from "./Pages/Missions/AddCommandsPage";

import LogsPage from "./Pages/SatelliteHealth/LogsPage";
import HealthPage from "./Pages/SatelliteHealth/HealthPage";
import BetaAnglePage from "./Pages/SatelliteHealth/BetaAnglePage";
import LifetimePredictionPage from "./Pages/SatelliteHealth/LifetimePredictionPage";
import SatelliteHealthDashboard from "./Pages/SatelliteHealth/SatelliteHealthDashboard";

import Home from "./Pages/Home";
import InfoPage from "./Pages/Info";
import KitchenSink from "./Pages/KitchenSink";
import RouteList from "./Pages/RouteList";
import DownloadCart from "./Pages/DataManagement/DownloadCart";

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <ScrollToTop />
        <Switch>
          <Route path="/route-list" component={RouteList} />
          <Route path="/info" component={InfoPage} />
          <ProtectedRoute path="/components" component={KitchenSink} />
          <ProtectedRoute
            path="/satellite_health_management/satellite_health_dashboard"
            component={SatelliteHealthDashboard}
          />
          <ProtectedRoute
            path="/satellite_health_management/lifetime-prediction"
            component={LifetimePredictionPage}
          />
          <ProtectedRoute
            path="/satellite_health_management/beta_angles"
            component={BetaAnglePage}
          />
          <ProtectedRoute path="/satellite/health" component={HealthPage} />
          <ProtectedRoute path="/satellite/logs" component={LogsPage} />

          <ProtectedRoute
            exact
            path="/missions_management/download_missions/view/:id"
            component={ViewDownloadMissionsPage}
          />
          <ProtectedRoute
            exact
            path="/missions_management/download_missions/add"
            component={AddDownloadMissionsPage}
          />
          <ProtectedRoute
            exact
            path="/missions_management/download_missions"
            component={DownloadMissionsPage}
          />

          <ProtectedRoute
            exact
            path="/missions_management/upload_missions"
            component={UploadMissionsPage}
          />
          <ProtectedRoute
            exact
            path="/missions_management/upload_missions/add"
            component={AddUploadMissionPage}
          />
          <ProtectedRoute
            exact
            path="/missions_management/upload_missions/view/:id"
            component={ViewUploadMissionPage}
          />

          <ProtectedRoute
            exact
            path="/missions_management/acquisition_missions/view/:id"
            component={ViewAcquisitionMissionPage}
          />
          <ProtectedRoute
            exact
            path="/missions_management/acquisition_missions/add"
            component={AddAcquisitionMissionPage}
          />
          <ProtectedRoute
            exact
            path="/missions_management/acquisition_missions"
            component={AcquisitionMissionPage}
          />

          <ProtectedRoute
            exact
            path="/missions_management/acu_log_download_missions/view/:id"
            component={ViewAcuLogsDownloadMissionPage}
          />
          <ProtectedRoute
            exact
            path="/missions_management/acu_log_download_missions/add"
            component={AddAcuLogsDownloadMissionsPage}
          />
          <ProtectedRoute
            exact
            path="/missions_management/acu_log_download_missions"
            component={AcuLogsDownloadMissionsPage}
          />

          <ProtectedRoute
            exact
            path="/missions_management/command_batches"
            component={CommandBatchesMissionPage}
          />
          <ProtectedRoute
            exact
            path="/missions_management/command_batches/add"
            component={AddCommandBatchesPage}
          />
          <ProtectedRoute
            exact
            path="/missions_management/command_batches/view/:id"
            component={ViewCommandBatchesPage}
          />

          <ProtectedRoute
            path="/missions_management/commands/view/:id"
            component={ViewCommandsPage}
          />
          <ProtectedRoute
            path="/missions_management/commands/add"
            component={AddCommandsPage}
          />
          <ProtectedRoute
            path="/missions_management/commands"
            component={CommandsMission}
          />

          <ProtectedRoute
            path="/missions/passes/update-command-batch"
            component={UpdateCommandBatchPage}
          />
          <ProtectedRoute
            path="/missions/passes/create-command-batch"
            component={CreateCommandBatchPage}
          />

          <ProtectedRoute
            exact
            path="/missions_management/targets"
            component={TargetsPage}
          />
          <ProtectedRoute
            exact
            path="/missions_management/targets/view/:id"
            component={ViewTargetPage}
          />
          <ProtectedRoute
            exact
            path="/missions/targets/update"
            component={UpdateTargetPage}
          />
          <ProtectedRoute
            exact
            path="/missions_management/targets/add"
            component={CreateTargetPage}
          />
          <ProtectedRoute
            path="/missions/GRS"
            component={GRSOperationsDashboardPage}
          />
          <ProtectedRoute
            path="/missions_management/mission_planning_dashboard"
            component={MissionPlaningDashboardPage}
          />
          <ProtectedRoute
            path="/missions_management/mission_reports"
            component={ReportsPage}
          />
          <ProtectedRoute
            path="/data/captures/manage-distribution"
            component={ManageDistributionPage}
          />
          <ProtectedRoute
            path="/data/captures/update"
            component={UpdateCapturePage}
          />
          <ProtectedRoute
            path="/data_management/dashboard"
            component={DataManagementDashboard}
          />
          <ProtectedRoute
            exact
            path="/data_management/telemetry_files"
            component={Telemetry}
          />
          <ProtectedRoute
            exact
            path="/data_management/raw_images"
            component={RawImages}
          />
          <ProtectedRoute
            exact
            path="/data_management/merged_raw_images"
            component={MergedRawImages}
          />
          <ProtectedRoute
            exact
            path="/data_management/captures_raw"
            component={RawDataManagement}
          />
          <ProtectedRoute
            exact
            path="/data_management/captures_level0"
            component={LevelZeroDataManagement}
          />
          <ProtectedRoute
            exact
            path="/data_management/captures_level1"
            component={LevelOneDataManagement}
          />
          <ProtectedRoute
            exact
            path="/data_management/capture_groups"
            component={CaptureGroups}
          />
          <ProtectedRoute
            exact
            path="/data_management/capture_groups/view/:id"
            component={ViewCaptureGroupPage}
          />
          <ProtectedRoute
            exact
            path="/data_management/capture_group_products"
            component={CaptureGroupProducts}
          />
          <ProtectedRoute
            exact
            path="/data_management/capture_group_products/view/:id"
            component={ViewCaptureGroupProductPage}
          />
          <OpenRoute
            path="/data_management/distribution_downloads_dashboard"
            component={DistributionDownloadsPage}
          />
          <OpenRoute
            path="/data_management/distribution_users_dashboard"
            component={DistributionUsersPage}
          />
          <ProtectedRoute
            exact
            path="/data_management/download_cart"
            component={DownloadCart}
          />
          <Route path="/login" component={Login} />
          <ProtectedRoute exact path="/" component={Home} />
          <ProtectedRoute exact path="/home" component={Home} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
