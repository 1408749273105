import React, { Component } from "react";
import { Modal, Button, Icon, Popover, Input, Row, Col } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import API from "../API";
import CommandView from "./CommandView";

export default class InputCommand extends Component {
  state = { visible: false, data: "" };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.commandId !== this.props.commandId) {
      this.passData(this.props.commandId);
    }
  }

  passData(data) {
    this.setState({ satelliteList: data }, () => this.fetchData());
  }

  fetchData = () => {
    API.get(`missions_management/commands/${this.props.commandId}`)
      .then(res => {
        this.setState({
          data: res.data.content
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleOk = e => {
    this.setState({
      visible: false
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false
    });
  };

  render() {
    return (
      <>
        <Row gutter={16}>
          <Col md={15}>
            <Input
              {...this.props.input}
              autoComplete="off"
              type={this.props.type}
            />
          </Col>
          <Col md={6}>
            <Button type="primary" ghost onClick={this.showModal}>
              Preview
            </Button>
          </Col>
        </Row>
        <Modal
          title="Command Content"
          style={{ top: 20 }}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          width={800}
        >
          <CommandView data={this.state.data} />
        </Modal>
      </>
    );
  }
}
