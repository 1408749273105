import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  Button,
  Row,
  Col,
  Card,
  Pagination,
  DatePicker,
  Select,
  Switch,
  Input
} from "antd";
import Media from "react-media";
import queryString from "query-string";
import { connect } from "react-redux";
import moment from "moment";
import * as actions from "../../Actions";
import API from "../../Components/API";
import SearchComponent from "../../Components/MissionsComponents/SearchComponent";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import Drawer from "../../Components/CaptureGroupComponents/DrawerCaptureGroups";
import {
  extractOrder,
  toTimeString,
  syncFilters,
  tableIndexStyle,
  convertToUTC,
  reverseUTC
} from "../../Components/CommonFunctions";
import FilterComponent from "../../Components/MissionsComponents/FilterComponent";
import Config from "../../Config/const";
import withToast from "../../Components/Hocs/withToast";
import noImage from "../../Assets/Images/noImage.png";
import axios from "axios";

let controllerRef;

const redirectLink = (url, id) => {
  return `${url}${id}`;
};

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD HH:mm:ss";
const { Option } = Select;

let query = {};

class CaptureGroups extends Component {
  constructor(props) {
    query = queryString.parse(window.location.search);
    super(props);
    this.state = {
      selectedRowKeys: [],
      pagination: {},
      loading: false,
      captureGroupFiles: "",
      rawData: [],
      payloads: [],
      visible: false,
      currentPage: 1,
      pageSize: 25,
      action: "",
      columns: [
        {
          title: <span className="table-index-number">No</span>,
          dataIndex: "index",
          align: "center",
          render(text) {
            return tableIndexStyle(text);
          }
        },
        {
          title: "Id",
          dataIndex: "id",
          sorter: true,
          align: "center",
          render: (text, row) => (
            <Link
              to={redirectLink(
                "/data_management/capture_groups/view/",
                row.capture_group_id
              )}
            >
              {text}
            </Link>
          )
        },
        {
          title: "Image",
          dataIndex: "thumbnail",
          align: "center",
          render: image => (
            <img
              src={image ? image : noImage}
              alt="dataImages"
              height="40px"
              width="50px"
            />
          )
        },
        {
          title: "Published?",
          dataIndex: "is_published",
          align: "center",
          render: (publish, row) => {
            return (
              <Switch
                checkedChildren="Published"
                unCheckedChildren="Unpublished"
                defaultChecked={publish === "true" ? true : false}
                onChange={e => {
                  const data = {
                    is_published: e
                  };
                  this.updateCapture(data, row.capture_group_id, "row update");
                }}
              />
            );
          }
        },
        {
          title: "Group ID",
          dataIndex: "capture_group_id",
          sorter: true,
        },
        {
          title: "Mission",
          dataIndex: "mission",
          sorter: true,
          render: (mission, row) => (
            <Link
              to={redirectLink(
                "/missions_management/acquisition_missions/view/",
                row.mission_id
              )}
            >
              {row.mission_id} - {mission}
            </Link>
          )
        },
        {
          title: "Created Time",
          dataIndex: "created_time",
          sorter: true
        },
        {
          title: "",
          dataIndex: "key",
          key: "key",
          align: "center",
          render: key => {
            return (
              <Button
                shape="circle"
                icon="eye"
                onClick={event => {
                  this.showDrawer(key);
                }}
              />
            );
          }
        }
      ],
      // FILTERS
      search: query.search || "",
      capture_group_id: query.capture_group_id || "",
      is_published: query.is_published || "",
      payload: query.payload || "",
      created_time_from: query.created_time_from || "",
      created_time_to: query.created_time_to || ""
    };
  }

  componentDidMount() {
    this.fetch();
    this.props.listPayload();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.payloads !== this.props.payloads) {
      this.passData(this.props.payloads);
    }
  }

  passData(data) {
    const payloadList = data.map(tag => (
      <Option key={tag.name}>{tag.name}</Option>
    ));
    this.setState({ payloads: payloadList });
  }

  fetch = (page = 1, sort = "-id") => {
    this.setState({ loading: true });
    if (controllerRef) {
      controllerRef.cancel();
    }
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    controllerRef = source;

    const payload = this.state.payload ? `&payload=${this.state.payload}` : "";
    API.get(
      `data_management/capture_groups?format=json&search=${this.state.search}&ordering=${sort}&page=${this.state.currentPage}&page_size=${this.state.pageSize}&capture_group_id=${this.state.capture_group_id}&is_published=${this.state.is_published}&from:created_time=${this.state.created_time_from}&to:created_time=${this.state.created_time_to}${payload}`, { cancelToken: source.token }
    )
      .then(res => {
        const getTotalPages = parseInt(res ? res.data.pagination.count : 0);
        this.setState({
          loading: false,
          captureGroupFiles: res.data,
          rawData: this.formatData(res.data),
          total: getTotalPages
        });
        controllerRef = null;
      })
      .catch(function(error) {
        if (error.toString() !== "Cancel") {
          console.log(error);
        }
      });
  };

  formatData = tableData => {
    const captures = [];
    const rawData = tableData ? tableData.data : [];
    rawData.map((val, index) => {
      return captures.push({
        index: `${this.state.pageSize * (this.state.currentPage - 1) +
          1 +
          index}`,
        key: `${val.capture_group_id}`,
        id: `${val.id}`,
        thumbnail: `${val.thumbnail}`,
        is_published: `${val.is_published}`,
        payload: `${val.payload}`,
        capture_group_id: `${val.capture_group_id}`,
        mission: `${val.mission ? val.mission.name : ""}`,
        mission_id: `${val.mission ? val.mission.id : ""}`,
        description: val.description,
        created_time: `${toTimeString(val.created_time)}`
      });
    });
    return captures;
  };

  updateCapture = (data, id, type) => {
    this.setState({ loading: true });
    const freezeData = JSON.parse(JSON.stringify(data));
    return API.patch(`/data_management/capture_groups/${id}/`, freezeData)
      .then(res => {
        if (type === "row update") {
          const rowItems = [...this.state.rawData];
          const rowIndex = rowItems.findIndex(
            row => row.capture_group_id === id
          );
          rowItems[rowIndex].is_published = data.is_published;

          this.setState(
            {
              rawData: rowItems
            },
            () => {
              this.setState({ loading: false });
            }
          );
        } else {
          this.setState({
            rawData: [],
            selectedRowKeys: []
          });
          return res;
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        this.props.openNotification("error", "Oops!", "Something went wrong!");
        console.log(err);
      });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.fetch(pagination.current, extractOrder(sorter));
  };

  handleFilterChange = (filter, data) => {
    // check if filter is a date
    const dateFilter = ["date_uploaded", "created_time"];
    if (dateFilter.includes(filter)) {
      const start = data.length > 0 ? convertToUTC(data[0]) : "";
      const end = data.length > 0 ? convertToUTC(data[1]) : "";

      // add start date to query
      query = queryString.parse(window.location.search);
      syncFilters(query, `${filter}_from`, start);

      // add end date to query
      query = queryString.parse(window.location.search);
      syncFilters(query, `${filter}_to`, end);
    } else {
      // if filter is not a date
      query = queryString.parse(window.location.search);
      syncFilters(query, filter, data);
    }

    switch (filter) {
      case "search":
        this.setState(
          {
            ...this.state,
            currentPage: 1,
            search: data
          },
          () => {
            this.fetch();
          }
        );
        break;
      case "capture_group_id":
        this.setState(
          {
            ...this.state,
            currentPage: 1,
            capture_group_id: data
          },
          () => {
            this.fetch();
          }
        );
        break;
      case "is_published":
        this.setState(
          {
            ...this.state,
            currentPage: 1,
            is_published: data
          },
          () => {
            this.fetch();
          }
        );
        break;
      case "payload":
        this.setState(
          {
            ...this.state,
            currentPage: 1,
            payload: data
          },
          () => {
            this.fetch();
          }
        );
        break;
      case "created_time":
        if (data.length > 0) {
          this.setState(
            {
              ...this.state,
              currentPage: 1,
              created_time_from: convertToUTC(data[0]),
              created_time_to: convertToUTC(data[1])
            },
            () => {
              this.fetch();
            }
          );
        } else {
          this.setState(
            {
              currentPage: 1,
              created_time_from: "",
              created_time_to: ""
            },
            () => {
              this.fetch();
            }
          );
        }
        break;
      default:
        return false;
    }
  };

  checkStatus = data => {
    switch (data) {
      case "&is_published=true":
        return "Published";
      case "&is_published=false":
        return "Unpublished";
      default:
        return "All";
    }
  };

  handleActionChange = () => {
    if (!this.state.action) return alert("Please choose an action");
    const selectedAction = this.state.action === "Publish" ? true : false;
    const data = {
      is_published: selectedAction
    };
    const res = this.state.selectedRowKeys.map(id => {
      return this.updateCapture(data, id);
    });

    if (res) {
      this.fetch();
    }
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  enterPressed = event => {
    const keyCode = window.event ? event.which : event.keyCode;
    if (keyCode === 13) {
      this.setState(
        {
          currentPage: 1
        },
        () => {
          this.fetch();
        }
      );
    }
  };

  onPaginationChange = (page = 1, size) => {
    if (page !== this.state.currentPage && page > 0) {
      this.onPageChange(page);
    }

    if (size !== this.state.pageSize) {
      this.onPageSizeChange(size);
    }
  };

  onPageChange(page) {
    this.setState(
      {
        currentPage: page
      },
      () => {
        this.fetch();
      }
    );
  }

  onPageSizeChange(size) {
    this.setState(
      {
        pageSize: size,
        currentPage: 1
      },
      () => {
        this.fetch();
      }
    );
  }

  showDrawer(key) {
    API.get(`data_management/capture_groups/${key}?format=json`)
      .then(res => {
        this.setState({
          drawerData: res.data,
          visible: true
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  handleTagChange = value => {
    query = queryString.parse(window.location.search);
    syncFilters(query, "payload", value.toString());
    this.setState(
      {
        ...this.state,
        currentPage: 1,
        payload: value.toString()
      },
      () => {
        this.fetch();
      }
    );
  };

  removeTagChange = value => {
    const inititalList = this.state.payload.split(",");
    const newTagList = inititalList.filter(x => x !== value);

    query = queryString.parse(window.location.search);
    syncFilters(query, "payload", newTagList.toString());
    this.setState(
      {
        ...this.state,
        currentPage: 1,
        payload: newTagList.toString()
      },
      () => {
        this.fetch();
      }
    );
  };

  render() {
    const urls = {
      "/": "DATA MANAGEMENT",
      "/data_management/capture_groups": "CAPTURE GROUPS"
    };

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true
    };
    const hasSelected = selectedRowKeys.length > 0;
    const computeSize = (size, count) => {
      if (count < size) {
        return count;
      }
      return size;
    };

    const getResponsiveColumns = matches => {
      if (matches.medium) {
        return this.state.columns.filter(column => !column.hideOnMedium);
      }
      if (matches.small) {
        return this.state.columns.filter(
          column => !column.hideOnMedium && !column.hideOnSmall
        );
      }
      return this.state.columns;
    };

    return (
      <div>
        <Drawer
          visible={this.state.visible}
          data={this.state.drawerData}
          // relatedImages={this.state.relatedImages}
          // updateCapture={this.updateCapture}
          updateCapture={(data, id) => this.updateCapture(data, id)}
          closeDrawer={() => {
            this.setState({
              visible: false,
              drawerData: {},
              // relatedImages: []
            });
          }}
        />
        <BreadCrumbComponent data={urls} titlePage="Capture Groups" />
        <Row>
          <Col>
            <Card>
              <Row gutter={{ md: 24 }}>
                <Col span={5} style={{ textAlign: "left" }}>
                  <span className="label">Group ID</span>
                  <Input
                    defaultValue={this.state.capture_group_id}
                    placeholder="Search By Group ID"
                    onKeyPress={e => {
                      this.enterPressed(e, "capture_group_id");
                    }}
                    onBlur={e => {
                      this.handleFilterChange(
                        "capture_group_id",
                        e.target.value
                      );
                    }}
                    onChange={e => {
                      this.setState({
                        capture_group_id: e.target.value
                      });
                    }}
                  />
                </Col>
                <Col span={4} style={{ textAlign: "left" }}>
                  <span className="label">Is Published</span>
                  <Select
                    value={this.checkStatus(this.state.is_published)}
                    style={{ width: "100%" }}
                    // placeholder="All"
                    onChange={e => this.handleFilterChange("is_published", e)}
                  >
                    <Option key={0} value="">
                      All
                    </Option>
                    <Option key="true">Published</Option>
                    <Option key="false">Unpublished</Option>
                  </Select>
                </Col>
                <Col span={5} style={{ textAlign: "left" }}>
                  <span className="label">Payload</span>
                  <Select
                    showSearch
                    allowClear
                    defaultValue={
                      this.state.payload ? this.state.payload.split(",") : []
                    }
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Select Tags"
                    filterOption={(input, option) =>
                      option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onBlur={this.handleTagChange}
                    onDeselect={this.removeTagChange}
                  >
                    {this.state.payloads}
                  </Select>
                </Col>
                <Col md={6} xs={24} style={{ textAlign: "left" }}>
                  <span className="label">Created Time</span>
                  <div>
                    <RangePicker
                      format={dateFormat}
                      showTime={{
                        hideDisabledOptions: true,
                        defaultValue: [
                          moment("00:00:00", "HH:mm:ss"),
                          moment("23:59:59", "HH:mm:ss")
                        ]
                      }}
                      defaultValue={
                        this.state.created_time_from
                          ? [
                              moment(
                                reverseUTC(this.state.created_time_from),
                                dateFormat
                              ),
                              moment(
                                reverseUTC(this.state.created_time_to),
                                dateFormat
                              )
                            ]
                          : null
                      }
                      style={{ width: "100%" }}
                      onChange={e => {
                        this.handleFilterChange("created_time", e);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={24}>
            <Card style={{ marginTop: "20px" }}>
              <Row>
                <Col md={12}>
                  <div className="actionGroup">
                    <span className="label">Action:</span>
                    <div style={{ width: "50%", margin: "0 15px" }}>
                      <FilterComponent
                        optionData={Config.dataManagement.captureGroups}
                        placeholder="Choose an action"
                        handleChange={e => {
                          this.setState({ action: e });
                        }}
                      />
                    </div>
                    <Button
                      type="primary"
                      onClick={() => {
                        this.handleActionChange();
                      }}
                      disabled={!hasSelected}
                      loading={this.state.loading}
                    >
                      Go
                    </Button>
                    <span style={{ marginLeft: 8 }}>
                      {hasSelected
                        ? `Selected ${selectedRowKeys.length} out of ${this.state.total}`
                        : ""}
                    </span>
                  </div>
                </Col>
                <Col md={12}>
                  <Row>
                    <Col md={14}>
                      <div
                        style={{
                          float: "right",
                          paddingTop: "22px"
                        }}
                      >
                        {this.state.captureGroupFiles
                          ? `${computeSize(
                              this.state.pageSize *
                                (this.state.currentPage - 1) +
                                1,
                              this.state.captureGroupFiles.pagination.count
                            )} - ${computeSize(
                              this.state.pageSize * this.state.currentPage,
                              this.state.captureGroupFiles.pagination.count
                            )} of ${
                              this.state.captureGroupFiles.pagination.count
                            } items`
                          : ""}
                      </div>
                    </Col>
                    <Col md={10}>
                      <div
                        style={{
                          width: "95%",
                          float: "right",
                          paddingTop: "16px",
                          marginBottom: "20px"
                        }}
                      >
                        <SearchComponent
                          name="search"
                          value={this.state.search}
                          onkeypress={e => {
                            this.enterPressed(e);
                          }}
                          onchange={e => {
                            this.setState({
                              search: e.target.value
                            });
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Media
                queries={{
                  xs: "(max-width: 1099px)",
                  small: "(min-width: 1100px) and (max-width: 1199px)",
                  medium: "(min-width: 1200px) and (max-width: 1299px)",
                  large: "(min-width: 1300px)"
                }}
              >
                {matches => (
                  <Fragment>
                    <Table
                      scroll={matches.xs ? { x: "max-content" } : {}}
                      size="middle"
                      rowSelection={rowSelection}
                      columns={getResponsiveColumns(matches)}
                      loading={this.state.loading}
                      dataSource={this.state.rawData}
                      // dataSource={this.formatData(this.state.captureGroupFiles)}
                      pagination={false}
                      onChange={this.handleTableChange}
                      bordered
                    />
                  </Fragment>
                )}
              </Media>
              <Pagination
                style={{ marginTop: "20px", float: "right" }}
                showSizeChanger
                onChange={this.onPaginationChange}
                onShowSizeChange={this.onPaginationChange}
                current={this.state.currentPage}
                total={this.state.total}
                pageSizeOptions={["25", "50", "100"]}
                pageSize={this.state.pageSize}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    payloads: state.list.listPayload
  };
};

export default connect(mapStateToProps, actions)(withToast(CaptureGroups));
