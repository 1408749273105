import React, { Component } from "react";
import { Drawer, Divider, Table } from "antd";
import { toTimeString, splitString } from "../CommonFunctions";

export default class DrawerTelemetry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      dataColumns: [
        {
          title: "",
          dataIndex: "label",
          key: "label"
        },
        {
          title: "",
          dataIndex: "value",
          key: "value",
          width: "75%"
        }
      ],
      dataColumnsImages: [
        {
          title: "",
          dataIndex: "label",
          key: "label"
        },
        {
          title: "",
          dataIndex: "value",
          key: "value",
          width: "85%"
        }
      ],
      dataInfoData: [],
      dataFile: [],
      dataImages: []
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setDataInformation(this.props.data, this.props.relatedImages);
    }
  }

  setDataInformation = (data, images) => {
    const imageArray = [];
    const currentInfoData = [
      {
        key: "1",
        label: "Type",
        value: data.telemetry_type
      },
      {
        key: "2",
        label: "Receving Station",
        value: data.receiving_station
      },
      {
        key: "3",
        label: "Satellite",
        value: data.satellite ? data.satellite.name : ""
      },
      {
        key: "4",
        label: "Created Date",
        value: toTimeString(data.date_created)
      },
      {
        key: "5",
        label: "Uploaded Date",
        value: toTimeString(data.date_uploaded)
      },
      {
        key: "6",
        label: "Uploaded by",
        value: data.uploaded_by
      },
      {
        key: "7",
        label: "Md5sum",
        value: data.md5sum
      }
    ];

    const currentFile = [
      {
        key: "1",
        label: "File",
        value: data.file ? <a href={data.file}>{splitString(data.file)}</a> : ""
      }
    ];

    if (images.length > 0) {
      images.map((image, index) => {
        return imageArray.push({
          key: index,
          label: <img src={image.thumbnail} alt="rawImage" height="40px" />,
          value: image.thumbnail ? (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a href={image.thumbnail} target="_blank">
              {splitString(image.thumbnail)}
            </a>
          ) : (
            ""
          )
        });
      });
    }

    this.setState({
      dataInfoData: currentInfoData,
      dataFile: currentFile,
      dataImages: imageArray
    });
  };

  render() {
    // console.log(window.location.hash)
    if (this.state.dataInfoData.length > 0) {
      return (
        <div>
          <Drawer
            title={
              this.props.data.name
                ? this.props.data.name.replace(".bin", "")
                : ""
            }
            placement="right"
            closable={false}
            onClose={this.props.closeDrawer}
            visible={this.props.visible}
            width="600"
          >
            <Table
              style={{ marginTop: "20px" }}
              columns={this.state.dataColumns}
              dataSource={this.state.dataInfoData}
              bordered
              pagination={false}
              showHeader={false}
              size="small"
            />
            <Divider style={{ marginTop: "50px" }}>Files</Divider>
            <Table
              style={{ marginTop: "20px" }}
              columns={this.state.dataColumns}
              dataSource={this.state.dataFile}
              bordered
              pagination={false}
              showHeader={false}
              size="small"
            />
            <Divider style={{ marginTop: "50px" }}>Extracted Images</Divider>
            <Table
              style={{ marginTop: "20px" }}
              columns={this.state.dataColumnsImages}
              dataSource={this.state.dataImages}
              bordered
              pagination={false}
              showHeader={false}
              size="small"
            />
          </Drawer>
        </div>
      );
    }
    return null;
  }
}
