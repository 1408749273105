export const LOGIN_START = "login_start";
export const LOADING_START = "loading_start";

export const LOGOUT = "logout";
export const AUTH = "auth";
export const AUTH_ERROR = "auth_error";
export const GET_USER_PERMISSIONS = "get_user_permissions";

export const DOWNLOAD_PROFILES = "download_profiles";
export const USER_PROFILES = "user_profiles";
export const DOWNLOAD_LOADING = "download_loading";
export const PROFILES_LOADING = "profiles_loading";

// PASSES
export const PASSES_SATELLITE = "passes_satellite";
export const PASS_NEXTGRS = "pass_nextGrs";

// POSITION
export const POSITION_SATELLITE = "position_satellite";

// TRACK
export const TRACK_SATELLITE = "track_satellite";
export const TRACK_SATELLITE_GRS = "track_satellite_grs";
export const TRACK_GRSPASS = "track_grsPass";

// DECAY
export const ORBITAL_DECAY = "orbital_decay";
export const END_LIFETIME = "end_lifetime";

// MISSION
export const PENDING_MISSIONS = "pending_missions";

// SHU
export const SHU_TRACKER = "shu_tracker";
export const SHU_PAYLOAD_LIST = "shu_payload_list";

// LIST
export const LIST_SATELLITE = "list_satellite";
export const LIST_PAYLOAD = "list_payload";
export const LIST_RECEIVING_STATION = "list_receivingStation";
export const LIST_TELEMETRY_TYPES = "list_telemetry_types";
export const LIST_USERS = "list_users";
export const LIST_IMAGE_TAGS = "list_image_tags";
export const LIST_MISSION_TYPES = "list_mission_types";
export const LIST_MISSION_STATUSES = "list_mission_statuses";
export const LIST_POINTING_MODES = "list_pointing_modes";
export const LIST_MISSION_STATUS_TYPES = "list_mission_status_types";

// TARGET
export const TARGET_GEOM = "target_geom";

// REPORT
export const REPORT_MISSION_STATUS = "report_mission_status";
export const REPORT_CAPTURE_STATUS = "report_capture_status";
export const REPORT_BREAKDOWN_STATUS = "report_breakdown_status";
export const REPORT_MISSIONTYPE_STATUS = "report_missionType_status";
export const REPORT_COVERAGE_MAP = "report_coverage_map";

export const GET_COMMAND_TYPES = "get_command_types";

export const DOWNLOAD_CART = "download_cart";
export const DELETE_CART = "delete_cart";
