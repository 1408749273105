import React, { Fragment } from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

export default function BreadCrumbComponent({ data, titlePage }) {
  const breads = data
    ? Object.keys(data).map((key, val) => (
        <Breadcrumb.Item key={key} val={val}>
          <Link to={key}>{data[key]}</Link>
        </Breadcrumb.Item>
      ))
    : "";

  return (
    <Fragment>
      <Breadcrumb>{breads}</Breadcrumb>
      <div className="pageTitle">{titlePage}</div>
    </Fragment>
  );
}
