import API from "../Components/API";
import { SHU_TRACKER, SHU_PAYLOAD_LIST } from "./types";

export const shuTracker = (satellite, payload) => async dispatch => {
  API.get(
    `missions_management/shu_tracker?satellite=${satellite}&payload=${payload}`
  )
    .then(res => {
      dispatch({
        type: SHU_TRACKER,
        payload: res.data[satellite][payload]
      });
    })
    .catch(error => {
      console.log(error);
      dispatch({
        type: SHU_TRACKER,
        payload: "Error"
      });
    });
};

export const shuPayloadList = satellite => async dispatch => {
  API.get(`missions_management/shu_tracker?satellite=${satellite}`)
    .then(res => {
      dispatch({
        type: SHU_PAYLOAD_LIST,
        payload: res.data[satellite]
      });
    })
    .catch(error => {
      console.log(error);
    });
};
