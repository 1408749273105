import React, { Component } from "react";
import { Row, Card, Form, Select, Spin, Icon, Modal } from "antd";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import * as actions from "../../Actions";
import {
  renderInput,
  renderSelect,
  renderTextArea,
  renderCheckBox,
  renderMap
} from "./TargetFormComponent";
import API from "../API/index";
import Const from "../../Config/const";
import history from "../../history";
import withToast from "../Hocs/withToast";

const { Option } = Select;

class UpdateTargetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingSubmit: false,
      visible: false
    };
  }

  componentWillUnmount() {
    this.props.targetGeom({});
  }

  onSubmit = formValues => {
    // NOTE: If after edit and the map is empty, the map will show the previous coordinates
    const freezeData = JSON.parse(JSON.stringify(formValues));
    freezeData.geom = this.props.mapData;
    this.setState({
      loadingSubmit: true
    });
    API.patch(
      `/missions_management/targets/${this.props.targetId}/`,
      freezeData
    )
      .then(res => {
        this.setState({
          loadingSubmit: false
        });
        this.props.openNotification("success", "Success!", "Target Edited");
        history.push(`/missions_management/targets/view/${res.data.id}`);
      })
      .catch(err => {
        this.setState({
          loadingSubmit: false
        });
        this.props.openNotification("error", "Oops!", "Something went wrong!");
        console.log(err);
      });
  };

  onDelete = () => {
    this.setState({
      loadingSubmit: true,
      visible: true
    });
  };

  delete = () => {
    this.setState({
      visible: false
    });
    API.delete(`/missions_management/targets/${this.props.targetId}`)
      .then(res => {
        this.setState({
          loadingSubmit: false
        });
        this.props.openNotification("success", "Success!", "Target Deleted");
        history.push(`/missions_management/targets`);
      })
      .catch(err => {
        this.setState({
          loadingSubmit: false
        });
        this.props.openNotification("error", "Oops!", "Something went wrong!");
        console.log(err);
      });
  };

  render() {
    // const data = this.props.initialData ? this.props.initialData.geom : "";
    return (
      <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Spin spinning={this.props.loading || this.state.loadingSubmit}>
          <Card
            title="Change Target"
            // extra={<Button shape="round">History</Button>}
          >
            <Row>
              <Field
                name="name"
                component={renderInput}
                label="Name"
                type="text"
              />
            </Row>
            <Row>
              <Field name="geom" component={renderMap} label="Geom" />
            </Row>
            <Row>
              <Field
                name="is_active"
                component={renderCheckBox}
                label="Is active"
              />
            </Row>
            <Row>
              <Field
                name="classification"
                component={renderSelect}
                label="Classification"
              >
                {Const.target.acquisition.classification.map(val => (
                  <Option key={val} value={val.val}>
                    {val.text}
                  </Option>
                ))}
              </Field>
            </Row>
            <Row>
              <Field name="purpose" component={renderSelect} label="Purpose">
                {Const.target.acquisition.purpose.map(val => (
                  <Option key={val} value={val.val}>
                    {val.text}
                  </Option>
                ))}
              </Field>
            </Row>
            <Row>
              <Field
                name="required_bands"
                component={renderTextArea}
                label="Required bands"
              />
            </Row>
          </Card>
          <button
            onClick={this.onDelete}
            className="ant-btn ant-btn-danger m-t-10px"
            type="button"
          >
            Delete
          </button>
          <button
            className="ant-btn ant-btn-primary m-t-10px"
            type="submit"
            style={{ marginLeft: "10px" }}
          >
            Save
          </button>
          <Modal
            visible={this.state.visible}
            onOk={this.delete}
            onCancel={() => {
              this.setState({ visible: false, loadingSubmit: false });
            }}
          >
            <h3 style={{ margin: "20px" }}>
              <Icon
                type="question-circle"
                className="modal_icon"
                style={{ color: "orange" }}
              />
              Are you sure you want to delete?
            </h3>
          </Modal>
        </Spin>
      </Form>
    );
  }
}

const validate = formValues => {
  const errors = {};
  if (!formValues.name) {
    errors.name = "You must enter a name";
  }
  if (!formValues.classification) {
    errors.classification = "You must enter a classification";
  }
  if (!formValues.purpose) {
    errors.purpose = "You must enter a purpose";
  }

  return errors;
};

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues, // retrieve name from redux store
  mapData: state.target.data
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "targetForm",
    validate,
    enableReinitialize: true
  })(withToast(UpdateTargetForm))
);
