import {
  LOGIN_START,
  LOADING_START,
  LOGOUT,
  AUTH,
  AUTH_ERROR,
  GET_USER_PERMISSIONS
} from "../Actions/types";

const INITIAL_STATE = {
  status: "",
  loading: false,
  error: "",
  auth: ""
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN_START:
      return { ...state, status: action.payload };
    case LOADING_START:
      return { ...state, loading: true };
    case LOGOUT:
      return { ...state, status: false };
    case AUTH:
      return { ...state, status: action.payload };
    case AUTH_ERROR:
      return { ...state, error: action.payload };
    case GET_USER_PERMISSIONS:
      return { ...state, rules: action.payload };
    default:
      return state;
  }
}
