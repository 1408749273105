import React, { Component } from "react";
import Leaflet from "leaflet";
import { Map, CircleMarker, TileLayer, Tooltip } from "react-leaflet";
import moment from "moment";
import { DatePicker, message } from "antd";
import "leaflet/dist/leaflet.css";
import "../../Styles/Components/grsComponent.css";
import API from "../API";
import Spinner from "../Layout/Spinner";

const dateFormat = "MMMM DD, YYYY";

export default class GPSMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      satellite: "",
      GPSData: [],
      center: [42, 72],
      end: new Date(),
      start: moment(new Date()).subtract(1, "days")
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.satellite !== this.props.satellite) {
      this.passData(this.props.satellite);
    }
  }

  passData(data) {
    this.setState(
      {
        loading: true,
        GPSData: [],
        satellite: data
      },
      () => {
        this.fetchData(1);
      }
    );
  }

  fetchData(page) {
    const startDate = moment(this.state.start).format("YYYY-MM-DD");
    const endDate = moment(this.state.end).format("YYYY-MM-DD");
    API.get(
      `satellite_health_management/satellite_location_velocity_computed_data?format=json&satellite=${this.props.satellite}&page_size=500&page=${page}&from:epoch_acu_date=${startDate}&to:epoch_acu_date=${endDate}&interval=seconds`
    )
      .then(res => {
        if (res.data.pagination.links.next) {
          const dataArray = this.state.GPSData;
          const combinedArray = dataArray.concat(res.data.data);
          this.setState(
            {
              GPSData: combinedArray,
              loading: true
            },
            () => {
              this.fetchData(page + 1);
            }
          );
        } else {
          const dataArray = this.state.GPSData;
          const combinedArray = dataArray.concat(res.data.data);
          this.setState({
            GPSData: combinedArray,
            loading: false
          });
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error) {
          message.error("Error in fetching data, please reload the page.");
        }
      });
  }

  rangePickerChangeHandler = e => {
    this.setState(
      {
        GPSData: [],
        loading: true,
        start: e,
        end: moment(e).add(1, "days")
      },
      () => {
        this.fetchData(1);
      }
    );
  };

  render() {
    const corner1 = Leaflet.latLng(-180, -200);
    const corner2 = Leaflet.latLng(90, 200);
    const bounds = Leaflet.latLngBounds(corner1, corner2);
    return (
      <div style={{ marginBottom: "130px" }}>
        <div
          style={{ float: "right", position: "relative", marginBottom: "60px" }}
        >
          <DatePicker
            defaultValue={moment(new Date(), dateFormat)}
            format={dateFormat}
            onChange={this.rangePickerChangeHandler}
            disabled={this.state.loading ? true : false}
          />
        </div>
        <div
          style={{ marginTop: "50px", position: "relative", bottom: "-50px" }}
        >
          <h3 className="panel__title label">GPS</h3>
        </div>
        {!this.state.loading ? (
          <Map
            style={{ height: "400px", width: "100%" }}
            zoom={1.5}
            center={this.state.center}
            scrollWheelZoom={false}
            maxBoundsViscosity={1.0}
            maxBounds={bounds}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {this.state.GPSData &&
              this.state.GPSData.map((coord, index) => {
                if (coord.elon_deg && coord.nlat_deg) {
                  return (
                    <CircleMarker
                      key={index}
                      center={[coord.nlat_deg, coord.elon_deg]}
                      radius={0.25}
                      fillOpacity={1}
                      color="red"
                    >
                      <Tooltip direction="right" opacity={1}>
                        <pre>
                          {`
Epoch: ${coord.epoch_acu_date}
Lat: ${coord.nlat_deg.toFixed(4)}
Long: ${coord.elon_deg.toFixed(4)}
Elev: ${coord.alt_km.avg.toFixed(4)}`}
                        </pre>
                      </Tooltip>
                    </CircleMarker>
                  );
                }
              })}
          </Map>
        ) : (
          <div
            className="panel"
            style={{ height: "400px", width: "100%", marginTop: "70px" }}
          >
            <Spinner />
          </div>
        )}
      </div>
    );
  }
}
