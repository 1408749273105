import L from "leaflet";
import grs from "../../Assets/Images/grs.png";

const iconGRS = new L.Icon({
  iconUrl: grs,
  iconRetinaUrl: grs,
  iconSize: new L.Point(30, 30)
});

export { iconGRS };
