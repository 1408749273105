import React from "react";
import { Input, Icon } from "antd";

const SearchComponent = ({ onkeypress, onchange, ...props }) => {
  return (
    <div>
      <Input
        {...props}
        onKeyPress={onkeypress}
        onChange={onchange}
        suffix={<Icon type="search" className="certain-category-icon" />}
      />
    </div>
  );
};

export default SearchComponent;
