import React, { Fragment } from "react";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import CommandBatchForm from "../../Components/MissionsComponents/CommandBatchForm";

export default function AddCommandBatchesPage() {
  const urls = {
    "/": "MISSIONS",
    "/missions/command-batches": "COMMAND BATCHES",
    "/add": "ADD"
  };
  return (
    <Fragment>
      <BreadCrumbComponent data={urls} titlePage="Add Command Batches" />
      <CommandBatchForm
        view="add"
        initialValues={{
          status: "Pending",
          satellite: "Diwata-1"
        }}
      />
    </Fragment>
  );
}
