import { ORBITAL_DECAY, END_LIFETIME } from "../Actions/types";

const INITIAL_STATE = {
  decayData: {},
  endLifetime: ""
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ORBITAL_DECAY:
      return {
        ...state,
        decayData: action.payload
      };
    case END_LIFETIME:
      return {
        ...state,
        endLifetime: action.payload
      };
    default:
      return state;
  }
}
