import Axios from "axios";
import moment from "moment";
import {
  LOGIN_START,
  LOGOUT,
  AUTH,
  AUTH_ERROR,
  GET_USER_PERMISSIONS
} from "./types";
import history from "../history";
import API from "../Components/API";

const getUserPermissions = () => async dispatch => {
  API.get(`core/user_permissions`)
    .then(res => {
      localStorage.setItem("rules", JSON.stringify(res.data));
      dispatch({ type: GET_USER_PERMISSIONS, payload: res });
    })
    .catch(error => {
      console.log(error);
    });
};

export const loginStart = (username, password, prevLink) => async dispatch => {
  dispatch({ type: LOGIN_START, payload: true });
  dispatch({ type: AUTH_ERROR, payload: "" });
  const reqData = { grant_type: "password", username, password };
  const data = Object.keys(reqData)
    .map(function(key) {
      return `${encodeURIComponent(key)}=${encodeURIComponent(reqData[key])}`;
    })
    .join("&");
  return Axios({
    url: `${process.env.REACT_APP_API_ROOT}/o/token/`,
    method: "post",
    auth: {
      username: process.env.REACT_APP_API_USER,
      password: process.env.REACT_APP_API_PASS
    },
    data
  })
    .then(response => {
      const authData = response.data;
      localStorage.setItem("auth", JSON.stringify(authData));
      const setExpiry = moment().add(authData.expires_in, "seconds");
      localStorage.setItem("expiry", setExpiry);
      // localStorage.removeItem("guest");
      dispatch(getUserPermissions());
      dispatch({ type: AUTH, payload: authData });

      setTimeout(() => {
        dispatch({ type: LOGIN_START, payload: false });
        if (prevLink) {
          history.push(prevLink.from.pathname);
        } else {
          history.push("/");
        }
      }, 1000);
    })
    .catch(error => {
      if (error.response) {
        dispatch({ type: LOGIN_START, payload: false });
        dispatch({ type: AUTH_ERROR, payload: error.response.data });
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.error(error.request);
        dispatch({ type: LOGIN_START, payload: false });
        alert("Login Failed [1]");
      } else {
        // Something happened in setting up the request and triggered an Error
        console.error(error);
        dispatch({ type: LOGIN_START, payload: false });
        alert("Login Failed [2]");
      }
    });
};

export const logOut = () => async dispatch => {
  localStorage.removeItem("auth");
  localStorage.removeItem("expiry");
  localStorage.removeItem("rules");
  dispatch({ type: AUTH_ERROR, payload: "" });
  history.push("/login");
  dispatch({ type: LOGOUT });
};
