import React, { Component } from "react";
import {
  Row,
  Card,
  Form,
  Button,
  Col,
  Select,
  Popover,
  Icon,
  Spin,
  Alert
} from "antd";
import { Field, reduxForm, FieldArray } from "redux-form";
import moment from "moment";
import { connect } from "react-redux";
import {
  renderInput,
  renderSelect,
  renderTextArea,
  renderCheckBox
} from "./MissionFormComponents";
import CommandComponent from "./CommandComponent";
import API from "../API/index";
import DatePickerWithTimezone from "./DatePickerWithTimezone";
import DownloadFormCameras from "./DownloadFormCameras";
import Const from "../../Config/const";
import history from "../../history";
import withToast from "../Hocs/withToast";

const { Option } = Select;

class DownloadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: "",
      loadingSubmit: false,
      aos_label: null,
      los_label: null,
      aos_value: null
    };
  }

  onSubmit = formValues => {
    this.setState({
      loadingSubmit: true
    });
    if (this.props.view === "add") {
      API.post("/missions_management/download_missions/?", formValues)
        .then(res => {
          this.props.openNotification(
            "success",
            "Success!",
            "Download Mission Add Successful!"
          );
          this.setState({
            loadingSubmit: false
          });
          history.push(
            `/missions_management/download_missions/view/${res.data.id}`
          );
        })
        .catch(err => {
          console.log(err);
          this.props.openNotification(
            "error",
            "Oops!",
            "Something went wrong!"
          );

          console.log(err);
          this.setState({
            loadingSubmit: false
          });
        });
    } else {
      API.patch(
        `/missions_management/download_missions/${this.props.downloadId}/`,
        formValues
      )
        .then(res => {
          this.setState({
            loadingSubmit: false
          });
          this.props.openNotification(
            "success",
            "Success!",
            "Download Mission Edited!"
          );
          history.push(
            `/missions_management/download_missions/view/${res.data.id}`
          );
        })
        .catch(err => {
          this.setState({
            loadingSubmit: false
          });
          this.props.openNotification(
            "error",
            "Oops!",
            "Something went wrong!"
          );
          console.log(err);
        });
    }
  };

  handleLabelChange = e => {
    if (e === "Diwata-1") {
      this.setState({
        aos_label: "Aos @ 10 time",
        los_label: "Los @ 10 time"
      });
    } else if (e === "Diwata-2") {
      this.setState({
        aos_label: "Aos @ 5 time",
        los_label: "Los @ 5 time"
      });
    }
  };

  render() {
    const commandVal = this.props.initialValues.command
      ? this.props.initialValues.command
      : {};
    const aosLabel =
      this.props.initialValues.satellite === "Diwata-1"
        ? "Aos @ 10 time"
        : "Aos @ 5 time";
    const losLabel =
      this.props.initialValues.satellite === "Diwata-1"
        ? "Los @ 10 time"
        : "Los @ 5 time";
    const aosValue =
      aosLabel === "Aos @ 5 time" ? this.props.initialValues.aos_time : null;
    return (
      <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Spin spinning={this.props.loading || this.state.loadingSubmit}>
          <Card title="Download Parameters">
            <Row>
              <Alert
                showIcon
                message="For D1, use AOS@10 and LOS@10. For D2, use AOS@5 and LOS@5"
                type="info"
              />
            </Row>
            <Row className="m-t-20px">
              <Field
                name="name"
                component={renderInput}
                label="Name"
                type="text"
              />
            </Row>
            <Row>
              <Field
                name="satellite"
                component={renderSelect}
                label="Satellite"
                onChange={this.handleLabelChange}
              >
                {Const.satellites.map(data => (
                  <Option key={data} value={data.val}>
                    {data.text}
                  </Option>
                ))}
              </Field>
            </Row>
            <Row>
              <Field
                name="aos_time"
                component={DatePickerWithTimezone}
                label={this.state.aos_label || aosLabel}
                note="You are 8 hours ahead of server time."
                onChange={e => this.setState({ aos_value: e })}
              />
            </Row>
            <Row>
              <Field
                name="los_time"
                component={DatePickerWithTimezone}
                label={this.state.los_label || losLabel}
                note="You are 8 hours ahead of server time."
              />
            </Row>
            <Row className="form-row">
              <Field
                note="For night downloads, uncheck this button and input eclipse time."
                name="day_download"
                component={renderCheckBox}
                label="Day Download"
              />
            </Row>
            <Row>
              <Field
                name="eclipse_time"
                component={DatePickerWithTimezone}
                label="Eclipse Time"
                note="You are 8 hours ahead of server time."
              />
            </Row>
            <Row>
              <Field
                name="receiving_station"
                component={renderSelect}
                label="Receiving Station"
              >
                {Const.mission.acquisition.receivingStation.map(data => (
                  <Option key={data} value={data.val}>
                    {data.text}
                  </Option>
                ))}
              </Field>
            </Row>
            <Row>
              <Field
                name="status"
                component={renderSelect}
                label="Status"
                defVal="Pending"
                note="Kindly input remarks for Failed/Cancelled missions."
              >
                {Const.mission.acquisition.addMissionFilterStats.map(data => (
                  <Option key={data} value={data.val}>
                    {data.text}
                  </Option>
                ))}
              </Field>
            </Row>
            <Row>
              <Field
                name="remarks"
                component={renderTextArea}
                label="Remarks"
              />
            </Row>
          </Card>
          <Card className="m-t-10px" title="D2-Specific Settings">
            <Row>
              <Field
                name="start_of_download_time"
                component={DatePickerWithTimezone}
                label="Start of download time"
                note="You are 8 hours ahead of server time."
              />
            </Row>
          </Card>
          <Card className="m-t-10px" title="D2-ARU Settings">
            <Row>
              <Field
                name="aru_on_time"
                component={DatePickerWithTimezone}
                label="ARU On Time"
                note="You are 8 hours ahead of server time."
                aosData={{
                  value: this.state.aos_value || aosValue,
                  label: this.state.aos_label || aosLabel
                }}
              />
            </Row>
            <Row>
              <Field
                name="aru_off_time"
                component={DatePickerWithTimezone}
                label="ARU Off Time"
                note="You are 8 hours ahead of server time."
                aosData={{
                  value: this.state.aos_value || aosValue,
                  label: this.state.aos_label || aosLabel
                }}
              />
            </Row>
          </Card>

          {this.props.view === "edit" ? (
            <Card className="m-t-10px">
              <Row>
                <CommandComponent command={commandVal} />
              </Row>
            </Card>
          ) : (
            ""
          )}

          <Card className="m-t-10px" title="Download Mission Cameras">
            <FieldArray
              name="download_mission_cameras"
              component={DownloadFormCameras}
            />
          </Card>
          <button className="ant-btn ant-btn-primary m-t-10px" type="submit">
            Submit
          </button>
        </Spin>
      </Form>
    );
  }
}
const validate = formValues => {
  const errors = {};
  if (!formValues.name) {
    errors.name = "You must enter a name";
  }

  return errors;
};

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues // retrieve name from redux store
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "DownloadForm",
    validate,
    enableReinitialize: true
  })(withToast(DownloadForm))
);
