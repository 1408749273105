import React, { Component, Fragment } from "react";
import CommandBatchForm from "../../Components/MissionsComponents/CommandBatchForm";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import API from "../../Components/API";

class ViewCommandBatchesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      init: {},
      loading: true
    };
  }

  componentDidMount() {
    API.get(
      `/missions_management/command_batches/${this.props.match.params.id}`
    )
      .then(res => {
        console.log(res);
        this.setState({
          init: res.data,
          loading: false
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          loading: false
        });
        alert(error);
      });
  }

  // processData = data => {
  //   // handle acu log seg object
  //   const flag = Object.entries(data).length !== 0 ? true : false;
  //   const acuName = flag ? data.acu_log_segment.name : "";
  //   const acuAddress = flag ? data.acu_log_segment.starting_address : "";
  //   data.acu_log_segment = `${acuName} | ${acuAddress}`;
  //   return data;
  // };

  render() {
    const cmdbatchId = this.props.match.params.id;
    const urls = {
      "/": "MISSIONS",
      "/missions_management/command_batches": "COMMAND BATCHES",
      "/view": "VIEW"
    };
    return (
      <Fragment>
        <BreadCrumbComponent data={urls} titlePage="View Command Batches" />
        <CommandBatchForm
          commandBatchId={cmdbatchId}
          view="edit"
          initialValues={this.state.init}
          loading={this.state.loading}
        />
      </Fragment>
    );
  }
}

export default ViewCommandBatchesPage;
