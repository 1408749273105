import React, { Component } from "react";
import { Form, Card, Row, Col, Select, Spin } from "antd";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import {
  renderInput,
  renderSelect,
  renderTextArea
} from "./MissionFormComponents";
import CommandComponent from "./CommandComponent";
import Const from "../../Config/const";
import DatePickerWithTimezone from "./DatePickerWithTimezone";
import API from "../API/index";
import history from "../../history";

const { Option } = Select;

class UploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: "",
      loadingSubmit: false
    };
  }

  onSubmit = formValues => {
    this.setState({
      loadingSubmit: true
    });
    if (this.props.view === "add") {
      API.post("/missions_management/upload_missions/?", formValues)
        .then(res => {
          this.setState({
            loadingSubmit: false
          });
          history.push(`/missions/upload/view/${res.data.id}`);
        })
        .catch(err => {
          console.log(err);
          alert("Oops, Something went wrong!");
          console.log(err);
          this.setState({
            loadingSubmit: false
          });
        });
    } else {
      API.patch(
        `/missions_management/upload_missions/${this.props.uploadId}/`,
        formValues
      )
        .then(res => {
          this.setState({
            loadingSubmit: false
          });
          alert("Upload Mission Edited");
          history.push(
            `/missions_management/upload_missions/view/${res.data.id}`
          );
        })
        .catch(err => {
          this.setState({
            loadingSubmit: false
          });
          alert("Oops, Something went wrong!");
          console.log(err);
        });
    }
  };

  render() {
    const commandVal = this.props.initialValues.command
      ? this.props.initialValues.command
      : {};
    return (
      <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Spin spinning={this.props.loading || this.state.loadingSubmit}>
          <Card title="Upload Parameters">
            <Row>
              <Field
                name="name"
                component={renderInput}
                label="Name"
                type="text"
              />
            </Row>
            <Row>
              <Field
                name="execution_time"
                component={DatePickerWithTimezone}
                label="Execution Time"
                note="You are 8 hours ahead of server time."
              />
            </Row>
            <Row style={{ marginBottom: "20px" }}>
              <Col md={5}>
                <span className="label">Satellite</span>
              </Col>
              <Col md={10}>Diwata 1</Col>
            </Row>
            <Row>
              <Field
                name="receiving_station"
                component={renderSelect}
                label="Receiving Station"
              >
                {Const.mission.acquisition.receivingStation.map(data => (
                  <Option key={data} value={data.val}>
                    {data.text}
                  </Option>
                ))}
              </Field>
            </Row>
            <Row>
              <Field
                name="status"
                component={renderSelect}
                label="Status"
                defVal="Pending"
                note="Kindly input remarks for Failed/Cancelled missions."
              >
                {Const.mission.acquisition.addMissionFilterStats.map(data => (
                  <Option key={data} value={data.val}>
                    {data.text}
                  </Option>
                ))}
              </Field>
            </Row>
            <Row>
              <Field
                name="remarks"
                component={renderTextArea}
                label="Remarks"
              />
            </Row>
            <Row>
              {this.props.view === "edit" ? (
                <CommandComponent command={commandVal} />
              ) : (
                ""
              )}
            </Row>
          </Card>
          <button className="ant-btn ant-btn-primary m-t-10px" type="submit">
            Submit
          </button>
        </Spin>
      </Form>
    );
  }
}

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues // retrieve name from redux store
});

const validate = formValues => {
  const errors = {};
  if (!formValues.name) {
    errors.name = "You must enter a name";
  }
};

export default connect(mapStateToProps)(
  reduxForm({
    form: "uploadForm",
    validate,
    enableReinitialize: true
  })(UploadForm)
);
