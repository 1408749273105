import React, { Component, Fragment } from "react";
import {
  Menu,
  Dropdown,
  Icon,
  message,
  Row,
  Col,
  Button,
  Skeleton
} from "antd";
import { connect } from "react-redux";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import * as actions from "../../Actions";

// COMPONENTS
import {
  SatellitePosition,
  GRSPasses,
  GRSPass,
  ShowMap,
  PendingMissions,
  OrbitalDecay
} from "../../Components/MissionPlanningComponents";

class GRSPlaningDashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "",
      satelliteCode: ""
    };
  }

  componentDidMount() {
    // const satellite = this.state.satelliteCode;
    this.props.listSatellite();
    // this.props.passesSatellite(satellite, grsLocation());/
    // this.props.passNextGRS(satellite, grsLocation());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.list !== this.props.list) {
      this.passData(this.props.list);
    }
  }

  passData(data) {
    const satelliteList = data.filter(
      satellite => satellite.status === "Available"
    );
    // console.log(satelliteList)
    this.setState({
      list: satelliteList,
      selected: satelliteList[0].name,
      satelliteCode: satelliteList[0].catalog_no
    });
  }

  render() {
    const onClick = ({ item, key }) => {
      message.info(`Selected Satellite: ${key}`);
      this.setState({
        selected: key,
        satelliteCode: Number(item.props.code)
      });
      // const satellite = item.props.code;
      // this.props.passesSatellite(satellite, grsLocation());
      // this.props.passNextGRS(satellite, grsLocation());
    };

    const menu = (
      <Menu onClick={onClick} className="panel__title label">
        {this.state.list
          ? Array.from(this.state.list)
              .filter(data => data.status === "Available")
              .map(val => (
                <Menu.Item key={val.name} code={val.catalog_no}>
                  {val.name}
                </Menu.Item>
              ))
          : ""}
      </Menu>
    );
    const urls = {
      "/missions/": "Mission Management",
      "/missions/planning": "Mission Planning Dashboard"
    };

    if (this.state.selected && this.state.satelliteCode) {
      return (
        <Fragment>
          <BreadCrumbComponent
            data={urls}
            titlePage="Mission Planning Dashboard"
          />
          <div style={{ paddingBottom: "30px" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button
                type="link"
                className="ant-dropdown-link"
                style={{ color: "black" }}
              >
                {this.state.selected} <Icon type="down" />
              </Button>
            </Dropdown>
          </div>
          <div>
            <ShowMap satellite={this.state.satelliteCode} />
          </div>
          <Row gutter={16} style={{ marginTop: "30px" }} type="flex">
            <Col sm={24} md={12}>
              <div className="panel" style={{ height: "100%" }}>
                <GRSPass satellite={this.state.satelliteCode} />
              </div>
            </Col>
            <Col sm={24} md={12}>
              <div style={{ height: "100%" }}>
                <SatellitePosition
                  satellite={this.state.selected}
                  satelliteCode={this.state.satelliteCode}
                />
              </div>
            </Col>
          </Row>
          <h3 style={{ marginTop: "30px" }} className="panel__title label">
            Upcoming Passes
          </h3>

          <GRSPasses satellite={this.state.satelliteCode} />

          <Row gutter={16} style={{ marginTop: "30px" }} type="flex">
            <Col sm={24} md={15}>
              <div className="panel" style={{ height: "100%" }}>
                <div className="panel__title label">PASSES OVER TARGETS</div>
                <Skeleton />
              </div>
            </Col>
            <Col sm={24} md={9}>
              <div className="panel" style={{ height: "100%" }}>
                <Skeleton />
              </div>
            </Col>
          </Row>
          <h3 style={{ marginTop: "30px" }} className="panel__title label">
            Pending Missions
          </h3>
          <div className="panel">
            <PendingMissions />
          </div>
          <h3 style={{ marginTop: "30px" }} className="panel__title label">
            Orbital Decay
          </h3>
          <div className="panel">
            <OrbitalDecay satellite={this.state.satelliteCode} />
          </div>
        </Fragment>
      );
    }

    return null;
  }
}

const mapStateToProps = state => {
  return {
    list: state.list.listSatellite
  };
};

export default connect(mapStateToProps, actions)(GRSPlaningDashboardPage);
