import React from "react";
import { Card, Row, Button, Select, Col } from "antd";
import { Field } from "redux-form";
import { TextInput } from "./MissionFormComponents";

const CommandBatchList = ({ fields, meta: { error, submitFailed } }) => (
  <div>
    <Button onClick={() => fields.push({})}>Add Command</Button>
    {submitFailed && error && <span>{error}</span>}
    {fields.map((cmd, index) => (
      <Card
        key={index}
        className="m-t-10px"
        title={`Command #${index + 1}`}
        extra={
          <Button type="danger" onClick={() => fields.remove(index)}>
            Remove
          </Button>
        }
      >
        <Row gutter={16}>
          <Col md={10}>
            <label className="label" htmlFor={`${cmd}.commands_list`}>
              Command ID:
            </label>
            <Field name={`${cmd}.id`} component={TextInput} type="text" />
          </Col>
        </Row>
      </Card>
    ))}
  </div>
);

export default CommandBatchList;
