// CONVERT THIS TO REDUX

import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import moment from "moment";
import * as actions from "../../Actions";
import "antd/dist/antd.css";

// COMPONENTS
// import SatelliteElevationLineChart from "../Vis/Distributions/SatelliteElevationLineChart";
import ShowMapSinglePass from "./ShowMapSinglePass";

class ShowSinglePass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // data: [],
      passes: [],
      passData: {}
      // elevation: []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data && nextProps.data[0].duration > 0) {
      // const start = nextProps.data[1][0][3];
      // console.log(start);

      this.setState({
        passes: nextProps.data[1].map(coordinate => [
          coordinate[0],
          coordinate[1]
        ]),
        passData: {
          grs: nextProps.data[0].grs,
          rise: nextProps.data[0].rise,
          set: nextProps.data[0].set,
          altTime: nextProps.data[0].altTime,
          alt: nextProps.data[0].alt,
          riseAzi: nextProps.data[0].riseAzi,
          setAzi: nextProps.data[0].setAzi,
          duration: nextProps.data[0].duration
        }
        // elevation: nextProps.data[1].map(coordinate => [
        //   {
        //     Elevation: coordinate[2] / 320000, // EDIT THIS!!!
        //     timestamp: `+${coordinate[3] - start}`
        //   }
        // ])
      });
    }
  }

  // static getDerivedStateFromProps(props, state) {
  //   console.log(props)
  //   console.log(state)
  //   if (props.data[0] !== state.data) {
  //     // console.log(props.data[0])
  //     let newData = props.data ? props.data : null
  //     if (newData) {
  //       return { data : newData  }
  //     }
  //   }
  // }

  render() {
    return (
      <div style={{ position: "relative" }}>
        <div className="map_small">
          {this.state.passes ? (
            <ShowMapSinglePass
              center={[
                this.props.grsCoordinates.lat,
                this.props.grsCoordinates.lon
              ]}
              passes={this.state.passes}
              position={[
                this.props.grsCoordinates.lat,
                this.props.grsCoordinates.lon
              ]}
            />
          ) : (
            ""
          )}
        </div>
        {this.state.passData ? (
          <div>
            {/* <div style={{ display: "none" }}>
                  <SatelliteElevationLineChart data={this.state.elevation} />
                </div> */}
            <div style={{ paddingBottom: "10px" }}>
              <div>
                <strong>{this.state.passData.grs}</strong>
              </div>
              <div>
                <small style={{ color: "grey" }}>A few seconds ago</small>
              </div>
            </div>
            <div>
              <Row gutter={8} className="details">
                <Col span={8}>Rise Time</Col>
                <Col span={16}>
                  {this.state.passData.rise
                    ? moment(this.state.passData.rise).format(
                        "MMMM DD YYYY, HH:mm:ss Z"
                      )
                    : ""}
                </Col>
              </Row>
              <Row gutter={8} className="details">
                <Col span={8}>Max Altitude Time</Col>
                <Col span={16}>
                  {this.state.passData.altTime
                    ? moment(this.state.passData.altTime).format(
                        "MMMM DD YYYY, HH:mm:ss Z"
                      )
                    : ""}
                </Col>
              </Row>
              <Row gutter={8} className="details">
                <Col span={8}>Max Altitude</Col>
                <Col span={16}>{this.state.passData.alt}</Col>
              </Row>
              <Row gutter={8} className="details">
                <Col span={8}>Set Time</Col>
                <Col span={16}>
                  {this.state.passData.set
                    ? moment(this.state.passData.set).format(
                        "MMMM DD YYYY, HH:mm:ss Z"
                      )
                    : ""}
                </Col>
              </Row>
              <Row gutter={8} className="details">
                <Col span={8}>Duration</Col>
                <Col span={16}>{this.state.passData.duration}</Col>
              </Row>
              <Row gutter={8} className="details">
                <Col span={8}>Rise Azimuth</Col>
                <Col span={16}>{this.state.passData.riseAzi}</Col>
              </Row>
              <Row gutter={8} className="details">
                <Col span={8}>Set Azimuth</Col>
                <Col span={16}>{this.state.passData.setAzi}</Col>
              </Row>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.passes.trackSatelliteGRS
  };
};

export default connect(mapStateToProps, actions)(ShowSinglePass);
