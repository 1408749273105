import React, { Component } from "react";
import { Row, Card, Form, Select, Spin } from "antd";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import * as actions from "../../Actions";
import {
  renderInput,
  renderSelect,
  renderTextArea,
  renderCheckBox,
  renderBlankMap
} from "./TargetFormComponent";
import API from "../API/index";
import Const from "../../Config/const";
import history from "../../history";
import withToast from "../Hocs/withToast";

const { Option } = Select;

class CreateTargetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingSubmit: false
    };
  }

  componentWillUnmount() {
    this.props.targetGeom({});
  }

  onSubmit = formValues => {
    const freezeData = JSON.parse(JSON.stringify(formValues));
    if (this.props.mapData) {
      freezeData.geom = this.props.mapData;
      this.setState({
        loadingSubmit: true
      });
      API.post("/missions_management/targets/", freezeData)
        .then(res => {
          this.setState({
            loadingSubmit: false
          });
          this.props.openNotification("success", "Success!", "Target Added");
          history.push(`/missions_management/targets/view/${res.data.id}`);
        })
        .catch(err => {
          this.setState({
            loadingSubmit: false
          });
          this.props.openNotification(
            "error",
            "Oops!",
            "Something went wrong!"
          );
          console.log(err);
        });
    } else {
      alert("Oops, Something went wrong! You must draw on map.");
    }
  };

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Spin spinning={this.props.loading || this.state.loadingSubmit}>
          <Card title="Target Details">
            <Row>
              <Field
                name="name"
                component={renderInput}
                label="Name"
                type="text"
              />
            </Row>
            <Row>
              <Field name="geom" component={renderBlankMap} label="Geom" />
            </Row>
            <Row>
              <Field
                name="is_active"
                component={renderCheckBox}
                label="Is active"
              />
            </Row>
            <Row>
              <Field
                name="classification"
                component={renderSelect}
                label="Classification"
              >
                {Const.target.acquisition.classification.map(val =>
                  val.val ? (
                    <Option key={val} value={val.val}>
                      {val.text}
                    </Option>
                  ) : (
                    ""
                  )
                )}
              </Field>
            </Row>
            <Row>
              <Field name="purpose" component={renderSelect} label="Purpose">
                {Const.target.acquisition.purpose.map(val =>
                  val.val ? (
                    <Option key={val} value={val.val}>
                      {val.text}
                    </Option>
                  ) : (
                    ""
                  )
                )}
              </Field>
            </Row>
            <Row>
              <Field
                name="required_bands"
                component={renderTextArea}
                label="Required bands"
              />
            </Row>
          </Card>
          <button className="ant-btn ant-btn-primary m-t-10px" type="submit">
            Save
          </button>
        </Spin>
      </Form>
    );
  }
}

const validate = formValues => {
  const errors = {};
  if (!formValues.name) {
    errors.name = "You must enter a name";
  }
  if (!formValues.classification) {
    errors.classification = "You must enter a classification";
  }
  if (!formValues.purpose) {
    errors.purpose = "You must enter a purpose";
  }

  return errors;
};

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues, // retrieve name from redux store
  mapData: state.target.data
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "targetForm",
    validate,
    enableReinitialize: true
  })(withToast(CreateTargetForm))
);
