/* eslint-disable camelcase */
import React, { Component } from "react";
import Plot from "react-plotly.js";
import PlotlyCommon from "./PlotlyCommon";

export default class PlotlyAltVelocity extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const processData = (value, avg) => {
      // avg is added to replace null values of min/max with a value
      // without replacement, error on graph tooltip occurs
      if (value !== null) {
        return value.toFixed(4);
        // eslint-disable-next-line no-else-return
      } else if (avg !== null) {
        return avg;
      } else {
        return null;
      }
    };
    const { dataAll } = this.props;
    const XData = [];

    const YData_alt_km = [];
    const YData_alt_km_low = [];
    const YData_alt_km_high = [];

    const YData_gps_v_eci_0 = [];
    const YData_gps_v_eci_0_low = [];
    const YData_gps_v_eci_0_high = [];

    const YData_gps_v_eci_1 = [];
    const YData_gps_v_eci_1_low = [];
    const YData_gps_v_eci_1_high = [];

    const YData_gps_v_eci_2 = [];
    const YData_gps_v_eci_2_low = [];
    const YData_gps_v_eci_2_high = [];

    dataAll.forEach(element => {
      XData.push(element.epoch_acu_date);

      YData_alt_km.push(processData(element.alt_km.avg));
      YData_alt_km_low.push(
        processData(element.alt_km.min, processData(element.alt_km.avg))
      );
      YData_alt_km_high.push(
        processData(element.alt_km.max, processData(element.alt_km.avg))
      );

      YData_gps_v_eci_0.push(processData(element.gps_v_eci_0.avg));
      YData_gps_v_eci_0_low.push(
        processData(
          element.gps_v_eci_0.min,
          processData(element.gps_v_eci_0.avg)
        )
      );
      YData_gps_v_eci_0_high.push(
        processData(
          element.gps_v_eci_0.max,
          processData(element.gps_v_eci_0.avg)
        )
      );

      YData_gps_v_eci_1.push(processData(element.gps_v_eci_1.avg));
      YData_gps_v_eci_1_low.push(
        processData(
          element.gps_v_eci_1.min,
          processData(element.gps_v_eci_1.avg)
        )
      );
      YData_gps_v_eci_1_high.push(
        processData(
          element.gps_v_eci_1.max,
          processData(element.gps_v_eci_1.avg)
        )
      );

      YData_gps_v_eci_2.push(processData(element.gps_v_eci_2.avg));
      YData_gps_v_eci_2_low.push(
        processData(
          element.gps_v_eci_2.min,
          processData(element.gps_v_eci_2.avg)
        )
      );
      YData_gps_v_eci_2_high.push(
        processData(
          element.gps_v_eci_2.max,
          processData(element.gps_v_eci_2.avg)
        )
      );
    });

    const shapesArray = [];
    XData.forEach((element, index) => {
      if (YData_alt_km_low[index] !== YData_alt_km_high[index]) {
        const shapeData = {
          type: "line",
          layer: "below",
          x0: element,
          y0: YData_alt_km_low[index],
          x1: element,
          y1: YData_alt_km_high[index],
          line: {
            color: "#FFAE42",
            width: 2
          }
        };
        shapesArray.push(shapeData);
      }
    });

    return (
      <div style={{ width: "100%", height: "100%" }}>
        <div style={{ marginTop: "50px" }}>
          <h3 className="panel__title label">ALTITUDE</h3>
        </div>
        <div className="panel" style={{ height: "100%" }}>
          <Plot
            data={[
              {
                x: XData,
                y: YData_alt_km_low,
                type: "scatter",
                mode: "markers",
                marker: { color: "#FFAE42", size: "2" },
                name: "Min"
              },
              {
                x: XData,
                y: YData_alt_km_high,
                type: "scatter",
                mode: "markers",
                marker: { color: "#FFAE42", size: "2" },
                name: "Max"
              },
              {
                x: XData,
                y: YData_alt_km,
                type: "scatter",
                mode: "lines+markers",
                line: { color: "#00d69f" },
                marker: { color: "#17BECF", size: "4" },
                name: "Avg"
              }
            ]}
            layout={{
              showlegend: false,
              hovermode: "x unified",
              xaxis: {
                showgrid: false,
                title: {
                  text: "Epoch Acu Date",
                  font: {
                    size: 15,
                    color: "#7f7f7f"
                  }
                }
              },
              yaxis: {
                rangemode: "tozero",
                title: {
                  text: "Altitude",
                  font: {
                    size: 15,
                    color: "#7f7f7f"
                  }
                },
                fixedrange: true
              },
              autosize: true,
              shapes: shapesArray
            }}
            useResizeHandler
            style={{
              width: "100%",
              height: "300px",
              x: 0,
              xanchor: "left",
              y: 0,
              yanchor: "top"
            }}
            tracetoggle={false}
            config={{
              displayModeBar: false,
              responsive: true
            }}
          />
        </div>
        <div style={{ marginTop: "50px" }}>
          <h3 className="panel__title label">VELOCITY</h3>
        </div>
        <div className="panel" style={{ height: "100%" }}>
          <PlotlyCommon
            dataType="Velocity"
            X={XData}
            Y={YData_gps_v_eci_0}
            yTitle="Velocity 0"
            xTitle="Epoch Acu Date"
            low={YData_gps_v_eci_0_low}
            high={YData_gps_v_eci_0_high}
          />
          <PlotlyCommon
            dataType="Velocity"
            X={XData}
            Y={YData_gps_v_eci_1}
            yTitle="Velocity 1"
            xTitle="Epoch Acu Date"
            low={YData_gps_v_eci_1_low}
            high={YData_gps_v_eci_1_high}
          />
          <PlotlyCommon
            dataType="Velocity"
            X={XData}
            Y={YData_gps_v_eci_2}
            yTitle="Velocity 2"
            xTitle="Epoch Acu Date"
            low={YData_gps_v_eci_2_low}
            high={YData_gps_v_eci_2_high}
          />
        </div>
      </div>
    );
  }
}
