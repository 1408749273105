import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  convertToUTC
} from "../../Components/CommonFunctions";
import API from "../../Components/API";
import {
  Table,
  Tooltip
} from "antd";
import {
  toTimeString,
  tableIndexStyle
} from "../CommonFunctions";

const redirectLink = (url, id) => {
  return `${url}${id}`;
};

const columns = [
  {
    title: <span className="table-index-number">NO</span>,
    dataIndex: "index",
    align: "center",
    width: "45px",
    render(text) {
      return tableIndexStyle(text);
    }
  },
  {
    title: "NAME",
    dataIndex: "name",
    sorter: true,
    render: (text, row) => (
      <Link
        to={redirectLink(
          "/missions_management/acquisition_missions/view/",
          row.id
        )}
      >
        {text}
      </Link>
    )
  },
  {
    title: "CAPTURE TIME",
    dataIndex: "capture_time",
    sorter: true
  },
  {
    title: "STATUS",
    dataIndex: "status",
    sorter: true,
    ellipsis: true,
    render(stat) {
      return (
        <div>
        <span style={{ color: "#ffc658" }}>
          <Tooltip placement="bottom" title="Processed">
            <b>{stat.processed_count}</b>
          </Tooltip>
        </span> / 
        <span style={{ color: "#E56F6F", marginLeft: "5px" }}>
          <Tooltip placement="bottom" title="Processable">
            <b>{stat.processable_count}</b>
          </Tooltip>
        </span> / 
        <span style={{ color: "#8884d8", marginLeft: "5px" }}>
          <Tooltip placement="bottom" title="Dowloaded">
            <b>{stat.number_of_images}</b>
          </Tooltip>
        </span> / 
        <span style={{ color: "#6EB5FF", marginLeft: "5px" }}>
          <Tooltip placement="bottom" title="Captured">
            {/* Check which API data refers to total captured */}
            <b>{stat.number_of_images}</b>
          </Tooltip>
        </span>
        </div>
      );
    }
  }
];

export default class PendingMissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.fetch()
  }

  fetch = async (pageSize=10) => {
    this.setState({ loading: true });
    try {
      await API.get(
        // `missions_management/missions?format=json&satellite=Diwata-2&status=Captured&ordering=capture_time&page=1&page_size=${pageSize}`
        `stats/missions_management/missions_for_processing`
      )
        .then(res => {
          // if (res.data) {
          //   const { count } = res.data.meta.pagination
          //   if (count !== res.data.data.length) {
          //     this.fetch(res.data.meta.pagination.count)
          //   } else {
          //     this.setState({
          //       loading: false,
          //       missionData: res.data
          //     });
          //   }
          // }
          this.setState({
            loading: false,
            missionData: res
          });
        })
    } catch (error) {
      console.log(error)
      this.setState({
        missionData: {
          data: []
        },
        loading: false 
      });
    }
  };

  formatMissions = tableData => {
    const missions = [];
    const rawData = tableData ? tableData.data : [];
    rawData.map((val, index) => {
      return missions.push({
        index: `${index + 1}`,
        key: `${val.id}`,
        id: `${val.id}`,
        name: `${val.name}`,
        // created_time: `${toTimeString(val.created_time)}`,
        capture_time: `${toTimeString(val.capture_time)}`,
        status: {
          number_of_images: val.number_of_images,
          processable_count: val.processable_count,
          processed_count: val.processed_count
        }
      });
    });
    return missions;
  };

  render() {
    return (
      <div>
        <Table
          size="small"
          columns={columns}
          loading={this.state.loading}
          dataSource={this.formatMissions(this.state.missionData)}
          pagination={false}
          scroll={{
            y: "310px",
          }}
          empty
        />
      </div>
    );
  }
}
