/* eslint-disable camelcase */
import React, { Component } from "react";
import PlotlyCommon from "./PlotlyCommon";

export default class PlotlyCurrent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const processData = value => {
      if (value) {
        return value.toFixed(4);
      }
      return value;
    };
    const { dataAll } = this.props;
    const XData = [];

    const YData_hpt_vis = [];
    const YData_hpt_vis_low = [];
    const YData_hpt_vis_high = [];

    const YData_hpt_nir = [];
    const YData_hpt_nir_low = [];
    const YData_hpt_nir_high = [];

    const YData_smi_vis_nir = [];
    const YData_smi_vis_nir_low = [];
    const YData_smi_vis_nir_high = [];

    const YData_lctf_3_3v = [];
    const YData_lctf_3_3v_low = [];
    const YData_lctf_3_3v_high = [];

    const YData_lctf_15v = [];
    const YData_lctf_15v_low = [];
    const YData_lctf_15v_high = [];

    const YData_lctf_neg_15v = [];
    const YData_lctf_neg_15v_low = [];
    const YData_lctf_neg_15v_high = [];

    const YData_3_3v = [];
    const YData_3_3v_low = [];
    const YData_3_3v_high = [];

    const YData_digital_5v = [];
    const YData_digital_5v_low = [];
    const YData_digital_5v_high = [];

    const YData_analog_5v = [];
    const YData_analog_5v_low = [];
    const YData_analog_5v_high = [];

    dataAll.forEach(element => {
      XData.push(element.capture_time);

      YData_hpt_vis.push(processData(element.current_hpt_vis.avg));
      YData_hpt_vis_low.push(processData(element.current_hpt_vis.min));
      YData_hpt_vis_high.push(processData(element.current_hpt_vis.max));

      YData_hpt_nir.push(processData(element.current_hpt_nir.avg));
      YData_hpt_nir_low.push(processData(element.current_hpt_nir.min));
      YData_hpt_nir_high.push(processData(element.current_hpt_nir.max));

      YData_smi_vis_nir.push(processData(element.current_smi_vis_nir.avg));
      YData_smi_vis_nir_low.push(processData(element.current_smi_vis_nir.min));
      YData_smi_vis_nir_high.push(processData(element.current_smi_vis_nir.max));

      YData_lctf_3_3v.push(processData(element.current_lctf_3_3v.avg));
      YData_lctf_3_3v_low.push(processData(element.current_lctf_3_3v.min));
      YData_lctf_3_3v_high.push(processData(element.current_lctf_3_3v.max));

      YData_lctf_15v.push(processData(element.current_lctf_15v.avg));
      YData_lctf_15v_low.push(processData(element.current_lctf_15v.min));
      YData_lctf_15v_high.push(processData(element.current_lctf_15v.max));

      YData_lctf_neg_15v.push(processData(element.current_lctf_neg_15v.avg));
      YData_lctf_neg_15v_low.push(
        processData(element.current_lctf_neg_15v.min)
      );
      YData_lctf_neg_15v_high.push(
        processData(element.current_lctf_neg_15v.max)
      );

      YData_3_3v.push(processData(element.current_3_3v.avg));
      YData_3_3v_low.push(processData(element.current_3_3v.min));
      YData_3_3v_high.push(processData(element.current_3_3v.max));

      YData_digital_5v.push(processData(element.current_digital_5v.avg));
      YData_digital_5v_low.push(processData(element.current_digital_5v.min));
      YData_digital_5v_high.push(processData(element.current_digital_5v.max));

      YData_analog_5v.push(processData(element.current_analog_5v.avg));
      YData_analog_5v_low.push(processData(element.current_analog_5v.min));
      YData_analog_5v_high.push(processData(element.current_analog_5v.max));
    });

    return (
      <div style={{ width: "100%" }}>
        <PlotlyCommon
          dataType="Current"
          X={XData}
          Y={YData_hpt_vis}
          yTitle="HPT Vis"
          xTitle="Capture Time"
          low={YData_hpt_vis_low}
          high={YData_hpt_vis_high}
        />
        <PlotlyCommon
          dataType="Current"
          X={XData}
          Y={YData_hpt_nir}
          yTitle="HPT Nir"
          xTitle="Capture Time"
          low={YData_hpt_nir_low}
          high={YData_hpt_nir_high}
        />
        <PlotlyCommon
          dataType="Current"
          X={XData}
          Y={YData_smi_vis_nir}
          yTitle="SMI Vis Nir"
          xTitle="Capture Time"
          low={YData_smi_vis_nir_low}
          high={YData_smi_vis_nir_high}
        />
        <PlotlyCommon
          dataType="Current"
          X={XData}
          Y={YData_lctf_3_3v}
          yTitle="LCTF 3_3V"
          xTitle="Capture Time"
          low={YData_lctf_3_3v_low}
          high={YData_lctf_3_3v_high}
        />
        <PlotlyCommon
          dataType="Current"
          X={XData}
          Y={YData_lctf_15v}
          yTitle="LCTF 15V"
          xTitle="Capture Time"
          low={YData_lctf_15v_low}
          high={YData_lctf_15v_high}
        />
        <PlotlyCommon
          dataType="Current"
          X={XData}
          Y={YData_lctf_neg_15v}
          yTitle="LCTF NEG 15V"
          xTitle="Capture Time"
          low={YData_lctf_neg_15v_low}
          high={YData_lctf_neg_15v_high}
        />
        <PlotlyCommon
          dataType="Current"
          X={XData}
          Y={YData_3_3v}
          yTitle="LCTF 3_3V"
          xTitle="Capture Time"
          low={YData_3_3v_low}
          high={YData_3_3v_high}
        />
        <PlotlyCommon
          dataType="Current"
          X={XData}
          Y={YData_digital_5v}
          yTitle="LCTF Digital 5V"
          xTitle="Capture Time"
          low={YData_digital_5v_low}
          high={YData_digital_5v_high}
        />
        <PlotlyCommon
          dataType="Current"
          X={XData}
          Y={YData_analog_5v}
          yTitle="LCTF Analog 5V"
          xTitle="Capture Time"
          low={YData_analog_5v_low}
          high={YData_analog_5v_high}
        />
      </div>
    );
  }
}
