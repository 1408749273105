import React, { Component, Fragment } from "react";
import UpdateTargetForm from "../../Components/TargetsComponents/UpdateTargetForm";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import API from "../../Components/API";

class ViewTargetPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      init: {}
    };
  }

  componentDidMount() {
    API.get(`missions_management/targets/${this.props.match.params.id}`)
      .then(res => {
        this.setState({
          init: res.data
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  render() {
    const targetId = this.props.match.params.id;
    const urls = {
      "/": "MISSIONS",
      "/missions_management/targets/": "TARGETS",
      id: targetId
    };
    return (
      <Fragment>
        <BreadCrumbComponent data={urls} titlePage="View Target" />
        <UpdateTargetForm
          targetId={targetId}
          initialData={this.state.init}
          initialValues={this.state.init}
        />
      </Fragment>
    );
  }
}

export default ViewTargetPage;
