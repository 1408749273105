import React from "react";
import { Spin } from "antd";

const Spinner = () => (
  <div className="spinner-container">
    <Spin />
  </div>
);

export default Spinner;
