import { DOWNLOAD_CART, DELETE_CART } from "./types";

export const addDownload = cart => async dispatch => {
  dispatch({
    type: DOWNLOAD_CART,
    payload: cart
  });
};

export const deleteAll = () => async dispatch => {
  dispatch({
    type: DELETE_CART
  });
};
