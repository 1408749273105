import React, { Component, Fragment } from "react";
import { Row, Col, Icon } from "antd";
import { connect } from "react-redux";
import * as actions from "../../Actions";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import UsersOverTimeByUserType from "../../Components/Vis/Distributions/UsersOverTimeByUserType";
import UsersOverTime from "../../Components/Vis/Distributions/UsersOverTime";
import WaffleChartComponent from "../../Components/Vis/WaffleChartComponent";
import DistributionAccessChart from "../../Components/Vis/Distributions/DistributionAccessChart";
import Spinner from "../../Components/Layout/Spinner";
import {
  getTotalUsers,
  groupObject,
  constructUserWaffle,
  userTypeOverTime,
  userOverTime,
  constructAveRating,
  getAverageRating,
  getNewUsersThisMonth,
  getActiveUsersThisMonth
} from "../../Components/CommonFunctions/DistributionFunctions";

class DistributionUsersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    this.props.fetchUserProfiles();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.profiles !== this.props.profiles) {
      this.passData(this.props.profiles);
    }
  }

  passData(profiles) {
    this.setState({ data: profiles });
  }

  render() {
    const profiles = this.state.data;
    const totalUsers = getTotalUsers(profiles);
    const usersByType = groupObject(profiles, "user_type");
    const userDataTypeOverTime = userTypeOverTime(profiles);
    const userDataOverTime = userOverTime(profiles);
    const waffleData = constructUserWaffle(usersByType);
    const distributionRatingData = constructAveRating(
      profiles,
      "distribution_rating"
    );
    const accessRatingData = constructAveRating(profiles, "openaccess_rating");
    const aveDistribRate = getAverageRating(distributionRatingData);
    const aveOpenAccessRate = getAverageRating(accessRatingData);
    const newUsersThisMonth = getNewUsersThisMonth(profiles);
    const activeUsers = getActiveUsersThisMonth(profiles);

    const urls = {
      "/": "HOME",
      "/data_management/distribution_users_dashboard": "DISTRIBUTION SITE USERS"
    };
    return (
      <Fragment>
        <BreadCrumbComponent data={urls} titlePage="Distribution Site Users" />
        <Row gutter={16}>
          <Col sm={24} md={12}>
            <Row gutter={16} type="flex">
              <Col sm={24} md={12}>
                <div className="panel panel--small" style={{ height: "100%" }}>
                  <div className="trend">
                    <span
                      className={
                        newUsersThisMonth.trendType
                          ? "trend--up"
                          : "trend--down"
                      }
                    >
                      <Icon
                        className="trend__icon"
                        type={
                          newUsersThisMonth.trendType
                            ? "up-circle"
                            : "down-circle"
                        }
                        theme="filled"
                      />
                      {newUsersThisMonth.trendType
                        ? `+${newUsersThisMonth.trendVal}`
                        : newUsersThisMonth.trendVal}
                    </span>
                  </div>
                  {this.state.data.length === 0 ? (
                    <Spinner />
                  ) : (
                    <div>
                      <div className="statNumber">
                        {this.props.loader ? 0 : newUsersThisMonth.count}
                      </div>
                      <div className="caption">New Users this Month</div>
                    </div>
                  )}
                </div>
              </Col>
              <Col sm={24} md={12}>
                <div className="panel panel--small" style={{ height: "100%" }}>
                  <div className="trend">
                    <span
                      className={
                        activeUsers.trendType ? "trend--up" : "trend--down"
                      }
                    >
                      <Icon
                        type={
                          activeUsers.trendType ? "up-circle" : "down-circle"
                        }
                        theme="filled"
                      />
                      {activeUsers.trendType
                        ? `+${activeUsers.trendVal}`
                        : activeUsers.trendVal}
                    </span>
                  </div>
                  {this.state.data.length === 0 ? (
                    <Spinner />
                  ) : (
                    <div>
                      <div className="statNumber">
                        {this.props.loader ? 0 : activeUsers.count}
                      </div>
                      <div className="caption">Active Users This Month</div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: "16px" }}>
              <Col span={24}>
                <div className="panel">
                  <div className="panel__title label">
                    NEW USERS BY USER TYPE
                  </div>
                  {userDataTypeOverTime.length > 0 ? (
                    <UsersOverTimeByUserType data={userDataTypeOverTime} />
                  ) : (
                    <Spinner />
                  )}
                </div>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: "16px" }}>
              <Col span={24}>
                <div className="panel">
                  <div className="panel__title label">
                    TOTAL USERS OVER TIME
                  </div>
                  {userDataOverTime.length > 0 ? (
                    <UsersOverTime data={userDataOverTime} />
                  ) : (
                    <Spinner />
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm={24} md={12}>
            <Row>
              <div className="panel">
                <div className="panel__stats">
                  <div className="panel__total-user">
                    <div className="caption w100">Total User</div>
                    <div className="statNumber">{totalUsers}</div>
                  </div>
                  <div className="panel__breakdown">
                    {Object.entries(usersByType).length > 0 ? (
                      Object.keys(usersByType).map((key, value) => (
                        <div className="panel__breakdown--item" key={value}>
                          <div className="small-label">{key}</div>
                          <div className="small-stat-no">
                            {usersByType[key].length}
                          </div>
                        </div>
                      ))
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </div>
                <hr className="hrStyled" />
                <div className="label">Number of User Per User Type</div>
                <div className="userPerUserTypeCont">
                  {waffleData.length > 0 ? (
                    <WaffleChartComponent data={waffleData} />
                  ) : (
                    <Spinner />
                  )}
                </div>
              </div>
            </Row>
            <Row gutter={16} style={{ marginTop: "16px" }} type="flex">
              <Col sm={24} md={12}>
                <div className="panel" style={{ height: "100%" }}>
                  <div className="panel__title label">DISTRIBUTION RATING</div>
                  {distributionRatingData.length > 0 ? (
                    <div>
                      <div className="statNumber">{aveDistribRate}</div>
                      <div className="small-label">
                        Average Distribution Rating
                      </div>
                      <DistributionAccessChart
                        fill="#7BD1AA"
                        data={distributionRatingData}
                      />
                    </div>
                  ) : (
                    <Spinner />
                  )}
                </div>
              </Col>
              <Col sm={24} md={12}>
                <div className="panel" style={{ height: "100%" }}>
                  <div className="panel__title label">OPEN ACCESS RATING</div>
                  {accessRatingData.length > 0 ? (
                    <div>
                      <div className="statNumber">{aveOpenAccessRate}</div>
                      <div className="small-label">
                        Average Open Access Rating
                      </div>
                      <DistributionAccessChart
                        fill="#E56F6F"
                        data={accessRatingData}
                      />
                    </div>
                  ) : (
                    <Spinner />
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    profiles: state.profiles.user_profiles,
    loader: state.profiles.profile_loading
  };
};

export default connect(mapStateToProps, actions)(DistributionUsersPage);
