import React from "react";
import { Select } from "antd";

const { Option } = Select;

const FilterComponent = ({ optionData, placeholder, handleChange, value }) => {
  return (
    <Select
      // value={value}
      defaultValue={value}
      placeholder={placeholder}
      onChange={handleChange}
      optionFilterProp="children"
      onSearch={val => {
        console.log(val);
      }}
      style={{ width: "100%" }}
    >
      {optionData.map(data => (
        <Option key={data} value={data.val}>
          {data.text}
        </Option>
      ))}
    </Select>
  );
};

export default FilterComponent;
