import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  Button,
  Row,
  Col,
  Form,
  Card,
  Icon,
  Modal,
  Pagination,
  Input
} from "antd";
import Media from "react-media";
import queryString from "query-string";
import API from "../API";
import history from "../../history";
import withToast from "../Hocs/withToast";
import Config from "../../Config/const";
import FilterComponent from "./FilterComponent";
import SearchComponent from "../MissionsComponents/SearchComponent";
import {
  extractOrder,
  toTimeString,
  syncFilters,
  tableIndexStyle
} from "../CommonFunctions";
import axios from "axios";

let controllerRef;

const redirectLink = (url, id) => {
  return `${url}${id}`;
};

const columns = [
  {
    title: <span className="table-index-number">NO</span>,
    dataIndex: "index",
    align: "center",
    width: "80px",
    render(text) {
      return tableIndexStyle(text);
    }
  },
  {
    title: "ID",
    dataIndex: "id",
    width: "80px",
    render: text => (
      <Link to={redirectLink("/missions_management/targets/view/", text)}>
        {text}
      </Link>
    ),
    sorter: true
  },
  {
    title: "NAME",
    dataIndex: "name",
    sorter: true
  },
  {
    title: "CREATED TIME",
    dataIndex: "created_time",
    sorter: true,
    // ellipsis: true,
    hideOnMedium: true
  },
  {
    title: "REQUIRED BANDS",
    dataIndex: "required_bands",
    sorter: true,
    ellipsis: true,
    hideOnMedium: true
  },
  {
    title: "CLASSIFICATION",
    dataIndex: "classification",
    sorter: true
  },
  {
    title: "PURPOSE",
    dataIndex: "purpose",
    sorter: true
  }
];

const getResponsiveColumns = matches => {
  if (matches.medium) {
    return columns.filter(column => !column.hideOnMedium);
  }
  if (matches.small) {
    return columns.filter(
      column => !column.hideOnMedium && !column.hideOnSmall
    );
  }
  return columns;
};

let query = {};

class TargetsTable extends Component {
  constructor(props) {
    query = queryString.parse(window.location.search);
    super(props);
    this.state = {
      selectedRowKeys: [],
      name: query.name || "",
      action: "",
      classification: query.classification || "",
      purpose: query.purpose || "",
      keyword: "",
      missionData: "",
      pagination: {},
      deleted: false,
      visible: false,
      currentPage: 1,
      pageSize: 25
    };
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = (page = 1, sort = "-id") => {
    this.setState({ loading: true });
    if (controllerRef) {
      controllerRef.cancel();
    }
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    controllerRef = source;

    API.get(
      `missions_management/targets?format=json&name=${this.state.name}&classification=${this.state.classification}&purpose=${this.state.purpose}&ordering=${sort}&search=${this.state.keyword}&page=${this.state.currentPage}&page_size=${this.state.pageSize}`, { cancelToken: source.token }
    )
      .then(res => {
        const getTotalPages = parseInt(res ? res.data.pagination.count : 0);
        this.setState({
          loading: false,
          missionData: res.data,
          total: getTotalPages
        });
        controllerRef = null;
      })
      .catch(function(error) {
        if (error.toString() !== "Cancel") {
          console.log(error);
          this.props.openNotification("error", "Oops!", "Something went wrong!");
        }
      });
  };

  formatMissions = tableData => {
    const missions = [];
    const rawData = tableData ? tableData.data : [];
    rawData.map((val, index) => {
      return missions.push({
        index: `${this.state.pageSize * (this.state.currentPage - 1) +
          1 +
          index}`,
        key: `${val.id}`,
        id: `${val.id}`,
        name: `${val.name}`,
        created_time: `${toTimeString(val.created_time)}`,
        required_bands: `${val.required_bands}`,
        classification: `${val.classification}`,
        purpose: `${val.purpose}`
      });
    });
    return missions;
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.fetch(pagination.current, extractOrder(sorter));
  };

  handleFilterChange = (filter, data) => {
    query = queryString.parse(window.location.search);
    syncFilters(query, filter, data);

    switch (filter) {
      case "name":
        this.setState(
          {
            ...this.state,
            currentPage: 1,
            name: data
          },
          () => {
            this.fetch();
          }
        );
        break;
      case "classification":
        this.setState(
          {
            ...this.state,
            currentPage: 1,
            classification: data
          },
          () => {
            this.fetch();
          }
        );
        break;
      case "purpose":
        this.setState(
          {
            ...this.state,
            currentPage: 1,
            purpose: data
          },
          () => {
            this.fetch();
          }
        );
        break;
      default:
        return false;
    }
  };

  deleteRows = () => {
    this.setState({ visible: false });
    const data = this.state.selectedRowKeys;
    const deleted = data.map(rowId => {
      return API.delete(`/missions_management/targets/${rowId}`)
        .then(res => {
          this.setState({
            loading: false,
            selectedRowKeys: []
          });
          this.fetch();
          history.push(`/missions_management/targets`);
        })
        .catch(err => {
          this.setState({
            loading: false,
            selectedRowKeys: []
          });
          this.props.openNotification(
            "error",
            "Oops!",
            "Something went wrong!"
          );
          console.log(err);
        });
    });
    if (deleted) {
      this.props.openNotification("success", "Success!", "Request successful!");
    }
  };

  handleActionChange = () => {
    if (this.state.action === "Delete") {
      // DELETE ACTION ONLY
      this.setState({
        loading: true,
        visible: true
      });
    } else {
      alert("Please choose an action");
    }
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  enterPressed = (event, filterName) => {
    const keyCode = window.event ? event.which : event.keyCode;
    if (keyCode === 13) {
      const { value } = event.target;
      this.setState(
        {
          currentPage: 1
        },
        () => {
          if (filterName) {
            this.handleFilterChange(filterName, value);
          } else {
            this.fetch();
          }
        }
      );
    }
  };

  onPaginationChange = (page = 1, size) => {
    if (page !== this.state.currentPage && page > 0) {
      this.onPageChange(page);
    }

    if (size !== this.state.pageSize) {
      this.onPageSizeChange(size);
    }
  };

  onPageChange(page) {
    this.setState(
      {
        currentPage: page
      },
      () => {
        this.fetch();
      }
    );
  }

  onPageSizeChange(size) {
    this.setState(
      {
        pageSize: size,
        currentPage: 1
      },
      () => {
        this.fetch();
      }
    );
  }

  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true
    };
    const hasSelected = selectedRowKeys.length > 0;
    const computeSize = (size, count) => {
      if (count < size) {
        return count;
      }
      return size;
    };
    return (
      <div>
        <Form className="ant-advanced-search-form">
          <Row>
            <Col>
              <Card
                title="Filters"
                extra={
                  <Button type="primary">
                    <Link to="/missions_management/targets/add">
                      Add Target <Icon type="plus-circle" />
                    </Link>
                  </Button>
                }
              >
                <Row gutter={{ md: 24 }}>
                  <Col md={6} xs={24}>
                    <span className="label">By Name</span>
                    <Input
                      defaultValue={this.state.name}
                      onKeyPress={e => {
                        this.enterPressed(e, "name");
                      }}
                      onBlur={e => {
                        this.handleFilterChange("name", e.target.value);
                      }}
                      onChange={e => {
                        this.setState({
                          name: e.target.value
                        });
                      }}
                    />
                  </Col>
                  <Col md={6} xs={24}>
                    <span className="label">By Classification</span>
                    <FilterComponent
                      value={this.state.classification}
                      optionData={Config.target.acquisition.classification}
                      placeholder="Choose a Classification"
                      handleChange={e => {
                        this.handleFilterChange("classification", e);
                      }}
                    />
                  </Col>
                  <Col md={6} xs={24}>
                    <span className="label">By Purpose</span>
                    <FilterComponent
                      value={this.state.purpose}
                      optionData={Config.target.acquisition.purpose}
                      placeholder="Choose a Purpose"
                      handleChange={e => {
                        this.handleFilterChange("purpose", e);
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>

        <Row>
          <Col md={24}>
            <Card style={{ marginTop: "20px" }}>
              <Row>
                <Col md={12}>
                  <div className="actionGroup">
                    <span className="label">Action:</span>
                    <div style={{ width: "300px", margin: "0 15px" }}>
                      <FilterComponent
                        optionData={Config.target.acquisition.selectedAction}
                        placeholder="Choose an action"
                        handleChange={e => {
                          this.setState({ action: e });
                        }}
                      />
                    </div>
                    <Button
                      type="primary"
                      onClick={() => {
                        this.handleActionChange();
                      }}
                      disabled={!hasSelected}
                      loading={this.state.loading}
                    >
                      Go
                    </Button>
                    <span style={{ marginLeft: 8 }}>
                      {hasSelected
                        ? `Selected ${selectedRowKeys.length} items out of ${this.state.pagination.total}`
                        : ""}
                    </span>
                  </div>
                </Col>
                <Col md={12}>
                  <Row>
                    <Col md={14}>
                      <div
                        style={{
                          float: "right",
                          paddingTop: "18px"
                        }}
                      >
                        {this.state.missionData
                          ? `${computeSize(
                              this.state.pageSize *
                                (this.state.currentPage - 1) +
                                1,
                              this.state.missionData.pagination.count
                            )} - ${computeSize(
                              this.state.pageSize * this.state.currentPage,
                              this.state.missionData.pagination.count
                            )} of ${
                              this.state.missionData.pagination.count
                            } items`
                          : ""}
                      </div>
                    </Col>
                    <Col md={10}>
                      <div
                        style={{
                          width: "95%",
                          float: "right",
                          paddingTop: "16px"
                        }}
                      >
                        <SearchComponent
                          name="search"
                          onkeypress={e => {
                            this.enterPressed(e);
                          }}
                          onchange={e => {
                            this.setState({
                              keyword: e.target.value
                            });
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Media
                queries={{
                  xs: "(max-width: 1099px)",
                  small: "(min-width: 1100px) and (max-width: 1199px)",
                  medium: "(min-width: 1200px) and (max-width: 1299px)",
                  large: "(min-width: 1300px)"
                }}
              >
                {matches => (
                  <Fragment>
                    <Table
                      scroll={matches.xs ? { x: "max-content" } : {}}
                      size="middle"
                      rowSelection={rowSelection}
                      columns={getResponsiveColumns(matches)}
                      loading={this.state.loading}
                      dataSource={this.formatMissions(this.state.missionData)}
                      pagination={false}
                      onChange={this.handleTableChange}
                      bordered
                    />
                  </Fragment>
                )}
              </Media>
              <Pagination
                style={{ marginTop: "20px", float: "right" }}
                showSizeChanger
                onChange={this.onPaginationChange}
                onShowSizeChange={this.onPaginationChange}
                current={this.state.currentPage}
                total={this.state.total}
                pageSizeOptions={["25", "50", "100"]}
                pageSize={this.state.pageSize}
              />
            </Card>
          </Col>
          <Modal
            visible={this.state.visible}
            onOk={this.deleteRows}
            onCancel={() => {
              this.setState({ visible: false, loading: false });
            }}
          >
            <h3 style={{ margin: "20px" }}>
              <Icon
                type="question-circle"
                className="modal_icon"
                style={{ color: "orange" }}
              />
              Are you sure you want to delete?
            </h3>
          </Modal>
        </Row>
      </div>
    );
  }
}

export default withToast(TargetsTable);
