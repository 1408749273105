import React, { Component, Fragment } from "react";
import CommandsForm from "../../Components/MissionsComponents/CommandsForm";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import API from "../../Components/API";

class ViewCommandsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      init: {},
      loading: true
    };
  }

  componentDidMount() {
    API.get(`/missions_management/commands/${this.props.match.params.id}`)
      .then(res => {
        // console.log(res);
        res.data.command_type = res.data.command_type.id;
        this.setState({
          init: res.data,
          loading: false
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          loading: false
        });
        alert(error);
      });
  }

  // processData = data => {
  //   // handle acu log seg object
  //   const flag = Object.entries(data).length !== 0 ? true : false;
  //   const acuName = flag ? data.acu_log_segment.name : "";
  //   const acuAddress = flag ? data.acu_log_segment.starting_address : "";
  //   data.acu_log_segment = `${acuName} | ${acuAddress}`;
  //   return data;
  // };

  render() {
    const commandId = this.props.match.params.id;
    const urls = {
      "/": "MISSIONS",
      "/missions_management/commands": "COMMANDS",
      "/view": "VIEW"
    };
    return (
      <Fragment>
        <BreadCrumbComponent data={urls} titlePage="View Commands" />
        <CommandsForm
          commandId={commandId}
          view="edit"
          initialValues={this.state.init}
          loading={this.state.loading}
        />
      </Fragment>
    );
  }
}

export default ViewCommandsPage;
