import { DOWNLOAD_PROFILES, DOWNLOAD_LOADING } from "../Actions/types";

const INITIAL_STATE = {
  downloads: "",
  download_loading: ""
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DOWNLOAD_PROFILES:
      return { ...state, downloads: action.payload };
    case DOWNLOAD_LOADING:
      return { ...state, download_loading: action.payload };
    default:
      return state;
  }
}
