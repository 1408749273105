import React from "react";
import { ResponsiveWaffle } from "@nivo/waffle";
import _ from "lodash";

export default function WaffleChartComponent({ data, total }) {
  let maxVal = _.sumBy(data, "value");

  return (
    <ResponsiveWaffle
      data={_.orderBy(data, "value", "desc")}
      total={maxVal}
      rows={20}
      columns={20}
      fillDirection="top"
      margin={{ top: 10, right: 10, bottom: 10, left: 150 }}
      colors={{ scheme: "dark2" }}
      borderColor={{ from: "color", modifiers: [["darker", 0.3]] }}
      animate
      motionStiffness={90}
      motionDamping={11}
      legends={[
        {
          anchor: "left",
          direction: "column",
          justify: false,
          translateX: -130,
          translateY: 0,
          itemsSpacing: 4,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 1,
          itemTextColor: "#777",
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
                itemBackground: "#f7fafb"
              }
            }
          ]
        }
      ]}
    />
  );
}
