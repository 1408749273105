import {
  PASSES_SATELLITE,
  PASS_NEXTGRS,
  TRACK_SATELLITE,
  TRACK_SATELLITE_GRS,
  TRACK_GRSPASS
} from "../Actions/types";

const INITIAL_STATE = {
  passes: [],
  nextGrs: [],
  trackSatellite: {},
  trackSatelliteGRS: [],
  trackDiwata1Target: [],
  trackGRSPass: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PASSES_SATELLITE:
      return {
        ...state,
        passes: action.payload
      };
    case PASS_NEXTGRS:
      return {
        ...state,
        nextGrs: action.payload
      };
    case TRACK_SATELLITE:
      return {
        ...state,
        trackSatellite: action.payload
      };
    case TRACK_SATELLITE_GRS:
      return {
        ...state,
        trackSatelliteGRS: action.payload
      };
    case TRACK_GRSPASS:
      return {
        ...state,
        trackGRSPass: action.payload
      };
    default:
      return state;
  }
}
