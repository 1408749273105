import { GET_COMMAND_TYPES } from "../Actions/types";

const INITIAL_STATE = {
  command_types: ""
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_COMMAND_TYPES:
      return { ...state, command_types: action.payload };
    default:
      return state;
  }
}
