import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, Row, Col, Icon, Button, Popover } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import downloadFile from "../API/downloadFile";
import CommandView from "./CommandView";

export default function CommandComponent({ command }) {
  const [copied, setCopied] = useState(false);
  return (
    <Card className="m-t-10px" title="Generated Command">
      <Row style={{ marginTop: "20px" }}>
        <Col md={5}>
          <span className="label">Download: </span>
        </Col>
        <Col md={10}>
          <Button
            onClick={() =>
              downloadFile(
                command.download_link_csv,
                // eslint-disable-next-line prefer-template
                command.section_number + ".csv"
              )
            }
          >
            <Icon type="download" /> CSV
          </Button>
        </Col>
      </Row>

      <Row style={{ marginTop: "20px" }}>
        <Col md={5}>
          <span className="label">Command Execution Time:</span>
        </Col>
        <Col md={10}>{command.execution_time}</Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col md={5}>
          <span className="label">Command: </span>
        </Col>
        {/* <Col md={10}>{command.section_number}</Col> */}
        <Col md={10}>
          {command.section_number ? (
            <Popover content="Edit Command">
              <Link
                to={`/missions_management/commands/view/${command.id}`}
                target="_blank"
              >
                {command.section_number} <Icon type="edit" />
              </Link>
            </Popover>
          ) : (
            command.section_number
          )}
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col md={5}>
          <span className="label">Command String: </span>
        </Col>
        <Col>
          <CommandView data={command.content ? command.content : ""} />
        </Col>
      </Row>
    </Card>
  );
}
