export default {
  sidebar_menu: [
    {
      key: "home",
      link: "/",
      title: "HOME"
    },
    {
      key: "sub2",
      title: "SATELLITE HEALTH MANAGEMENT",
      sub_menu: [
        {
          key: "17",
          link: "/satellite_health_management/beta_angles",
          title: "Beta Angle Monitoring"
        },
        {
          key: "26",
          link: "/satellite_health_management/satellite_health_dashboard",
          title: "Dashboard"
        }
      ]
    },
    {
      key: "sub1",
      title: "MISSION MANAGEMENT",
      sub_menu: [
        {
          key: "6",
          link: "/missions_management/mission_planning_dashboard",
          title: "Mission Planning Dashboard"
        },
        {
          key: "8",
          link: "/missions_management/targets",
          title: "Target List"
        },
        {
          key: "12",
          link: "/missions_management/acquisition_missions",
          title: "Acquisition Missions"
        },
        {
          key: "13",
          link: "/missions_management/upload_missions",
          title: "Upload Missions"
        },
        {
          key: "14",
          link: "/missions_management/download_missions",
          title: "Download Missions"
        },
        {
          key: "15",
          link: "/missions_management/acu_log_download_missions",
          title: "ACU Logs Download Missions"
        },
        {
          key: "21",
          link: "/missions_management/command_batches",
          title: "Command Batches"
        },
        {
          key: "22",
          link: "/missions_management/commands",
          title: "Commands"
        },
        {
          key: "20",
          link: "/missions_management/mission_reports",
          title: "Mission Reports"
        }
      ]
    },
    {
      key: "data management",
      title: "DATA MANAGEMENT",
      sub_menu: [
        {
          key: "23",
          link: "/data_management/telemetry_files",
          title: "Telemetry"
        },
        {
          key: "24",
          link: "/data_management/raw_images",
          title: "Raw Images"
        },
        {
          key: "25",
          link: "/data_management/merged_raw_images",
          title: "Merged Raw Images"
        },
        {
          key: "raw",
          link: "/data_management/captures_raw",
          title: "Raw"
        },
        {
          key: "level0",
          link: "/data_management/captures_level0",
          title: "Level 0"
        },
        {
          key: "level1",
          link: "/data_management/captures_level1",
          title: "Level 1"
        },
        {
          key: "capture_groups",
          link: "/data_management/capture_groups",
          title: "Capture Groups"
        },
        {
          key: "capture_group_products",
          link: "/data_management/capture_group_products",
          title: "Capture Group Products"
        },
        {
          key: "sign-ups",
          link: "/data_management/distribution_users_dashboard",
          title: "Distribution Site Users"
        },
        {
          key: "dls",
          link: "/data_management/distribution_downloads_dashboard",
          title: "Downloads Statistics"
        }
      ]
    }
  ],
  command_name: [
    { val: "", text: "ALL" },
    { val: "ACU Log Download", text: "ACU Log Download" },
    { val: "Acquisition", text: "Acquisition" },
    { val: "Download", text: "Download" },
    { val: "Upload", text: "Upload" }
  ],
  band: [
    { val: "", text: "------" },
    { val: "HPT-R", text: "HPT-R" },
    { val: "HPT-G", text: "HPT-G" },
    { val: "HPT-B", text: "HPT-B" },
    { val: "HPT-N", text: "HPT-N" },
    { val: "MFC", text: "MFC" },
    { val: "WFC", text: "WFC" },
    { val: "SMI-N", text: "SMI-N" },
    { val: "SMI-V", text: "SMI-V" },
    { val: "ERC", text: "ERC" }
  ],
  payload: [
    { val: "", text: "------" },
    { val: "HPT", text: "HPT" },
    { val: "MFC", text: "MFC" },
    { val: "WFC", text: "WFC" },
    { val: "SMI", text: "SMI" },
    { val: "ERC", text: "ERC" }
  ],
  satellites: [
    { val: "", text: "------" },
    { val: "Diwata-1", text: "Diwata-1" },
    { val: "Diwata-2", text: "Diwata-2" },
    { val: "Maya-1", text: "Maya-1" }
  ],
  segments: [
    { val: "", text: "------" },
    { val: "SEG-A", text: "SEG-A | adrs_0x600000" },
    { val: "SEG-B", text: "SEG-B | adrs_0x648000" },
    { val: "SEG-A-1", text: "SEG-A-1 | adrs_0x600000" },
    { val: "SEG-A-2", text: "SEG-A-2 | adrs_0x624000" },
    { val: "SEG-B-1", text: "SEG-B-1 | adrs_0x648000" },
    { val: "SEG-B-2", text: "SEG-B-2 | adrs_0x66C000" }
  ],
  acu_rec_start: [{ label: "", value: "true" }],
  mission: {
    acquisition: {
      commandName: [
        { val: "", text: "All" },
        { val: "ACU Log Download", text: "ACU Log Download" },
        { val: "Acquisition", text: "Acquisition" },
        { val: "Download", text: "Download" },
        { val: "Upload", text: "Upload" }
      ],
      filterStats: [
        { val: "", text: "All" },
        { val: "Successful", text: "Successful" },
        { val: "Failed", text: "Failed" },
        { val: "Cancelled", text: "Cancelled" },
        { val: "Pending", text: "Pending" }
      ],
      commandType: [
        { val: "Diwata-1 - Acquisition", text: "Diwata-1 - Acquisition" },
        { val: "Diwata-1 - Download", text: "Diwata-1 - Download" },
        { val: "Diwata-1 - Upload", text: "Diwata-1 - Upload" },
        {
          val: "Diwata-2 - ACU Log Download",
          text: "Diwata-2 - ACU Log Download"
        },
        { val: "Diwata-2 - Acquisition", text: "Diwata-2 - Acquisition" },
        { val: "Diwata-2 - Download", text: "Diwata-2 - Download" }
      ],
      addMissionFilterStats: [
        { val: "", text: "----" },
        { val: "Pending", text: "Pending" },
        { val: "Successful", text: "Successful" },
        { val: "Failed", text: "Failed" },
        { val: "Cancelled", text: "Cancelled" }
      ],
      addMissionPointingMode: [
        { val: "", text: "----" },
        { val: "Not Determined", text: "Not Determined" },
        { val: "Target Pointing", text: "Target Pointing" },
        { val: "Off Nadir", text: "Off Nadir" },
        { val: "Nadir", text: "Nadir" },
        { val: "Rpy", text: "Rpy" },
        { val: "Qt", text: "Qt" },
        { val: "Off Nadir Rpy", text: "Off Nadir Rpy" },
        { val: "Manual Qt", text: "Manual Qt" }
      ],
      filterPointingMode: [
        { val: "", text: "All" },
        { val: "Not Determined", text: "Not Determined" },
        { val: "Target Pointing", text: "Target Pointing" },
        { val: "Off Nadir", text: "Off Nadir" },
        { val: "Nadir", text: "Nadir" },
        { val: "Rpy", text: "Rpy" },
        { val: "Qt", text: "Qt" },
        { val: "Off Nadir Rpy", text: "Off Nadir Rpy" },
        { val: "Manual Qt", text: "Manual Qt" }
      ],
      filterSatellite: [
        { val: "", text: "All" },
        { val: "Diwata-1", text: "Diwata-1" },
        { val: "Diwata-2", text: "Diwata-2" },
        { val: "Maya-1", text: "Maya-1" }
      ],
      genericAction: [
        { val: "", text: "------" },
        { val: "Delete", text: "Delete Selected" },
        { val: "Pending", text: "Mark Selected as Pending" },
        { val: "Successful", text: "Mark Selected as Successful" },
        { val: "Failed", text: "Mark Selected as Failed" },
        { val: "Cancelled", text: "Mark Selected as Cancelled" }
      ],
      selectedActionAcquisition: [
        { val: "", text: "------" },
        { val: "Delete", text: "Delete Missions" },
        { val: "Pending", text: "Mark as Pending" },
        { val: "Successful", text: "Mark as Successful" },
        { val: "Failed", text: "Mark as Failed" },
        { val: "Cancelled", text: "Mark as Cancelled" }
      ],
      receivingStation: [
        { val: "", text: "------" },
        { val: "ASTI", text: "ASTI" },
        { val: "TU", text: "TU" },
        { val: "HAKODATE", text: "HAKODATE" },
        { val: "KIRUNA", text: "KIRUNA" }
      ],
      filterReceivingStation: [
        { val: "", text: "All" },
        { val: "ASTI", text: "ASTI" },
        { val: "TU", text: "TU" },
        { val: "HAKODATE", text: "HAKODATE" },
        { val: "KIRUNA", text: "KIRUNA" }
      ],
      dayDownload: [
        { val: "all", text: "All" },
        { val: "Yes", text: "Yes" },
        { val: "No", text: "No" }
      ]
    }
  },
  rawFiles: {
    telemetry: [{ val: "bin", text: "Telemetry (.bin)" }],
    rawImages: [
      { val: "bin", text: "Telemetries (.bin)" },
      { val: "rg3", text: "Raw Images (.rg3)" }
    ],
    mergedRawImages: [
      { val: "bin", text: "Merged rg3 images (.bin)" },
      { val: "file_tif", text: "Tif files (.tif)" },
      { val: "file_full_tif", text: "Full tif files (_full.tif)" },
      { val: "csv", text: "Meta csv (.csv)" }
    ]
  },
  captures: {
    downloads: [
      { val: "bin", text: "Merged rg3 images (.bin)" },
      { val: "file_tif", text: "Tif files (.tif)" },
      { val: "file_full_tif", text: "Full tif files (_full.tif)" },
      { val: "csv", text: "Meta csv (.csv)" }
    ]
  },
  target: {
    acquisition: {
      purpose: [
        { val: "", text: "All" },
        { val: "Agriculture", text: "Agriculture" },
        { val: "Agriculture Validation", text: "Agriculture Validation" },
        { val: "Agroforestry", text: "Agroforestry" },
        { val: "Aquaculture", text: "Aquaculture" },
        { val: "cal/val", text: "cal/val" },
        { val: "Calibration Validation", text: "Calibration Validation" },
        { val: "Coastal Monitoring", text: "Coastal Monitoring" },
        { val: "Cultural Heritage", text: "Cultural Heritage" },
        { val: "Fisheries", text: "Fisheries" },
        { val: "Forest Validation", text: "Forest Validation" },
        { val: "Forestry", text: "Forestry" },
        { val: "Flood and storm surge", text: "Flood and storm surge" },
        { val: "Land Use Change", text: "Land Use Change" },
        { val: "Ocean Validation", text: "Ocean Validation" },
        { val: "Protected Area", text: "Protected Area" },
        { val: "Security", text: "Security" },
        { val: "Typhoon", text: "Typhoon" },
        { val: "Tourism", text: "Tourism" },
        { val: "Urban", text: "Urban" },
        { val: "Validation Site (urban)", text: "Validation Site (urban)" }
      ],
      classification: [
        { val: "", text: "All" },
        { val: 0, text: 0 },
        { val: 1, text: 1 },
        { val: 2, text: 2 },
        { val: 3, text: 3 },
        { val: 4, text: 4 },
        { val: 5, text: 5 },
        { val: "-", text: "-" }
      ],
      selectedAction: [
        { val: "", text: "------" },
        { val: "Delete", text: "Delete Selected Targets" }
      ]
    }
  },
  dataManagement: {
    captureGroups: [
      { val: "", text: "------" },
      { val: "Publish", text: "Publish Selected Capture Groups" },
      { val: "Unpublish", text: "Unpublish Selected Capture Groups" }
    ],
    captureGroupProducts: [
      { val: "", text: "------" },
      { val: "Publish", text: "Publish Selected Capture Group Products" },
      { val: "Unpublish", text: "Unpublish Selected Capture Group Products" }
    ]
  }
};
