import React, { Component, Fragment } from "react";
import AcuLogsDownloadForm from "../../Components/MissionsComponents/AcuLogsDownloadForm";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import API from "../../Components/API";

class ViewAcuLogsDownloadMissionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      init: {},
      loading: true
    };
  }

  componentDidMount() {
    API.get(
      `/missions_management/acu_log_download_missions/${this.props.match.params.id}`
    )
      .then(res => {
        console.log(res);
        this.setState({
          init: this.processData(res.data),
          loading: false
        });
      })
      .catch(function(error) {
        console.log(error);
        this.setState({
          loading: false
        });
        alert(error);
      });
  }

  processData = data => {
    // handle acu log seg object
    const flag = Object.entries(data).length !== 0 ? true : false;
    const acuName = flag ? data.acu_log_segment.name : "";
    const acuAddress = flag ? data.acu_log_segment.starting_address : "";
    data.acu_log_segment = acuName;
    return data;
  };

  render() {
    const acuLogId = this.props.match.params.id;
    const urls = {
      "/": "MISSIONS",
      "/missions_management/acu_log_download_missions": "ACU LOGS",
      "/view": "VIEW"
    };
    return (
      <Fragment>
        <BreadCrumbComponent
          data={urls}
          titlePage="View ACU Logs Download Missions"
        />
        <AcuLogsDownloadForm
          acuLogId={acuLogId}
          view="edit"
          initialValues={this.state.init}
          loading={this.state.loading}
        />
      </Fragment>
    );
  }
}

export default ViewAcuLogsDownloadMissionPage;
