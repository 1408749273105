import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Drawer, Divider, Table, Popover, Button } from "antd";
import Fullscreen from "react-full-screen";
import { toTimeString, splitString } from "../CommonFunctions";
import noImage from "../../Assets/Images/noImage.png";

export default class DrawerMergedRawImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      isFull: false,
      dataColumns: [
        {
          title: "",
          dataIndex: "label",
          key: "label"
        },
        {
          title: "",
          dataIndex: "value",
          key: "value",
          width: "70%"
        }
      ],
      dataColumnsImages: [
        {
          title: "IMAGE",
          dataIndex: "image",
          key: "image",
          width: "80px"
        },
        {
          title: "RAW",
          dataIndex: "raw",
          key: "raw",
          width: "50%"
        },
        {
          title: "TELEMETRY",
          dataIndex: "telemetry",
          key: "telemetry",
          width: "250px"
        }
      ],
      dataInfoData: [],
      dataFile: [],
      dataImages: []
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setDataInformation(this.props.data);
    }
  }

  setDataInformation = data => {
    const imageArray = [];
    const currentInfoData = [
      {
        key: "1",
        label: "Satellite",
        value: data.satellite ? data.satellite.name : ""
      },
      {
        key: "2",
        label: "SHU Address",
        value: data.shu_address
      },
      {
        key: "3",
        label: "Capture Time",
        value: toTimeString(data.capture_time)
      },
      {
        key: "4",
        label: "Depacketed Time",
        value: toTimeString(data.date_depacketed)
      },
      // {
      //   key: "5",
      //   label: "Upload Time",
      //   value: toTimeString(data.date_uploaded)
      // },
      {
        key: "6",
        label: "Uploaded by",
        value: data.uploaded_by
      },
      {
        key: "7",
        label: "Md5sum rg3 image",
        value: data.md5sum_rg3_image
      }
    ];

    const currentFile = [
      {
        key: "1",
        label: "Merged rg3 image",
        value: data.merged_rg3_image ? (
          <a href={data.merged_rg3_image}>
            {splitString(data.merged_rg3_image)}
          </a>
        ) : (
          ""
        )
      },
      {
        key: "2",
        label: "File tif",
        value: data.file_tif ? (
          <a href={data.file_tif}>{splitString(data.file_tif)}</a>
        ) : (
          ""
        )
      },
      {
        key: "3",
        label: "File full tif",
        value: data.file_full_tif ? (
          <a href={data.file_full_tif}>{splitString(data.file_full_tif)}</a>
        ) : (
          ""
        )
      },
      {
        key: "4",
        label: "Thumbnail",
        value: data.thumbnail ? (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a href={data.thumbnail} target="_blank">
            {splitString(data.thumbnail)}
          </a>
        ) : (
          ""
        )
      },
      {
        key: "5",
        label: "Meta json",
        value: data.meta_json ? (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a href={data.meta_json} target="_blank">
            {splitString(data.meta_json)}
          </a>
        ) : (
          ""
        )
      },
      {
        key: "6",
        label: "Meta csv",
        value: data.meta_csv ? (
          <a href={data.meta_csv}>{splitString(data.meta_csv)}</a>
        ) : (
          ""
        )
      }
    ];

    if (data.raw_images_details) {
      data.raw_images_details.map((image, index) => {
        return imageArray.push({
          key: index,
          image: (
            <img
              src={image.thumbnail ? image.thumbnail : noImage}
              alt="rawImage"
              height="40px"
              width="50px"
            />
          ),
          raw: image.file ? (
            <a href={image.file}>{splitString(image.file)}</a>
          ) : (
            ""
          ),
          telemetry: image.from_telemetry_file ? (
            <Popover content="Click to view details">
              <Link
                to="/data_management/telemetry_files"
                onClick={() => {
                  localStorage.setItem(
                    "openTelemetryId",
                    image.from_telemetry_file.id
                  );
                }}
              >
                {splitString(image.from_telemetry_file.name)}
              </Link>
            </Popover>
          ) : (
            ""
          )
        });
      });
    }

    this.setState({
      dataInfoData: currentInfoData,
      dataFile: currentFile,
      dataImages: imageArray
    });
  };

  render() {
    if (this.state.dataInfoData.length > 0) {
      return (
        <div>
          <Drawer
            title={this.props.data.capture_id}
            placement="right"
            closable={false}
            onClose={this.props.closeDrawer}
            visible={this.props.visible}
            width="600"
          >
            <div className="imageContainer">
              <div style={{ textAlign: "center" }}>
                <Fullscreen
                  enabled={this.state.isFull}
                  onChange={isFull => this.setState({ isFull })}
                >
                  <img
                    className={`${this.state.isFull ? `imageFullscreen` : ""}`}
                    src={
                      this.props.data.thumbnail
                        ? this.props.data.thumbnail
                        : noImage
                    }
                    alt="dataImages"
                    width={!this.state.isFull ? "100%" : ""}
                  />
                </Fullscreen>
              </div>
              <div className="fullscreenButton">
                <Button
                  icon="fullscreen"
                  onClick={() => this.setState({ isFull: true })}
                />
              </div>
            </div>
            <Table
              style={{ marginTop: "20px" }}
              columns={this.state.dataColumns}
              dataSource={this.state.dataInfoData}
              bordered
              pagination={false}
              showHeader={false}
              size="small"
            />
            <Divider style={{ marginTop: "50px" }}>Files</Divider>
            <Table
              style={{ marginTop: "20px" }}
              columns={this.state.dataColumns}
              dataSource={this.state.dataFile}
              bordered
              pagination={false}
              showHeader={false}
              size="small"
            />
            <Divider style={{ marginTop: "50px" }}>Raw Images</Divider>
            <Table
              style={{ marginTop: "20px" }}
              columns={this.state.dataColumnsImages}
              dataSource={this.state.dataImages}
              bordered
              pagination={false}
              size="small"
            />
          </Drawer>
        </div>
      );
    }
    return null;
  }
}
