import React, { Component, Fragment } from "react";
import {
  Menu,
  Dropdown,
  Icon,
  message,
  Row,
  Col,
  Button,
  DatePicker
} from "antd";
import moment from "moment";
import { connect } from "react-redux";
import * as actions from "../../Actions";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";

// Components
import {
  BarChart,
  BreakdownPieChart,
  DLCapturedPieChart,
  MissionActPieChart,
  PercentCovered,
  SuccessFailPieChart
} from "../../Components/ReportsComponents";

const { RangePicker } = DatePicker;
const dateFormat = "MMMM DD, YYYY";

class ReportsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      satelliteSelected: "Diwata-1",
      yearSelected: "2019",
      menuSelected: "Year",
      startDate: "",
      endDate: ""
    };
  }

  componentDidMount() {
    this.props.reportStats(this.state.satelliteSelected);
    this.props.listSatellite();
  }

  rangePickerChangeHandler = e => {
    if (e.length !== 0) {
      /* eslint no-underscore-dangle: 0 */
      const start = moment(e[0]._d).format("YYYY-MM-DD");
      const end = moment(e[1]._d).format("YYYY-MM-DD");
      const satellite = this.state.satelliteSelected;
      this.setState({
        startDate: start,
        endDate: end
      });
      this.props.reportStats(satellite, start, end);
      // const diff = moment(e[1]._d).diff(moment(e[0]._d), "days"); // this will be used for the image processed bargraph
      // console.log(diff)
    }
  };

  render() {
    const urls = {
      "/": "MISSION MANAGEMENT",
      "/missions_management/mission_reports": "MISSION REPORTS"
    };

    const satelliteOnClick = ({ key }) => {
      this.setState({ satelliteSelected: key });
      message.info(`Selected Satellite: ${key}`);
      this.props.reportStats(key, this.state.startDate, this.state.endDate);
    };

    const satelliteMenu = (
      <Menu onClick={satelliteOnClick} className="panel__title label">
        {this.props.list
          ? Array.from(this.props.list).map(val => (
              <Menu.Item key={val.name} code={val.catalog_no}>
                {val.name}
              </Menu.Item>
            ))
          : ""}
      </Menu>
    );

    const emptyPieChart = (
      <SuccessFailPieChart
        success={0}
        fail={0}
        pending={0}
        cancel={0}
        total={0}
      />
    );

    return (
      <Fragment>
        <BreadCrumbComponent data={urls} titlePage="Mission Reports" />
        <div style={{ float: "right", position: "relative", top: "-60px" }}>
          <Button
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              marginRight: "10px"
            }}
            onClick={e => {
              this.props.reportStats(this.state.satelliteSelected);
              this.setState({
                startDate: "",
                endDate: ""
              });
            }}
          >
            All
          </Button>
          <RangePicker
            defaultValue={[
              moment(new Date(), dateFormat),
              moment(new Date(), dateFormat)
            ]}
            format={dateFormat}
            onChange={this.rangePickerChangeHandler}
          />
        </div>
        <div>
          <Dropdown overlay={satelliteMenu} trigger={["click"]}>
            <Button
              type="link"
              className="ant-dropdown-link"
              style={{ color: "black" }}
            >
              {this.state.satelliteSelected} <Icon type="down" />
            </Button>
          </Dropdown>
        </div>
        {this.props.missionStatus ? (
          <Row gutter={[16, 16]} style={{ marginTop: "20px" }} type="flex">
            <Col sm={24} md={12}>
              <div className="panel" style={{ height: "100%" }}>
                <div className="panel__title label">ACQUISITIONS</div>
                {this.props.missionStatus.Acquisition ? (
                  <SuccessFailPieChart
                    success={
                      this.props.missionStatus.Acquisition.stats.Successful?.count
                    }
                    fail={
                      this.props.missionStatus.Acquisition.stats.Failed.count
                    }
                    pending={
                      this.props.missionStatus.Acquisition.stats.Pending.count
                    }
                    cancel={
                      this.props.missionStatus.Acquisition.stats.Cancelled.count
                    }
                    total={this.props.missionStatus.Acquisition.total}
                  />
                ) : (
                  emptyPieChart
                )}
              </div>
            </Col>
            <Col sm={24} md={12}>
              <div className="panel" style={{ height: "100%" }}>
                <div className="panel__title label">
                  SUMMARY OF DIWATA MISSION ACTIVITIES
                </div>
                <Row gutter={16} style={{ marginTop: "20px" }}>
                  {this.props.missionTypeStatus ? (
                    <MissionActPieChart
                      routineOps={
                        this.props.missionTypeStatus.stats["Routine Ops"].count
                      }
                      specialOpsDisaster={
                        this.props.missionTypeStatus.stats[
                          "Special Ops - Disaster"
                        ].count
                      }
                      specialOpsRequest={
                        this.props.missionTypeStatus.stats[
                          "Special Ops - Requests"
                        ].count
                      }
                      calibrationTest={
                        this.props.missionTypeStatus.stats[
                          "Calibration and Tests"
                        ].count
                      }
                      housekeepingOps={
                        this.props.missionTypeStatus.stats["Housekeeping Ops"]
                          .count
                      }
                      anomalyOps={
                        this.props.missionTypeStatus.stats["Anomaly Ops"].count
                      }
                      none={
                        this.props.missionTypeStatus.stats.None
                          ? this.props.missionTypeStatus.stats.None.count
                          : 0
                      }
                      total={this.props.missionTypeStatus.total}
                    />
                  ) : (
                    ""
                  )}
                </Row>
              </div>
            </Col>
            <Col sm={24} md={12}>
              <div className="panel" style={{ height: "100%" }}>
                <div className="panel__title label">UPLOADS</div>
                {this.props.missionStatus.Upload ? (
                  <SuccessFailPieChart
                    success={
                      this.props.missionStatus.Upload.stats.Successful.count
                    }
                    fail={this.props.missionStatus.Upload.stats.Failed.count}
                    pending={
                      this.props.missionStatus.Upload.stats.Pending.count
                    }
                    cancel={
                      this.props.missionStatus.Upload.stats.Cancelled.count
                    }
                    total={this.props.missionStatus.Upload.total}
                  />
                ) : (
                  emptyPieChart
                )}
              </div>
            </Col>
            <Col sm={24} md={12}>
              <div className="panel" style={{ height: "100%" }}>
                <div className="panel__title label">DOWNLOADS</div>
                {this.props.missionStatus.Download ? (
                  <SuccessFailPieChart
                    success={
                      this.props.missionStatus.Download.stats.Successful?.count
                    }
                    fail={this.props.missionStatus.Download.stats.Failed.count}
                    pending={
                      this.props.missionStatus.Download.stats.Pending.count
                    }
                    cancel={
                      this.props.missionStatus.Download.stats.Cancelled.count
                    }
                    total={this.props.missionStatus.Download.total}
                  />
                ) : (
                  emptyPieChart
                )}
              </div>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Row gutter={16} style={{ marginTop: "20px" }} type="flex">
          <Col sm={24} md={12}>
            <PercentCovered
              satellite={this.state.satelliteSelected}
              start={this.state.startDate}
              end={this.state.endDate}
            />
          </Col>
          <Col sm={24} md={12}>
            <div style={{ height: "100%" }}>
              <div className="panel">
                <div className="panel__title label">
                  IMAGES PROCESSED PER MONTH
                </div>
                <i
                  className="fas fa-square"
                  style={{
                    color: "#7BD1AA",
                    paddingRight: "10px",
                    paddingBottom: "20px"
                  }}
                />
                Processed Image
                <BarChart
                  satellite={this.state.satelliteSelected}
                  start={this.state.startDate}
                  end={this.state.endDate}
                />
              </div>
              <Row gutter={16} style={{ marginTop: "20px" }} type="flex">
                <Col sm={24} md={12}>
                  <div className="panel" style={{ height: "100%" }}>
                    <div className="panel__title label">
                      PERCENTAGE OF DOWNLOADED & CAPTURED
                    </div>
                    {this.props.captureStatus ? (
                      <DLCapturedPieChart
                        downloaded={
                          this.props.captureStatus.Downloaded.count +
                          this.props.captureStatus.Successful.count
                        }
                        captured={this.props.captureStatus.Captured.count}
                        total={this.downloaded + this.captured}
                      />
                    ) : (
                      <DLCapturedPieChart
                        downloaded={0}
                        capture={0}
                        total={0}
                      />
                    )}
                  </div>
                </Col>
                <Col sm={24} md={12}>
                  <div className="panel" style={{ height: "100%" }}>
                    <div className="panel__title label">
                      BREAKDOWN OF CAUSES & FAILURES
                    </div>
                    {this.props.breakdownStatus ? (
                      <BreakdownPieChart
                        fail={
                          this.props.breakdownStatus.UploadAttempts
                            .failure_stats.failure_per_status.Failed.count
                        }
                        cancel={
                          this.props.breakdownStatus.UploadAttempts
                            .failure_stats.failure_per_status.Cancelled.count
                        }
                        // others={0} // not final
                        total={this.fail + this.cancel}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    missionStatus: state.report.missionStats,
    captureStatus: state.report.captureStats,
    breakdownStatus: state.report.breakdownStats,
    missionTypeStatus: state.report.missionTypeStats,
    list: state.list.listSatellite
  };
};

export default connect(mapStateToProps, actions)(ReportsPage);
