import React from "react";

const tableIndexStyle = text => {
  return {
    props: {
      style: {
        background: "rgb(247, 247, 247)",
        color: "rgba(5, 5, 5, 0.418)"
      }
    },
    children: <div className="table-index-number">{text}</div>
  };
};

export default tableIndexStyle;
