import React, { Component } from "react";
import Leaflet from "leaflet";
import {
  Map,
  CircleMarker,
  TileLayer,
  Tooltip,
  Marker
  // GeoJSON
} from "react-leaflet";
import { connect } from "react-redux";
import * as actions from "../../Actions";
import { iconSatellite } from "./iconSatellite";
import "leaflet/dist/leaflet.css";
import "../../Styles/Components/grsComponent.css";
import Spinner from "../Layout/Spinner";

// let clickedLatLng = { lat: null, lng: null };

// function that creates the popup content
// function createPopupContent(values) {
//   return function() {
//     return `${clickedLatLng.lat.toFixed(4)}, ${clickedLatLng.lng.toFixed(4)}`;
//   };
// }

// function that is called when line is clicked
// function onLineClick(e) {
//   // e is a leaflet Event object
//   // eslint-disable-next-line no-const-assign
//   clickedLatLng = e.latlng;
// }

class ShowMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      passes: {},
      marker: [],
      center: []
    };
  }

  componentDidMount() {
    this.props.trackSatellite(this.props.satellite);
    this.intervalTrack = setInterval(() => {
      if (this.props.passes === "Error") {
        this.stop();
      }
      this.props.trackSatellite(this.props.satellite);
    }, 300000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.satellite !== this.props.satellite) {
      this.stop();
      this.clearData();
      this.props.trackSatellite(this.props.satellite);
      this.intervalTrack = setInterval(() => {
        if (this.props.passes === "Error") {
          this.stop();
        }
        this.props.trackSatellite(this.props.satellite);
      }, 300000);
    }

    if (prevProps.passes !== this.props.passes) {
      this.passData(this.props.passes);
    }

    if (prevProps.position !== this.props.position) {
      if (Object.entries(this.props.position).length > 0) {
        this.processMarker(this.props.position.data.coordinates);
      }
    }
  }

  clearData() {
    this.props.trackSatellite();
    this.setState({ loading: true, passes: {}, center: [] });
  }

  passData(data) {
    this.setState({
      passes: data,
      loading: false
    });
    if (this.state.center.length > 0) {
      const newCenter = this.props.position.data.coordinates;
      this.setState({ center: [newCenter[1], newCenter[0]] });
    }
  }

  processMarker(data) {
    this.setState({ marker: [data[1], data[0]] });
    if (this.state.center.length === 0) {
      this.setState({ center: [data[1], data[0]] });
    }
  }

  componentWillUnmount() {
    this.stop();
  }

  stop() {
    clearInterval(this.intervalMarker);
    clearInterval(this.intervalTrack);
  }

  render() {
    if (
      Object.entries(this.state.passes).length > 0 &&
      this.state.marker.length > 0 &&
      this.state.center.length > 0
    ) {
      const corner1 = Leaflet.latLng(-90, -200);
      const corner2 = Leaflet.latLng(90, 200);
      const bounds = Leaflet.latLngBounds(corner1, corner2);
      return (
        // <Map
        //   style={{ height: "400px", width: "100%" }}
        //   zoom={1.5}
        //   center={[
        //     this.state.passes.coordinates[0][1],
        //     this.state.passes.coordinates[0][0]
        //   ]}
        //   scrollWheelZoom={false}
        //   maxBoundsViscosity={1.0}
        //   maxBounds={bounds}
        // >
        //   <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        //   <GeoJSON
        //     // stroke={false}
        //     color="red"
        //     data={this.state.passes}
        //     onEachFeature={function(feature, layer) {
        //       layer.on("click", onLineClick);
        //       layer.bindPopup(createPopupContent(feature));
        //     }}
        //   />
        //   <Marker position={this.state.marker} icon={iconSatellite} />
        // </Map>
        <Map
          style={{ height: "400px", width: "100%" }}
          zoom={1.5}
          // center={[
          //   this.state.passes.coordinates[0][1],
          //   this.state.passes.coordinates[0][0]
          // ]}
          center={this.state.center}
          scrollWheelZoom={false}
          maxBoundsViscosity={1.0}
          maxBounds={bounds}
        >
          <TileLayer 
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {this.state.passes.coordinates.map((coordinates, index) => {
            return (
              <CircleMarker
                key={index}
                center={[coordinates[1], coordinates[0]]}
                radius={0.25}
                fillOpacity={1}
                color="red"
              >
                <Tooltip direction="right" opacity={1}>
                  <pre>
                    {`
Lat: ${coordinates[1].toFixed(4)}
Long: ${coordinates[0].toFixed(4)}
Elev: ${coordinates[2].toFixed(4)}`}
                  </pre>
                </Tooltip>
              </CircleMarker>
            );
          })}
          <Marker position={this.state.marker} icon={iconSatellite} />
        </Map>
      );
    }
    return (
      <div style={{ height: "400px", width: "100%", backgroundColor: "white" }}>
        <Spinner />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    passes: state.passes.trackSatellite,
    position: state.position.positionData
  };
};

export default connect(mapStateToProps, actions)(ShowMap);
