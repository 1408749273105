import React, { Component } from "react";
import { notification } from "antd";

export default function withToast(WrappedComponent) {
  return class extends Component {
    openNotificationWithIcon = (type, title, description) => {
      notification[type]({
        message: title,
        description
      });
    };

    render() {
      // return original react component with additional props
      return (
        <WrappedComponent
          {...this.props}
          openNotification={this.openNotificationWithIcon}
        />
      );
    }
  };
}
