import React, { Fragment } from "react";
import { Col, Input, Row, Select, Checkbox, Form } from "antd";
import UpdateMap from "./UpdateMap";
import BlankMap from "./BlankMap";

const { TextArea } = Input;

const renderError = ({ error, touched }) => {
  let errObj = { status: "", msg: "" };
  if (touched && error) {
    errObj = { status: "error", msg: error };
  }
  return errObj;
};

export const TextInput = ({ input, meta, type }) => {
  const err = renderError(meta);
  return (
    <Fragment>
      <Form.Item validateStatus={err.status} help={err.msg}>
        {/* eslint-disable react/jsx-props-no-spreading */}
        <Input {...input} autoComplete="off" type={type} />
      </Form.Item>
    </Fragment>
  );
};

export const renderInput = ({ input, label, meta, note, type, defval }) => {
  const err = renderError(meta);
  return (
    <Fragment>
      <Col md={5}>
        <label className="label" htmlFor={label}>
          {label}:
        </label>
        <p className="mute">{note}</p>
      </Col>
      <Col md={11}>
        <Row>
          <Form.Item validateStatus={err.status} help={err.msg}>
            {/* eslint-disable react/jsx-props-no-spreading */}
            <Input
              {...input}
              autoComplete="off"
              type={type}
              defaultValue="192"
            />
          </Form.Item>
        </Row>
      </Col>
    </Fragment>
  );
};

export const showDropDown = ({ input, meta, children }) => {
  const err = renderError(meta);
  return (
    <Fragment>
      <Form.Item validateStatus={err.status} help={err.msg}>
        {/* eslint-disable react/jsx-props-no-spreading */}
        <Select {...input} onChange={input.onChange}>
          {children}
        </Select>
      </Form.Item>
    </Fragment>
  );
};

export const renderSelect = ({
  input,
  label,
  meta,
  note,
  children,
  defVal
}) => {
  const err = renderError(meta);
  return (
    <Fragment>
      <Col md={5}>
        <label className="label" htmlFor={label}>
          {label}:
        </label>
        <p className="mute">{note}</p>
      </Col>
      <Col md={11}>
        <Form.Item validateStatus={err.status} help={err.msg}>
          {/* eslint-disable react/jsx-props-no-spreading */}
          <Select defaultValue={defVal} {...input} onChange={input.onChange}>
            {children}
          </Select>
        </Form.Item>
      </Col>
    </Fragment>
  );
};

export const showTextArea = ({ input, meta }) => {
  const err = renderError(meta);
  return (
    <Fragment>
      <Form.Item validateStatus={err.status} help={err.msg}>
        {/* eslint-disable react/jsx-props-no-spreading */}
        <TextArea rows={10} {...input} />
      </Form.Item>
    </Fragment>
  );
};

export const renderTextArea = ({ input, label, meta, note }) => {
  const err = renderError(meta);
  return (
    <Fragment>
      <Col md={5}>
        <label className="label" htmlFor={label}>
          {label}:
        </label>
        <p className="mute">{note}</p>
      </Col>
      <Col md={11}>
        <Form.Item validateStatus={err.status} help={err.msg}>
          {/* eslint-disable react/jsx-props-no-spreading */}
          <TextArea rows={10} {...input} />
        </Form.Item>
      </Col>
    </Fragment>
  );
};

export const renderCheckBox = ({ input, label, meta, note, cbval }) => {
  const err = renderError(meta);
  return (
    <Fragment>
      <Col md={5}>
        <label className="label" htmlFor={label}>
          {label}:
        </label>
        <p className="mute">{note}</p>
      </Col>
      <Col md={10}>
        <Form.Item validateStatus={err.status} help={err.msg}>
          <Checkbox onChange={input.onChange} checked={input.value}>
            {cbval}
          </Checkbox>
        </Form.Item>
      </Col>
    </Fragment>
  );
};

export const renderMap = ({ input, label, meta, note }) => {
  const err = renderError(meta);
  const center = meta.initial ? meta.initial.coordinates[0][0] : null;
  return (
    <Fragment>
      <Col md={5}>
        <label className="label" htmlFor={label}>
          {label}:
        </label>
        <p className="mute">{note}</p>
      </Col>
      <Col md={11}>
        <Form.Item validateStatus={err.status} help={err.msg}>
          {meta.initial ? (
            <UpdateMap center={[center[1], center[0]]} polygon={meta.initial} />
          ) : (
            <BlankMap />
          )}
        </Form.Item>
      </Col>
    </Fragment>
  );
};

export const renderBlankMap = ({ input, label, meta, note }) => {
  const err = renderError(meta);
  return (
    <Fragment>
      <Col md={5}>
        <label className="label" htmlFor={label}>
          {label}:
        </label>
        <p className="mute">{note}</p>
      </Col>
      <Col md={11}>
        <Form.Item validateStatus={err.status} help={err.msg}>
          <BlankMap id="submit" />
        </Form.Item>
      </Col>
    </Fragment>
  );
};
