import React, { Component } from "react";
import { Map, TileLayer, GeoJSON } from "react-leaflet";
import { connect } from "react-redux";
import * as actions from "../../Actions";
import "leaflet/dist/leaflet.css";
import "../../Styles/Components/grsComponent.css";
import Spinner from "../Layout/Spinner";

class CoveredMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: true
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.coverageMapStatus !== this.props.coverageMapStatus) {
      this.processData(this.props.coverageMapStatus);
    }

    if (
      prevProps.SMI !== this.props.SMI ||
      prevProps.HPT !== this.props.HPT ||
      prevProps.ERC !== this.props.ERC ||
      prevProps.satellite !== this.props.satellite ||
      prevProps.start !== this.props.start ||
      prevProps.end !== this.props.end
    ) {
      this.clearData();
    }
  }

  clearData() {
    this.setState({ data: null, loading: true });
  }

  processData(mapData) {
    this.setState({ data: mapData, loading: false });
  }

  render() {
    if (this.state.data && !this.state.loading) {
      return (
        <Map
          style={{ minHeight: "750px", width: `calc(100% + 50px)` }}
          zoom={6}
          center={[12.57513, 122.27081]}
          scrollWheelZoom={false}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <GeoJSON data={this.state.data} />
        </Map>
      );
    }
    return <Spinner />;
  }
}

const mapStateToProps = state => {
  return {
    coverageMapStatus: state.report.coverageMapStats
  };
};

export default connect(mapStateToProps, actions)(CoveredMap);
