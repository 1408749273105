import React, { Component } from "react";
import { Modal, Popover } from "antd";
import { HighlightOutlined } from "@ant-design/icons";
import CommandView from "./CommandView";

export default class CommandModal extends Component {
  state = { visible: false };

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleOk = e => {
    this.setState({
      visible: false
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false
    });
  };

  render() {
    return (
      <>
        <label className="label" htmlFor={this.props.label}>
          {this.props.label}:{" "}
          <Popover
            placement="bottom"
            content={<div style={{ fontSize: "10px" }}>View Highlighted</div>}
          >
            <HighlightOutlined
              style={{ color: "#3BB0E3", marginLeft: "10px" }}
              onClick={this.showModal}
              on
            />
          </Popover>
        </label>
        <Modal
          title="Command Content"
          style={{ top: 20 }}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          width={800}
        >
          <CommandView data={this.props.data} />
        </Modal>
      </>
    );
  }
}
