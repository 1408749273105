import React, { Component } from "react";
// import PropTypes from "prop-types";
import "../../Styles/Components/countdown.css";
import { connect } from "react-redux";
import * as actions from "../../Actions";
// import {passNextGRS} from "../../Actions";

function addLeadingZeros(val) {
  let value = String(val);
  while (value.length < 2) {
    value = `0${value}`;
  }
  return value;
}

class Countdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // days: 0,
      hours: 0,
      min: 0,
      sec: 0
    };

    this.calculateCountdown = this.calculateCountdown.bind(this);
  }

  componentDidMount() {
    // update every second
    this.interval = setInterval(() => {
      const date = this.calculateCountdown(this.props.date);
      if (date) {
        this.setState(date);
      } else {
        this.stop();
      }
      // date ? return this.setState(date) : return this.stop();
    }, 1000);
  }

  componentWillUnmount() {
    this.stop();
  }

  calculateCountdown(endDate) {
    let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

    // clear countdown when date is reached
    if (diff <= 0)
      return this.props.passNextGRS(this.props.satellite, this.props.grs);
    // if (diff <= 0) return window.location.replace("/missions/GRS");

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
      millisec: 0
    };

    // calculate time difference between now and expected date
    if (diff >= 365.25 * 86400) {
      // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) {
      // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) {
      // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = diff;

    return timeLeft;
  }

  stop() {
    clearInterval(this.interval);
  }

  render() {
    const countDown = this.state;
    return (
      <div
        className="Countdown"
        style={{ textAlign: "center", paddingTop: "15px" }}
      >
        {/* <span className="Countdown-col">
          <span className="Countdown-col-element">
              <strong>{addLeadingZeros(countDown.days)}</strong>
              <span>{countDown.days === 1 ? 'Day' : 'Days'}</span>
          </span>
        </span> */}

        <span className="Countdown-col">
          <span className="Countdown-col-element">
            <strong> {addLeadingZeros(countDown.hours)}</strong>
            <span> Hours </span>
          </span>
        </span>

        <span className="Countdown-col">
          <span className="Countdown-col-element">
            <strong>{addLeadingZeros(countDown.min)}</strong>
            <span> Minutes </span>
          </span>
        </span>

        <span className="Countdown-col">
          <span className="Countdown-col-element">
            <strong>{addLeadingZeros(countDown.sec)}</strong>
            <span> Seconds </span>
          </span>
        </span>
      </div>
    );
  }
}

Countdown.defaultProps = {
  date: new Date()
};

const mapStateToProps = state => {
  return {
    passes: state.passes.nextGrs[0]
  };
};

export default connect(mapStateToProps, actions)(Countdown);
