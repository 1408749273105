import { GET_COMMAND_TYPES } from "./types";
import API from "../Components/API";

export const getCommandTypes = () => async dispatch => {
  API.get("/missions_management/command_types?format=json")
    .then(res => {
      dispatch({ type: GET_COMMAND_TYPES, payload: res.data.data });
    })
    .catch(err => console.log(err));
};
