import React, { Component } from "react";
import { Switch } from "antd";
import { connect } from "react-redux";
import * as actions from "../../Actions";

// Components
import CoveredMap from "./CoveredMap";

class PercentCovered extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SMI: true,
      HPT: true,
      ERC: true,
      data: "",
      payload: "SMI, HPT, ERC"
    };
  }

  componentDidMount() {
    this.props.coverageMapStats(
      this.props.satellite,
      "SMI,HPT,ERC",
      this.props.start,
      this.props.end
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.satellite !== this.props.satellite ||
      prevProps.start !== this.props.start ||
      prevProps.end !== this.props.end
    ) {
      this.processPayloads();
    }

    if (prevProps.coverageMapStatus !== this.props.coverageMapStatus) {
      this.processData(this.props.coverageMapStatus);
    }
  }

  processPayloads() {
    const payload = [];
    if (this.state.SMI) {
      payload.push("SMI");
    }
    if (this.state.HPT) {
      payload.push("HPT");
    }
    if (this.state.ERC) {
      payload.push("ERC");
    }
    this.props.coverageMapStats(
      this.props.satellite,
      payload.toString(),
      this.props.start,
      this.props.end
    );
    this.setState({ data: "", payload: payload.toString() });
  }

  processData(mapData) {
    if (mapData.features.length > 0) {
      this.setState({
        data: mapData.features[0].properties.percent_covered.toFixed(2),
        loading: false
      });
    } else {
      this.setState({ data: 0 });
    }
  }

  render() {
    return (
      <div className="panel" style={{ height: "100%" }}>
        <div className="panel__title label">
          PERCENTAGE COVERED IN THE PHILIPPINES
        </div>
        <div style={{ float: "right", position: "relative", top: "-40px" }}>
          <Switch
            style={{ marginBottom: "10px" }}
            checkedChildren="SMI"
            unCheckedChildren="SMI"
            defaultChecked
            onChange={e => {
              this.setState(
                {
                  SMI: e
                },
                () => this.processPayloads()
              );
            }}
          />
          <br />
          <Switch
            style={{ marginBottom: "10px" }}
            checkedChildren="HPT"
            unCheckedChildren="HPT"
            defaultChecked
            onChange={e => {
              this.setState(
                {
                  HPT: e
                },
                () => this.processPayloads()
              );
            }}
          />
          <br />
          <Switch
            checkedChildren="ERC"
            unCheckedChildren="ERC"
            defaultChecked
            onChange={e => {
              this.setState(
                {
                  ERC: e
                },
                () => this.processPayloads()
              );
            }}
          />
        </div>
        <div style={{ fontSize: "40px" }}>{this.state.data}%</div>
        <i
          className="fas fa-square"
          style={{ color: "#7BD1AA", paddingRight: "10px" }}
        />
        {this.props.satellite} {this.state.payload} Coverage
        <div style={{ position: "relative", left: "-25px", bottom: "-25px" }}>
          <CoveredMap
            SMI={this.state.SMI}
            HPT={this.state.HPT}
            ERC={this.state.ERC}
            satellite={this.props.satellite}
            start={this.props.start}
            end={this.props.end}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    coverageMapStatus: state.report.coverageMapStats
  };
};

export default connect(mapStateToProps, actions)(PercentCovered);
