import React from "react";
import { Link } from "react-router-dom";
import Config from "../Config/const";

export default function RouteList() {
  const routes = [
    "/data/dashboard",
    "/data/raw",
    "/data/levelZero",
    "/data/levelOne",
    "/data/captures/update",
    "/data/captures/manage-distribution",
    "/distribution/users",
    "/distribution/downloads",
    "/missions/planning",
    "/missions/GRS",
    "/targets",
    "/targets/add/",
    "/targets/view/:id",
    "/missions/targets/update",
    "/missions/passes/create-command-batch",
    "/missions/passes/update-command-batch",
    "/missions/acquisition",
    "/missions/acquisition/view/:id",
    "/missions/acquisition/add",
    "/missions/upload",
    "/missions/upload/add",
    "/missions/upload/view/:id",
    "/missions/download",
    "/missions/download/add",
    "/missions/download/view/:id",
    "/missions/acu-log-download-missions",
    "/missions/acu-log-download-missions/add",
    "/missions/acu-log-download-missions/view/:id",
    "/missions/reports",
    "/satellite/health",
    "/satellite/beta-angle",
    "/satellite/logs",
    "/satellite/lifetime-prediction"
  ];

  return (
    <div>
      <h3>Route Items</h3>
      <ul>
        {routes.map(e => (
          <li>
            <Link to={e} target="_blank">
              {e}
            </Link>
          </li>
        ))}
      </ul>

      <span>Config version: {Config.config_version}</span>
    </div>
  );
}
