import React, { Component } from "react";
import { Map, CircleMarker, TileLayer, Marker } from "react-leaflet";
import axios from "axios";
import moment from "moment";
import { iconGRS } from "./iconGRS";
import "leaflet/dist/leaflet.css";

function processTimestamp(time) {
  return moment
    .parseZone(time)
    .utc()
    .format()
    .replace(/[&/\\#,+()$~%.'":*?<>{}-]/g, "");
}

class ShowMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passes: []
    };
  }

  async componentDidMount() {
    const startTime = processTimestamp(this.props.riseTime);
    const endTime = processTimestamp(this.props.setTime);
    const response = await axios.get(
      `${process.env.REACT_APP_API_ORBIT}/v1/track/${this.props.satellite}/${startTime}/${endTime}`
    );
    const data = await response.data.data.features.map(info => [
      info.geometry.coordinates[1],
      info.geometry.coordinates[0]
    ]);
    this.setState({ passes: data });
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.riseTime !== this.props.riseTime) {
      const startTime = processTimestamp(nextProps.riseTime);
      const endTime = processTimestamp(nextProps.setTime);
      const response = await axios.get(
        `${process.env.REACT_APP_API_ORBIT}/v1/track/${nextProps.satellite}/${startTime}/${endTime}`
      );
      const data = await response.data.data.features.map(info => [
        info.geometry.coordinates[1],
        info.geometry.coordinates[0]
      ]);
      this.setState({ passes: data });
    }
  }

  render() {
    if (this.state.passes) {
      return (
        <Map
          style={{ height: "300px", width: `calc(100% + 50px)` }}
          zoom={3}
          center={[
            this.props.grsCoordinates.lat,
            this.props.grsCoordinates.lon
          ]}
          scrollWheelZoom={false}
        >
          <TileLayer
            url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {this.state.passes.map((coordinates, index) => {
            return (
              <CircleMarker
                key={index}
                center={coordinates}
                radius={0.5}
                fillOpacity={1}
                color="red"
              />
            );
          })}
          <Marker
            position={[
              this.props.grsCoordinates.lat,
              this.props.grsCoordinates.lon
            ]}
            icon={iconGRS}
          />
        </Map>
      );
    }
    return null;
  }
}

export default ShowMap;
