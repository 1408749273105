import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label
} from "recharts";
import { Empty } from "antd";
import * as actions from "../../Actions";

class OrbitalDecayLineChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <ResponsiveContainer width="100%" height={250}>
        {this.props.data ? (
          <LineChart
            data={this.props.data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis id="xAxis" dataKey="Date(UTCG)" height={50}>
              <Label
                htmlFor="xAxis"
                value="Date(UTCG)"
                offset={10}
                position="insideBottom"
                style={{ textAnchor: "middle", color: "grey" }}
              />
            </XAxis>
            <YAxis id="yAxis">
              <Label
                htmlFor="yAxis"
                angle={-90}
                value="Altitude(km)"
                position="insideLeft"
                style={{ textAnchor: "middle" }}
              />
            </YAxis>
            <Tooltip />

            <Line
              dataKey="Altitude(km)"
              stroke="#E56F6F"
              fill="#1A9E76"
              dot={false}
            />
          </LineChart>
        ) : (
          <Empty description={false} />
        )}
      </ResponsiveContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    decayData: state.decay.decayData
  };
};

export default connect(mapStateToProps, actions)(OrbitalDecayLineChart);
