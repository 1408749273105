import React, { Fragment } from "react";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import AcquisitionForm from "../../Components/MissionsComponents/AcquisitionForm";

const AddAcquisitionMissionPage = () => {
  const urls = {
    "/": "MISSIONS",
    "/missions/acquisition": "ACQUISITION",
    "/add": "ADD"
  };
  return (
    <Fragment>
      <BreadCrumbComponent data={urls} titlePage="Add Acquisition Missions" />
      <AcquisitionForm
        view="add"
        loading={false}
        initialValues={{
          number_of_sets: 192,
          record_interval_seconds: 5,
          status: "Pending"
        }}
      />
    </Fragment>
  );
};

export default AddAcquisitionMissionPage;
