import React from "react";
import {
  Typography,
  Table,
  Divider,
  Tag,
  Form,
  Icon,
  Input,
  Button
} from "antd";

const { Title, Paragraph } = Typography;
const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: text => <a href="/">{text}</a>
  },
  {
    title: "Age",
    dataIndex: "age",
    key: "age"
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address"
  },
  {
    title: "Tags",
    key: "tags",
    dataIndex: "tags",
    render: tags => (
      <span>
        {tags.map(tag => {
          let color = tag.length > 5 ? "geekblue" : "green";
          if (tag === "loser") {
            color = "volcano";
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </span>
    )
  },
  {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <span>
        <a href="/">Invite {record.name}</a>
        <Divider type="vertical" />
        <a href="/">Delete</a>
      </span>
    )
  }
];

const data = [
  {
    key: "1",
    name: "John Brown",
    age: 32,
    address: "New York No. 1 Lake Park",
    tags: ["nice", "developer"]
  },
  {
    key: "2",
    name: "Jim Green",
    age: 42,
    address: "London No. 1 Lake Park",
    tags: ["loser"]
  },
  {
    key: "3",
    name: "Joe Black",
    age: 32,
    address: "Sidney No. 1 Lake Park",
    tags: ["cool", "teacher"]
  }
];

export default function KitchenSink() {
  return (
    <div className="components">
      <Title className="" level={1}>
        Components
      </Title>
      <div className="components__typography">
        <Title className="" level={3}>
          Typography
        </Title>

        <Paragraph>
          You can use AntDesings typography components for titles and
          paragraphs.
        </Paragraph>
        <Paragraph>
          For Chart Labels you can use: .label <br />
          <span className="label">This is a Sample Chart Label</span>
        </Paragraph>

        <Paragraph>
          For Stat numbers Labels you can use: .statNumber <br />
          <span className="statNumber">242</span>
        </Paragraph>

        <Paragraph>
          For Captions you can use: .caption <br />
          <span className="caption">New Users This Month</span>
        </Paragraph>
      </div>
      <hr />
      <div className="components__colors">
        <Title className="" level={3}>
          Colors
        </Title>
        <Paragraph>Current color scheme</Paragraph>
        <div className="colors__cont">
          <div className="colors__item c1"> #FFF</div>
          <div className="colors__item c2"> #f2f2f2</div>
          <div className="colors__item c3"> #707070</div>
          <div className="colors__item c4"> #484848</div>
          <div className="colors__item c5"> #1f1f1f</div>
          <div className="colors__item c6"> #29313c</div>
          <div className="colors__item c7"> #008e4c</div>
          <div className="colors__item c8"> #7bd1aa</div>
          <div className="colors__item c9"> #828fa2</div>
        </div>
      </div>
      <hr />
      <div className="components__tables">
        <Paragraph>Basic Usage of Antd Tables</Paragraph>
        <Title className="" level={3}>
          Tables
        </Title>
        <Table columns={columns} dataSource={data} />
      </div>
      <hr />
      <div className="Form Components">
        <Title className="" level={3}>
          Form Components
        </Title>
        <Paragraph>Basic Usage of Antd Form Components</Paragraph>
        <Form className="login-form">
          <Form.Item>
            <Input
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item>
            <Input
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <a className="login-form-forgot" href="/">
              Forgot password
            </a>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Log in
            </Button>
            Or <a href="/">register now!</a>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
