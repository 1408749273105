/* eslint-disable camelcase */
import React, { Component } from "react";
import PlotlyCommon from "./PlotlyCommon";

export default class PlotlyVoltage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const processData = value => {
      if (value) {
        return value.toFixed(4);
      }
      return value;
    };
    const { dataAll } = this.props;
    const XData = [];

    const YData_3_3v = [];
    const YData_3_3v_low = [];
    const YData_3_3v_high = [];

    const YData_5v = [];
    const YData_5v_low = [];
    const YData_5v_high = [];

    const YData_15v = [];
    const YData_15v_low = [];
    const YData_15v_high = [];

    const YData_neg_15v = [];
    const YData_neg_15v_low = [];
    const YData_neg_15v_high = [];

    const YData_1_8v = [];
    const YData_1_8v_low = [];
    const YData_1_8v_high = [];

    dataAll.forEach(element => {
      XData.push(element.capture_time);

      YData_3_3v.push(processData(element.voltage_3_3v.avg));
      YData_3_3v_low.push(processData(element.voltage_3_3v.min));
      YData_3_3v_high.push(processData(element.voltage_3_3v.max));

      YData_5v.push(processData(element.voltage_5v.avg));
      YData_5v_low.push(processData(element.voltage_5v.min));
      YData_5v_high.push(processData(element.voltage_5v.max));

      YData_15v.push(processData(element.voltage_15v.avg));
      YData_15v_low.push(processData(element.voltage_15v.min));
      YData_15v_high.push(processData(element.voltage_15v.max));

      YData_neg_15v.push(processData(element.voltage_neg_15v.avg));
      YData_neg_15v_low.push(processData(element.voltage_neg_15v.min));
      YData_neg_15v_high.push(processData(element.voltage_neg_15v.max));

      YData_1_8v.push(processData(element.voltage_1_8v.avg));
      YData_1_8v_low.push(processData(element.voltage_1_8v.min));
      YData_1_8v_high.push(processData(element.voltage_1_8v.max));
    });

    return (
      <div style={{ width: "100%" }}>
        <PlotlyCommon
          dataType="Voltage"
          X={XData}
          Y={YData_3_3v}
          yTitle="3_3V"
          xTitle="Capture Time"
          low={YData_3_3v_low}
          high={YData_3_3v_high}
        />
        <PlotlyCommon
          dataType="Voltage"
          X={XData}
          Y={YData_5v}
          yTitle="5V"
          xTitle="Capture Time"
          low={YData_5v_low}
          high={YData_5v_high}
        />
        <PlotlyCommon
          dataType="Voltage"
          X={XData}
          Y={YData_15v}
          yTitle="15V"
          xTitle="Capture Time"
          low={YData_15v_low}
          high={YData_15v_high}
        />
        <PlotlyCommon
          dataType="Voltage"
          X={XData}
          Y={YData_neg_15v}
          yTitle="NEG 15V"
          xTitle="Capture Time"
          low={YData_neg_15v_low}
          high={YData_neg_15v_high}
        />
        <PlotlyCommon
          dataType="Voltage"
          X={XData}
          Y={YData_1_8v}
          yTitle="1_8V"
          xTitle="Capture Time"
          low={YData_1_8v_low}
          high={YData_1_8v_high}
        />
      </div>
    );
  }
}
