import axios from "axios";
import moment from "moment";
import { ORBITAL_DECAY, END_LIFETIME } from "./types";

export const orbitalDecay = satellite => async dispatch => {
  // if (satellite === 41463) {
  const response = await axios.get(
    `https://api.orbit.phl-microsat.xyz/decay/${satellite}`
  );

  const data = response.data.data.map((info, index) => ({
    id: index,
    "Date(UTCG)": moment(info.timestamp).format("YYYY-MM-DD"),
    "Altitude(km)": info.altitude
  }));

  const endLifetime = response.data.data.find(function getFirtInstance(decay) {
    return decay.altitude < 120;
  });

  dispatch({
    type: END_LIFETIME,
    payload: response.data.data[response.data.data.indexOf(endLifetime) - 1]
  });

  dispatch({
    type: ORBITAL_DECAY,
    payload: data
  });
  // } else {
  // dispatch({
  //   type: ORBITAL_DECAY,
  //   payload: null
  // });

  // dispatch({
  //   type: END_LIFETIME,
  //   payload: null
  // });
  // }
};
