import React, { Component } from "react";
import moment from "moment";
import { Button, Row, Col, Popover, Menu, Dropdown, Icon, message } from "antd";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as actions from "../../Actions";

// COMPONENTS
import SHU from "./SHU";
import CurrentPosition from "./CurrentPosition";
import Spinner from "../Layout/Spinner";

const ButtonGroup = Button.Group;

class SatellitePosition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
      copiedSource: false,
      copiedEpoch: false,
      copiedPosition: false,
      selected: "HPT",
      dataType: "address_hex",
      loading: true,
      position: {}
    };
  }

  componentDidMount() {
    this.props.listPayload();
    this.props.shuPayloadList(this.props.satellite);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.satelliteCode !== this.props.satelliteCode ||
      prevProps.satellite !== this.props.satellite
    ) {
      this.clearData();
      this.props.shuPayloadList(this.props.satellite);
    }

    if (prevProps.position !== this.props.position) {
      this.passData(this.props.position);
    }
  }

  clearData() {
    this.setState({ selected: "HPT", dataType: "address_hex", loading: true });
  }

  passData(data) {
    if (Object.entries(data).length > 0) {
      this.setState({ position: data, loading: false });
    }
  }

  dataTypeHandler = e => {
    this.setState({
      dataType: e.target.value
    });
  };

  render() {
    const onClick = ({ key }) => {
      message.info(`Selected Camera: ${key}`);
      this.setState(
        {
          selected: key
        },
        () => {
          this.props.shuTracker(this.props.satellite, this.state.selected);
        }
      );
    };

    const menu = (
      <Menu onClick={onClick} className="panel__title label">
        {this.props.payloadList
          ? Array.from(Object.keys(this.props.payloadList)).map(val => (
              <Menu.Item key={val}>{val}</Menu.Item>
            ))
          : ""}
      </Menu>
    );

    return (
      <div>
        <Row gutter={16} style={{ display: "flex" }}>
          <Col sm={24} md={12}>
            <CurrentPosition satelliteCode={this.props.satelliteCode} />
          </Col>
          <Col sm={24} md={12}>
            <div
              className="panel"
              style={{ paddingBottom: "20px", height: "100%" }}
            >
              <div style={{ paddingBottom: "10px" }}>
                <span className="panel__title label">SHU ADDRESS</span>
                <span className="span_component" style={{ float: "right" }}>
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <Button
                      type="link"
                      className="ant-dropdown-link"
                      style={{ color: "black" }}
                    >
                      {this.state.selected} <Icon type="down" />
                    </Button>
                  </Dropdown>
                </span>
              </div>
              <div>
                <SHU
                  selected={this.state.selected}
                  satellite={this.props.satellite}
                  dataType={this.state.dataType}
                />
              </div>
              <div style={{ textAlign: "center", paddingTop: "20px" }}>
                <ButtonGroup onClick={this.dataTypeHandler}>
                  <Button
                    value="address_hex"
                    type={
                      this.state.dataType === "address_hex" ? "primary" : ""
                    }
                  >
                    Hex
                  </Button>
                  <Button
                    value="address_int"
                    type={
                      this.state.dataType === "address_int" ? "primary" : ""
                    }
                  >
                    Decimal
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </Col>
        </Row>
        <div className="panel" style={{ marginTop: "25px", height: "100%" }}>
          <p className="panel__title label">TLE</p>
          {!this.state.loading ? (
            <div>
              <div style={{ paddingBottom: "20px" }}>
                <strong>Source</strong>
                <br />
                <CopyToClipboard
                  text="https://celestrak.com/NORAD/elements/cubesat.txt"
                  onCopy={() => this.setState({ copiedSource: true })}
                >
                  <Popover
                    content={this.state.copiedSource ? "Copied!" : ""}
                    trigger="click"
                  >
                    <Button type="link" style={{ color: "black" }}>
                      https://celestrak.com/NORAD/elements/cubesat.txt
                    </Button>
                  </Popover>
                </CopyToClipboard>
              </div>
              <div style={{ paddingBottom: "20px" }}>
                <strong>Epoch</strong>
                <br />
                <CopyToClipboard
                  text={
                    this.props.position.meta
                      ? moment(this.props.position.meta.tle.epoch).format(
                          "DD MMMM YYYY, HH:mm:ss Z"
                        )
                      : ""
                  }
                  onCopy={() => this.setState({ copiedEpoch: true })}
                >
                  <Popover
                    content={this.state.copiedEpoch ? "Copied!" : ""}
                    trigger="click"
                  >
                    <Button type="link" style={{ color: "black" }}>
                      {this.props.position.meta
                        ? moment(this.props.position.meta.tle.epoch).format(
                            "DD MMMM YYYY, HH:mm:ss Z"
                          )
                        : ""}
                    </Button>
                  </Popover>
                </CopyToClipboard>
              </div>
              <div>
                <pre
                  style={{
                    color: "white",
                    backgroundColor: "#29313c",
                    padding: "10px",
                    borderRadius: "3px"
                  }}
                >
                  <span style={{ float: "right" }}>
                    <CopyToClipboard
                      text={
                        this.state.position.meta
                          ? `${this.state.position.meta.tle.line1}\n${this.state.position.meta.tle.line2}`
                          : ""
                      }
                      onCopy={() => this.setState({ copied: true })}
                    >
                      <Popover
                        content={this.state.copied ? "Copied!" : ""}
                        trigger="click"
                      >
                        <Button type="link" style={{ color: "#7bd1aa" }}>
                          <Icon type="copy" /> Copy
                        </Button>
                      </Popover>
                    </CopyToClipboard>
                  </span>
                  <br />
                  {this.props.satellite}
                  <br />
                  {this.state.position.meta
                    ? this.state.position.meta.tle.line1
                    : ""}
                  <br />
                  {this.state.position.meta
                    ? this.state.position.meta.tle.line2
                    : ""}
                </pre>
              </div>
            </div>
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    position: state.position.positionData,
    payloads: state.list.listPayload,
    payloadList: state.shu.payloadList
  };
};

export default connect(mapStateToProps, actions)(SatellitePosition);
