import React, { Component } from "react";
import BreadCrumbComponent from "../../Components/Layout/BreadCrumbComponent";
import TargetsTable from "../../Components/TargetsComponents/TargetsTable";

export default class ViewTargetsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const urls = {
      "/": "MISSIONS",
      "/missions_management/targets": "TARGETS"
    };

    return (
      <div>
        <BreadCrumbComponent data={urls} titlePage="Targets" />
        <TargetsTable />
      </div>
    );
  }
}
