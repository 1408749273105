import React, { Component } from "react";

export default class BetaAnglePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div>This is BetaAnglePage</div>;
  }
}
