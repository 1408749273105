import API from ".";

const FileDownload = require('js-file-download');

const downloadFile = (url, filename, resolve, reject) => {

    API.get(url)
        .then((response) => {
            FileDownload(response.data, filename);
        });
};

export default downloadFile;
