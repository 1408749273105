import { SHU_TRACKER, SHU_PAYLOAD_LIST } from "../Actions/types";

const INITIAL_STATE = {
  tracker: null,
  payloadList: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SHU_TRACKER:
      return {
        ...state,
        tracker: action.payload
      };
    case SHU_PAYLOAD_LIST:
      return {
        ...state,
        payloadList: action.payload
      };
    default:
      return state;
  }
}
