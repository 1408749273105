import React, { Component } from "react";
import Plot from "react-plotly.js";

export default class PlotlyCommon extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const shapesArray = [];
    this.props.X.forEach((element, index) => {
      if (this.props.low[index] !== this.props.high[index]) {
        const shapeData = {
          type: "line",
          layer: "below",
          x0: element,
          y0: this.props.low[index],
          x1: element,
          y1: this.props.high[index],
          line: {
            color: "#FFAE42",
            width: 2
          }
        };
        shapesArray.push(shapeData);
      }
    });

    return (
      <div style={{ width: "100%", height: "100%" }}>
        <Plot
          data={[
            {
              x: this.props.X,
              y: this.props.low,
              type: "scatter",
              mode: "markers",
              marker: { color: "#FFAE42", size: "2" },
              name: "Min"
            },
            {
              x: this.props.X,
              y: this.props.high,
              type: "scatter",
              mode: "markers",
              marker: { color: "#FFAE42", size: "2" },
              name: "Max"
            },
            {
              x: this.props.X,
              y: this.props.Y,
              type: "scatter",
              mode: "lines+markers",
              line: { color: "#00d69f" },
              marker: { color: "#17BECF", size: "4" },
              name: "Avg"
            }
          ]}
          layout={{
            showlegend: false,
            hovermode: "x unified",
            title: {
              text: this.props.yTitle,
              yref: "paper",
              y: 1.2,
              x: 0,
              xanchor: "left",
              yanchor: "bottom"
            },
            xaxis: {
              showgrid: false,
              title: {
                text: this.props.xTitle,
                font: {
                  size: 15,
                  color: "#7f7f7f"
                }
              }
            },
            yaxis: {
              rangemode: "tozero",
              title: {
                text: this.props.dataType,
                font: {
                  size: 15,
                  color: "#7f7f7f"
                }
              },
              fixedrange: true
            },
            autosize: true,
            shapes: shapesArray
          }}
          useResizeHandler
          style={{
            width: "100%",
            height: "300px",
            x: 0,
            xanchor: "left",
            y: 0,
            yanchor: "top"
          }}
          tracetoggle={false}
          config={{
            displayModeBar: false,
            responsive: true
          }}
        />
      </div>
    );
  }
}
