import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Card,
  Form,
  Button,
  Col,
  Select,
  Popover,
  Icon,
  Spin,
  Alert
} from "antd";
import { Field, reduxForm, FieldArray } from "redux-form";
import moment from "moment";
import { connect } from "react-redux";
import * as actions from "../../Actions";
import {
  renderInput,
  renderSelect,
  renderTextArea,
  renderCheckBox
} from "./MissionFormComponents";
import API from "../API/index";
import renderCameras from "./MissionCamerasComponent";
import DatePickerWithTimezone from "./DatePickerWithTimezone";
import Const from "../../Config/const";
import history from "../../history";
import downloadFile from "../API/downloadFile";
import CommandView from "./CommandView";
import CapturesTable from "./CapturesTable";

const { Option } = Select;

class AcquisitionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: "",
      loadingSubmit: false
    };
  }

  componentDidMount() {
    this.props.listSatellite();
    this.props.listMissionTypes();
    this.props.listPointingModes();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.satelliteList !== this.props.satelliteList) {
      this.passData("satelliteList", this.props.satelliteList);
    }
    if (prevProps.missionTypesList !== this.props.missionTypesList) {
      this.passData("missionTypesList", this.props.missionTypesList);
    }
    if (prevProps.pointingModesList !== this.props.pointingModesList) {
      this.passData("pointingModesList", this.props.pointingModesList);
    }
  }

  passData(dataType, data) {
    switch (dataType) {
      case "satelliteList":
        if (!this.state.satelliteList) {
          this.setState({ satelliteList: data });
        }
        break;
      case "missionTypesList":
        if (!this.state.missionTypesList) {
          this.setState({ missionTypesList: data });
        }
        break;
      case "pointingModesList":
        if (!this.state.pointingModesList) {
          this.setState({ pointingModesList: data });
        }
        break;
      default:
        break;
    }
  }

  processValues = data => {
    if (data.mission_cameras) {
      const cams = data.mission_cameras;
      for (let i = 0; i < cams.length; i++) {
        const a = cams[i].wavelengths_nir
          ? cams[i].wavelengths_nir.toString()
          : "";
        cams[i].wavelengths_nir = a;
      }
      for (let b = 0; b < cams.length; b++) {
        const x = cams[b].wavelengths_vis
          ? cams[b].wavelengths_vis.toString()
          : "";
        cams[b].wavelengths_vis = x;
      }
    }

    if (data.satellite === "Diwata-2") {
      data.aru_on = true;
      data.aru_on_time = moment().format();
      data.aru_off_time = moment().format();
    }

    if (data.mission_type === null || data.mission_type === "") {
      data.mission_type = null;
    }

    if (!data.acu_rec_start) {
      data.acu_rec_start = false;
    }

    return data;
  };

  onSubmit = formValues => {
    const freezeData = JSON.parse(JSON.stringify(formValues));
    this.setState({
      loadingSubmit: true
    });
    if (this.props.view === "add") {
      API.post("/missions_management/missions/", this.processValues(freezeData))
        .then(res => {
          this.setState({
            loadingSubmit: false
          });
          alert("Acquisition Mission Added");
          history.push(
            `/missions_management/acquisition_missions/view/${res.data.id}`
          );
        })
        .catch(err => {
          this.setState({
            loadingSubmit: false
          });
          alert("Oops, Something went wrong!");
          console.log(err);
        });
    } else {
      API.patch(
        `/missions_management/missions/${this.props.missionId}/`,
        this.processValues(freezeData)
      )
        .then(res => {
          this.setState({
            loadingSubmit: false
          });
          alert("Acquisition Mission Edited");
          history.push(
            `/missions_management/acquisition_missions/view/${res.data.id}`
          );
        })
        .catch(err => {
          this.setState({
            loadingSubmit: false
          });
          alert("Oops, Something went wrong!");
          console.log(err);
        });
    }
  };

  render() {
    const commandVal = this.props.initialValues.command
      ? this.props.initialValues
      : {};
    const ccontent =
      Object.entries(commandVal).length !== 0
        ? commandVal.command.content
        : "-----";
    const cExecTime =
      Object.entries(commandVal).length !== 0
        ? commandVal.command.execution_time
        : "None";

    const secNo =
      Object.entries(commandVal).length !== 0
        ? commandVal.command.section_number
        : "None";

    const commandCsvLink =
      Object.entries(commandVal).length !== 0
        ? commandVal.command.download_link_csv
        : "#";

    const int = value => parseInt(value);
    const float = value => parseFloat(value);
    return (
      <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Spin spinning={this.props.loading || this.state.loadingSubmit}>
          <Card title="Acquisition Parameters">
            <Row>
              <Field
                name="name"
                component={renderInput}
                label="Name"
                type="text"
              />
            </Row>
            <Row>
              <Field
                name="capture_time"
                component={DatePickerWithTimezone}
                label="Capture Time"
                note="You are 8 hours ahead of server time."
              />
            </Row>
            <Row>
              <Field
                name="image_count"
                component={renderInput}
                label="Image Count"
                type="number"
                normalize={int}
              />
            </Row>
            <Row>
              <Field
                name="frame_interval"
                component={renderInput}
                label="Frame Interval"
                type="number"
                note="Milliseconds"
                normalize={float}
              />
            </Row>
            <Row>
              <Field
                name="target_lat"
                component={renderInput}
                label="Target Latitude"
                type="number"
                normalize={float}
              />
            </Row>
            <Row>
              <Field
                name="target_lng"
                component={renderInput}
                label="Target Longitude"
                type="number"
                normalize={float}
              />
            </Row>
            <Row>
              <Field
                name="mission_type"
                component={renderSelect}
                label="Mission Type"
              >
                <Option key={0} value="">
                  ------
                </Option>
                {this.state.missionTypesList
                  ? this.state.missionTypesList.map((data, index) => (
                      <Option key={data.id} value={data.name}>
                        {data.name}
                      </Option>
                    ))
                  : ""}
              </Field>
            </Row>
            <Row>
              <Field
                name="satellite"
                component={renderSelect}
                label="Satellite"
              >
                <Option key={0} value="">
                  ------
                </Option>
                {this.state.satelliteList
                  ? this.state.satelliteList.map((data, index) => (
                      <Option key={data.id} value={data.name}>
                        {data.name}
                      </Option>
                    ))
                  : ""}
              </Field>
            </Row>
            <Row>
              <Field
                name="status"
                component={renderSelect}
                label="Status"
                defVal="Pending"
                note="Kindly input remarks for Failed/Cancelled missions."
              >
                {Const.mission.acquisition.addMissionFilterStats.map(data => (
                  <Option key={data} value={data.val}>
                    {data.text}
                  </Option>
                ))}
              </Field>
            </Row>
            <Row>
              <Field
                name="remarks"
                component={renderTextArea}
                label="Remarks"
              />
            </Row>
            <Row>
              <Field
                name="pointing_mode"
                component={renderSelect}
                label="Pointing Mode"
              >
                <Option key={0} value="">
                  ------
                </Option>
                {this.state.pointingModesList
                  ? this.state.pointingModesList.map((data, index) => (
                      <Option key={data.id} value={data.name}>
                        {data.name}
                      </Option>
                    ))
                  : ""}
              </Field>
            </Row>
          </Card>

          <Card className="m-t-10px" title="D2-Specific Settings">
            <Row>
              <Field
                name="sunshine_time"
                component={DatePickerWithTimezone}
                label="Sunshine Time"
                note="You are 8 hours ahead of server time."
              />
            </Row>
            <Row className="form-row">
              <Field
                note="Select to include ACU-REC-START and STOP commands"
                name="acu_rec_start"
                component={renderCheckBox}
                label="Acu rec start"
              />
            </Row>
            <Row>
              <Field
                name="acu_log_segment"
                component={renderSelect}
                label="Acu Log Segment"
              >
                {Const.segments.map(data => (
                  <Option key={data} value={data.val}>
                    {data.text}
                  </Option>
                ))}
              </Field>
            </Row>
            <Row>
              <Field
                label="Number of sets"
                name="number_of_sets"
                note="Standard number of sets: 192 or 384"
                component={renderInput}
                type="number"
                normalize={int}
                defval="192"
              />
            </Row>
            <Row>
              <Field
                label="Record interval seconds"
                name="record_interval_seconds"
                note="Standard record interval: 1, 2, 5, or 20 seconds"
                component={renderInput}
                type="number"
                normalize={int}
              />
            </Row>
          </Card>

          <Card className="m-t-10px" title="Generated Command">
            <Row style={{ marginTop: "20px" }}>
              <Col md={5}>
                <span className="label">Command Execution Time:</span>
              </Col>
              <Col md={10}>{cExecTime}</Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
              <Col md={5}>
                <span className="label">Command: </span>
              </Col>
              <Col md={10}>
                {this.props.initialValues.command ? (
                  <Popover content="Edit Command">
                    <Link
                      to={`/missions_management/commands/view/${this.props.initialValues.command.id}`}
                      target="_blank"
                    >
                      {secNo} <Icon type="edit" />
                    </Link>
                  </Popover>
                ) : (
                  secNo
                )}
              </Col>
            </Row>

            <Row style={{ marginTop: "20px" }}>
              <Col md={5}>
                <span className="label">Download: </span>
              </Col>
              <Col md={10}>
                <Button
                  onClick={() =>
                    // eslint-disable-next-line prefer-template
                    downloadFile(commandCsvLink, secNo + "-Acquisition.csv")
                  }
                >
                  <Icon type="copy" /> CSV
                </Button>
              </Col>
            </Row>

            <Row style={{ marginTop: "20px" }}>
              <Col md={5}>
                <span className="label">Command String: </span>
              </Col>
              <Col>
                <CommandView data={ccontent} />
              </Col>
            </Row>
          </Card>

          <Card className="m-t-10px" title="Information">
            <Row>
              <Alert
                showIcon
                message="Diwata-1 Standard Combinations: [SMI], [SMI+MFC], [SMI+HPT],
              [MFC], [MFC+HPT], and [WFC]."
                type="info"
              />
            </Row>
          </Card>

          <Card className="m-t-10px" title="Mission Cameras">
            <FieldArray name="mission_cameras" component={renderCameras} />
          </Card>

          <button className="ant-btn ant-btn-primary m-t-10px" type="submit">
            Submit
          </button>

          {this.props.missionId ? (
            <Card className="m-t-20px" title="Captures">
              <CapturesTable missionId={this.props.missionId} />
            </Card>
          ) : (
            ""
          )}
        </Spin>
      </Form>
    );
  }
}

const validate = formValues => {
  const errors = {};
  if (!formValues.name) {
    errors.name = "You must enter a name";
  }
  if (!formValues.capture_time) {
    errors.capture_time = "You must enter a Capture time";
  }
  if (!formValues.image_count) {
    errors.image_count = "You must enter an image count";
  }
  if (!formValues.frame_interval) {
    errors.frame_interval = "You must enter a frame interval";
  }
  if (!formValues.target_lat) {
    errors.target_lat = "You must enter a target latitude";
  }
  if (!formValues.target_lon) {
    errors.target_lon = "You must enter a target longitude";
  }
  if (!formValues.satellite) {
    errors.satellite = "You must pick a satellite";
  }
  if (!formValues.status) {
    errors.status = "You must pick a status";
  }
  // if (!formValues.remarks) {
  //   errors.remarks = "You must put a remark";
  // }
  if (!formValues.pointing_mode) {
    errors.pointing_mode = "You must select a pointing mode";
  }
  // if (!formValues.sunshine_time) {
  //   errors.sunshine_time = "You must pick a sunshine time";
  // }
  if (!formValues.mission_cameras || !formValues.mission_cameras.length) {
    errors.mission_cameras = "Required";
  }

  return errors;
};

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues, // retrieve name from redux store
  satelliteList: state.list.listSatellite,
  missionTypesList: state.list.listMissionTypes,
  pointingModesList: state.list.listPointingModes
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "acquisitionForm",
    validate,
    enableReinitialize: true
  })(AcquisitionForm)
);
