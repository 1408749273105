import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label
} from "recharts";
import API from "../API";
import Spinner from "../Layout/Spinner";

export default class Example extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      stats: null
    };
  }

  componentDidMount() {
    this.fetchData(this.props.satellite, this.props.start, this.props.end);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.satellite !== prevProps.satellite ||
      this.props.start !== prevProps.start ||
      this.props.end !== prevProps.end
    ) {
      this.clearData();
      this.fetchData(this.props.satellite, this.props.start, this.props.end);
    }
  }

  clearData() {
    this.setState({ stats: null });
  }

  fetchData(satellite, start, end) {
    if (start && end) {
      // FILTER BY SATELLITE AND DATE RANGE
      API.get(
        `data_management/image_processed_stats?satellite=${satellite}&from:capture_time=${start}&to:capture_time=${end}`
      )
        .then(res => {
          this.setState({
            stats:
              res.data[Object.keys(res.data)[0]].ImageProcessed
                .per_product_by_date.daily
          });
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      // FILTER BY SATELLITE ONLY
      API.get(`data_management/image_processed_stats?satellite=${satellite}`)
        .then(res => {
          this.setState({
            stats:
              res.data[Object.keys(res.data)[0]].ImageProcessed
                .per_product_by_date.daily
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  render() {
    if (this.state.stats) {
      const data = Object.keys(this.state.stats).map(entry => {
        return { Date: entry, Images: this.state.stats[entry].count };
      });

      return (
        <ResponsiveContainer width="100%" height={350}>
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5
            }}
            isloading
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis id="xAxis" dataKey="Date" height={50}>
              <Label
                htmlFor="xAxis"
                value="Date"
                offset={10}
                position="insideBottom"
              />
            </XAxis>
            <YAxis id="yAxis">
              <Label
                htmlFor="yAxis"
                angle={-90}
                value="Images"
                position="insideLeft"
                style={{ textAnchor: "middle" }}
              />
            </YAxis>
            <Bar dataKey="Images" fill="#7bd1aa">
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill="#7bd1aa" />
              ))}
            </Bar>
            <Tooltip />
          </BarChart>
        </ResponsiveContainer>
      );
    }
    return <Spinner />;
  }
}
